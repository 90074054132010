import { useState } from 'react';

export const ReadMore = ({ id, text, amountOfWords = 80 }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  
  // Split text into paragraphs by double new lines and handle <br> by replacing them with line breaks
  const paragraphs = text.split('\n\n');

  // Handle the initial displayed text (before "Read more")
  const initialParagraph = paragraphs[0];
  const initialText = initialParagraph.split(' ').slice(0, amountOfWords).join(' ');
  const isOverflow = initialParagraph.split(' ').length > amountOfWords;

  const remainingParagraphs = isOverflow ? paragraphs.slice(1) : paragraphs.slice(1);

  const handleToggle = () => {
    setIsExpanded((prev) => !prev);
  };

  const handleKeyboard = (e) => {
    if (e.code === 'Space' || e.code === 'Enter') {
      handleToggle();
    }
  };

  // Helper function to render HTML safely with <br> tags
  const renderTextWithLineBreaks = (text) => (
    <span dangerouslySetInnerHTML={{ __html: text.replace(/\n/g, '<br />') }} />
  );

  return (
    <div id={id}>
      {/* Display the initial text and handle overflow with "Read more" */}
      <div>
        {!isExpanded ? (
          <>
            <div>
              {renderTextWithLineBreaks(isOverflow ? `${initialText}...` : initialParagraph)}
            </div>
          </>
        ) : (
          <>
            <div>
              {renderTextWithLineBreaks(initialParagraph)}
            </div>
            {remainingParagraphs.map((paragraph, index) => (
              <div key={index}>
                <div>{renderTextWithLineBreaks(paragraph)}</div>
              </div>
            ))}
          </>
        )}
      </div>

      {/* Read More/Read Less button */}
      {(isOverflow || remainingParagraphs.length > 0) && (
        <div className="read-more-container">
          <span
            className='read-more-button'
            role="button"
            tabIndex={0}
            aria-expanded={isExpanded}
            aria-controls={id}
            onKeyDown={handleKeyboard}
            onClick={handleToggle}
          >
            {isExpanded ? 'Read less' : 'Read more'}
          </span>
        </div>
      )}
    </div>
  );
};

export default ReadMore;
