const why_turbo_content =[
    {
      "title": "Be Among the First 100",
      "description": "Secure your spot now and enjoy the exclusive perks of early access. Once these limited spots are filled, the opportunity disappears, and pricing adjusts."
    },
    {
      "title": "What Early Access Offers",
      "description": "• Special early access program pricing for the duration of the program. • Full access to all features, ensuring you experience the AI magic firsthand.\n• Direct engagement with our product and engineering team to shape the future of Flow Turbo."
    },
    {
      "title": "Why Join Early?",
      "description": "• Shape the Future: Your feedback matters, influencing the evolution of Flow Turbo. • Exclusive Promo: Benefit from launch pricing available only during early access."
    },
    {
      "title": "Time is of the Essence",
      "description": "• Don’t miss your chance. Early Access is a limited-time offer.'\n'• Join the select few who pioneer a new era in AI sales prospecting."
    },
    {
      "title": "Once-in-a-Lifetime Pricing",
      "description": "Lock in your early access program pricing now. As soon as the first 100 accounts are claimed, pricing adjusts to reflect post-program pricing."
    },
    {
      "title": "Act Now – Seize Your Spot!",
      "description": "Click below to secure your exclusive pricing and embark on a sales journey transformed by the power of Flow Turbo. Limited spots available – don’t miss out!"
    }
  ];
  
export default why_turbo_content;
