import React from 'react';
import Footer from '../components/Footer';
import '../assets/css/Resource.css';

import { Link } from 'react-router-dom';

const Resource = () => {
  return (
    <div>
      <div className='resource_container fadeInAnimation'>
        <h1 className='header_text'>Resources Page</h1>
        <p>Resources designed for organizations like yours</p>
        <Link to="/contact/sales-support" className='btn_link'><div className='green_btn'>Schedule Strategy Session</div></Link>

        <h2>Welcome to the Flow Resources Hub</h2>
        <p>Your go-to destination for valuable insights, expert guidance, and in-depth knowledge about our innovative AI-driven platform. Explore a variety of resources designed to empower you on your journey towards sales excellence.</p>

        <div className="resource-section">
          <h3>Product Briefs</h3>
          <p>Dive into our product briefs to get a quick overview of the key features and functionalities of Flow Turbo. Discover how our platform 
            can revolutionize your sales prospecting process and drive success for your business.</p>
          <ul>
            <li>Product Brief: AI Account Selection</li>
            <li>Product Brief: AI Flat Files</li>
            <li>Product Brief: Integration with Third-Party Tools</li>
          </ul>
        </div>

        <div className="resource-section">
          <h3>Whitepapers</h3>
          <p>Explore our whitepapers for a deeper understanding of the technologies, methodologies, and AI algorithms powering Flow Turbo. 
            Gain insights into industry trends, best practices, and the future of AI in sales prospecting.</p>
          <ul>
            <li>Whitepaper: The Role of AI in Modern Sales</li>
            <li>Whitepaper: Enhancing CRM with AI Integration</li>
            <li>Whitepaper: Webhooks and Real-time Updates</li>
          </ul>
        </div>

        <div className="resource-section">
          <h3>Blogs</h3>
          <p>Stay informed about the latest trends, tips, and industry news through our insightful blogs. Our experts share their knowledge on AI, 
            sales prospecting, and the evolving landscape of B2B sales.</p>
          <ul>
            <li>The Power of AI in Account Selection</li>
            <li>Streamlining Sequencing Workflows with AI</li>
            <li>Customization Techniques for Optimal AI Performance</li>
          </ul>
        </div>

        <div className="resource-section">
          <h3>Webinars</h3>
          <p>Participate in our webinars to engage with industry experts, learn best practices, and explore the features of [AI Company's Name] in 
            real-time. Stay connected with our community and stay ahead in the world of AI-driven sales prospecting.</p>
          <ul>
            <li>Upcoming Webinars</li>
            <li>Webinar Archive</li>
          </ul>
        </div>

        <div className="resource-section">
          <h3>Customer Stories and Testimonials</h3>
          <p>Discover how businesses like yours have achieved success with [AI Company's Name]. Read customer stories and testimonials to 
            learn about their experiences, challenges, and the transformative impact of our platform.</p>
          <ul>
            <li>Customer Success Stories</li>
            <li>Testimonials</li>
          </ul>
        </div>

        <div className="resource-section">
          <h3>Support Documentation</h3>
          <p>Access our comprehensive support documentation for step-by-step guides, troubleshooting tips, and FAQs. Ensure a smooth 
            experience with [AI Company's Name] by leveraging our detailed resources.</p>
          <ul>
            <li>User Documentation</li>
            <li>Developer Documentation</li>
          </ul>
        </div>

        <div className="resource-section">
          <h3>Infographics and Guides</h3>
          <p>Visualize complex concepts and workflows with our infographics. Download guides to help you navigate through different features of 
            [AI Company's Name] effortlessly.</p>
          <ul>
            <li>AI Selection Workflow Infographic</li>
            <li>Flat Files Feature Guide</li>
            <li>Integration Quick Reference Guide</li>
          </ul>
        </div>

        <div className="resource-section">
          <h3>Videos</h3>
          <p>Watch our videos for hands-on demonstrations, tutorials, and success stories. Subscribe to our YouTube channel for the latest updates 
            and video releases.</p>
          <ul>
            <li>Video: Getting Started with [AI Company's Name]</li>
            <li>Video: AI in Action - Real Use Cases</li>
            <li>Video Series: Mastering [AI Company's Name]</li>
          </ul>
        </div>

        <p>We're committed to providing you with a wealth of resources to enhance your experience with [AI Company's Name]. If you have 
          specific questions or topics you'd like us to cover, feel free to reach out through our Flow Community.</p>
          <Link to="/contact/sales-support" className='btn_link'><div className='green_btn'>Schedule Strategy Session</div></Link>
      </div>
      <Footer />
    </div>
  );
};

export default Resource;
