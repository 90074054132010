import { Link } from "react-router-dom";
import "./BillingManagementStyles.css";

function BillingProfiles() {
  const profiles = [
    {
      company: "USD-DEFERRED",
      subscriptions: 0,
    },
    {
      company: "company-organization",
      subscriptions: 4,
    },
  ];

  return (
    <>
      <h2 className="font-light text-lg mb-5">Admin/company-organization</h2>
      <h1 className="font-extrabold text-4xl mb-8">Manage Billing Profiles</h1>
      <h2 className="font-light text-lg mb-10">
        View subscriptions that are billed together, and manage their linked
        payment methods and addresses
      </h2>
      <div className="billing-profiles">
        {profiles.map((profile, index) => (
          <div className="billing-profile" key={index}>
            <div className="profile-company">{profile.company}</div>
            <div className="profile-subs">
              <h2>{profile.subscriptions} Subscriptions</h2>
              <Link className="manage-profile">Manage</Link>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default BillingProfiles;
