import "./BillingManagementStyles.css";

function BillingPermissions() {
  return (
    <>
      <h2 className="font-light text-lg mb-5">Admin/company-organization</h2>
      <h1 className="font-extrabold text-4xl mb-8">Billing Permissions</h1>
      <h2 className="font-light text-lg mb-10">
        Billing admins have full access to manage everything in your billing
        account. Business contacts are assigned to individual subscriptions, and
        receive notifications. You can add business contacts from the
        subscription page.
      </h2>

      <table className="mb-10">
        <thead>
          <tr>
            <th className="table-heading-permissions w-[50%]">Admin</th>
            <th className="table-heading-permissions w-[15%]">Role</th>
            <th className="table-heading-permissions w-[15%]"></th>
            <th className="table-heading-permissions w-[10%]">Access level</th>
            <th className="table-heading-permissions w-[10%]">Actions</th>
          </tr>
        </thead>
        <tbody>
          {/* Below is where the data goes */}
          <tr className="border-b-2">
            <td className="table-data flex flex-row gap-8 ">
              <div className="rounded-full w-[11%] bg-[#d9d9d9] flex justify-center items-center">
                <h1 className="font-bold text-2xl text-[#707070]">SA</h1>
              </div>
              <div className="flex flex-col text-left">
                <h1 className="font-bold">Flow Turbo</h1>
                <p>https://company-organization.flow.net</p>
              </div>
            </td>
            <td className="table-data ">
              <h1 className="font-semibold">Billing admin</h1>
            </td>
            <td className="table-data ">
              <h1 className="font-semibold">Business contact</h1>
            </td>
            <td className="table-data">
              <p>Billing Account</p>
            </td>
            <td className="table-data">
              <p>Manage</p>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="w-[100%] flex flex-row justify-start align-middle">
        <button>Add new admin</button>
      </div>
    </>
  );
}

export default BillingPermissions;
