import React, { useEffect, useState } from 'react';
import Footer from '../../components/Footer.js';
import product_intro from '../../assets/img/product-intro.png';
//import img1 from '../assets/img/img_product.png';
import why_flow from '../../assets/img/why-flow.png';
import feature_overview from '../../assets/img/feature-overview.png';
import feature_overview2 from '../../assets/img/feature-overview-2.png';
import img2 from '../../assets/img/app_laptop.png';
//import img3 from '../assets/img/img_product2.png';
//import img4 from '../assets/img/img_product3.png';
//import img5 from '../assets/img/img_product4.png';

import '../../assets/css/Product.css';

import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

import { Link } from 'react-router-dom';

import { MacbookScroll } from '../../AcertinityAnimations/MacBookScroll.tsx';

import key_features_content from '../../components/Content/Product-Page/KeyFeaturesContent.js'

import KeyFeatureCard from '../../components/Product/DesktopComponents/KeyFeatureCard.jsx';
import BenefitsCard from '../../components/Product/DesktopComponents/BenefitsCard.jsx';
import BenefitsCardComponent from '../../components/Product//DesktopComponents/BenefitsCardComponent.jsx';
import { TextGenerateEffect } from '../../AcertinityAnimations/TextGenerateEffect.tsx';
import BenefitsComponent from '../../components/Product/MobileComponents/BenefitsComponent.jsx';

const Product = () => {
  const [isOpen, setIsOpen] = useState({ section1: true, section2: false, section3: false, section4: false, section5: false });

  const [keyFeatureList,setKeyFeatureList] = useState(null);

  const toggleSection = (section) => {
    setIsOpen(prevState => ({ ...prevState, [section]: !prevState[section] }));
  };

  useEffect(()=>{
    setKeyFeatureList(key_features_content.map((feature)=><KeyFeatureCard icon={feature.icon} title={feature.title} description={feature.description}></KeyFeatureCard>));
  },[])

  return (
    <div>
      <div className='product'>
        <div className='container1 fadeInAnimation'>
          <div className="centered-text">
            <div className='bold' style={{fontSize: "60pt"}}>Revolutionizing Sales Prospecting with the Flow Turbo SaaS <span style={{ color: 'var(--primary)' }}>AI Platform</span></div>
            <TextGenerateEffect words='Empower Your Sales Team With AI-Driven Sales Prospecting' className='large3'></TextGenerateEffect>
          </div>
        </div>
        <div className='container2 fadeInAnimation'>
          <div className='text1 large2 bold'>To generate more qualified deals and opportunities with your sales team, use Flow Turbo, with the Gray Matter AI Engine™.</div>
          <div className='product-introduction'>
            <div className='large3 text2'>Introducing Flow Turbo: The World's First SaaS AI Sales Prospecting Solution</div>
            <div className='product-introduction-img'><img src={product_intro} alt="img" /></div>
          </div>
          <div className='centerAlign overflow-hidden'>
            <MacbookScroll></MacbookScroll>
          </div>

        </div>
        <div className='container3'>
          {/* <div className='large2 bold'>Guided End-to-End AI Sales Platform</div>
        <div className='large3'>Revolutionizing Sales Excellence: Guided End-to-End AI Sales Platform</div>
        <div className='text3 medium'>Welcome to the future of sales—Flow's Guided End-to-End AI Sales Platform. In an era where success in sales is both an art and a science, we've harnessed the inherent brilliance of great salespeople, transforming it into a repeatable, AI-infused process. The core philosophy is simple yet profound—selling as a science, where every action is meticulously aligned with the fundamental principle of sales success: the numbers game.</div>
        <button className='green_btn medium bold' onClick={() => toggleSection('readMore')} style={{ margin: '20px 0px' }}>Read {isOpen.readMore ? "Less" : "More"}</button>
        {isOpen.readMore && (
          <div className='additional-content'>
            <div className='subcontent1'>
              <div className="row">
                <div className="section">
                  <h2>Precision in Design</h2>
                  <p>Our UI/UX design is not just a visual treat; it's a strategic masterpiece. Meticulously crafted to resonate with the essence of sales dynamics, it empowers sales reps with a consistent pool of 300 AI pre-qualified prospects every day. This principle, deeply woven into the AI architectural design of the product, acknowledges that the quantity and quality of interactions are the linchpin of success in sales. The platform's structure is purpose-built for efficient, targeted outreach—a nod to the established dynamics that govern successful sales strategies.</p>
                </div>
                <div className="section">
                  <h2>Empowering Sales Reps</h2>
                  <p>At the heart of this feature derives from a simple yet powerful truth—more quality and quantity of interactions with more prospects lead to greater success. In our Flow Turbo SaaS AI sales platform, this translates to the Live AI Sales Rep Assistant presenting a daily set of 300 pre-qualified prospects. This revolutionary approach allows sales reps to concentrate on meaningful engagements, eliminating the need to sift through potential prospects or worry about accounts with automatic disqualifications. The design ensures that the focus remains on what truly matters—connecting with high-potential leads.</p>
                </div>
              </div>

              <div className="row">
                <div className="section">
                  <h2>User-Friendly, Insightful, Proactive</h2>
                  <p>For our customers, the platform is not just a sales AI; it's a strategic ally. The intuitive and user-friendly interface, coupled with deep insights, optimizes workflows. Picture a daily set of high-potential prospects at your fingertips, encouraging a proactive and consistent sales approach—the very essence of success in the sales world. Our product's design and functionality revolve around this pivotal principle, ensuring that each interaction is a step closer to unparalleled success.</p>
                </div>
                <div className="section">
                  <h2>Elevate Your Sales Game</h2>
                  <p>The Guided End-to-End AI Sales Platform by Flow is more than a platform; it's a paradigm shift in the way sales excellence is achieved. Elevate your sales game with a platform designed to rewrite the rules of success in sales. Flow is not just a company; it's your partner in the pursuit of sales excellence.</p>
                </div>
              </div>
            </div>
            <div className='subcontent2'>
              <div>
                At the forefront of an AI B2B sales evolution, where innovation meets certainty. Our SaaS AI sales platform is like your live sales rep 
                assistant; it's your strategic companion, meticulously designed to guide sales reps through every step toward unparalleled success. 
                Picture a platform where success is not a hopeful outcome but a planned journey, embedded in the very fabric of the UI/UX design.
              </div>
              <div>
                In the heart of Flow Turbo is the commitment to optimizing the fastest flow day-to-day for sales reps. Take, for instance, our AI 
                Account Selection Page, a revolutionary space where 300 AI pre-qualified contacts are displayed – a direct result of thorough vetting 
                from AI pre-qualified accounts, without automatic disqualifications. This eliminates the guesswork, empowering sales reps to engage 
                in 300 meaningful calls per day, unburdened by impediments or barriers.
              </div>
              <div>
                At Flow, we've harnessed the power of proprietary technologies – AI models, ML algorithms, and NLP – forging a seamless synergy 
                that eliminates the manual and mundane. The result? An end-to-end AI-guided platform that transforms your sales approach. No 
                longer weighed down by obstacles, sales reps can focus on what truly matters – building relationships and closing deals.
              </div>
              <div>
                Flow Turbo isn't just a platform; it's a blueprint for success. Every interaction, and every decision is strategically crafted to enable 
                massive success in sales. This isn't about chance; it's about precision, speed, and strategic guidance converging to redefine the very 
                essence of B2B sales. Welcome to a future where success is not a goal but a virtual certainty – welcome to Flow Turbo. Your journey 
                to unprecedented success in B2B sales starts here.
              </div>
            </div>
            <div className="subcontent3">
              <p>Flow Turbo's innovative approach to guiding sales reps through the correct actions is a testament to the heavy integrations of AI intelligence with an intuitive UI/UX design. The platform's AI-driven features ensure that sales reps perform the optimal actions, with a focus on engaging in at least 300 meaningful calls per day. Here's how the combination of AI and UI/UX design works cohesively to guide sales reps through their daily activities:</p>
              <ul>
                <li>
                  <div className="feature-highlight">
                    <div className="subtitle">AI-Prequalified Contacts:</div>
                    <div className="text">The AI within Flow Turbo goes beyond conventional lead generation. It prequalifies contacts based on a sophisticated processing of information, ensuring that the sales reps are presented with 300 high-potential contacts on the AI Account Selection page. These contacts are meticulously chosen to maximize the chances of successful engagements.</div>
                  </div>
                </li>
                <li>
                  <div className="feature-highlight">
                    <div className="subtitle">Sequencing Tool Sequences:</div>
                    <div className="text">Flow Turbo integrates with an existing number of market-leading third party sequencing tools that orchestrates the entire outreach strategy. The AI, in collaboration with the UI/UX design, allows the sales reps to pick their existing templates and saved sequences through the creation and execution of your own custom sequences involving calls, voicemails, texts, emails, LinkedIn messages, and re-targeting follow-ups with other new information and belief builders where you send them. This ensures a comprehensive and multichannel approach to engaging prospects in a correct way guided by the AI.</div>
                  </div>
                </li>
                <li>
                  <div className="feature-highlight">
                    <div className="subtitle">Time Cadence Optimization:</div>
                    <div className="text">The platform understands the importance of timing in sales. Through the UI/UX design, the AI helps sales reps optimize their time cadence for each outreach channel. Whether it's the frequency of calls (Morning, mid-day, late afternoon), the timing of emails, or the interval between follow-ups, Flow Turbo ensures that every action aligns with the most effective time cadence for maximum impact.</div>
                  </div>
                </li>
                <li>
                  <div className="feature-highlight">
                    <div className="subtitle">Intuitive User Interface:</div>
                    <div className="text">The UI/UX design of Flow Turbo is meticulously crafted to guide sales reps effortlessly. It provides a user-friendly interface where reps can easily navigate through the platform, access crucial information about AI-prequalified contacts, and execute their outreach sequences without unnecessary complexities.</div>
                  </div>
                </li>
                <li>
                  <div className="feature-highlight">
                    <div className="subtitle">Data-Driven Insights:</div>
                    <div className="text">The AI continuously analyzes the outcomes of sales actions, providing real-time, data-driven insights. This information is seamlessly integrated into the UI, offering sales reps visibility into the effectiveness of their strategies. The UI acts as a dashboard, displaying analytics and key performance indicators, guiding reps to refine their approach based on tangible results.</div>
                  </div>
                </li>
                <li>
                  <div className="feature-highlight">
                    <div className="subtitle">Personalized Guidance:</div>
                    <div className="text">Flow Turbo goes beyond generic recommendations. Through the UI, the AI provides personalized guidance to each sales rep based on their performance, strengths, and areas for improvement. This ensures that the guidance is tailored to the individual, maximizing the impact of every action.</div>
                  </div>
                </li>
              </ul>
            </div>
            <div className='subcontent4'>
              <div className='row'>
              <div>
                In essence, Flow Turbo is not just a platform but a partner in the sales journey. By intricately combining AI capabilities with an 
                intuitive UI/UX design, it creates a guided experience that empowers sales reps to navigate the complex landscape of B2B sales with 
                precision and success. The AI ensures that the correct actions are not only known but seamlessly integrated into the daily workflow, 
                making 300 calls a day not just a goal but a tangible reality.
              </div>
              <div>
                Flow Turbo's innovative approach to guiding sales reps is a synergy between advanced AI capabilities and a meticulously designed UI/
                UX, creating a seamless and efficient sales journey. The platform's AI is not just a passive tool but an active collaborator, ensuring that 
                sales reps perform the correct actions with precision.
              </div>
              </div>
              <div className='row'>
              <div>
                The core guidance revolves around a proactive strategy of engaging in at least 300 calls per day with AI-prequalified contacts. This is 
                made possible through a combination of AI-driven insights and the intuitive UI/UX design. The platform intelligently identifies and 
                presents 300 AI-prequalified contacts directly on the AI Account Selection page, eliminating the guesswork and enabling sales reps to 
                focus on meaningful conversations.
              </div>
              <div>
                Flow Turbo goes beyond traditional sales methods by incorporating a diverse set of communication channels – calls, voicemails, texts, 
                emails, LinkedIn interactions, and re-targeting follow-ups. The AI seamlessly integrates these channels into the UI/UX design, 
                creating a unified dashboard that guides sales reps through a comprehensive and effective engagement strategy. The platform acts as a 
                virtual coach, ensuring that the sales rep squeezes every opportunity dry over a specified time cadence with the help of sequencing 
                tools.
              </div>
              </div>
              <div className='row'>
              <div>
                By combining AI-driven insights with an intuitive interface, Flow Turbo transforms the sales process into a guided experience. Sales 
                reps are not merely left with a list of tasks; they are provided with a strategic roadmap where each action is purposeful and optimized 
                for success. This blend of technology and design ensures that sales reps navigate through their daily tasks with precision, maximizing 
                their efficiency, and ultimately achieving the goal of 300 meaningful engagements per day. Flow Turbo's guidance is not just a feature; 
                it's a revolutionary approach to AI-powered sales excellence.
              </div>
              <div>
                Imagine Flow Turbo's AI as your dedicated live sales assistant, working tirelessly to handle all the non-value-added activities that 
                typically consume a sales rep's precious time. The AI is designed to be a proactive partner, stepping in to perform tasks that sales reps 
                either don't want to do or shouldn't have to do, ensuring their focus remains on engaging in meaningful conversations and achieving 
                the target of 300 calls per day with AI-prequalified prospects from non-conflict accounts.
              </div>
              </div>
              <div className='row'>
              <div>
                This AI-powered assistant takes on a multitude of responsibilities, seamlessly integrated into the platform's UI/UX design. From 
                sorting through vast datasets to identify the most promising prospects to automating follow-ups through various communication 
                channels like calls, voicemails, texts, emails, LinkedIn interactions, and re-targeting efforts, the AI becomes a force multiplier for sales 
                reps. It acts as a guardian of efficiency, eliminating the need for sales reps to engage in non-value-added tasks that often lead to 
                burnout and decreased productivity.
              </div>
              <div>
                In essence, the AI in Flow Turbo becomes the ultimate ally, allowing sales reps to focus on what they do best – building relationships 
                and closing deals. By taking care of the routine and time-consuming activities, the AI ensures that sales reps can maximize their 
                efforts, achieving the ambitious goal of 300 meaningful engagements per day. This live sales assistant isn't just a tool; it's a gamechanger in the world of B2B sales, providing invaluable support and guidance to elevate the sales process to new heights of efficiency 
                and success.
              </div>
              </div>
              <div className='row'>
              <div>
                Imagine Flow Turbo's AI as your dedicated live sales rep assistant, a real person working tirelessly alongside you to handle all the 
                manual and mundane activities that steal precious time from your core, valuable tasks—like making those crucial calls to prospects. 
                This AI assistant steps in to take care of everything you don't want to or shouldn't have to do, ensuring that your focus remains on 
                engaging with prospects and driving meaningful conversations.
              </div>
              <div>
                Picture this AI sales assistant sorting through data, identifying the most promising leads, and ensuring that every call you make is to an 
                AI-verified, high-potential contact from non-conflict accounts. It's like having a proactive partner that prequalifies prospects, 
                eliminating the need for you to spend time on unproductive calls or wade through irrelevant information.
              </div>
              </div>
              <div className='row'>
              <div>
                In the realm of Flow Turbo, envision a transformative shift where the AI takes on the role of a proactive intermediary, liberating sales 
                reps from the shackles of manual interactions with various sales tools. It's a game-changer – now, instead of navigating through 
                multiple tools, the sales rep communicates directly with the AI. The AI becomes the adept executor of all the manual and mundane 
                activities associated with using different sales tools, allowing the sales rep to concentrate solely on engaging with 300 AI pre-qualified 
                prospects per day.
              </div>
              <div>
                Think of it as having a personal assistant specifically dedicated to handling the intricacies of various sales tools. No more toggling 
                between applications, entering data, or managing different interfaces. The sales rep articulates their needs to the AI, and it seamlessly 
                translates those instructions into action across the sales ecosystem. Whether it's sending follow-up messages, optimizing 
                communication strategies, or managing multi-channel outreach, the AI is the capable conductor orchestrating these tasks.
              </div>
              </div>
              <div className='row'>
              <div>
                By eliminating the need for direct manual interactions with sales tools, Flow Turbo's AI empowers sales reps to streamline their 
                workflow. Now, the focus is on what truly matters – building meaningful connections with AI-prequalified prospects. It's a paradigm 
                shift where the AI becomes the ally, taking on the manual tasks, and allowing sales reps to channel their energy into impactful, high value activities. Welcome to a future where the synergy between the sales rep and AI optimizes efficiency, ensuring that every 
                engagement contributes to the overarching goal of reaching and connecting with potential clients.
              </div>
              </div>
            </div>
          </div>
        )} */}
        </div>
        <div className='container4'>
          <div className='text1 large2 bold'>Introducing the Future of Sales<br /> Productivity: Live AI Sales Rep Assistant</div>
          <div className='text2 large3'>Key Features</div>
          <div className='grid grid-cols-2'>
            {keyFeatureList}
          </div>
        </div>
        
          
       <BenefitsCardComponent></BenefitsCardComponent>
        <div className='container6'>
          <div className='sub_section1'>
            <div className='large3 text1'>Flow Turbo: Ensuring An Abundance of AI Qualified-Out Accounts, Streamlined Success</div>
            <div className='sub_section1_text'>
              Don't let scarcity of truly qualified-out accounts hinder your sales team's potential. Flow Turbo is here to revolutionize your prospecting game, ensuring your sales reps are never scarce for truly qualified-out accounts and relevant contacts
            </div>
            <div className='large3 text1'>Unlocking 300 AI-Prequalified Contacts:</div>
            <div className='sub_section1_text'>
              In a world overflowing with data from multiple sources, researching, verifying, and validating the right accounts and leads can be extremely overwhelming for a sales rep. Flow Turbo simplifies this process by delivering 300 AI-prequalified contacts directly from AI-qualified accounts. This means your sales team gets access to a rich pool of potential clients without the unusual hassle.
            </div>
            <div className='large3 text1'>AI Account Selection: Eliminating the Daily Struggle</div>
            <div className='sub_section1_text'>
              Traditionally, sales reps spend a significant portion of their day navigating through hundreds if not thousands of potential accounts or contacts that are completely junk or trash, an energy intensive, mentally strenuous, and time-consuming process that often leads to sales rep burnout. Flow Turbo changes this narrative by introducing the powerful AI Account Selection feature.
            </div>
          </div>
          <div className='sub_section2'>
            <div className='list'>
              <div className='head bold'>
                <div className='number'>1</div>
                <div>Streamlined Efficiency</div>
              </div>
              <div className='content'>
                By leveraging sophisticated AI algorithms, Flow Turbo automates the account selection process. No more wasting precious hours sifting through countless accounts and prospects—our AI does the heavy lifting for you.
              </div>
            </div>
            <div className='list'>
              <div className='head bold'>
                <div className='number'>2</div>
                <div>Precision Personified</div>
              </div>
              <div className='content'>
                The AI Account Selection feature ensures that your sales team is presented with accounts and contacts that align precisely with your business objectives like your particular industry, and the particular product and service that you're selling. Say goodbye to guesswork; say hello to precision prospecting.
              </div>
            </div>
            <div className='list'>
              <div className='head bold'>
                <div className='number'>3</div>
                <div>Unleash More Sales Time</div>
              </div>
              <div className='content'>
                Imagine what your sales reps could achieve with the thousands of hours per year reclaimed from the prospecting grind. Flow Turbo empowers your team to invest more time in meaningful interactions, relationship-building, and ultimately, generating more qualified deals and opportunities for the closing team.
              </div>
            </div>
          </div>
          <div className='sub_section3'>
            <div className='large3 bold'>Why Flow Turbo?</div>
            <div className='content'>
              <div className='text_content'>
                <div className='sub_content'>
                  <div className='bold'>Abundance in Conflict-Free Accounts</div>
                  <div>Never face scarcity again. Flow Turbo delivers an abundance of AI-prequalified contacts, so your sales reps always have a pool of potential clients at their fingertips.</div>
                </div>
                <div className='sub_content'>
                  <div className='bold'>Maximized Sales Productivity</div>
                  <div>Reclaim lost days and boost overall sales productivity. Flow Turbo ensures that your team’s efforts are channeled into tasks that directly contribute to your bottom line.</div>
                </div>
                <div className='sub_content'>
                  <div className='bold'>Effortless Prospecting</div>
                  <div>Never face scarcity again. Flow Turbo delivers an abundance of AI-prequalified contacts, so your sales reps always have a pool of potential clients at their fingertips.</div>
                </div>
              </div>
              <div className='image_container'>
                <img src={why_flow} alt="Flow Turbo illustration" />
              </div>
            </div>
          </div>
          <div className='sub_section4'>
            <div className='section_left'>
              <div className='box'>
                <div className='semi-bold'>Features Overview: </div>
                <div className='semi-bold'>Harness the Power of AI in Sales Prospecting</div>
                <div className='sub_text_section4'>Key Features and Benefits</div>
                <button className='alternate-btn small semi-bold' onClick={() => toggleSection('sub_section4')}>Read {isOpen.sub_section4 ? "Less" : "More"}</button>
                {isOpen.sub_section4 && <div className='list_container'>
                  <div className='list'>
                    <div className='head bold'>
                      <div className='number'>1</div>
                      <div>Cloud-Native AI Desktop Application for Unmatched Speed</div>
                    </div>
                    <div className='content'>
                      Experience the best of both worlds with our cloud-native desktop application. Enjoy the speed and flexibility of cloud computing combined with the personalized control of a desktop application, ensuring an exceptional user experience.
                    </div>
                  </div>
                  <div className='list'>
                    <div className='head bold'>
                      <div className='number'>2</div>
                      <div>AI Account Selection with Gray Matter AI Engine</div>
                    </div>
                    <div className='content'>
                      Unleash the power of AI-driven prospecting. Our Gray Matter AI Engine guides your sales team through account selection with precision, eliminating manual efforts and steering you towards the most promising leads.
                    </div>
                  </div>
                  <div className='list'>
                    <div className='head bold'>
                      <div className='number'>3</div>
                      <div>AI Flat Files Feature for Streamlined Data Analysis</div>
                    </div>
                    <div className='content'>
                      Bid farewell to data analysis headaches. Our AI Flat Files Feature harnesses sophisticated algorithms to analyze and categorize data effortlessly, saving you time and boosting the accuracy of your insights.
                    </div>
                  </div>
                  <div className='list'>
                    <div className='head bold'>
                      <div className='number'>4</div>
                      <div>Unified Access Integrations for Seamless Workflows</div>
                    </div>
                    <div className='content'>
                      Streamline your workflow with Unified Access Integrations. No more jumping between tabs – Flow Turbo offers a unified platform that combines various tools, fostering collaboration and maximizing efficiency.
                    </div>
                  </div>
                </div>}
              </div>
              <img src={feature_overview} alt="img" />
              <div className='box'>
                <div className='semi-bold'>Revolutionizing Account Selection with the Gray Matter AI Engine</div>
                <div className='sub_text_section4'>Unlock the future of sales prospecting with Flow Turbo's cutting-edge AI Account Selection feature, powered by the Gray Matter AI Engine. Say goodbye to the inefficiencies of tedious manual account selection and hello to a paradigm shift in prospecting.</div>

                <button className='alternate-btn small semi-bold' onClick={() => toggleSection('sub_section5')}>Read {isOpen.sub_section5 ? "Less" : "More"}</button>
                {isOpen.sub_section5 && <div className='list_container'>
                  <div>Key Features:</div>
                  <div className='list'>
                    <div className='head bold'>
                      <div className='number'>1</div>
                      <div>Neural Network-Powered Precision:</div>
                    </div>
                    <div className='content'>
                      <ul>
                        <li>Our Gray Matter AI Engine employs advanced neural network algorithms, ensuring unparalleled accuracy in account selection.</li>
                        <li>Say farewell to the laborious task of manually sifting through countless accounts; our AI does it with a precision that transforms your prospecting game.</li>
                      </ul>
                    </div>
                  </div>
                  <div className='list'>
                    <div className='head bold'>
                      <div className='number'>2</div>
                      <div>Automated Disqualification Magic:</div>
                    </div>
                    <div className='content'>
                      <ul>
                        <li>Tired of dealing with junk accounts and automatic disqualifications? Let our AI take the reins.</li>
                        <li>The Gray Matter AI Engine performs a nuanced analysis, swiftly identifying and eliminating accounts that don't align with your criteria, saving you precious time and effort.</li>
                      </ul>
                    </div>
                  </div>
                  <div className='list'>
                    <div className='head bold'>
                      <div className='number'>3</div>
                      <div>Replace Manual Traditional Tools Usage:</div>
                    </div>
                    <div className='content'>
                      <ul>
                        <li>Flow Turbo isn't just another tool; it's the evolution of sales software. The AI Account Selection feature replaces the need to manually use traditional software tools.</li>
                        <li>No more hopping between platforms; everything you need is seamlessly integrated into Flow Turbo, streamlining your workflow and boosting productivity.</li>
                      </ul>
                    </div>
                  </div>
                  <div className='list'>
                    <div className='head bold'>
                      <div className='number'>4</div>
                      <div>Replace Manual Traditional Tools Usage:</div>
                    </div>
                    <div className='content'>
                      <ul>
                        <li>Flow Turbo isn't just another tool; it's the evolution of sales software. The AI Account Selection feature replaces the need to manually use traditional software tools.</li>
                        <li>No more hopping between platforms; everything you need is seamlessly integrated into Flow Turbo, streamlining your workflow and boosting productivity.</li>
                      </ul>
                    </div>
                  </div>
                  <div>Why Choose Flow Turbo?</div>
                  <ul>
                    <li>Innovation Beyond Tools
                      <div>We're not just providing tools; we're redefining the sales prospecting landscape with the latest in AI technology.</div>
                    </li>
                    <li>Precision You Can Trust
                      <div>The Gray Matter AI Engine undergoes continuous refinement, guaranteeing accuracy and adapting to the dynamic nature of sales prospecting.</div>
                    </li>
                    <li>Unparalleled Integration:
                      <div>Say farewell to the chaos of multiple tools. Flow Turbo brings all your sales essentials under one roof, providing a unified platform for your success.</div>
                    </li>
                  </ul>
                </div>}
              </div>
              <div className='box'>
                <div className='semi-bold'>Revolutionize Your Workflow: Unified Access Integrations by Flow Turbo</div>
                <div className='sub_text_section4'>Welcome to the future of seamless sales operations! Say goodbye to the chaos of managing 10-20+ different browser tabs and navigating through various sales tools. Introducing the Unified Access Integrations and Integrations Pop-ups feature by Flow Turbo - a game-changer in consolidating your existing sales toolstack into one unified, cohesive platform.</div>
                <button className='alternate-btn small semi-bold' onClick={() => toggleSection('sub_section6')}>Read {isOpen.sub_section6 ? "Less" : "More"}</button>
                {isOpen.sub_section6 && <div className='list_container'>
                  <div className='list'>
                    <div>Key Features:</div>
                    <div className='head bold'>
                      <div className='number'>1</div>
                      <div>Eradicate Browser Tab Overload:</div>
                    </div>
                    <div className='content'>
                      <ul>
                        <li>Tired of juggling a multitude of tabs for different sales tools? Unified Access Integrations brings everything together, streamlining your workspace and reducing clutter.</li>
                      </ul>
                    </div>
                  </div>
                  <div className='list'>
                    <div className='head bold'>
                      <div className='number'>2</div>
                      <div>Five-Category Consolidation:</div>
                    </div>
                    <div className='content'>
                      <ul>
                        <li>Our Unified Access Integrations combine direct access to five diverse sales tool categories, offering a comprehensive solution under one roof.</li>
                        <li>From prospecting to sequencing, bid farewell to the hassle of managing different tools for various tasks.</li>
                      </ul>
                    </div>
                  </div>
                  <div className='list'>
                    <div className='head bold'>
                      <div className='number'>3</div>
                      <div>Integrations Pop-ups for Instant Action:</div>
                    </div>
                    <div className='content'>
                      <ul>
                        <li>No more screen switching! Our Integrations Pop-ups feature allows you to perform actions directly from the AI account selection page.</li>
                        <li>Connect with a prospect on LinkedIn, sequence them instantly, or initiate other critical actions without losing focus or time.</li>
                      </ul>
                    </div>
                  </div>
                  <div className='list'>
                    <div className='head bold'>
                      <div className='number'>4</div>
                      <div>Enhanced Speed and Productivity:</div>
                    </div>
                    <div className='content'>
                      <ul>
                        <li>Accelerate your daily operations by eliminating the need to move between numerous tabs. With Unified Access Integrations, your team can perform tasks more efficiently, saving valuable time.</li>
                      </ul>
                    </div>
                  </div>
                  <div className='list'>
                    <div className='head bold'>
                      <div className='number'>5</div>
                      <div>AI-Driven Sequencing:</div>
                    </div>
                    <div className='content'>
                      <ul>
                        <li>Our AI-enhanced sequencing tool ensures that your communication with prospects is not just efficient but also personalized.</li>
                        <li>Send targeted messages directly from the AI account selection page, reducing manual steps and enhancing the effectiveness of your outreach.</li>
                      </ul>
                    </div>
                  </div>
                  <div>Why Choose Unified Access Integrations?</div>
                  <ul>
                    <li>Efficiency Unleashed:
                      <div>Centralize your sales operations into one unified cohesive composable platform, minimizing the complexity of handling multiple tools simultaneously.</div>
                    </li>
                    <li>Streamlined Workflow:
                      <div>Enjoy a seamless workflow where AI and integrations work hand in hand, eliminating the need for constant tab-switching.</div>
                    </li>
                    <li>Time-Saving Actions:
                      <div>Instantly perform critical actions through Integrations Pop-ups, reducing the time spent on manual processes and boosting productivity.</div>
                    </li>
                    <li>Tailored for Sales Success:
                      <div>Unified Access Integrations is not just a consolidation tool; it's a strategic solution designed to enhance your team's overall efficiency and success.</div>
                    </li>
                  </ul>
                </div>}
              </div>
              <div className='box'>
                <div className='semi-bold'>Unlocking Value: Clear Use Cases and Benefits</div>
                <div className='sub_text_section4'>In the dynamic realm of B2B sales, staying ahead demands more than routine strategies. Introducing Flow Turbo - the revolutionary SaaS AI Sales Prospecting Solution designed for B2B sales organizations ready to elevate their game.</div>
                <button className='alternate-btn small semi-bold' onClick={() => toggleSection('sub_section7')}>Read {isOpen.sub_section7 ? "Less" : "More"}</button>
                {isOpen.sub_section7 && <div className='list_container'>
                  <div className='list'>
                    <div className='semi-bold'>Sales Teams:</div>
                    <div>Equip your sales team with the ultimate tool for prospecting success. Flow Turbo enables efficient account selection, targeted outreach, and data-driven decision-making, increased speed, accelerated days for sales reps - resulting in increased conversions, collapsed time, shortened sales cycles, and higher revenue.</div>
                    <div className='semi-bold'>Business Development:</div>
                    <div>Expand your sales and tap into new accounts with confidence. Leverage our AI-powered solution to identify qualified accounts, relevant decision makers, lucrative opportunities, streamline lead generation, and penetrate untapped businesses effectively.</div>
                    <div>Key Use Cases:</div>
                    <div className='head bold'>
                      <div className='number'>1</div>
                      <div>Precise Account Selection:</div>
                    </div>
                    <div className='content'>
                      <ul>
                        <li>Turbocharge your account selection process with AI precision. Flow Turbo sifts through vast data oceans to pinpoint prospects aligned with your ideal customer profile without automatic disqualifications.</li>
                      </ul>
                    </div>
                  </div>
                  <div className='list'>
                    <div className='head bold'>
                      <div className='number'>2</div>
                      <div>Automated Prospect Qualification:</div>
                    </div>
                    <div className='content'>
                      <ul>
                        <li>Say goodbye to manual qualification processes. Our Gray Matter AI Engine utilizes ML algorithms to automatically qualify leads, ensuring you spend time on prospects most likely to convert.</li>
                      </ul>
                    </div>
                  </div>
                  <div className='list'>
                    <div className='head bold'>
                      <div className='number'>3</div>
                      <div>Unified Access Integrations:</div>
                    </div>
                    <div className='content'>
                      <ul>
                        <li>No more juggling multiple tools. Flow Turbo brings together lead source databases, CRM systems, sequencing tools, and more into one unified platform, streamlining your workflow.</li>
                      </ul>
                    </div>
                  </div>
                  <div className='list'>
                    <div className='head bold'>
                      <div className='number'>4</div>
                      <div>AI Flat Files Feature:</div>
                    </div>
                    <div className='content'>
                      <ul>
                        <li>Revolutionize your data analysis. Flow Turbo's AI Flat Files Feature enables seamless processing of thousands of accounts, eliminating the manual effort in research, analysis, and validation.</li>
                      </ul>
                    </div>
                  </div>
                  <div className='list'>
                    <div className='head bold'>
                      <div className='number'>5</div>
                      <div>Scalable Cloud-Native Desktop App:</div>
                    </div>
                    <div className='content'>
                      <ul>
                        <li>Access the power of Flow Turbo from anywhere. Our cloud-native desktop application ensures scalability and flexibility, empowering your team to be productive on the go.</li>
                      </ul>
                    </div>
                  </div>
                  <div>Benefits for B2B Sales Organizations:</div>
                  <ul>
                    <li>Increased Efficiency:
                      <div>Streamline your sales operations and focus on high-value activities with automated lead selection and qualification.</div>
                    </li>
                    <li>Maximized Productivity:
                      <div>Unified access and integrations reduce the need for multiple tools, increasing your team's efficiency and reducing manual effort.</div>
                    </li>
                    <li>Data-Driven Decision-Making:
                      <div>Leverage AI-powered insights for strategic decision-making, ensuring your sales efforts are data-backed and effective.</div>
                    </li>
                    <li>Enhanced Customer Engagement:
                      <div>With precise lead selection, you can engage with prospects that truly matter, leading to improved conversion rates.</div>
                    </li>
                    <li>Future-Ready Sales:
                      <div>Stay ahead of the curve with Flow Turbo's cutting-edge features, positioning your sales team as industry leaders.</div>
                    </li>
                  </ul>
                </div>}
              </div>
            </div>
            <div className='section_right'>
              <div className='box'>
                <div className='semi-bold'>Experience Sales Empowerment in the Cloud:</div>
                <div className='semi-bold'>Flow Turbo's Cloud-Native AI Desktop Application</div>
                <div className='sub_text_section4'>Welcome to the forefront of innovation with Flow Turbo's Cloud-Native AI Desktop Application. Our solution redefines the landscape of sales prospecting by combining the power of artificial intelligence with the convenience of a desktop application. Say goodbye to the constraints of traditional software - embrace a new era of agility, efficiency, and unparalleled productivity.</div>
                <button className='alternate-btn small semi-bold' onClick={() => toggleSection('sub_section8')}>Read {isOpen.sub_section8 ? "Less" : "More"}</button>
                {isOpen.sub_section8 && <div className='list_container'>
                  <div>Key Features</div>
                  <div className='list'>
                    <div className='head bold'>
                      <div className='number'>1</div>
                      <div>Seamless Desktop Experience</div>
                    </div>
                    <div className='content'>
                      Enjoy the familiarity of a desktop environment while harnessing the dynamic capabilities of the cloud. Flow Turbo seamlessly integrates the best of both worlds, providing a user-friendly and powerful interface.
                    </div>
                  </div>
                  <div className='list'>
                    <div className='head bold'>
                      <div className='number'>2</div>
                      <div>Agile AI Processing:</div>
                    </div>
                    <div className='content'>
                      Experience the speed and agility of AI processing right on your desktop. Flow Turbo's cloud-native architecture ensures that the AI engine works effortlessly in the background, delivering real-time insights without compromising performance.
                    </div>
                  </div>
                  <div className='list'>
                    <div className='head bold'>
                      <div className='number'>3</div>
                      <div>Enhanced Security:</div>
                    </div>
                    <div className='content'>
                      Trust in a secure and reliable platform. Flow Turbo's cloud-native design incorporates robust security measures, safeguarding your data and operations against potential threats.
                    </div>
                  </div>
                  <div className='list'>
                    <div className='head bold'>
                      <div className='number'>4</div>
                      <div>Offline Accessibility:</div>
                    </div>
                    <div className='content'>
                      Stay productive even without an internet connection. Flow Turbo's cloud-native desktop application offers offline accessibility, ensuring that your sales operations continue seamlessly regardless of connectivity.
                    </div>
                  </div>
                  <div className='list'>
                    <div className='head bold'>
                      <div className='number'>5</div>
                      <div>Optimized Resource Utilization:</div>
                    </div>
                    <div className='content'>
                      Say goodbye to resource-heavy installations. Flow Turbo's cloud-native design optimizes resource utilization, ensuring that your device's performance remains unaffected while experiencing the power of AI.
                    </div>
                  </div>
                  <div>
                    <div className='medium bold text1'>Why Choose Flow Turbo's Cloud-Native AI Desktop Application?</div>
                    <ul>
                      <li>
                        <div>Effortless Integration</div>
                        <div className='content'>
                          Seamlessly integrate Flow Turbo into your desktop environment, creating a cohesive and unified workspace for sales excellence.
                        </div>
                      </li>
                      <li>
                        <div>Responsive and Adaptive:</div>
                        <div className='content'>
                          Experience AI responsiveness without compromise. Flow Turbo adapts to your desktop's capabilities, delivering an agile and efficient prospecting experience.
                        </div>
                      </li>
                      <li>
                        <div>Continuous Innovation:</div>
                        <div className='content'>
                          Benefit from continuous updates and innovations. Flow Turbo's cloud-native design allows for swift and seamless updates, ensuring you always have access to the latest features and improvements.
                        </div>
                      </li>
                      <li>
                        <div>User-Centric Design:</div>
                        <div className='content'>
                          Designed with you in mind. Flow Turbo's Cloud-Native AI Desktop Application prioritizes user experience, offering an intuitive interface that aligns with your workflow.
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>}
              </div>
              <div className='box'>
                <div className='semi-bold'>Unlock Efficiency:</div>
                <div className='semi-bold'>AI Flat Files Feature by Flow Turbo</div>
                <div className='sub_text_section4'>Welcome to a new era in sales prospecting, where tedious tasks of sifting through flat files become a thing of the past. Introducing the AI Flat Files Feature by Flow Turbo, armed with the Gray Matter AI Engine, incorporating Neural Network and ML algorithms for unparalleled efficiency.</div>
                <button className='alternate-btn small semi-bold' onClick={() => toggleSection('sub_section9')}>Read {isOpen.sub_section9 ? "Less" : "More"}</button>
                {isOpen.sub_section9 && <div className='list_container'>
                  <div>Key Features:</div>
                  <div className='list'>
                    <div className='head bold'>
                      <div className='number'>1</div>
                      <div>Automated Precision Analysis:</div>
                    </div>
                    <div className='content'>
                      <ul>
                        <li>Bid farewell to the laborious task of manually parsing through CSVs or Excel sheets. Our AI Flat Files Feature harnesses the power of neural networks to automate the analysis of vast datasets.</li>
                        <li>Enjoy a refined, error-free process that significantly reduces the risk of overlooking vital information.</li>
                      </ul>
                    </div>
                  </div>
                  <div className='list'>
                    <div className='head bold'>
                      <div className='number'>2</div>
                      <div>Gray Matter AI Engine Insights:</div>
                    </div>
                    <div className='content'>
                      <ul>
                        <li>The heart of our system, the Gray Matter AI Engine, continually learns from patterns, ensuring that it not only processes data but understands it.</li>
                        <li>Uncover actionable insights effortlessly, transforming your flat files into strategic assets.</li>
                      </ul>
                    </div>
                  </div>
                  <div className='list'>
                    <div className='head bold'>
                      <div className='number'>3</div>
                      <div>Time-Saving Automation:</div>
                    </div>
                    <div className='content'>
                      <ul>
                        <li>No more spending hours on data validation and cross-referencing. The AI Flat Files Feature streamlines your workflow, automating the mundane and allowing your sales team to focus on what matters - closing deals.</li>
                        <li>Accelerate your sales cycle by allocating time where it's needed most.</li>
                      </ul>
                    </div>
                  </div>
                  <div className='list'>
                    <div className='head bold'>
                      <div className='number'>4</div>
                      <div>Replace Manual Validation:</div>
                    </div>
                    <div className='content'>
                      <ul>
                        <li>Say goodbye to the manual grind of validating each account and contact. Our AI identifies and flags potential issues, saving you from the pitfalls of overlooked disqualifications.</li>
                        <li>Trust the Gray Matter AI Engine to enforce quality standards without the need for exhaustive manual checks.</li>
                      </ul>
                    </div>
                  </div>
                  <div className='list'>
                    <div className='head bold'>
                      <div className='number'>5</div>
                      <div>Format-Agnostic Solution:</div>
                    </div>
                    <div className='content'>
                      <ul>
                        <li>Whether it's a CSV or an Excel sheet, our AI Flat Files Feature is format-agnostic. Embrace flexibility without sacrificing efficiency.</li>
                        <li>Seamlessly integrate your existing data structures, ensuring a smooth transition without the hassle of data reformatting.</li>
                      </ul>
                    </div>
                  </div>
                  <div>Why Embrace the AI Flat Files Feature?</div>
                  <ul>
                    <li>Efficiency Redefined:
                      <div>Transform cumbersome data handling into a streamlined, automated process that liberates your team from repetitive tasks.</div>
                    </li>
                    <li>Data Confidence:
                      <div>Rest easy with the assurance that our AI Engine not only processes data but understands it, reducing the risk of oversights.</div>
                    </li>
                    <li>Accelerate Decision-Making:
                      <div>Uncover insights faster and make data-driven decisions with the actionable intelligence generated by the Gray Matter AI Engine.</div>
                    </li>
                    <li>Tailored for Your Workflow:
                      <div>Embrace an AI solution that integrates seamlessly into your existing flat file structures, adapting to your workflow rather than disrupting it.</div>
                    </li>
                  </ul>
                </div>}
              </div>
              <img src={feature_overview2} alt="img" />
              <div className='box'>
                <div className='semi-bold'>Elevate Your Sales Game:</div>
                <div className='semi-bold'>Flow Turbo's Existing Toolstack Integrations</div>
                <div className='sub_text_section4'>Welcome to the era where your sales toolstack isn't a patchwork of disconnected solutions but a harmonious symphony of powerful, integrated tools. Flow Turbo introduces a groundbreaking solution that seamlessly integrates with your existing sales toolstack, providing a unified platform that enhances every aspect of your sales process.</div>
                <button className='alternate-btn small semi-bold' onClick={() => toggleSection('sub_section10')}>Read {isOpen.sub_section10 ? "Less" : "More"}</button>
                {isOpen.sub_section10 && <div className='list_container'>
                  <div>Key Features:</div>
                  <div className='list'>
                    <div className='head bold'>
                      <div className='number'>1</div>
                      <div>Lead Source Databases:</div>
                    </div>
                    <div className='content'>
                      <ul>
                        <li>Effortlessly connect with various lead source databases. Flow Turbo ensures compatibility with a range of brands, bringing an extensive pool of prospects at your fingertips.</li>
                      </ul>
                    </div>
                  </div>
                  <div className='list'>
                    <div className='head bold'>
                      <div className='number'>2</div>
                      <div>CRM Systems Integration:</div>
                    </div>
                    <div className='content'>
                      <ul>
                        <li>No need to switch between platforms. Integrate Flow Turbo directly into your CRM system, facilitating a smooth flow of data and ensuring that your customer information is always up-to-date.</li>
                      </ul>
                    </div>
                  </div>
                  <div className='list'>
                    <div className='head bold'>
                      <div className='number'>3</div>
                      <div>Sequencing Tools Harmony:</div>
                    </div>
                    <div className='content'>
                      <ul>
                        <li>Achieve coherence in your outreach strategies by integrating with different sequencing tools. Whether you prefer automation or a more personalized approach, Flow Turbo seamlessly adapts to your preferred sequencing tool.</li>
                      </ul>
                    </div>
                  </div>
                  <div className='list'>
                    <div className='head bold'>
                      <div className='number'>4</div>
                      <div>Communication Tools Alignment:</div>
                    </div>
                    <div className='content'>
                      <ul>
                        <li>Connect your communication tools effortlessly. Flow Turbo ensures that your messaging platforms, emails, and other communication tools work in tandem, eliminating the need for constant tab-switching.</li>
                      </ul>
                    </div>
                  </div>
                  <div className='list'>
                    <div className='head bold'>
                      <div className='number'>5</div>
                      <div>Ad Hoc Tool Compatibility:</div>
                    </div>
                    <div className='content'>
                      <ul>
                        <li>Have a specific tool your team loves? No problem. Flow Turbo is designed to integrate seamlessly with a variety of ad hoc tools, ensuring that your preferred solutions remain part of your workflow.</li>
                      </ul>
                    </div>
                  </div>
                  <div>Why Choose Flow Turbo Integrations?</div>
                  <ul>
                    <li>Enhanced Efficiency:
                      <div>Streamline your processes by bringing all your tools under one umbrella. No more switching tabs; focus on what matters - closing deals.</div>
                    </li>
                    <li>Data Consistency:
                      <div>Integrate your CRM and other data sources to maintain consistency. Flow Turbo ensures that your team works with the most accurate and updated information.</div>
                    </li>
                    <li>Adaptability to Your Workflow:
                      <div>Regardless of your tool preferences, Flow Turbo adapts. Enjoy the freedom to use the tools that work best for your team while still benefiting from the power of integration.</div>
                    </li>
                    <li>Accelerated Sales Cycles:
                      <div>With everything effortlessly connected, your sales cycles become shorter. Reduce manual efforts, automate where needed, and see results faster.</div>
                    </li>
                  </ul>
                  <div className='bold'>Experience the Power of Integration:</div>
                  <div>Say goodbye to the chaos of managing multiple logins, scattered data, and inconsistent processes. Flow Turbo’s seamless integrations redefine how you work, making your toolstack an asset, not a hurdle. Elevate your sales strategy, boost your team’s productivity, and witness a new era of sales success. Try Flow Turbo now and discover a world where your tools work together seamlessly to drive unparalleled results!</div>
                </div>}
              </div>
              <div className='box'>
                <div className='semi-bold'>Envision Your Sales Organization Down-The-Road</div>
                {/* <div>In the current realm of sales, the processes are characterized by a slow, manual, and mundane rhythm that often feels exhaustive, boring, and painfully repetitive. Sales reps find themselves caught in a time-consuming cycle, struggling to break free from the monotony. The current state of affairs is not just limiting; it's draining the life out of your sales team.</div>
                <div>Now, envision a future where your sales process is not just a routine but a symphony of efficiency, innovation, and boundless potential. Picture a landscape where the SaaS AI sales prospecting solution transforms the ordinary into the extraordinary, liberating your sales reps from the shackles of repetitive actions. In this new reality, every engagement propels your team toward increased commissions, turning mundane tasks into opportunities for financial success.</div>
                <div>Embrace a world where your sales organization thrives on the cutting edge—a world where the AI-powered prospecting solution is a game-changer, a beacon of transformation. Your sales reps, once confined by monotony, now operate in a realm of limitless possibilities. This isn’t just a product; it’s a revolution—a groundbreaking shift that promises to put money in your pocket, elevate your sales team to new heights, and bring a sense of awe and enlightenment to every interaction. Step into this future, where the ordinary is replaced by the extraordinary, and watch as your sales organization ascends to unparalleled success.</div>
                 */}
                <div className='sub_text_section4'>Ready to redefine your B2B sales journey? <Link to='/contact/sales-support' style={{color: 'black'}}>Discover Flow Turbo</Link> - where innovation meets sales excellence. Your sales organization's success story starts here.</div>
                {/* <button className='alternate-btn small semi-bold' onClick={() => toggleSection('sub_section11')}>Read {isOpen.sub_section11 ? "Less" : "More"}</button>
                {isOpen.sub_section11 && <div className='list_container'>
                  <div className='semi-bold'>It's Very Simple to Get Started</div>
                  <div>At Flow, we believe that unlocking the power of AI for your sales team should be a breeze. Getting started is simpler than ever, designed with you in mind. Here's what the Flow experience promises:</div>
                  <div className='list'>
                    <div className='head bold'>
                      <div className='number'>1</div>
                      <div>Intuitive Onboarding:</div>
                    </div>
                    <div className='content'>
                      <ul>
                        <li>Our user-friendly interface ensures a smooth start, with intuitive steps guiding you through the setup process.</li>
                      </ul>
                    </div>
                  </div>
                  <div className='list'>
                    <div className='head bold'>
                      <div className='number'>2</div>
                      <div>Rapid Deployment:</div>
                    </div>
                    <div className='content'>
                      <ul>
                        <li>No lengthy waiting periods. Start benefiting from AI-driven insights almost instantly after setup, enhancing your prospecting efforts immediately.</li>
                      </ul>
                    </div>
                  </div>
                  <div className='list'>
                    <div className='head bold'>
                      <div className='number'>3</div>
                      <div>Streamlined Integration:</div>
                    </div>
                    <div className='content'>
                      <ul>
                        <li>Easily integrate Flow with your existing sales tools, ensuring a seamless fit into your workflow without disruptions.</li>
                      </ul>
                    </div>
                  </div>
                  <div className='list'>
                    <div className='head bold'>
                      <div className='number'>4</div>
                      <div>Guided Onboarding:</div>
                    </div>
                    <div className='content'>
                      <ul>
                        <li>Our platform is crafted to empower all users, regardless of technical expertise. Guided onboarding sessions and community resources help you make the most out of Flow's capabilities.</li>
                      </ul>
                    </div>
                  </div>
                  <div className='list'>
                    <div className='head bold'>
                      <div className='number'>5</div>
                      <div>Clear Value Proposition:</div>
                    </div>
                    <div className='content'>
                      <ul>
                        <li>From day one, experience the value of AI in your sales prospecting efforts. Target the right accounts, eliminate manual research, and turbocharge your team's productivity. Start making more money and increase the commissions that your sales reps receive.</li>
                      </ul>
                    </div>
                    <div>Getting started with Flow means embracing a new era of efficiency in your sales strategy. Join the future of prospecting with Flow, where simplicity meets unmatched performance.</div>
                  </div>
                </div>} */}
              </div>
            </div>
          </div>
        </div>
        <div className='container7'>
          <div className='large2 bold white text1'>Experience the Future of Sales Prospecting Today</div>
          <div className='large3 bold green text1'>Limited-Time Early Access: Secure Your Launch Pricing Now!</div>
          <div className='large3 bold white text2'>Exciting News! Flow Turbo's Early Access Program is here with exclusive pricing, but act fast - it's limited to the first 100 accounts!</div>
          <div className='content'>
            <div className='left_content'>
              <div className='sub_content'>
                <div className='green bold'>Be Among the First 100</div>
                <div className='white bold'>
                  Secure your spot now and enjoy the exclusive perks of early access. Once these limited spots are filled, the opportunity disappears, and pricing adjusts.
                </div>
              </div>
              <div className='sub_content'>
                <div className='green bold'>What Early Access Offers</div>
                <div className='white bold'>
                  <ul>
                    <li>Special early access program pricing for the duration of the program.</li>
                    <li>Full access to all features, ensuring you experience the AI magic firsthand.</li>
                    <li>Direct engagement with our product and engineering team to shape the future of Flow Turbo.</li>
                  </ul>
                </div>
              </div>
              <div className='sub_content'>
                <div className='green bold'>Why Join Early?</div>
                <div className='white bold'>
                  <ul>
                    <li>Shape the Future: Your feedback matters, influencing the evolution of Flow Turbo.</li>
                    <li>Exclusive Promo: Benefit from launch pricing available only during early access.</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='right_content'>
              <div className='sub_content'>
                <div className='green bold'>Time is of the Essence</div>
                <div className='white bold'>
                  <ul>
                    <li>Don't miss your chance. Early Access is a limited-time offer.</li>
                    <li>Join the select few who pioneer a new era in AI sales prospecting.</li>
                  </ul>
                </div>
              </div>
              <div className='sub_content'>
                <div className='green bold'>Once-in-a-Lifetime Pricing</div>
                <div className='white bold'>
                  <ul>
                    <li>Lock in your early access program pricing now. As soon as the first 100 accounts are claimed, pricing adjusts to reflect post-program pricing.</li>
                  </ul>
                </div>
              </div>
              <div className='sub_content'>
                <div className='green bold'>Act Now - Seize Your Spot!</div>
                <div className='white bold'>
                  Click below to secure your exclusive pricing and embark on a sales journey transformed by the power of Flow Turbo. Limited spots available – don’t miss out!
                </div>
              </div>
            </div>
          </div>
          <div className='content2'>
            <div className='left_content2'>
              <div className='white bold large2'>Ready to Get Started?</div>
              <div className='white content2_text'>Schedule your free product tour right now and witness firsthand how Flow Turbo, the world's first SaaS AI sales prospecting platform, can transform your sales process. Unlock the potential of AI and achieve unparalleled success in your sales efforts. It's time to elevate your prospecting game with Flow Turbo.</div>
              <Link to="/contact/sales-support" className='btn_link'><div className='green_btn'><u>Schedule A Product Tour</u></div></Link>
            </div>
            <div className='right_content2'>
              <img src={img2} alt="img" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Product;
