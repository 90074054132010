import "./BillingManagementStyles.css";
import { BsThreeDots } from "react-icons/bs";
import flowTurboLogo from "./../../../assets/img/icon/flow_turbo.png";

function BillingSubscriptions() {
  return (
    <>
      <h2 className="font-light text-lg mb-5">Admin/company-organization</h2>
      <h1 className="font-extrabold text-4xl mb-8">Manage Subscriptions</h1>
      <h2 className="font-light text-lg mb-10">Subscription details</h2>
      <div className="flex flex-row justify-between w-[100%] items-center mb-10">
        <h3 className="font-bold text-[1.2rem] w-[50%]">Current plan</h3>
        <div className="w-[100%] flex flex-row justify-end items-center gap-5">
          <button>Change Plan</button>
          <div>
            <BsThreeDots />
          </div>
        </div>
      </div>
      <div className="subscription-plans">
        <div className="subscription-plan">
          <div className="subs-desc">
            <div className="desc-icon">
              <img src={flowTurboLogo} />
            </div>
            <div className="desc">
              <h1>Flow Turbo</h1>
              <p>https://company-organization.flow.net</p>
              <p>
                Users: <span className="users text-black">5</span>
              </p>
            </div>
          </div>
          <div className="subs-price">
            <h1>$1080</h1>
          </div>
        </div>
      </div>
    </>
  );
}

export default BillingSubscriptions;
