import React, { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Home from "./pages/Home/Home.js";
import Blog from "./pages/Blog";
import Contact from "./pages/contact/Contact";
import AboutUs from "./pages/AboutUs";
import SalesSupport from "./pages/contact/SalesSupport";
import Privacy from "./pages/Privacy";
import Pricing from "./pages/Pricing";
import Career from "./pages/Career";
import Resource from "./pages/Resource";
import UseCases from "./pages/usecases/UseCase";
import CustomerService from "./pages/CustomerService";
import LandingPage from "./pages/LandingPage/LandingPageGateway.js";
import Marketplace from "./pages/Marketplace";
import Post from "./components/Blog/Post";
import Header from "./components/Header";
import HRLogin from "./components/internal-hr/HRLogin/HRLogin";

import ApplicationSuccessPage from "./pages/ApplicationSuccessPage";
import JobDetails from "./components/internal-hr/InternalJob/JobDetails";
import JobList from "./components/internal-hr/InternalJob/JobList";
import JobForm from "./components/internal-hr/InternalJob/JobForm";
import UpdateJobForm from "./components/internal-hr/InternalJob/UpdateJobForm";
import CareerJobDetail from "./pages/CareerJobDetail";
import ApplicationForm from "./pages/ApplicationForm";
import ApplicationList from "./components/internal-hr/InternalJob/ApplicationList";

import ProductGateway from "./pages/product/ProductGateway";
import Billing from "./pages/billing/Billing";
import BillingManagement from "./pages/account/billing_management/BillingManagement";
import BillingSubscriptions from "./pages/account/billing_management/BillingSubscriptions";
import BillingQuotes from "./pages/account/billing_management/BillingQuotes";
import BillingProfiles from "./pages/account/billing_management/BillingProfiles";
import BillingPayments from "./pages/account/billing_management/BIllingPayments";
import BillingAddress from "./pages/account/billing_management/BillingAddress";
import BillingPermissions from "./pages/account/billing_management/BillingPermissions";
import BillingInvoices from "./pages/account/billing_management/BillingInvoices";
import AccountLayout from "./pages/account/AccountLayout";
import BillingPaymentDetails from "./pages/account/billing_management/BillingPaymentDetails";
import PurchaseForm from "./pages/billing/PurchaseForm";

import loadAnimations from "./animate.js";

const PrivateRoute = ({ children }) => {
  const isLoggedIn = !!localStorage.getItem("accessToken");
  return isLoggedIn ? children : <Navigate to="/internal-hr" />;
};

// All routes in the application are defined here
const routes = [
  {
    path: "/",
    element: <Home />,
    title: "Flow | Home",
  },
  {
    path: "/product",
    element: <ProductGateway />,
    title: "Flow | Product",
  },
  {
    path: "/use-cases",
    element: <UseCases />,
    title: "Flow | Use Cases",
  },
  {
    path: "/customer-service",
    element: <CustomerService />,
    title: "Flow | Customer Service",
  },
  {
    path: "/blog",
    element: <Blog />,
    title: "Flow | Blog",
  },
  {
    path: "/contact/sales-support",
    element: <SalesSupport />,
    title: "Flow | Sales Support",
  },
  {
    path: "/contact/*",
    element: <Contact />,
    title: "Flow | Contact",
  },
  {
    path: "/aboutus",
    element: <AboutUs />,
    title: "Flow | About Us",
  },
  {
    path: "/billing",
    element: <Billing />,
    title: "Flow | Billing",
  },
  {
    path: "/privacy",
    element: <Privacy />,
    title: "Flow | Privacy",
  },
  {
    path: "/pricing",
    element: <Pricing />,
    title: "Flow | Pricing",
  },
  {
    path: "/careers",
    element: <Career />,
    title: "Flow | Careers",
  },
  {
    path: "/:title",
    element: <CareerJobDetail />,
    title: "Flow | Career - Job Detail",
  },
  {
    path: "/apply/:jobId/:jobTitle",
    element: <ApplicationForm />,
    title: "Flow | Application Form",
  },
  {
    path: "/resource",
    element: <Resource />,
    title: "Flow | Resource",
  },
  {
    path: "/turbo/lp",
    element: <LandingPage />,
    title: "Flow | Landing Page",
  },
  {
    path: "/marketplace",
    element: <Marketplace />,
    title: "Flow | Marketplace",
  },
  {
    path: "/blog/:id",
    element: <Post />,
    title: "Flow | Blog Post",
  },
  {
    path: "/internal-hr",
    element: <HRLogin />,
    title: "Flow | HR Login",
  },
  {
    path: "/application-success-page",
    element: <ApplicationSuccessPage />,
    title: "Flow | Application Success Page",
  },
  {
    path: "/internal-hr-joblist",
    element: (
      <PrivateRoute>
        <JobList />
      </PrivateRoute>
    ),
    title: "Flow | Internal HR Job List",
  },
  {
    path: "/create-job",
    element: (
      <PrivateRoute>
        <JobForm />
      </PrivateRoute>
    ),
    title: "Flow | Create Job",
  },
  {
    path: "/update-job/:jobId",
    element: (
      <PrivateRoute>
        <UpdateJobForm />
      </PrivateRoute>
    ),
    title: "Flow | Update Job",
  },
  {
    path: "/job-details/:id",
    element: <JobDetails />,
    title: "Flow | Job Details",
  },
  {
    path: "/job/:jobId/applications",
    element: (
      <PrivateRoute>
        <ApplicationList />
      </PrivateRoute>
    ),
    title: "Flow | Job Applications",
  },
  {
    path: "/account/12345",
    element: <AccountLayout />,
    children: [
      {
        path: "billing-management",
        element: <BillingManagement />,
        title: "Flow | Dashboard",
      },
      {
        path: "billing-subs",
        element: <BillingSubscriptions />,
        title: "Flow | Dashboard",
      },
      {
        path: "billing-quotes",
        element: <BillingQuotes />,
        title: "Flow | Dashboard",
      },
      {
        path: "billing-profiles",
        element: <BillingProfiles />,
        title: "Flow | Dashboard",
      },
      {
        path: "billing-payments",
        element: <BillingPayments />,
        title: "Flow | Dashboard",
      },
      {
        path: "billing-addresses",
        element: <BillingAddress />,
        title: "Flow | Dashboard",
      },
      {
        path: "billing-permissions",
        element: <BillingPermissions />,
        title: "Flow | Dashboard",
      },
      {
        path: "billing-invoices",
        element: <BillingInvoices />,
        title: "Flow | Dashboard",
      },
      { path: "billing-payments/details", element: <BillingPaymentDetails /> },
    ],
    title: "Flow | Dashboard",
  },
];

function AppRouter() {
  const currentPath = useLocation().pathname;
  const isAccountPage = currentPath.startsWith("/account");
  useEffect(() => {
    const route = routes.find((route) => route.path === currentPath);
    if (route) {
      document.title = route.title;
    }
  }, [currentPath]);

  const pathName = useLocation().pathname;

  useEffect(() => {
    if (pathName === "/" || pathName === "/contact") {
      loadAnimations();
    }
    window.scrollTo(0, 0);
  }, [pathName]);

  return (
    <>
      {!isAccountPage && <Header />}
      <Routes>
        {routes.map((route) => (
          <Route key={route.path} {...route}>
            {route.children &&
              route.children.map((child) => (
                <Route key={child.path} {...child} />
              ))}
          </Route>
        ))}
      </Routes>
    </>
  );
}

export default AppRouter;
