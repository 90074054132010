import React, { useEffect, useState } from 'react'
import BenefitsCardComponent from './BenefitsCardComponent'

import benefits_content from '../../Content/Product-Page/BenefitsContent'


const BenefitsComponent = () => {
 
  const [benefitsList,setBenefitsList] = useState(null)
  useEffect(()=>{
        setBenefitsList(benefits_content.map((content,index)=><BenefitsCardComponent title={content.title} key={index} description={content.description}></BenefitsCardComponent>))
  },[])
  return (
    <div className='bg-white px-4 py-10'>
        <h1 className='font-bold font-neue-haas text-2xl pb-6'>Benefits</h1>
        {benefitsList && benefitsList}
    </div>
  )
}

export default BenefitsComponent