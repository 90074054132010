import React, { useEffect, useRef, useState, createContext } from "react";
import { cn } from "../../lib/utils";
import { AnimatePresence, motion } from "framer-motion";
import { Link } from 'react-router-dom';

export const CarouselContext = createContext({
  onCardClose: () => { },
  currentIndex: 0,
});

const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(window.matchMedia(query).matches);

  useEffect(() => {
    const mediaQueryList = window.matchMedia(query);
    const listener = () => setMatches(mediaQueryList.matches);

    mediaQueryList.addEventListener('change', listener);
    return () => mediaQueryList.removeEventListener('change', listener);
  }, [query]);

  return matches;
};

export const Carousel = ({ items, initialScroll = 0 }) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const carouselRef = useRef(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (carouselRef.current) {
      carouselRef.current.scrollLeft = initialScroll;
      checkScrollability();
    }
  }, [initialScroll]);

  const checkScrollability = () => {
    if (carouselRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = carouselRef.current;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(Math.round(scrollLeft) < scrollWidth - clientWidth);
      const newIndex = Math.round(scrollLeft / (scrollWidth / items.length));
      setCurrentIndex(newIndex);
    }
  };

  const scrollLeft = () => {
    if (carouselRef.current) {
      const scrollAmount = window.innerWidth < 1068 ? -280 : -750;
      carouselRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (carouselRef.current) {
      const scrollAmount = window.innerWidth < 1068 ? 280 : 750;
      carouselRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  const goToSlide = (index) => {
    if (carouselRef.current) {
      const slideWidth = carouselRef.current.scrollWidth / items.length;
      carouselRef.current.scrollTo({
        left: slideWidth * index,
        behavior: "smooth",
      });
    }
  };

  return (
    <CarouselContext.Provider value={{ currentIndex }}>
      <div className="relative w-full flex items-center">
        {/* Left Arrow */}
        {!isMobile && (
          <button
            className="relative z-40 text-6xl md:text-8xl 2xl:text-9xl mr-2 text-gray-700 hover:text-white transition-colors duration-300 disabled:opacity-50"
            onClick={scrollLeft}
            disabled={!canScrollLeft}
          >
            {"<"}
          </button>
        )}

        {/* Cards Container */}
        <div
          className="flex w-full overflow-x-scroll overscroll-x-auto pb-5 scroll-smooth [scrollbar-width:none]"
          ref={carouselRef}
          onScroll={checkScrollability}
        >
          <div
            className={cn(
              "absolute right-0 z-[1000] h-auto w-[5%] overflow-hidden bg-gradient-to-l"
            )}
          ></div>

          <div
            className={cn(
              "flex flex-row justify-start gap-4 pl-4 pr-8",
              "mx-auto"
            )}
          >
            {items.map((item, index) => (
              <motion.div
                initial={{
                  opacity: 0,
                  y: 20,
                }}
                animate={{
                  opacity: 1,
                  y: 0,
                  transition: {
                    duration: 0.5,
                    delay: 0.2 * index,
                    ease: "easeOut",
                    once: true,
                  },
                }}
                key={"card" + index}
                className="rounded-3xl"
              >
                {item}
              </motion.div>
            ))}
          </div>
        </div>

        {/* Right Arrow */}
        {!isMobile && (
          <button
            className="relative z-40 text-6xl md:text-8xl 2xl:text-9xl ml-2 text-gray-700 hover:text-white transition-colors duration-300 disabled:opacity-50"
            onClick={scrollRight}
            disabled={!canScrollRight}
          >
            {">"}
          </button>
        )}

        {/* Slide Indicators */}
        {isMobile && (
          <div className="absolute z-30 flex -translate-x-1/2 bottom-0 left-1/2 space-x-3 rtl:space-x-reverse">
            {items.map((_, index) => (
              <button
                key={index}
                type="button"
                className={`w-3 h-3 rounded-full ${index === currentIndex ? "bg-primary" : "bg-gray-300"
                  }`}
                onClick={() => goToSlide(index)}
              ></button>
            ))}
          </div>
        )}
      </div>
    </CarouselContext.Provider>
  );
};

export const Card = ({ tier, index, layout = false }) => {
  return (
    <AnimatePresence>
      <motion.div
        layoutId={layout ? `card-${tier.title}` : undefined}
        className="rounded-3xl bg-white shadow-lg h-full w-[75vw] md:w-[28vw] 2xl:w-[23vw] overflow-hidden flex flex-col p-6 relative z-10"
      >
        <div className="flex flex-col items-center justify-center">
          <motion.h3
            layoutId={layout ? `title-${tier.title}` : undefined}
            className="text-md md:text-2xl 2xl:text-4xl font-bold text-black mb-2 2xl:mb-4 font-neue-haas"
          >
            {tier.title}
          </motion.h3>
          <motion.div
            layoutId={layout ? `price-${tier.title}` : undefined}
            className="text-5xl 2xl:text-8xl font-bold text-accent mb-1 2xl:mb-2 font-neue-haas"
          >
            ${tier.price}
          </motion.div>
          <p className="text-sm 2xl:text-2xl text-black font-bold font-neue-haas">user/annually</p>
        </div>
        <div className="flex-grow">
          <h4 className="text-sm 2xl:text-2xl font-semibold mt-4 2xl:mt-6 text-black font-neue-haas">Features</h4>
          <ul className="space-y-2 my-4 2xl:my-6">
            {tier.features.map((feature, idx) => (
              <li key={idx} className="flex items-center py-0 2xl:py-2">
                <img src={tier.checkIcon} alt="Checkmark" className="w-4 h-4 2xl:w-8 2xl:h-8 mr-2" />
                <span className="text-sm 2xl:text-2xl">{feature}</span>
              </li>
            ))}
          </ul>
        </div>
        <div className="mt-auto">
          <h4 className="text-sm 2xl:text-2xl font-semibold mb-2 2xl:mb-4 text-black font-neue-haas">{tier.ctaText}</h4>
          <Link to={tier.title.toLowerCase() !== 'starter tier' ? "/contact/sales-support" : "/signup"}>
            <button
              className={`w-full h-8 2xl:h-12 text-sm 2xl:text-2xl font-neue-haas font-bold flex items-center justify-center text-white py-2 rounded-lg transition-colors
          ${tier.title.toLowerCase() === 'starter tier'
                  ? 'bg-primary hover:bg-black text-primary'
                  : 'bg-accent hover:bg-black text-accent'}`}
            >
              {tier.ctaAction}
            </button>
          </Link>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

