import featureIcon1 from '../../../assets/img/icon/feature_icon1.png';
import featureIcon2 from '../../../assets/img/icon/feature_icon2.png';
import featureIcon3 from '../../../assets/img/icon/feature_icon3.png';
import featureIcon4 from '../../../assets/img/icon/feature_icon4.png';
import featureIcon5 from '../../../assets/img/icon/feature_icon5.png';


const key_features_content = [
  {
    title: "Automated Task Execution",
    description: "Our Live AI Sales Rep Assistant seamlessly executes routine tasks, eliminating the need for sales reps to engage in time-consuming manual activities. From data entry to follow-up emails, let the AI handle it all.",
    icon: featureIcon1,
  },
 
  {
    title: "Enhanced Productivity Metrics",
    description: "Track and measure productivity like never before. Our Live AI Sales Rep Assistant provides detailed metrics on task completion, response rates, and other key performance indicators, enabling sales teams to continually refine their strategies.",
    icon: featureIcon5,
  },
  {
    title: "Sales Tool Integration",
    description: "With deep integration into various sales tools, the AI ensures a cohesive experience. It acts as a bridge between different platforms, reducing the need for constant switching and allowing sales reps to access all necessary information in one unified interface.",
    icon: featureIcon3,
  },
  {
    title: "Time Optimization",
    description: "By taking care of repetitive tasks, our AI frees up valuable time for sales reps. This time optimization allows them to concentrate on value-added activities like personalized outreach, relationship building, and strategic planning.",
    icon: featureIcon4,
  },
  
  {
    title: "Intelligent Decision Support",
    description: "By taking care of repetitive tasks, our AI frees up valuable time for sales reps. This time optimization allows them to concentrate on value-added activities like personalized outreach, relationship building, and strategic planning.",
    icon: featureIcon2,
  }
];

export default key_features_content;
