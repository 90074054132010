import React, { useState } from 'react';
import card_dropdown from '../../../assets/img/icon/dropdown.png'; // Ensure correct path

import whyturbo2 from '../../../assets/img/productmobile/whyturbo2.png'
import whyturbo3 from '../../../assets/img/productmobile/whyturbo3.png'
import ImageRevealAnimation from './ImageRevealAnimation';


// DropdownSection Component with Automatic "Why Choose" Handling
const DropdownCard = ({ title, content, isWhyChoose }) => {
  const [dropDownClicked, setDropDownClicked] = useState(false);

  const toggleDropDown = () => {
    setDropDownClicked(!dropDownClicked);
  };

  return (
    <div className="bg-white rounded-2xl px-5 py-6 my-5 shadow-md text-base font-neue-haas">
      {/* Title section with dropdown icon */}
      <div className="flex justify-between items-center cursor-pointer" onClick={toggleDropDown}>
        <h2 className="text-lg font-bold text-gray-800">{title}</h2>
        <img
          src={card_dropdown}
          className={`w-8 h-8 transition-transform duration-300 ${dropDownClicked ? 'rotate-180' : ''}`}
          alt="dropdown_icon"
        />
      </div>

      {/* Dropdown content */}
      {dropDownClicked && (
        <div className="mt-4 text-black">
          {/* Render content directly unless it's Why Choose */}
          {!isWhyChoose ? (
            content
          ) : (
            <ul className="list-disc list-inside space-y-2">
              {content.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  );
};

// Main WhyTurboDropdownCard Component with Multiple Dropdowns
const WhyTurboDropdownCard = () => {
  const [isImageVisible, setIsImageVisible] = useState(false);

  return (
    <div>





      <DropdownCard
        title="Features Overview: Harness the Power of AI in Sales Prospecting"
        content={
          <>
            <div>
              <ul className="list-none pl-0 space-y-4">
                <li className="flex">
                  <div className="bg-[#01f7d3] text-black rounded-full w-8 h-8 px-3 flex items-center justify-center mr-3">
                    1
                  </div>
                  <div>
                    <h4 className="font-semibold">
                      Cloud-Native AI Desktop Application for Unmatched Speed
                    </h4>
                    <p className="font-medium font-work-sans">
                      Experience the best of both worlds with our cloud-native desktop application.
                      Enjoy the speed and flexibility of cloud computing combined with the personalized
                      control of a desktop application, ensuring an exceptional user experience.
                    </p>
                  </div>
                </li>
                <li className="flex">
                  <div className="bg-[#01f7d3] text-black rounded-full w-8 h-8 px-3 flex items-center justify-center mr-3">
                    2
                  </div>
                  <div>
                    <h4 className="font-semibold">AI Account Selection with Gray Matter AI Engine</h4>
                    <p className="font-medium font-work-sans">
                      Unleash the power of AI-driven prospecting. Our Gray Matter AI Engine guides your sales team
                      through account selection with precision, eliminating manual efforts and steering you towards the most
                      promising leads.
                    </p>
                  </div>
                </li>
                <li className="flex">
                  <div className="bg-[#01f7d3] text-black rounded-full w-8 h-8 px-3 flex items-center justify-center mr-3">
                    3
                  </div>
                  <div>
                    <h4 className="font-semibold">AI Flat Files Feature for Streamlined Data Analysis</h4>
                    <p className="font-medium font-work-sans">
                      Bid farewell to data analysis headaches. Our AI Flat Files Feature harnesses sophisticated
                      algorithms to analyze and categorize data effortlessly, saving you time and boosting the accuracy
                      of your insights.
                    </p>
                  </div>
                </li>
                <li className="flex">
                  <div className="bg-[#01f7d3] text-black rounded-full w-8 h-8 px-3 flex items-center justify-center mr-3">
                    4
                  </div>
                  <div>
                    <h4 className="font-semibold">
                      Unified Access Integrations for Seamless Workflows
                    </h4>
                    <p className="font-medium font-work-sans">
                      Streamline your workflow with Unified Access Integrations. No more jumping between tabs –
                      Flow Turbo offers a unified platform that combines various tools, fostering collaboration and
                      maximizing efficiency.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </>
        }
      />

      <DropdownCard
        title="Experience Sales Empowerment in the Cloud: Flow Turbo's Cloud-Native AI Desktop Application"
        content={
          <>
            <p className="font-medium font-work-sans mb-4">
              Welcome to the forefront of innovation with Flow Turbo's Cloud-Native AI Desktop Application.
              Our solution redefines the landscape of sales prospecting by combining the power of artificial
              intelligence with the convenience of a desktop application. Say goodbye to the constraints of traditional
              software - embrace a new era of agility, efficiency, and unparalleled productivity.
            </p>
            <div>
              <h3 className="font-semibold font-work-sans text-lg text-gray-900 mb-3">Key Features</h3>
              <ul className="list-none pl-0 space-y-4">
                <li className="flex">
                  <div className="bg-[#01f7d3] text-black rounded-full w-8 h-8 px-3 flex items-center justify-center mr-3">
                    1
                  </div>
                  <div>
                    <h4 className="font-semibold font-work-sans">Seamless Desktop Experience</h4>
                    <p className="font-medium font-work-sans">
                      Enjoy the familiarity of a desktop environment while harnessing the dynamic capabilities of the cloud.
                      Flow Turbo seamlessly integrates the best of both worlds, providing a user-friendly and powerful interface.
                    </p>
                  </div>
                </li>
                <li className="flex">
                  <div className="bg-[#01f7d3] text-black rounded-full w-8 h-8 px-3 flex items-center justify-center mr-3">
                    2
                  </div>
                  <div>
                    <h4 className="font-semibold font-work-sans">Agile AI Processing</h4>
                    <p className="font-medium font-work-sans">
                      Experience the speed and agility of AI processing right on your desktop. Flow Turbo's cloud-native
                      architecture ensures that the AI engine works effortlessly in the background, delivering real-time
                      insights without compromising performance.
                    </p>
                  </div>
                </li>
                <li className="flex">
                  <div className="bg-[#01f7d3] text-black rounded-full w-8 h-8 px-3 flex items-center justify-center mr-3">
                    3
                  </div>
                  <div>
                    <h4 className="font-semibold font-work-sans">Enhanced Security</h4>
                    <p className="font-medium font-work-sans">
                      Trust in a secure and reliable platform. Flow Turbo's cloud-native design incorporates robust security
                      measures, safeguarding your data and operations against potential threats.
                    </p>
                  </div>
                </li>
                <li className="flex">
                  <div className="bg-[#01f7d3] text-black rounded-full w-8 h-8 px-3 flex items-center justify-center mr-3">
                    4
                  </div>
                  <div>
                    <h4 className="font-semibold font-work-sans">Offline Accessibility</h4>
                    <p className="font-medium font-work-sans">
                      Stay productive even without an internet connection. Flow Turbo's cloud-native desktop application
                      offers offline accessibility, ensuring that your sales operations continue seamlessly regardless of
                      connectivity.
                    </p>
                  </div>
                </li>
                <li className="flex">
                  <div className="bg-[#01f7d3] text-black rounded-full w-8 h-8 px-3 flex items-center justify-center mr-3">
                    5
                  </div>
                  <div>
                    <h4 className="font-semibold font-work-sans">Optimized Resource Utilization</h4>
                    <p className="font-medium font-work-sans">
                      Say goodbye to resource-heavy installations. Flow Turbo's cloud-native design optimizes resource
                      utilization, ensuring that your device's performance remains unaffected while experiencing the
                      power of AI.
                    </p>
                  </div>
                </li>
              </ul>
            </div>

            <div className="mt-6">
              <h3 className="font-semibold font-work-sans text-lg text-gray-900 mb-3">
                Why Choose Flow Turbo's Cloud-Native AI Desktop Application?
              </h3>
              <ul className="list-disc list-inside space-y-2 font-medium font-work-sans">
                <li>Effortless Integration: Seamlessly integrate Flow Turbo into your desktop environment, creating a cohesive and unified workspace for sales excellence.</li>
                <li>Responsive and Adaptive: Experience AI responsiveness without compromise. Flow Turbo adapts to your desktop's capabilities, delivering an agile and efficient prospecting experience.</li>
                <li>Continuous Innovation: Benefit from continuous updates and innovations. Flow Turbo's cloud-native design allows for swift and seamless updates, ensuring you always have access to the latest features and improvements.</li>
                <li>User-Centric Design: Designed with you in mind. Flow Turbo's Cloud-Native AI Desktop Application prioritizes user experience, offering an intuitive interface that aligns with your workflow.</li>
              </ul>
            </div>
          </>
        }
      />

      <ImageRevealAnimation>
        <img src={whyturbo2} alt="" className='w-[363px] h-[220px]' />
      </ImageRevealAnimation>

      <DropdownCard
        title="Revolutionizing Account Selection with the Gray Matter AI Engine"
        content={
          <>
            <p className="font-medium font-work-sans mb-4">
              Unlock the future of sales prospecting with Flow Turbo's cutting-edge AI Account Selection feature, powered by
              the Gray Matter AI Engine. Say goodbye to the inefficiencies of tedious manual account selection and hello to a
              paradigm shift in prospecting.
            </p>

            <div>
              <h3 className="font-semibold text-lg text-gray-900 mb-3">Key Features</h3>
              <ul className="list-none pl-0 space-y-4">
                <li className="flex">
                  <div className="bg-[#01f7d3] text-black rounded-full w-8 h-8 px-3 flex items-center justify-center mr-3">
                    1
                  </div>
                  <div>
                    <h4 className="font-semibold">Neural Network-Powered Precision:</h4>
                    <p className="font-medium font-work-sans">
                      <ul className="list-disc list-inside">
                        <li>Our Gray Matter AI Engine employs advanced neural network algorithms, ensuring unparalleled accuracy in account selection.</li>
                        <li>Say farewell to the laborious task of manually sifting through countless accounts; our AI does it with a precision that transforms your prospecting game.</li>
                      </ul>
                    </p>
                  </div>
                </li>
                <li className="flex">
                  <div className="bg-[#01f7d3] text-black rounded-full w-8 h-8 px-3 flex items-center justify-center mr-3">
                    2
                  </div>
                  <div>
                    <h4 className="font-semibold">Automated Disqualification Magic:</h4>
                    <p className="font-medium font-work-sans">
                      <ul className="list-disc list-inside">
                        <li>Tired of dealing with junk accounts and automatic disqualifications? Let our AI take the reins.</li>
                        <li>The Gray Matter AI Engine performs a nuanced analysis, swiftly identifying and eliminating accounts that don't align with your criteria, saving you precious time and effort.</li>
                      </ul>
                    </p>
                  </div>
                </li>
                <li className="flex">
                  <div className="bg-[#01f7d3] text-black rounded-full w-8 h-8 px-3 flex items-center justify-center mr-3">
                    3
                  </div>
                  <div>
                    <h4 className="font-semibold">Replace Manual Traditional Tools Usage:</h4>
                    <p className="font-medium font-work-sans">
                      <ul className="list-disc list-inside">
                        <li>Flow Turbo isn't just another tool; it's the evolution of sales software. The AI Account Selection feature replaces the need to manually use traditional software tools.</li>
                        <li>No more hopping between platforms; everything you need is seamlessly integrated into Flow Turbo, streamlining your workflow and boosting productivity.</li>
                      </ul>
                    </p>
                  </div>
                </li>
                <li className="flex">
                  <div className="bg-[#01f7d3] text-black rounded-full w-8 h-8 px-3 flex items-center justify-center mr-3">
                    4
                  </div>
                  <div>
                    <h4 className="font-semibold">Replace Manual Traditional Tools Usage:</h4>
                    <p className="font-medium font-work-sans">
                      <ul className="list-disc list-inside">
                        <li>Flow Turbo isn't just another tool; it's the evolution of sales software. The AI Account Selection feature replaces the need to manually use traditional software tools.</li>
                        <li>No more hopping between platforms; everything you need is seamlessly integrated into Flow Turbo, streamlining your workflow and boosting productivity.</li>
                      </ul>
                    </p>
                  </div>
                </li>
              </ul>
            </div>

            <div className="mt-6">
              <h3 className="font-semibold text-lg text-gray-900 mb-3">Why Choose Flow Turbo?</h3>
              <ul className="list-disc list-inside space-y-2 font-medium font-work-sans">
                <li>Innovation Beyond Tools: We're not just providing tools; we're redefining the sales prospecting landscape with the latest in AI technology.</li>
                <li>Precision You Can Trust: The Gray Matter AI Engine undergoes continuous refinement, guaranteeing accuracy and adapting to the dynamic nature of sales prospecting.</li>
                <li>Unparalleled Integration: Say farewell to the chaos of multiple tools. Flow Turbo brings all your sales essentials under one roof, providing a unified platform for your success.</li>
              </ul>
            </div>
          </>
        }
      />

      <ImageRevealAnimation>
        <img src={whyturbo3} alt="" className='w-[363px] h-[220px]' />
      </ImageRevealAnimation>



      <DropdownCard
        title="Unlock Efficiency: AI Flat Files Feature by Flow Turbo"
        content={
          <>
            <p className="font-medium font-work-sans mb-4">
              Welcome to a new era in sales prospecting, where tedious tasks of sifting through flat files become a
              thing of the past. Introducing the AI Flat Files Feature by Flow Turbo, armed with the Gray Matter AI
              Engine, incorporating Neural Network and ML algorithms for unparalleled efficiency.
            </p>

            <div>
              <h3 className="font-semibold text-lg text-gray-900 mb-3">Key Features</h3>
              <ul className="list-none pl-0 space-y-4">
                <li className="flex">
                  <div className="bg-[#01f7d3] text-black rounded-full w-8 h-8 px-3 flex items-center justify-center mr-3">
                    1
                  </div>
                  <div>
                    <h4 className="font-semibold">Automated Precision Analysis:</h4>
                    <p className="font-medium font-work-sans">
                      <ul className="list-disc list-inside">
                        <li>Bid farewell to the laborious task of manually parsing through CSVs or Excel sheets. Our AI Flat Files Feature harnesses the power of neural networks to automate the analysis of vast datasets.</li>
                        <li>Enjoy a refined, error-free process that significantly reduces the risk of overlooking vital information.</li>
                      </ul>
                    </p>
                  </div>
                </li>
                <li className="flex">
                  <div className="bg-[#01f7d3] text-black rounded-full w-8 h-8 px-3 flex items-center justify-center mr-3">
                    2
                  </div>
                  <div>
                    <h4 className="font-semibold">Gray Matter AI Engine Insights:</h4>
                    <p className="font-medium font-work-sans">
                      <ul className="list-disc list-inside">
                        <li>The heart of our system, the Gray Matter AI Engine, continually learns from patterns, ensuring that it not only processes data but understands it.</li>
                        <li>Uncover actionable insights effortlessly, transforming your flat files into strategic assets.</li>
                      </ul>
                    </p>
                  </div>
                </li>
                <li className="flex">
                  <div className="bg-[#01f7d3] text-black rounded-full w-8 h-8 px-3 flex items-center justify-center mr-3">
                    3
                  </div>
                  <div>
                    <h4 className="font-semibold">Time-Saving Automation:</h4>
                    <p className="font-medium font-work-sans">
                      <ul className="list-disc list-inside">
                        <li>No more spending hours on data validation and cross-referencing. The AI Flat Files Feature streamlines your workflow, automating the mundane and allowing your sales team to focus on what matters - closing deals.</li>
                        <li>Accelerate your sales cycle by allocating time where it's needed most.</li>
                      </ul>
                    </p>
                  </div>
                </li>
                <li className="flex">
                  <div className="bg-[#01f7d3] text-black rounded-full w-8 h-8 px-3 flex items-center justify-center mr-3">
                    4
                  </div>
                  <div>
                    <h4 className="font-semibold">Replace Manual Validation:</h4>
                    <p className="font-medium font-work-sans">
                      <ul className="list-disc list-inside">
                        <li>Say goodbye to the manual grind of validating each account and contact. Our AI identifies and flags potential issues, saving you from the pitfalls of overlooked disqualifications.</li>
                        <li>Trust the Gray Matter AI Engine to enforce quality standards without the need for exhaustive manual checks.</li>
                      </ul>
                    </p>
                  </div>
                </li>
                <li className="flex">
                  <div className="bg-[#01f7d3] text-black rounded-full w-8 h-8 px-3 flex items-center justify-center mr-3">
                    5
                  </div>
                  <div>
                    <h4 className="font-semibold">Format-Agnostic Solution:</h4>
                    <p className="font-medium font-work-sans">
                      <ul className="list-disc list-inside">
                        <li>Whether it's a CSV or an Excel sheet, our AI Flat Files Feature is format-agnostic. Embrace flexibility without sacrificing efficiency.</li>
                        <li>Seamlessly integrate your existing data structures, ensuring a smooth transition without the hassle of data reformatting.</li>
                      </ul>
                    </p>
                  </div>
                </li>
              </ul>
            </div>

            <div className="mt-6">
              <h3 className="font-semibold text-lg text-gray-900 mb-3">Why Embrace the AI Flat Files Feature?</h3>
              <ul className="list-disc list-inside space-y-2 font-medium font-work-sans">
                <li>Efficiency Redefined: Transform cumbersome data handling into a streamlined, automated process that liberates your team from repetitive tasks.</li>
                <li>Data Confidence: Rest easy with the assurance that our AI Engine not only processes data but understands it, reducing the risk of oversights.</li>
                <li>Accelerate Decision-Making: Uncover insights faster and make data-driven decisions with the actionable intelligence generated by the Gray Matter AI Engine.</li>
                <li>Tailored for Your Workflow: Embrace an AI solution that integrates seamlessly into your existing flat file structures, adapting to your workflow rather than disrupting it.</li>
              </ul>
            </div>
          </>
        }
      />

      <DropdownCard
        title="Revolutionize Your Workflow: Unified Access Integrations by Flow Turbo"
        content={
          <>
            <p className="font-medium font-work-sans mb-4">
              Welcome to the future of seamless sales operations! Say goodbye to the chaos of managing 10-20+ different
              browser tabs and navigating through various sales tools. Introducing the Unified Access Integrations and
              Integrations Pop-ups feature by Flow Turbo – a game-changer in consolidating your existing sales toolstack into
              one unified, cohesive platform.
            </p>

            <div>
              <h3 className="font-semibold text-lg text-gray-900 mb-3">Key Features</h3>
              <ul className="list-none pl-0 space-y-4">
                <li className="flex">
                  <div className="bg-[#01f7d3] text-black rounded-full w-8 h-8 px-3 flex items-center justify-center mr-3">
                    1
                  </div>
                  <div>
                    <h4 className="font-semibold">Eradicate Browser Tab Overload:</h4>
                    <p className="font-medium font-work-sans">
                      <ul className="list-disc list-inside">
                        <li>Tired of juggling a multitude of tabs for different sales tools? Unified Access Integrations brings everything together, streamlining your workspace and reducing clutter.</li>
                      </ul>
                    </p>
                  </div>
                </li>
                <li className="flex">
                  <div className="bg-[#01f7d3] text-black rounded-full w-8 h-8 px-3 flex items-center justify-center mr-3">
                    2
                  </div>
                  <div>
                    <h4 className="font-semibold">Five-Category Consolidation:</h4>
                    <p className="font-medium font-work-sans">
                      <ul className="list-disc list-inside">
                        <li>Our Unified Access Integrations combine direct access to five diverse sales tool categories, offering a comprehensive solution under one roof.</li>
                        <li>From prospecting to sequencing, bid farewell to the hassle of managing different tools for various tasks.</li>
                      </ul>
                    </p>
                  </div>
                </li>
                <li className="flex">
                  <div className="bg-[#01f7d3] text-black rounded-full w-8 h-8 px-3 flex items-center justify-center mr-3">
                    3
                  </div>
                  <div>
                    <h4 className="font-semibold">Integrations Pop-ups for Instant Action:</h4>
                    <p className="font-medium font-work-sans">
                      <ul className="list-disc list-inside">
                        <li>No more screen switching! Our Integrations Pop-ups feature allows you to perform actions directly from the AI account selection page.</li>
                        <li>Connect with a prospect on LinkedIn, sequence them instantly, or initiate other critical actions without losing focus or time.</li>
                      </ul>
                    </p>
                  </div>
                </li>
                <li className="flex">
                  <div className="bg-[#01f7d3] text-black rounded-full w-8 h-8 px-3 flex items-center justify-center mr-3">
                    4
                  </div>
                  <div>
                    <h4 className="font-semibold">Enhanced Speed and Productivity:</h4>
                    <p className="font-medium font-work-sans">
                      <ul className="list-disc list-inside">
                        <li>Accelerate your daily operations by eliminating the need to move between numerous tabs. With Unified Access Integrations, your team can perform tasks more efficiently, saving valuable time.</li>
                      </ul>
                    </p>
                  </div>
                </li>
                <li className="flex">
                  <div className="bg-[#01f7d3] text-black rounded-full w-8 h-8 px-3 flex items-center justify-center mr-3">
                    5
                  </div>
                  <div>
                    <h4 className="font-semibold">AI-Driven Sequencing:</h4>
                    <p className="font-medium font-work-sans">
                      <ul className="list-disc list-inside">
                        <li>Our AI-enhanced sequencing tool ensures that your communication with prospects is not just efficient but also personalized.</li>
                        <li>Send targeted messages directly from the AI account selection page, reducing manual steps and enhancing the effectiveness of your outreach.</li>
                      </ul>
                    </p>
                  </div>
                </li>
              </ul>
            </div>

            <div className="mt-6">
              <h3 className="font-semibold text-lg text-gray-900 mb-3">Why Choose Unified Access Integrations?</h3>
              <ul className="list-disc list-inside space-y-2 font-medium font-work-sans">
                <li>Efficiency Unleashed: Centralize your sales operations into one unified cohesive platform, minimizing the complexity of handling multiple tools simultaneously.</li>
                <li>Streamlined Workflow: Enjoy a seamless workflow where AI and integrations work hand in hand, eliminating the need for constant tab-switching.</li>
                <li>Time-Saving Actions: Instantly perform critical actions through Integrations Pop-ups, reducing the time spent on manual processes and boosting productivity.</li>
                <li>Tailored for Sales Success: Unified Access Integrations is not just a consolidation tool; it's a strategic solution designed to enhance your team's overall efficiency and success.</li>
              </ul>
            </div>
          </>
        }
      />

      <DropdownCard
        title="Elevate Your Sales Game: Flow Turbo's Existing Toolstack Integrations"
        content={
          <>
            <p className="font-medium font-work-sans mb-4">
              Welcome to the era where your sales toolstack isn't a patchwork of disconnected solutions but a harmonious
              symphony of powerful, integrated tools. Flow Turbo introduces a groundbreaking solution that seamlessly
              integrates with your existing sales toolstack, providing a unified platform that enhances every aspect of your sales process.
            </p>

            <div>
              <h3 className="font-semibold text-lg text-gray-900 mb-3">Key Features</h3>
              <ul className="list-none pl-0 space-y-4">
                <li className="flex">
                  <div className="bg-[#01f7d3] text-black rounded-full w-8 h-8 px-3 flex items-center justify-center mr-3">
                    1
                  </div>
                  <div>
                    <h4 className="font-semibold">Lead Source Databases:</h4>
                    <p className="font-medium font-work-sans">
                      <ul className="list-disc list-inside">
                        <li>Effortlessly connect with various lead source databases. Flow Turbo ensures compatibility with a range of brands, bringing an extensive pool of prospects at your fingertips.</li>
                      </ul>
                    </p>
                  </div>
                </li>
                <li className="flex">
                  <div className="bg-[#01f7d3] text-black rounded-full w-8 h-8 px-3 flex items-center justify-center mr-3">
                    2
                  </div>
                  <div>
                    <h4 className="font-semibold">CRM Systems Integration:</h4>
                    <p className="font-medium font-work-sans">
                      <ul className="list-disc list-inside">
                        <li>No need to switch between platforms. Integrate Flow Turbo directly into your CRM system, facilitating a smooth flow of data and ensuring that your customer information is always up-to-date.</li>
                      </ul>
                    </p>
                  </div>
                </li>
                <li className="flex">
                  <div className="bg-[#01f7d3] text-black rounded-full w-8 h-8 px-3 flex items-center justify-center mr-3">
                    3
                  </div>
                  <div>
                    <h4 className="font-semibold">Sequencing Tools Harmony:</h4>
                    <p className="font-medium font-work-sans">
                      <ul className="list-disc list-inside">
                        <li>Achieve coherence in your outreach strategies by integrating with different sequencing tools. Whether you prefer automation or a more personalized approach, Flow Turbo seamlessly adapts to your preferred sequencing tool.</li>
                      </ul>
                    </p>
                  </div>
                </li>
                <li className="flex">
                  <div className="bg-[#01f7d3] text-black rounded-full w-8 h-8 px-3 flex items-center justify-center mr-3">
                    4
                  </div>
                  <div>
                    <h4 className="font-semibold">Communication Tools Alignment:</h4>
                    <p className="font-medium font-work-sans">
                      <ul className="list-disc list-inside">
                        <li>Connect your communication tools effortlessly. Flow Turbo ensures that your messaging platforms, emails, and other communication tools work in tandem, eliminating the need for constant tab-switching.</li>
                      </ul>
                    </p>
                  </div>
                </li>
                <li className="flex">
                  <div className="bg-[#01f7d3] text-black rounded-full w-8 h-8 px-3 flex items-center justify-center mr-3">
                    5
                  </div>
                  <div>
                    <h4 className="font-semibold">Ad Hoc Tool Compatibility:</h4>
                    <p className="font-medium font-work-sans">
                      <ul className="list-disc list-inside">
                        <li>Have a specific tool your team loves? No problem. Flow Turbo is designed to integrate seamlessly with a variety of ad hoc tools, ensuring that your preferred solutions remain part of your workflow.</li>
                      </ul>
                    </p>
                  </div>
                </li>
              </ul>
            </div>

            <div className="mt-6">
              <h3 className="font-semibold text-lg text-gray-900 mb-3">Why Choose Flow Turbo Integrations?</h3>
              <ul className="list-disc list-inside space-y-2 font-medium font-work-sans">
                <li>Enhanced Efficiency: Streamline your processes by bringing all your tools under one umbrella. No more switching tabs; focus on what matters – closing deals.</li>
                <li>Data Consistency: Integrate your CRM and other data sources to maintain consistency. Flow Turbo ensures that your team works with the most accurate and updated information.</li>
                <li>Adaptability to Your Workflow: Regardless of your tool preferences, Flow Turbo adapts. Enjoy the freedom to use the tools that work best for your team while still benefiting from the power of integration.</li>
                <li>Accelerated Sales Cycles: With everything effortlessly connected, your sales cycles become shorter. Reduce manual efforts, automate where needed, and see results faster.</li>
              </ul>
            </div>
          </>
        }
      />

      <DropdownCard
        title="Unlocking Value: Clear Use Cases and Benefits"
        content={
          <>
            <p className="font-medium font-work-sans mb-4">
              Sales Teams: Equip your sales team with the ultimate tool for prospecting success. Flow Turbo enables efficient
              account selection, targeted outreach, and data-driven decision-making. Increased speed, accelerated days for
              sales reps – resulting in increased conversions, collapsed time, shortened sales cycles, and higher revenue.
            </p>
            <p className="font-medium font-work-sans mb-4">
              Business Development: Expand your sales and tap into new accounts with confidence. Leverage our AI-powered
              solution to identify qualified accounts, relevant decision makers, lucrative opportunities, streamline lead
              generation, and penetrate untapped businesses effectively.
            </p>
            <p className="font-medium font-work-sans mb-4">
              In the dynamic realm of B2B sales, staying ahead demands more than routine strategies. Introducing Flow Turbo
              – the revolutionary SaaS AI Sales Prospecting Solution designed for B2B sales organizations ready to elevate
              their game.
            </p>

            <div>
              <h3 className="font-semibold text-lg text-gray-900 mb-3">Key Features</h3>
              <ul className="list-none pl-0 space-y-4">
                <li className="flex">
                  <div className="bg-[#01f7d3] text-black rounded-full w-8 h-8 px-3 flex items-center justify-center mr-3">
                    1
                  </div>
                  <div>
                    <h4 className="font-semibold">Precise Account Selection:</h4>
                    <p className="font-medium font-work-sans">
                      <ul className="list-disc list-inside">
                        <li>Turbocharge your account selection process with AI precision. Flow Turbo sifts through vast data oceans to pinpoint prospects aligned with your ideal customer profile without automatic disqualifications.</li>
                      </ul>
                    </p>
                  </div>
                </li>
                <li className="flex">
                  <div className="bg-[#01f7d3] text-black rounded-full w-8 h-8 px-3 flex items-center justify-center mr-3">
                    2
                  </div>
                  <div>
                    <h4 className="font-semibold">Automated Prospect Qualification:</h4>
                    <p className="font-medium font-work-sans">
                      <ul className="list-disc list-inside">
                        <li>Say goodbye to manual qualification processes. Our Gray Matter AI Engine utilizes ML algorithms to automatically qualify leads, ensuring you spend time on prospects most likely to convert.</li>
                      </ul>
                    </p>
                  </div>
                </li>
                <li className="flex">
                  <div className="bg-[#01f7d3] text-black rounded-full w-8 h-8 px-3 flex items-center justify-center mr-3">
                    3
                  </div>
                  <div>
                    <h4 className="font-semibold">Unified Access Integrations:</h4>
                    <p className="font-medium font-work-sans">
                      <ul className="list-disc list-inside">
                        <li>No more juggling multiple tools. Flow Turbo brings together lead source databases, CRM systems, sequencing tools, and more into one unified platform, streamlining your workflow.</li>
                      </ul>
                    </p>
                  </div>
                </li>
                <li className="flex">
                  <div className="bg-[#01f7d3] text-black rounded-full w-8 h-8 px-3 flex items-center justify-center mr-3">
                    4
                  </div>
                  <div>
                    <h4 className="font-semibold">AI Flat Files Feature:</h4>
                    <p className="font-medium font-work-sans">
                      <ul className="list-disc list-inside">
                        <li>Revolutionize your data analysis. Flow Turbo's AI Flat Files Feature enables seamless processing of thousands of accounts, eliminating the manual effort in research, analysis, and validation.</li>
                      </ul>
                    </p>
                  </div>
                </li>
                <li className="flex">
                  <div className="bg-[#01f7d3] text-black rounded-full w-8 h-8 px-3 flex items-center justify-center mr-3">
                    5
                  </div>
                  <div>
                    <h4 className="font-semibold">Scalable Cloud-Native Desktop App:</h4>
                    <p className="font-medium font-work-sans">
                      <ul className="list-disc list-inside">
                        <li>Access the power of Flow Turbo from anywhere. Our cloud-native desktop application ensures scalability and flexibility, empowering your team to be productive on the go.</li>
                      </ul>
                    </p>
                  </div>
                </li>
              </ul>
            </div>

            <div className="mt-6">
              <h3 className="font-semibold text-lg text-gray-900 mb-3">Benefits for B2B Sales Organizations:</h3>
              <ul className="list-disc list-inside space-y-2 font-medium font-work-sans">
                <li>Increased Efficiency: Streamline your sales operations and focus on high-value activities with automated lead selection and qualification.</li>
                <li>Maximized Productivity: Unified access and integrations reduce the need for multiple tools, increasing your team's efficiency and reducing manual effort.</li>
                <li>Data-Driven Decision-Making: Leverage AI-powered insights for strategic decision-making, ensuring your sales efforts are data-backed and effective.</li>
                <li>Enhanced Customer Engagement: With precise lead selection, you can engage with prospects that truly matter, leading to improved conversion rates.</li>
                <li>Future-Ready Sales: Stay ahead of the curve with Flow Turbo's cutting-edge features, positioning your sales team as industry leaders.</li>
              </ul>
            </div>
          </>
        }
      />

      <DropdownCard
        title="Envision Your Sales Organization Down-The-Road"
        content={
          <>
            <p className="font-medium font-work-sans mb-4">
              In the current realm of sales, the processes are characterized by a slow, manual, and mundane rhythm that
              often feels exhaustive, boring, and painfully repetitive. Sales reps find themselves caught in a
              time-consuming cycle, struggling to break free from the monotony. The current state of affairs is not just
              limiting; it's draining the life out of your sales team.
            </p>
            <p className="font-medium font-work-sans mb-4">
              Now, envision a future where your sales process is not just a routine but a symphony of efficiency,
              innovation, and boundless potential. Picture a landscape where the SaaS AI sales prospecting solution
              transforms the ordinary into the extraordinary, liberating your sales reps from the shackles of repetitive
              actions. In this new reality, every engagement propels your team toward increased conversions, turning mundane
              tasks into opportunities for financial success. Embrace a world where your sales organization thrives on the
              cutting edge—a world where the AI-powered prospecting solution is a game-changer, a beacon of transformation.
            </p>
            <p className="font-medium font-work-sans mb-4">
              Your sales reps, once confined by monotony, now operate in a realm of limitless possibilities. This isn't just
              a product; it's a revolution—a groundbreaking shift that promises to put money in your pocket, elevate your
              sales team to new heights, and bring a sense of awe and enlightenment to every interaction. Step into this
              future, where the ordinary is replaced by the extraordinary, and watch as your sales organization ascends to
              unparalleled success. Ready to redefine your B2B sales journey? Discover Flow Turbo - where innovation meets
              sales excellence. Your sales organization's success story starts here.
            </p>
            <p className="font-medium font-work-sans mb-4">
              It's Simpler to Get Started At Flow, we believe that unlocking the power of AI for your sales team should be a
              breeze. Getting started is simpler than ever, designed with you in mind. Here's what the Flow experience
              promises:
            </p>

            <div>
              <h3 className="font-semibold text-lg text-gray-900 mb-3">Key Features</h3>
              <ul className="list-none pl-0 space-y-4">
                <li className="flex">
                  <div className="bg-[#01f7d3] text-black rounded-full w-8 h-8 px-3 flex items-center justify-center mr-3">
                    1
                  </div>
                  <div>
                    <h4 className="font-semibold">Intuitive Onboarding:</h4>
                    <p className="font-medium font-work-sans">
                      <ul className="list-disc list-inside">
                        <li>Our user-friendly interface ensures a smooth start, with intuitive steps guiding you through the setup process.</li>
                      </ul>
                    </p>
                  </div>
                </li>
                <li className="flex">
                  <div className="bg-[#01f7d3] text-black rounded-full w-8 h-8 px-3 flex items-center justify-center mr-3">
                    2
                  </div>
                  <div>
                    <h4 className="font-semibold">Rapid Deployment:</h4>
                    <p className="font-medium font-work-sans">
                      <ul className="list-disc list-inside">
                        <li>No lengthy waiting periods. Start benefiting from AI-driven insights almost instantly after setup, enhancing your prospecting efforts immediately.</li>
                      </ul>
                    </p>
                  </div>
                </li>
                <li className="flex">
                  <div className="bg-[#01f7d3] text-black rounded-full w-8 h-8 px-3 flex items-center justify-center mr-3">
                    3
                  </div>
                  <div>
                    <h4 className="font-semibold">Streamlined Integration:</h4>
                    <p className="font-medium font-work-sans">
                      <ul className="list-disc list-inside">
                        <li>Easily integrate Flow with your existing sales tools, ensuring a seamless fit into your workflow without disruptions.</li>
                      </ul>
                    </p>
                  </div>
                </li>
                <li className="flex">
                  <div className="bg-[#01f7d3] text-black rounded-full w-8 h-8 px-3 flex items-center justify-center mr-3">
                    4
                  </div>
                  <div>
                    <h4 className="font-semibold">Guided Onboarding:</h4>
                    <p className="font-medium font-work-sans">
                      <ul className="list-disc list-inside">
                        <li>Our platform is crafted to empower all users, regardless of technical expertise. Guided onboarding sessions and community resources help you make the most out of Flow's capabilities.</li>
                      </ul>
                    </p>
                  </div>
                </li>
                <li className="flex">
                  <div className="bg-[#01f7d3] text-black rounded-full w-8 h-8 px-3 flex items-center justify-center mr-3">
                    5
                  </div>
                  <div>
                    <h4 className="font-semibold">Clear Value Proposition:</h4>
                    <p className="font-medium font-work-sans">
                      <ul className="list-disc list-inside">
                        <li>From day one, experience the value of AI in your sales prospecting efforts. Target the right accounts, eliminate manual research, and turbocharge your team’s productivity. Start making more money and increase the commissions that your sales reps receive.</li>
                      </ul>
                    </p>
                  </div>
                </li>
              </ul>
            </div>

            <p className="font-medium font-work-sans mt-6">
              Getting started with Flow means embracing a new era of efficiency in your sales strategy. Join the future of
              prospecting with Flow, where simplicity meets unmatched performance.
            </p>
          </>
        }
      />







    </div>
  );
};

export default WhyTurboDropdownCard;
