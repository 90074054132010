import React, { useState } from "react";
import axios from "axios";
import "../../assets/css/SalesSupport.css";
import Footer from "../../components/Footer";
import { Button } from "@mui/material";

const SalesSupportInput = ({ name, placeholder, error, onChange }) => {
  return (
    <>
      <input
        className="sales-support_input"
        name={name}
        placeholder={`${placeholder}*`}
        onChange={onChange}
      />
      <div className="error-validation">{error}</div>
    </>
  );
};

const SalesSupport = () => {
  const [formData, setFormData] = useState({
    FirstName: "",
    LastName: "",
    Title: "",
    Email: "",
    Phone: "",
    Company: "",
    form_type: "SalesSupport", // To differentiate between sales support and landing page
  });

  const [errors, setErrors] = useState({
    FirstName: "",
    LastName: "",
    Title: "",
    Email: "",
    Phone: "",
    Company: "",
  });

  const [submitted, setSubmitted] = useState(false);
  const [message, setMessage] = useState("");

  const freeEmailDomains = [
    "gmail.com",
    "yahoo.com",
    "hotmail.com",
    "outlook.com",
    "icloud.com",
    "aol.com",
    "protonmail.com",
    "zoho.com",
    "mail.com",
  ];

  const validateField = (name, value) => {
    let error = "";
    if (name === "Email" && value) {
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
        error = "Please use a valid email address";
      } else if (freeEmailDomains.includes(value.split("@")[1])) {
        error = "Please use a valid business email address";
      }
    }
    if (
      name === "Phone" &&
      value &&
      !/^(1\s|1|)?((\(\d{3}\))|\d{3})(-|\s)?(\d{3})(-|\s)?(\d{4})$/.test(value)
    ) {
      error = "Please enter a valid phone number";
    } else if (value === "") {
      error = `${name.split(/(?=[A-Z])/).join(" ")} is required`;
    }
    return error;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    const error = validateField(name, value);
    setErrors((prevState) => ({
      ...prevState,
      [name]: error,
    }));
  };

  const validateForm = (formData) => {
    let formErrors = {};
    for (const [name, value] of Object.entries(formData)) {
      formErrors[name] = validateField(name, value);
    }
    setErrors(formErrors);
    return Object.values(formErrors).every((error) => !error);
  };

  const submitForm = async (e) => {
    e.preventDefault();

    if (!validateForm(formData)) {
      return;
    }

    const payload = {
      ...formData,
      form_type: "SalesSupport", // Manually add the form_type
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}api/leads/`, // Use the API from .env
        payload
      );
      if (response.status === 201 || response.status === 200) {
        setSubmitted(true);
        setMessage("Details saved successfully.");
      }
    } catch (error) {
      setMessage("Error: " + error.message);
    }
  };

  return (
    <>
      <div className="fadeInAnimation sales-support">
        <div>
          <div className="large bold">
            Explore our solution by <br /> scheduling a free tour
          </div>
          <div className="medium2">
            Please complete the form below to reach a Flow senior consultant.
          </div>
        </div>
        <div className="sales-support_two-item-block">
          <div>
            <div className="medium semi-bold achievements-header">
              Big achievements
              <br />
              with Flow Turbo
            </div>
            <div className="achievements">
              <div className="achievement">
                <ul className="achievement-marker">
                  <li className="achievement-text">
                    <div className="bold">Maximum Efficiency</div>
                    <div>Save your time from performing mundane activities</div>
                  </li>
                </ul>
                <div>
                  <img
                    src="/assets/images/increased-efficiency.png"
                    alt=""
                    className="achievement-image"
                  />
                </div>
              </div>
              <div className="achievement">
                <ul className="achievement-marker">
                  <li className="achievement-text">
                    <div className="bold">Achieve Sales Quota</div>
                    <div>Achieve a 300 call day with no DQs</div>
                  </li>
                </ul>
                <div>
                  <img
                    src="/assets/images/improved-sales-quota.png"
                    alt=""
                    className="achievement-image"
                  />
                </div>
              </div>
              <div className="achievement">
                <ul className="achievement-marker">
                  <li className="achievement-text">
                    <div className="bold">Unified Cohesive Platform</div>
                    <div>
                      Eliminate 30-40 browser tabs for more capabilities
                    </div>
                  </li>
                </ul>
                <div>
                  <img
                    src="/assets/images/unified-cohesive-platform.png"
                    alt=""
                    className="achievement-image"
                  />
                </div>
              </div>
            </div>
          </div>
          <form
            className="sales-support-form_container"
            onSubmit={submitForm}
            id="form"
          >
            <input type="hidden" name="form_type" value="SalesSupport" />
            <div className="sales-support-form">
              <div className="sales-support_two-item-block">
                <div className="sales-support_input-container">
                  <SalesSupportInput
                    name="FirstName"
                    placeholder="First Name"
                    error={errors.FirstName}
                    onChange={handleChange}
                  />
                </div>
                <div className="sales-support_input-container">
                  <SalesSupportInput
                    name="LastName"
                    placeholder="Last Name"
                    error={errors.LastName}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="sales-support_input-container">
                <SalesSupportInput
                  name="Title"
                  placeholder="Title"
                  error={errors.Title}
                  onChange={handleChange}
                />
              </div>
              <div className="sales-support_two-item-block">
                <div className="sales-support_input-container">
                  <SalesSupportInput
                    name="Email"
                    placeholder="Work Email"
                    error={errors.Email}
                    onChange={handleChange}
                  />
                </div>
                <div className="sales-support_input-container">
                  <SalesSupportInput
                    name="Phone"
                    placeholder="Work Phone"
                    error={errors.Phone}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="sales-support_input-container">
                <SalesSupportInput
                  name="Company"
                  placeholder="Company Name"
                  error={errors.Company}
                  onChange={handleChange}
                />
              </div>
              <div className="privacy-policy">
                By submitting your information, you agree to our{" "}
                <a href="/privacy">Privacy policy</a>.
              </div>
            </div>
          </form>
        </div>
        <div className="sales-support_right-block">
          <Button
            type="submit"
            variant="contained"
            className="primary-btn"
            form="form"
          >
            Submit
          </Button>
        </div>
        {submitted && <div className="responseMessage">{message}</div>}
      </div>
      <Footer />
    </>
  );
};

export default SalesSupport;
