import React, { useState } from 'react';
import BillingInfoForm from './BillingInfoForm';
import { useLocation } from 'react-router-dom';
import Footer from '../../components/Footer'
import SuccessfulPurchase from './SuccessfulPurchase';

const Billing = () => {
  const location = useLocation();
  const { tierSelection, formData } = location.state || {};
  const baseUrlDev = "https:/sandbox.api.intuit.com/quickbooks/v4/payments/tokens";
  const backendUrl = "http://127.0.0.1:8000/api/integrations/quickbooks/process-payment"
  const [isPurchasedSuccessfully,setIsPurchasedSuccessfully] = useState(true)
  const handleBillingSubmit = async (billingData) => {

    // Use QuickBooks Payments API to tokenize the billing information
    const token = await tokenizeCreditCard(billingData);

      const requestBody = {
        "msg": {
          "currency": "USD", 
          "amount": billingData.cost, 
          "context": {
            "mobile": "false", 
            "isEcommerce": "true"
          }, 
          "token": token, 
        },
        "company-info": formData
      }

    // proccess the purchase
    try {
      const res  = await fetch(backendUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody),
      })

      if (res.status >= 200 && res.status < 300) {
        setIsPurchasedSuccessfully(true);
      }
    }
    catch(e) {
      console.log(e)
    }
  };

  const tokenizeCreditCard = async (creditCardData) => {
    try {
      const response = await fetch(baseUrlDev, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(creditCardData.cardObject),
      });

      if (!response.ok) throw new Error(`HTTP error, Status: ${response.status}`);

      return (await response.json()).value;
    } catch (error) {
      console.error('Error tokenizing credit card:', error.message);
      throw error;
    }
  };

  return (
    <>
    {!isPurchasedSuccessfully && (
    <BillingInfoForm
      onSubmit={(billingData) => handleBillingSubmit(billingData)}
      tierSelection={tierSelection}
      users={formData.userEmailAddressesArr}
    />
    )}
    {isPurchasedSuccessfully && <SuccessfulPurchase/>}
    <Footer />
    </>
  );
};

export default Billing;

