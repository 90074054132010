import React, { useState } from 'react';
import './readMoreCard.css';

const ReadMoreCard = ({ id, imageSrc, title, text, amountOfWords = 22 }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const splittedText = text.split(' ');
  const itCanOverflow = splittedText.length > amountOfWords;
  const beginText = itCanOverflow
    ? splittedText.slice(0, amountOfWords - 1).join(' ')
    : text;
  const endText = splittedText.slice(amountOfWords - 1).join(' ');

  const handleKeyboard = (e) => {
    if (e.code === 'Space' || e.code === 'Enter') {
      setIsExpanded(!isExpanded);
    }
  };

  return (
    <div className="read-more-card" id={id}>
      <div className="image-container">
        <img src={imageSrc} alt={title} className="circular-image" />
      </div>
      <div className="custom-title">{title}</div> 
      <div className="custom-text"> 
        {beginText}
        {itCanOverflow && (
          <>
            {!isExpanded && <span style={{ color: '#000000' }} >... </span>}
            <span style ={{ color: '#000000' }}  className={`${!isExpanded && 'hidden'}`} aria-hidden={!isExpanded}>
              {endText}
            </span>
            <span
              className="read-more-button"
              role="button"
              tabIndex={0}
              aria-expanded={isExpanded}
              aria-controls={id}
              onKeyDown={handleKeyboard}
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {isExpanded ? 'read less' : 'read more'}
            </span>
          </>
        )}
      </div>
    </div>
  );
};

export default ReadMoreCard;
