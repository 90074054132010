import "./BillingManagementStyles.css";
import { useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { CiCreditCard1 } from "react-icons/ci";
import { CiBank } from "react-icons/ci";
import { FaStripeS } from "react-icons/fa";
import { FaPaypal } from "react-icons/fa";
import { SiQuickbooks } from "react-icons/si";

function BillingPaymentDetails() {
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");
  const [billingDetails, setBillingDetails] = useState(true);
  const payment_methods = {
    card: true,
    bank: false,
    paypal: false,
    stripe: false,
    quickbooks: false,
  };
  const [paymentMethod, setPaymentMethod] = useState(payment_methods);

  const selectCountry = (val) => setCountry(val);
  const selectRegion = (val) => setRegion(val);

  const navigate = useNavigate();
  //   const [set]
  const accountID = "12345";
  return (
    <>
      <div className="w-[100%] flex justify-start mb-5">
        <FaArrowLeft
          onClick={() => {
            navigate(`/account/${accountID}/billing-payments`);
          }}
          className="cursor-pointer"
        />
      </div>
      <div className="w-[100%] flex justify-start border-b-2">
        <h1
          className={`text-lg pr-[80px] pl-5  cursor-pointer ${
            billingDetails ? "border-[#00f6d3] font-bold border-b-2" : ""
          }`}
          onClick={() => setBillingDetails(true)}
        >
          Billing Details
        </h1>
        <h1
          className={`text-lg pr-[80px] pl-5 cursor-pointer ${
            !billingDetails ? "border-[#00f6d3] font-bold border-b-2" : ""
          }`}
          onClick={() => setBillingDetails(false)}
        >
          Payment Method
        </h1>
      </div>
      {billingDetails ? (
        <div className="py-8">
          <h1 className="font-extrabold text-4xl mb-8">Your billing address</h1>
          <h3 className="font-bold text-[1.2rem] mb-5">
            Edit your billing address
          </h3>
          <form>
            <div className="w-[100%] flex flex-col gap-3">
              <div className="w-[100%] flex gap-4">
                <div className="w-[50%]">
                  <label>Company name *</label>
                  <input
                    type="text"
                    id="company_name"
                    className="input-style"
                  />
                </div>
                <div className="w-[50%]"></div>
              </div>
              <div className="w-[100%] flex gap-2">
                <div className="w-[50%]">
                  <label>Country *</label>
                  <CountryDropdown
                    value={country}
                    onChange={(val) => selectCountry(val)}
                    className="input-style"
                  />
                </div>
                <div className="w-[50%]">
                  <label>City *</label>
                  <input
                    type="text"
                    id="company_name"
                    className="input-style"
                  />
                </div>
              </div>
              <div className="w-[100%] flex gap-2">
                <div className="w-[50%]">
                  <label>Address 1 *</label>
                  <input
                    type="text"
                    id="company_name"
                    className="input-style"
                  />
                </div>
                <div className="w-[50%]">
                  <label>Address 2</label>
                  <input
                    type="text"
                    id="company_name"
                    className="input-style"
                  />
                </div>
              </div>
              <div className="w-[100%] flex gap-2">
                <div className="w-[50%]">
                  <label>State *</label>
                  <RegionDropdown
                    country={country}
                    value={region}
                    onChange={(val) => selectRegion(val)}
                    className="input-style"
                  />
                </div>
                <div className="w-[50%]">
                  <label>Postal code *</label>
                  <input
                    type="text"
                    id="company_name"
                    className="input-style"
                  />
                </div>
              </div>
              <div className="w-[100%] flex gap-2">
                <div className="w-[50%]">
                  <label>Tax ID *</label>
                  <input
                    type="text"
                    id="company_name"
                    className="input-style"
                  />
                </div>
                <div className="w-[50%]"></div>
              </div>
              <div className="w-[100%] flex gap-2">
                <div className="w-[50%]"></div>
                <div className="w-[50%] flex justify-end gap-3">
                  <button className="w-[30%] inverted">Cancel</button>
                  <button className="w-[30%]">Save</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div className="py-8">
          <h1 className="font-extrabold text-4xl mb-8">Add payment method</h1>
          <h3 className="font-light text-[1.2rem] mb-5">
            Your payment details are encrypted for security
          </h3>
          <div className="w-[100%] flex justify-start mb-5">
            <div className="w-[60%] flex flex-col gap-5">
              <div className="w-[100%] flex gap-3">
                <div className="payment-select-card active">
                  <CiCreditCard1 />
                  <p className="text-sm">Credit Card</p>
                </div>
                <div className="payment-select-card">
                  <CiBank />
                  <p className="text-sm">Bank ACH / Wire Transfer</p>
                </div>
              </div>
              <div className="w-[100%] flex gap-3">
                <div className="payment-select-card">
                  <FaPaypal />
                  <p className="text-sm">Paypal</p>
                </div>
                <div className="payment-select-card">
                  <FaStripeS />
                  <p className="text-sm">Stripe</p>
                </div>
                <div className="payment-select-card">
                  <SiQuickbooks />
                  <p className="text-sm">Quickbooks</p>
                </div>
              </div>
              <div className="w-[100%]">
                <p className="text-sm">
                  We accept all major credit/debit cards. For help with other
                  ways to pay, contact support
                </p>
              </div>
            </div>
          </div>
          <div className="w-[100%] flex gap-5 mb-5">
            <div className="w-[50%]">
              <form className="flex flex-col gap-3">
                <div className="w-[100%]">
                  <label>Credit card number</label>
                  <input
                    type="text"
                    id="card_number"
                    className="input-style"
                    placeholder="1234 1234 1234"
                  />
                </div>
                <div className="w-[100%]">
                  <label>Name on card</label>
                  <input
                    type="text"
                    id="card_holder_name"
                    className="input-style"
                  />
                </div>
                <div className="w-[100%]">
                  <label>Expiry Date</label>
                  <input
                    type="text"
                    id="expiry_date"
                    className="input-style"
                    placeholder="MM/YY"
                  />
                </div>
                <div className="w-[100%]">
                  <label>Security Code</label>
                  <input
                    type="text"
                    id="cvc"
                    className="input-style"
                    placeholder="CVC"
                  />
                </div>
              </form>
            </div>
            <div className="w-[50%] flex flex-col gap-2">
              <p className="text-sm">
                Flow will use this as the default payment method for future
                payment on this account. You can edit this later, or remove it
                by contacting support.
              </p>
              <p className="text-sm">
                Your billing statement will display Flow located in Austin,
                Texas, our principle place of business.
              </p>
              <p className="text-sm">
                Your credit card issuer may charge foreign transaction or
                cross-border fees in addition to the total price.
              </p>
            </div>
          </div>
          <div className="w-[100%] flex gap-2">
            <div className="w-[50%]"></div>
            <div className="w-[50%] flex justify-end gap-3">
              <button className="w-[30%] inverted">Back</button>
              <button className="w-[30%]">Confirm</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default BillingPaymentDetails;
