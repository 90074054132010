import React from 'react'

import IntroComponent from '../../components/Product/MobileComponents/IntroComponent';
import KeyFeaturesComponent from '../../components/Product/MobileComponents/KeyFeaturesComponent';
import BenefitsComponent from '../../components/Product/MobileComponents/BenefitsComponent';
import FlowTurboComponent from '../../components/Product/MobileComponents/FlowTurboComponent';
import ProductTourComponent from '../../components/Product/MobileComponents/ProductTourComponent';

import Footer from '../../components/Footer.js';



const ProductMobile = () => {
    return (
        
     <div className='font-neue-haas'>
            <IntroComponent></IntroComponent>
            <KeyFeaturesComponent title={'Testing the card'} details={''}></KeyFeaturesComponent>
            <BenefitsComponent></BenefitsComponent>
            <FlowTurboComponent></FlowTurboComponent>
            <ProductTourComponent></ProductTourComponent>
            <Footer></Footer>

     </div>

        
        
      );
};


export default ProductMobile;