
import React from 'react';
import './Billing.css'; 
import successfull_checkmark from '../../assets/img/successful-checkmark.png'

const SuccessfulPurchase = () => {
  return (
    <div className="success-container grey-bkg">
      <div className="success-content white-bkg">
        <h2 className="success-heading">Thank You for Your Purchase!</h2>
        <img className='checkmarkImg' src={successfull_checkmark} alt="" />
        <p className="success-text">Your purchase has been successfully processed.</p>
        <p className="success-text">We will get in contact with you regarding next steps.</p>
      </div>
    </div>
  );
};

export default SuccessfulPurchase;
