import "./BillingManagementStyles.css";

function BillingManagement() {
  return (
    <>
      <h2 className="font-light text-lg mb-5">Admin/company-organization</h2>
      <h1 className="font-extrabold text-4xl mb-8">Billing Overview</h1>
      <h2 className="font-light text-lg mb-10">
        Centrally manage and view a summary of all your product and app
        subscriptions.
      </h2>
      <h3 className="font-bold text-[1.2rem] mb-5">Billing Overview</h3>
      <table>
        <thead>
          <tr>
            <th className="table-heading w-[40%]">Product</th>
            <th className="table-heading w-[10%]">Plan</th>
            <th className="table-heading w-[10%]">Users</th>
            <th className="table-heading w-[30%]">Next Billing Date</th>
            <th className="table-heading w-[10%]">Actions</th>
          </tr>
        </thead>
        <tbody>
          {/* Below is where the data goes */}
          <tr>
            <td className="table-data" id="Product">
              <h1>Flow Turbo</h1>
              <p>https://company-organization.flow.net</p>
            </td>
            <td className="table-data" id="Plan">
              <h1>$1080</h1>
            </td>
            <td className="table-data" id="Users">
              <p>5</p>
            </td>
            <td className="table-data" id="Next-Billing-Date">
              <p>06/18/2025</p>
            </td>
            <td className="table-data" id="Actions">
              <p>Manage</p>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="w-[100%] flex flex-row justify-end align-middle">
        <button>Manage Subscriptions</button>
      </div>
    </>
  );
}

export default BillingManagement;
