import "./BillingManagementStyles.css";
import addFolder from "./../../../assets/img/icon/add-quotes.png";

function BillingInvoices() {
  return (
    <>
      <h2 className="font-light text-lg mb-5">Admin/company-organization</h2>
      <h1 className="font-extrabold text-4xl mb-8">Invoices</h1>
      <h2 className="font-light text-lg mb-10">
        View your pending invoices and payment history
      </h2>
      <div className="w-[100%] p-20 text-center flex flex-col justify-center items-center">
        <img src={addFolder} className="scale-50" />
        <h3 className="font-bold text-[15px] mb-2">No tax invoices yet</h3>
        <h2 className="font-light text-[15px] w-[70%]">
          Your tax invoices will appear here once you make your first payment
        </h2>
      </div>
    </>
  );
}

export default BillingInvoices;
