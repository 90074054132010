import React, { useState, useEffect } from "react";
import styles from "../../assets/css/MobileHome.module.css";
// import FlowIcon from "../../assets/img/icon/flow-logo.png"
import TurboCharger from "../../assets/img/icon/turbocharger.svg";
import DollarIcon from "../../assets/img/icon/Dollar Icon.svg";
import DealIcon from "../../assets/img/icon/Deal Icon.svg";
import IncreaseIcon from "../../assets/img/icon/Increase Icon.svg";
import GoalIcon from "../../assets/img/icon/Goal Icon.svg";
import CancelIcon from "../../assets/img/icon/Cancel Icon.svg";
import UpArrow from "../../assets/img/icon/Vector.svg";
// import FlowWork from "../../assets/img/flowWork.svg"
import Flow2 from "../../assets/img/flow2.svg";
// import Flow3 from "../../assets/img/flow3.svg"
import Flow4 from "../../assets/img/flow4.svg";
import Flow5 from "../../assets/img/flow5.svg";
import Flow6 from "../../assets/img/flow6.svg";
import Flow7 from "../../assets/img/flow7.svg";
// import Flow8 from "../../assets/img/flow8.svg"
import Flow9 from "../../assets/img/flow9.svg";
import Icon0 from "../../assets/img/icon/icon0.png";
import Icon1 from "../../assets/img/icon/icon1.png";
import Icon2 from "../../assets/img/icon/icon2.png";
import JunkIcon from "../../assets/img/icon/junk.png";
import ContVerIcon from "../../assets/img/icon/cont-ver.png";
import ConflictIcon from "../../assets/img/icon/conflicts.png";
import WorkflowIcon from "../../assets/img/icon/workflow.png";
import IntegrationsIcon from "../../assets/img/icon/integration.png";
import DataColIcon from "../../assets/img/icon/data-coll.png";
import DownArrow from "../../assets/img/icon/drop_down.png";
import FlowWork2 from "../../assets/img/flow-bg2.png";
import { Link } from "react-router-dom";
import axios from "axios";

import ScrollAnimation from "react-animate-on-scroll"; // Import ScrollAnimation
import "animate.css/animate.min.css";

import Footer from "../../components/Footer";

const MobileHomePage = () => {
  const [showHero, setShowHero] = useState(false);
  useEffect(() => {
    setTimeout(() => setShowHero(true), 0);
  }, []);

  const [formData, setFormData] = useState({
    FirstName: "",
    LastName: "",
    JobTitle: "",
    Email: "",
    Phone: "",
    Company: "",
    Industry: "",
    SalesTeamSize: "",
    AnnualBudget: "",
    Message: "",
  });

  const [errors, setErrors] = useState({});

  const freeEmailDomains = [
    "gmail.com",
    "yahoo.com",
    "hotmail.com",
    "outlook.com",
    "icloud.com",
    "aol.com",
    "protonmail.com",
    "zoho.com",
    "mail.com",
  ];

  const validateField = (name, value) => {
    let error = "";
    if (name === "Email" && value) {
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
        error = "Please use a valid email address";
      } else if (freeEmailDomains.includes(value.split("@")[1])) {
        error = "Please use a valid business email address";
      }
    }
    if (value === "") {
      error = `${name.split(/(?=[A-Z])/).join(" ")} is required`;
    }
    return error;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const error = validateField(name, value);

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form_type = "MobilePageContact"; // To differentiate form submissions for the mobile version

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}api/leads/`,
        { ...formData, form_type }
      );
      if (response.status === 201 || response.status === 200) {
        alert("Details submitted successfully!");
      }
    } catch (error) {
      alert("Error: " + error.message);
    }
  };

  const propositions = [
    {
      title: "Commision Booster",
      body: "Flow Turbo is engineered to be a comission accelerator. By automating mundane tasks and providing valuable insights, sales reps close more deals in less time, directly impacting their commision earnings.",
      logo: DollarIcon,
    },
    {
      title: "Sales Quota Attainment",
      body: "For B2B sales executives striving to meet sales quotas, Flow Turbo offers a strategic advantage. The guided end-to-end platform optimizes the sales process, ensuring that each interaction aligns with overarching sales targets.",
      logo: GoalIcon,
    },
    {
      title: "Increased Opportunities",
      body: "The platform's AI capabilities result in a surge of deals and opportunities for sales reps. By automating AI account selection and qualification, Flow Turbo provides a steady flow of high-potential prospects.",
      logo: IncreaseIcon,
    },
    {
      title: "Closed Deals Amplification",
      body: "Closing deals is the ultimate goal, and Flow Turbo is designed to enhance the efficiency of the closing process and eliminates pipeline gaps that drain the sales organization at the front-half of the sales cycle. The Live AI Sales Rep Assistant ensures that every deal that's generated by sales reps is well-processed and productive, increasing the likelihood of closing deals.",
      logo: DealIcon,
    },
    {
      title: "Elimination of Sales Rep Burn Out",
      body: "Manual and mundane tasks can be a drain on a sales rep's energy and time. Flow Turbo eliminates this burn out by taking care of repetitive activites, allowing sales reps to focus on high-value tasks and meaningful work.",
      logo: CancelIcon,
    },
  ];

  const section2Cards = [
    {
      title: "One Unified Cohesive Composable Platform",
      body: "The NEW Flow Turbo SaaS AI sales prospecting platform is designed to maximize the productivity day-to-day for sales reps. With real artificial intelligence living in its core foundation, Flow Turbo is ONE unified cohesive composable AI desktop super platform designed to turbocharge your sales team's efficiency and success, and to be the sole primary platform that sales reps utilize on a daily basis, eliminating the need to have 20-30 browser tabs and toolstacks having to be opened at the same time with chaotic, confusing, and messy screen switching, and back and forth interactions with different categories of sales tools and information.",
      image: Flow2,
    },

    {
      title: "Live AI Sales Rep Assistant",
      body: "The NEW Flow Turbo SaaS AI Sales Platform is designed and engineered to eliminate all impediments, blockers, barriers, and obstacles that slows sales reps down, and to accelerate your B2B sales organization to achieve demanding B2B sales quotas.",
      image: Flow5,
    },
    {
      title: "AI Account Selection",
      body: "From the transformative AI Account Selection and AI Flat Files capabilities that this almost real person AI has, the AI provides and delivers 300 net new AI pre-qualified contacts daily without automatic disqualification that are extrapolated from conflict-free accounts, to the Live AI Sales Rep Assistant eliminating manual and mundane, energy intensive, mentally strenuous tasks.",
      image: Flow4,
    },
  ];

  const section4Cards = [
    {
      title: "Live Sales Rep Assistant",
      body: "Introducing our groundbreaking Live AI Sales Rep Assistant, a revolutionary feature of the new Flow Turbo SaaS AI sales prospecting platform designed to transform the daily workflow of sales professionals. This cutting-edge AI is engineered to take charge of all manual and mundane tasks, eliminating the need for repetitive clicks and interactions that often consume valuable time. Our Live AI Sales Rep Assistant goes beyond conventional automation – it intuitively navigates through sales tools, streamlining processes, and delivering an unparalleled user experience. Say goodbye to tedious tasks and hello to productivity, as our AI empowers sales reps to concentrate on high-value activities, ensuring every interaction counts. It’s time to redefine efficiency in sales with the seamless integration of AI technology. Welcome to a future where your sales team’s potential is maximized, and every moment is dedicated to driving success. Imagine a seamless sales workflow where the AI takes the lead in handling all manual and mundane tasks that often consume a sales rep’s valuable time. Our innovative assistant eliminates the need for repetitive clicks, interactions, and navigating through various sales tools, empowering your sales team to concentrate on what truly matters – building relationships and driving revenue. With the Live AI Sales Rep Assistant, experience a shift from routine tasks to value-added activities, giving your sales reps the time and energy to engage with prospects, generating qualified deals, and achieve unparalleled success in the world of sales.",
      image: Flow6,
    },
    {
      title: "AI Account Selection",
      body: "Don’t let sales tools dictate your time. The Flow Turbo AI Account Selection feature empowers your sales reps with 300 AI pre-qualified contacts extracted from AI qualified accounts, eliminating the need to sift through unproductive leads. Traditional tools waste your sales reps’ valuable time—Flow Turbo streamlines this process, ensuring your team’s efficiency. Bid farewell to the days of tool-juggling that waste your team’s precious time. Sales tools, often touted as efficiency enhancers, paradoxically become time sinks, devouring half the day with manual and mundane tasks. The Flow Turbo AI Account Selection feature rewrites this narrative, effortlessly integrating into your workflow to identify not just any accounts and prospects, but the most promising ones. Say goodbye to the tedious prospecting account selection process—Flow’s AI-driven precision ensures your sales reps focus on what truly matters: meaningful engagement and generating qualified deals and opportunities. Transform your sales process with Flow Turbo—a paradigm shift in account selection that redefines productivity.",
      image: Flow7,
    },
    {
      title: "AI Flat Files Feature",
      body: "Empower your sales reps with Flow Turbo’s AI Flat Files Feature, your ticket to liberating sales reps from the arduous task of manually sifting through vast spreadsheets. Eliminate the need for exhaustive research and analysis when dealing with marketing generated lists, lead source database exports, CRM report exports, and more. Our sophisticated Gray Matter AI Engine uses advanced algorithms to automate the process of identifying and eliminating junk accounts and contacts with automatic disqualifications. Boost productivity, streamline workflows, and ensure your sales team focuses on what truly matters—building meaningful connections and generating qualified deals and opportunities. Embrace efficiency and elevate your data-driven decision-making with Flow’s AI Flat Files—where data complexity meets intelligent simplicity.",
      image: Flow9,
    },
    {
      title: "Unified Access Integrations and Integrations Pop-Ups",
      body: "Experience the future of streamlined sales prospecting with Flow Turbo’s innovative Unified Access Integrations and Integrations Pop-ups feature. Our SaaS AI sales platform is meticulously crafted to eliminate the hassles of constant manual actions and the chaos of managing multiple browser tabs simultaneously. By consolidating diverse categories of sales tools into one cohesive and composable platform, Flow Turbo ensures a seamless and efficient workflow. Say goodbye to screen-switching fatigue and the clutter of numerous tabs—Flow Turbo’s heavy integrations transform your sales toolstack into a unified powerhouse, offering unparalleled convenience and productivity for your prospecting endeavors. The Flow Turbo platform consolidates diverse sales tools into one seamlessly integrated, cohesive, and composable platform, significantly enhancing workflow efficiency.",
      image: Flow2,
    },
  ];
  return (
    <div className={styles["home-root"]}>
      <div className={styles["header"]} id={styles["hero-section"]}>
        <h1
          className={`${styles["large"]} ${styles["hero"]} ${
            showHero ? styles["show"] : styles["hide"]
          }`}
        >
          Transform Your Sales Day: Maximize Productivity, Meet Quotas, Generate
          Truly Qualified Opportunities, and Boost Commissions with Flow Turbo
        </h1>

        <h5
          className={`${styles["hero-sub"]} ${
            showHero ? styles["show"] : styles["hide"]
          } ${styles["delay1"]}`}
        >
          Introducing Flow Turbo: The World's First SaaS AI Sales Prospecting
          Platform
        </h5>

        <Link to="/turbo/lp">
          <div className={styles["orange-btn-container"]}>
            Get Started Today
          </div>
        </Link>

        <div
          className={`${styles["hero-image"]} ${
            showHero ? styles["show"] : styles["hide"]
          }`}
        >
          <img
            src={FlowWork2}
            className={styles["flowImages"]}
            alt="Flow Work"
          />
        </div>

        <ScrollAnimation
          animateIn="fadeIn"
          duration={500}
          delay={1000}
          animateOnce={true}
          style={{
            transform: "translateY(20px)",
            transition: "all 0.5s ease",
            transitionDelay: "0.2s",
          }}
        >
          <div className={`${styles["about"]}`}>
            <h4 className={styles["about-header"]}>About</h4>
            <h5 className={styles["hero-sub"]}>
              Flow is a{" "}
              <span className={styles["primary"]}>
                {" "}
                cutting-edge, high-tech enterprise AI company{" "}
              </span>{" "}
              leading the charge in transformative technologies. At the heart of
              our innovative offerings is a state-of-the-art SaaS AI sales
              prospecting platform, redefining the landscape of sales efficiency
              and productivity. Flow specializes in the development and
              implementation of advanced artificial intelligence solutions, we
              empower organizations to unlock unprecedented efficiency and
              productivity. With a relentless commitment to pushing the
              boundaries of what’s possible, Flow stands as a pioneer in the
              field, empowering enterprises to navigate the complexities of the
              modern business landscape with cutting-edge AI technology.
            </h5>
          </div>
        </ScrollAnimation>
      </div>

      <div id={styles["section2"]}>
        <div>
          <img
            src={TurboCharger}
            className={styles["turbocharger"]}
            alt="Turbo Charger"
          />
          <h1>Flow Turbo</h1>
        </div>
        <ScrollAnimation
          animateIn="fadeIn"
          duration={500}
          delay={1000}
          animateOnce={true}
          style={{
            transform: "translateY(20px)",
            transition: "all 0.5s ease",
            transitionDelay: "0.2s",
          }}
        >
          <div>
            <h5>The NEW Flow Turbo SaaS AI sales prospecting platform</h5>
          </div>
        </ScrollAnimation>

        <ScrollAnimation
          animateIn="fadeIn"
          duration={500}
          delay={1000}
          animateOnce={true}
          style={{
            transform: "translateY(20px)",
            transition: "all 0.5s ease",
            transitionDelay: "0.2s",
          }}
        >
          <div className={styles["card-stack"]}>
            {section2Cards.map((info) => {
              return (
                <Card
                  title={info["title"]}
                  bodyText={info["body"]}
                  image={info["image"]}
                  key={info["title"]}
                />
              );
            })}
          </div>
        </ScrollAnimation>
      </div>

      <div id={styles["section3"]}>
        <ScrollAnimation
          animateIn="fadeIn"
          duration={500}
          delay={1000}
          animateOnce={true}
          style={{
            transform: "translateY(20px)",
            transition: "all 0.5s ease",
            transitionDelay: "0.2s",
          }}
        >
          <div>
            <h1 className={styles["large2"]}>Key Value Propositions</h1>
            <h3 className={styles["small-header"]}>
              Flow Turbo SaaS AI Sales Platform: Elevating Sales Success
            </h3>

            <h5 className={styles["hero-sub"]}>
              At Flow, we are committed to revolutionizing the landscape of
              sales operations. The Flow Turbo SaaS AI Sales Platform is not
              just a brand new AI platform; it’s a strategic ally designed to
              propel sales reps and B2B sales executives toward unprecedented
              success.
            </h5>
          </div>
        </ScrollAnimation>

        <ScrollAnimation
          animateIn="fadeIn"
          duration={500}
          delay={1000}
          animateOnce={true}
          style={{
            transform: "translateY(20px)",
            transition: "all 0.5s ease",
            transitionDelay: "0.2s",
          }}
        >
          <div className={styles["key-propositions"]}>
            {propositions.map((proposition, index) => {
              return (
                <Accordion
                  key={proposition.id || index}
                  title={proposition["title"]}
                  body={proposition["body"]}
                  logo={proposition["logo"]}
                />
              );
            })}
          </div>
        </ScrollAnimation>
      </div>

      <div className={styles["divider-line"]}></div>

      <div id={styles["section3"]}>
        <ScrollAnimation
          animateIn="fadeIn"
          duration={500}
          delay={1000}
          animateOnce={true}
          style={{
            transform: "translateY(20px)",
            transition: "all 0.5s ease",
            transitionDelay: "0.2s",
          }}
        >
          <h1 className={styles["large2"]}>In essence,</h1>
          <h5 className={styles["hero-sub"]}>
            Flow Turbo is not just a sales tool; it's a strategic ally that
            aligns with your sales objectives, propelling your team towards
            increased earnings, quota attainment, and overall sales success.
            Welcome to a future where Flow Turbo redefines the landscape of B2B
            sales.
          </h5>
        </ScrollAnimation>

        <ScrollAnimation
          animateIn="fadeIn"
          duration={500}
          delay={1000}
          animateOnce={true}
          style={{
            transform: "translateY(20px)",
            transition: "all 0.5s ease",
            transitionDelay: "0.2s",
          }}
        >
          <h1 className={styles["large2"]}>As you embrace Flow Turbo,</h1>
          <h5 className={styles["hero-sub"]}>
            you're not just adopting a brand new SaaS AI sales prospecting
            platform; you're ushering in a new era of sales excellence. With
            Flow Turbo, experience a future where innovation meets efficiency,
            and your sales team redefines success. Elevate your sales journey -
            schedule your free product tour right now of the new Flow Turbo SaaS
            AI sales prospecting platform today.
          </h5>
        </ScrollAnimation>

        <ScrollAnimation
          animateIn="fadeIn"
          duration={500}
          delay={1000}
          animateOnce={true}
          style={{
            transform: "translateY(20px)",
            transition: "all 0.5s ease",
            transitionDelay: "0.2s",
          }}
        >
          <Link to="/turbo/lp">
            <div className={styles["green-button-container"]}>Get It Now</div>
          </Link>
        </ScrollAnimation>
      </div>

      <div className={styles["divider-line"]}></div>

      <div id={styles["section3"]}>
        <ScrollAnimation
          animateIn="fadeIn"
          duration={500}
          delay={1000}
          animateOnce={true}
          style={{
            transform: "translateY(20px)",
            transition: "all 0.5s ease",
            transitionDelay: "0.2s",
          }}
        >
          <div className={styles["parent-header"]}>
            <h1 className={styles["large2"]}>
              Reach your earnings targets and ideal prospect with Flow Turbo
            </h1>
          </div>
        </ScrollAnimation>

        <ScrollAnimation
          animateIn="fadeIn"
          duration={500}
          delay={1000}
          animateOnce={true}
          style={{
            transform: "translateY(20px)",
            transition: "all 0.5s ease",
            transitionDelay: "0.2s",
          }}
        >
          <div>
            <div className={styles["card-icon"]}>
              <img src={Icon0} alt="Icon 0" />
            </div>
            <h4 className={styles["about-header"]}>Increased Efficiency</h4>
            <h5 className={styles["hero-sub"]}>
              Eliminates wasted time for the sales reps from doing mundane or
              manual activities; reclaiming half the entire day back for sales
              reps.
            </h5>
          </div>
        </ScrollAnimation>

        <ScrollAnimation
          animateIn="fadeIn"
          duration={500}
          delay={1000}
          animateOnce={true}
          style={{
            transform: "translateY(20px)",
            transition: "all 0.5s ease",
            transitionDelay: "0.2s",
          }}
        >
          <div>
            <div className={styles["card-icon"]}>
              <img src={Icon1} alt="" />
            </div>
            <h4 className={styles["about-header"]}>Achieve Your Sales Quota</h4>

            <h5 className={styles["hero-sub"]}>
              Allows sales reps to be able to accomplish a 200-300 call day.
            </h5>
          </div>
        </ScrollAnimation>

        <ScrollAnimation
          animateIn="fadeIn"
          duration={500}
          delay={1000}
          animateOnce={true}
          style={{
            transform: "translateY(20px)",
            transition: "all 0.5s ease",
            transitionDelay: "0.2s",
          }}
        >
          <div>
            <div className={styles["card-icon"]}>
              <img src={Icon2} alt="" />
            </div>
            <h4 className={styles["about-header"]}>
              Unified Cohesive Composable Platform
            </h4>
            <h5 className={styles["hero-sub"]}>
              Combines 5-6+ different disjointed sales tools on Flow Turbo for
              elegance and cohesion. Eliminates the need to have 10-30 browser
              tabs open at the same time.
            </h5>
          </div>
        </ScrollAnimation>
      </div>

      <div id={styles["section4"]}>
        <ScrollAnimation
          animateIn="fadeIn"
          duration={500}
          delay={1000}
          animateOnce={true}
          style={{
            transform: "translateY(20px)",
            transition: "all 0.5s ease",
            transitionDelay: "0.2s",
          }}
        >
          <div>
            <h1 className={styles["large2"]}>
              Maximizing productivity day-to-day for sales reps
            </h1>
          </div>
        </ScrollAnimation>

        <ScrollAnimation
          animateIn="fadeIn"
          duration={500}
          delay={1000}
          animateOnce={true}
          style={{
            transform: "translateY(20px)",
            transition: "all 0.5s ease",
            transitionDelay: "0.2s",
          }}
        >
          <div className={styles["card-stack"]}>
            {section4Cards.map((info) => {
              return (
                <Card
                  title={info["title"]}
                  bodyText={info["body"]}
                  image={info["image"]}
                  key={info["title"]}
                  section="section4"
                />
              );
            })}
          </div>
        </ScrollAnimation>

        <ScrollAnimation
          animateIn="fadeIn"
          duration={500}
          delay={1000}
          animateOnce={true}
          style={{
            transform: "translateY(20px)",
            transition: "all 0.5s ease",
            transitionDelay: "0.2s",
          }}
        >
          <Link to="/product">
            <div className={styles["green-button"]}>Find Out More</div>
          </Link>
        </ScrollAnimation>
      </div>

      <div id={styles["section3"]}>
        <ScrollAnimation
          animateIn="fadeIn"
          duration={500}
          delay={1000}
          animateOnce={true}
          style={{
            transform: "translateY(20px)",
            transition: "all 0.5s ease",
            transitionDelay: "0.2s",
          }}
        >
          <div className={styles["parent-header"]}>
            <h1 className={styles["large2"]}>
              Laser target your next best prospects with all the right tools
            </h1>
          </div>
        </ScrollAnimation>
        <div className={styles["tool-list"]}>
          <ScrollAnimation
            animateIn="fadeIn"
            duration={500}
            delay={1000}
            animateOnce={true}
            style={{
              transform: "translateY(20px)",
              transition: "all 0.5s ease",
              transitionDelay: "0.2s",
            }}
          >
            <div className={styles["tool-item"]}>
              <div className={styles["card-icon"]}>
                <img src={JunkIcon} alt="" />
              </div>
              <h4 className={styles["tool-title"]}>No Junk Data</h4>
            </div>
          </ScrollAnimation>

          <ScrollAnimation
            animateIn="fadeIn"
            duration={500}
            delay={1000}
            animateOnce={true}
            style={{
              transform: "translateY(20px)",
              transition: "all 0.5s ease",
              transitionDelay: "0.2s",
            }}
          >
            <div className={styles["tool-item"]}>
              <div className={styles["card-icon"]}>
                <img src={ContVerIcon} alt="" />
              </div>
              <h4 className={styles["tool-title"]}>Contact Verification</h4>
            </div>
          </ScrollAnimation>

          <ScrollAnimation
            animateIn="fadeIn"
            duration={500}
            delay={1000}
            animateOnce={true}
            style={{
              transform: "translateY(20px)",
              transition: "all 0.5s ease",
              transitionDelay: "0.2s",
            }}
          >
            <div className={styles["tool-item"]}>
              <div className={styles["card-icon"]}>
                <img src={ConflictIcon} alt="" />
              </div>
              <h4 className={styles["tool-title"]}>Check for conflicts</h4>
            </div>
          </ScrollAnimation>

          <ScrollAnimation
            animateIn="fadeIn"
            duration={500}
            delay={1000}
            animateOnce={true}
            style={{
              transform: "translateY(20px)",
              transition: "all 0.5s ease",
              transitionDelay: "0.2s",
            }}
          >
            <div className={styles["tool-item"]}>
              <div className={styles["card-icon"]}>
                <img src={WorkflowIcon} alt="" />
              </div>
              <h4 className={styles["tool-title"]}>Automatic Workflow</h4>
            </div>
          </ScrollAnimation>

          <ScrollAnimation
            animateIn="fadeIn"
            duration={500}
            delay={1000}
            animateOnce={true}
            style={{
              transform: "translateY(20px)",
              transition: "all 0.5s ease",
              transitionDelay: "0.2s",
            }}
          >
            <div className={styles["tool-item"]}>
              <div className={styles["card-icon"]}>
                <img src={IntegrationsIcon} alt="" />
              </div>
              <h4 className={styles["tool-title"]}>Heavy Integrations</h4>
            </div>
          </ScrollAnimation>

          <ScrollAnimation
            animateIn="fadeIn"
            duration={500}
            delay={1000}
            animateOnce={true}
            style={{
              transform: "translateY(20px)",
              transition: "all 0.5s ease",
              transitionDelay: "0.2s",
            }}
          >
            <div className={styles["tool-item"]}>
              <div className={styles["card-icon"]}>
                <img src={DataColIcon} alt="" />
              </div>
              <h4 className={styles["tool-title"]}>Data Collection</h4>
            </div>
          </ScrollAnimation>
        </div>
      </div>

      <div id={styles["section5"]}>
        <div>
          <h1>Sign up for the Flow Turbo Early Access Program</h1>
        </div>

        <form id={styles["sign-up-form"]} onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="First Name *"
            name="FirstName"
            value={formData.FirstName}
            onChange={handleChange}
          />
          <input
            type="text"
            placeholder="Last Name *"
            name="LastName"
            value={formData.LastName}
            onChange={handleChange}
          />
          <input
            type="text"
            placeholder="Job Title *"
            name="JobTitle"
            value={formData.JobTitle}
            onChange={handleChange}
          />
          <input
            type="text"
            placeholder="Work Email *"
            name="Email"
            value={formData.Email}
            onChange={handleChange}
          />
          {errors.Email && (
            <div className="error-validation">{errors.Email}</div>
          )}{" "}
          {/* Display error */}
          <input
            type="text"
            placeholder="Work Phone *"
            name="Phone"
            value={formData.Phone}
            onChange={handleChange}
          />
          <input
            type="text"
            placeholder="Company Name *"
            name="Company"
            value={formData.Company}
            onChange={handleChange}
          />
          <input
            type="text"
            placeholder="Industry *"
            name="Industry"
            value={formData.Industry}
            onChange={handleChange}
          />
          <input
            type="text"
            placeholder="Sales Team Size *"
            name="SalesTeamSize"
            value={formData.SalesTeamSize}
            onChange={handleChange}
          />
          <input
            type="text"
            placeholder="Annual Budget *"
            name="AnnualBudget"
            value={formData.AnnualBudget}
            onChange={handleChange}
          />
          <textarea
            type="text"
            className={styles["message-textarea"]}
            placeholder="Message"
            name="Message"
            value={formData.Message}
            onChange={handleChange}
          />
          <button type="submit" className={styles["contact-us"]}>
            Submit
          </button>
        </form>
      </div>
      <Footer />
    </div>
  );
};

const Accordion = ({ title, body, logo }) => {
  const [expanded, setExpanded] = useState(false);

  function handleClick() {
    setExpanded(!expanded);
  }

  return (
    <div className={styles["accordion-container"]} onClick={handleClick}>
      <div
        className={`${styles["accordion-title-container"]} ${
          expanded ? styles["primary"] : ""
        }`}
      >
        <img src={logo} className={styles["accordion-logo"]} alt="" />
        <span
          className={`${styles["accordion-title"]} ${
            expanded ? styles["title-expanded"] : ""
          }`}
        >
          {title}
        </span>
        <img
          src={expanded ? UpArrow : DownArrow}
          className={styles["up-arrow"]}
          alt=""
        />
      </div>
      <div
        className={`${styles["proposition"]} ${
          expanded ? styles["expanded"] : ""
        }`}
      >
        {body}
      </div>
    </div>
  );
};

const Card = ({ title, bodyText, image, section }) => {
  const [isExpanded, setIsExpanded] = useState(false); // State to manage expansion

  const handleToggle = () => {
    setIsExpanded(!isExpanded); // Toggle the state between expanded and collapsed
  };

  // Determine class based on the section
  const cardClass =
    section === "section4"
      ? styles["card-container-section4"]
      : styles["card-container"];

  return (
    <div
      className={`${cardClass} ${isExpanded ? styles["card-expanded"] : ""}`}
    >
      {" "}
      {/* Apply expanded class conditionally */}
      <div className={styles["card-image"]}>
        <img src={image} className={styles["flowImages"]} alt="Flow Images" />
      </div>
      <div className={styles["card-body"]}>
        <div className={styles["card-body-title"]}>{title}</div>
        <div className={styles["card-body-text"]}>{bodyText}</div>
        <div
          className={styles["card-body-footer"]}
          onClick={handleToggle}
          style={{ cursor: "pointer", color: "#ff5b00" }}
        >
          {isExpanded ? "Show Less" : "Read More"} {/* Toggle button text */}
        </div>
      </div>
    </div>
  );
};

export default MobileHomePage;
