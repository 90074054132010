import React, { useState } from "react";
import Footer from "../../components/Footer";
import "../../assets/css/LandingPage.css";
import img1 from "../../assets/img/Landing_image.png";
import axios from "axios";
import { Link } from "react-router-dom";

const formFields = [
  { name: "FirstName", label: "First Name", type: "text" },
  { name: "LastName", label: "Last Name", type: "text" },
  { name: "Title", label: "Title", type: "text" },
  { name: "Company", label: "Company Name", type: "text" },
  { name: "Email", label: "Work Email", type: "email" },
];

function LandingPageDesktop() {
  const [formData, setFormData] = useState({
    FirstName: "",
    LastName: "",
    Title: "",
    Company: "",
    Email: "",
    form_type: "LandingPage",
  });

  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [message, setMessage] = useState("");
  const [downloaded, setDownloaded] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const newErrors = {};

    formFields.forEach((field) => {
      if (!formData[field.name]) {
        newErrors[field.name] = `${field.label} is required`;
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      console.log("Submitting to:", `${process.env.REACT_APP_API}api/leads/`);
      const response = await axios.post(
        `${process.env.REACT_APP_API}api/leads/`,
        formData
      );
      if (response.status === 201 || response.status === 200) {
        setFormData(response.data.updated_data || formData);
        setSubmitted(true);
        setMessage("Details saved Succesfully.");
      }
    } catch (error) {
      setMessage("Error: " + error.message);
    }
  };

  const handleDownloadClick = () => {
    if (!downloaded) {
      setDownloaded(true); // Disable future downloads
    }
  };

  return (
    <>
      <div className="landing-page">
        <div className="landing-page_section-white">
          <div className="padding1">
            <div className="landing-page_subtitle">
              <div className="">
                <h2 className="landing-page_subtext">
                  Discover the art of winning in B2B sales with our free ebook,
                  'B2B Sales Unveiled: The Numbers Game Redefined for Maximum
                  Commissions! Why Sales Is A Numbers Game and Why It's Also
                  About Eliminating The Ones Who Are Not Right For Your Product'
                </h2>
              </div>
            </div>

            <div className="landing-page_container">
              <div className="landing-page-icon_container">
                <img src={img1} alt="" />
              </div>
              <input type="hidden" name="form_type" value="Landing_page" />
              <div className="form-container">
                <form onSubmit={handleSubmit}>
                  {formFields.map((field) => (
                    <div key={field.name} className="formField">
                      <input
                        type={field.type}
                        id={field.name}
                        name={field.name}
                        placeholder={`${field.label}*`}
                        onChange={handleChange}
                        value={formData[field.name]}
                        className="landing-page-formTextBox"
                      />
                      {errors[field.name] && (
                        <div className="error">{errors[field.name]}</div>
                      )}
                    </div>
                  ))}
                  {submitted ? (
                    <>
                      <div className="responseMessage">{message}</div>
                      <div className="font4 form-container">
                        Download your Ebook{" "}
                        {downloaded ? (
                          <span style={{ color: "grey" }}>here.</span>
                        ) : (
                          <a
                            href="/Flow_Turbo_Ebook.pdf"
                            download="Flow_Turbo_Ebook.pdf"
                            style={{ color: "green" }}
                            onClick={handleDownloadClick}
                          >
                            here.
                          </a>
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="checkbox-container">
                        <input
                          type="checkbox"
                          id="privacyPolicy"
                          name="privacyPolicy"
                          required
                        />
                        <div className="font4" style={{ padding: "40px 0px" }}>
                          By clicking the checkbox, I acknowledge receipt of
                          Flow’s
                          <Link to="/privacy"> Privacy Policy</Link>.
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="formButton bold"
                        style={{ fontFamily: "Neue Haas Grotesk Display Pro" }}
                      >
                        Submit
                      </button>
                      <div className="responseMessage">{message}</div>
                    </>
                  )}
                </form>
              </div>
            </div>

            <div className="">
              <h2 className="bold">Key Takeaways:</h2>

              <div className="newcontainer">
                <div className="left">
                  <div className="key_takeaways">
                    <div className="center">
                      <div className="key_takeaways_square center">
                        <h3>1</h3>
                      </div>
                    </div>
                    Numbers Game Mastery: Learn why sales is a numbers game and
                    how to cast your rod for a greater catch.
                  </div>
                </div>

                <div className="left">
                  <div className="key_takeaways">
                    <div className="center">
                      <div className="key_takeaways_square center">
                        <h3>2</h3>
                      </div>
                    </div>
                    Strategic Eliminations: Stop wasting time on the wrong
                    prospects. Focus on those ready to buy.
                  </div>
                </div>
              </div>

              <div className="newcontainer">
                <div className="left">
                  <div className="key_takeaways">
                    <div className="center">
                      <div className="key_takeaways_square center">
                        <h3>3</h3>
                      </div>
                    </div>
                    Pipeline Optimization: Master the art of managing a steady
                    flow of quality leads.
                  </div>
                </div>

                <div className="left">
                  <div className="key_takeaways">
                    <div className="center">
                      <div className="key_takeaways_square center">
                        <h3>4</h3>
                      </div>
                    </div>
                    Exclusive Early Access: Be one of the first 100 accounts to
                    access this game-changing ebook. <br />
                    Early Access Accounts enjoy a limited-time promo offer.{" "}
                  </div>
                </div>
              </div>

              <div className="newcontainerbox">
                <div className="left">
                  <div className="key_takeaways">
                    <div className="center">
                      <div className="key_takeaways_square center">
                        <h3>5</h3>
                      </div>
                    </div>
                    Elevate Your Sales: Our expert consultants await you for a
                    complimentary strategy session. Unleash the potential of
                    your sales team with the Flow Turbo SaaS AI sales platform.{" "}
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="">
                <h2 className="bold">
                  Unleash Sales Success with Our Exclusive Ebook!
                </h2>
                <div className="newcenter2">
                  <div>
                    Are you a B2B Sales Executive looking to skyrocket your
                    commissions and fill your pipeline with qualified deals?
                    Discover the secrets to mastering the art of sales numbers
                    and strategic eliminations with our free ebook:
                  </div>
                  <div className="small">
                    <h2>
                      'B2B Sales Unveiled: The Numbers Game Redefined for
                      Maximum Commissions! Why Sales Is A Numbers Game and Why
                      It's Also About Eliminating The Ones Who Are Not Right For
                      Your Product'{" "}
                    </h2>
                  </div>
                </div>
              </div>
            </div>

            <div className="">
              <h2 className="bold">What You'll Gain:</h2>

              <div className="boxcontainer">
                <div className="border-elem left">
                  <div className="key_takeaways">
                    <div className="center">
                      <div className="key_takeaways_square center">
                        <h3 className="">1</h3>
                      </div>
                    </div>
                    <div className="">
                      <h4 className="title ">Insider Insights</h4>
                      <h4 className="normal">
                        Unlock revolutionary insights into the world of B2B
                        sales.
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="border-elem left">
                  <div className="key_takeaways">
                    <div className="center">
                      <div className="key_takeaways_square center">
                        <h3 className="">2</h3>
                      </div>
                    </div>
                    <div className="">
                      <h4 className="title ">Commission Triumph</h4>
                      <h4 className="normal">
                        Learn to navigate the numbers game for unprecedented
                        commission opportunities.
                      </h4>
                    </div>
                  </div>
                </div>
              </div>

              <div className="boxcontainer">
                <div className="border-elem left">
                  <div className="key_takeaways">
                    <div className="center">
                      <div className="key_takeaways_square center">
                        <h3 className="">3</h3>
                      </div>
                    </div>
                    <div className="">
                      <h4 className="title ">Strategic Eliminations</h4>
                      <h4 className="normal">
                        {" "}
                        Craft success by strategically eliminating barriers to
                        your sales journey.
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="border-elem left">
                  <div className="key_takeaways">
                    <div className="center">
                      <div className="key_takeaways_square center">
                        <h3 className="">4</h3>
                      </div>
                    </div>
                    <div className="">
                      <h4 className="title ">Roadmap to Opportunities</h4>
                      <h4 className="normal">
                        Empower your sales team with the wisdom needed for more
                        qualified deals.
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="early-access">
              <div className="newcenter_earlyAccess">
                <h2 className="bold">Exclusive Early Access</h2>
                Be among the first 100 accounts to access this game-changing
                ebook! Early Access accounts get a special promo offer for a
                limited time.
              </div>
            </div>

            <div className="newcenter3">
              <h2 className="bold">
                Discover the Winning Boost for Commission Triumph
              </h2>
              Fill out the form above to receive your free copy of the ebook and
              gain exclusive early access. <br />
              Our expert consultants are also available for a complimentary
              strategy session to discuss how our Flow Turbo SaaS AI sales
              platform can turbocharge your sales team. Don't miss out on this
              opportunity to revolutionize your B2B sales journey!
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default LandingPageDesktop;
