import { Outlet, useParams } from "react-router-dom";
import AccountHeader from "./../../components/account/AccountHeader";
import AccountNavigationPanel from "./../../components/account/AccountNavigationPanel";
import Footer from "./../../components/Footer";

function AccountLayout() {
  // const {accountID} = useParams();
  const accountID = "12345";

  return (
    <div className="account-layout bg-[#f3f3f3]">
      <AccountHeader accountID={accountID} />
      <div className="flex flex-row order-2 pl-16 pb-20 pt-10">
        <AccountNavigationPanel accountID={accountID} />
        <div className="pl-20 pr-20 w-[80%] flex flex-col">
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AccountLayout;
