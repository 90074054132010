import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import logo_white from "../assets/img/icon/logo_white.png";
import loadAnimations from "../animate.js";
import "../assets/css/Navigation.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

const DropdownContent = ({ outerLink }) => {
  return (
    <>
      <li className={outerLink}>
        <Link to="/blog">Blog</Link>
      </li>
      <li className={outerLink}>
        <Link to="/careers">Careers</Link>
      </li>
      <li className={outerLink}>
        <Link to="/resource">Resource</Link>
      </li>
      <li className={outerLink}>
        <Link to="/contact">Contact Flow</Link>
      </li>
      <li className={outerLink}>
        <Link to="/aboutus">About Us</Link>
      </li>
      <li className={outerLink}>
        <Link to="/marketplace">Marketplace</Link>
      </li>
    </>
  );
};

const Navigation = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  // eslint-disable-next-line
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1439);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    document.body.classList.toggle('no-scroll', !isMenuOpen);
  };

  const location = useLocation();

  useEffect(() => {
    setIsMenuOpen(false);
    document.body.classList.remove('no-scroll');
  }, [location]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1440);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <nav className="navigation nav-font">
      <div className="nav-wrapper">
        <div className="menu-wrapper" onClick={toggleMenu}>
          <div className={`mobile-nav-logo ${isMenuOpen ? "hide" : ""}`}>
            <Link to="/" onClick={() => loadAnimations()}>
              <img className="home-icon" src={logo_white} alt="Flow" />
            </Link>
          </div>
          <div className="menu-icon">
            {isMenuOpen ? (
              <CloseIcon fontSize="large" />
            ) : (
              <MenuIcon fontSize="large" />
            )}
          </div>
          <div className={`mobile-nav-logo ${!isMenuOpen ? "hide" : ""}`}>
            <Link to="/" onClick={() => loadAnimations()}>
              <img className="home-icon-mobile" src={logo_white} alt="Flow" />
            </Link>
          </div>
        </div>
        <ul className={`nav-links ${isMenuOpen ? "active" : ""}`}>
          <li className={`outer_link logo-link ${isMenuOpen ? "hide" : ""}`}>
            <Link to="/" onClick={() => loadAnimations()}>
              <img className="home-icon" src={logo_white} alt="Flow" />
            </Link>
          </li>
          <li className="outer_link">
            <Link to="/product">Product</Link>
          </li>
          <li className="outer_link">
            <Link to="/pricing">Pricing</Link>
          </li>
          <li className="outer_link">
            <Link to="/use-cases">Use Cases</Link>
          </li>
          <li className="outer_link">
            <Link to="/customer-service">Customer Service</Link>
          </li>

          <div className="nav-item outer_link">
            <Link>
              Explore More
              <ExpandMoreIcon className="expand-more-icon" />
            </Link>
            <div className="dropdown-content">
              <DropdownContent outerLink="" />
            </div>
          </div>
          <li>
            <div className="account-buttons">
              <Link to="/contact/sales-support">
                <div className="flex orange-button">Schedule A Tour</div>
              </Link>
              <Link to="/signup">
                <div className="flex green-button">Login</div>
              </Link>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navigation;