import { Link } from "react-router-dom";
import React from "react";
import "./OtherPostCard.css";
import blogFallbackImage from "../../assets/img/blog-image-placholder.webp";

export default function OtherPostCard(props) {
  const post = props.post;
  const id = props.post.id;
  return (
    <div className="other-post-card">
      <Link to={`/blog/${id}`}>
        <div className="flex flex-column h-100 md:pt-1 md:p-4">
          <div className="other-post-image-wrapper h-100 md:py-3 md:px-1">
            <img
              className="flex other-post-preview-image"
              src={post.feature_image ? post.feature_image : blogFallbackImage}
              alt="Blog"
            />
          </div>

          <div className="other-post-link sm:max-md:p-3">
            <div className="flex  h-100 flex-column md:pt-3 ">
              <h2 className="other-post-heading">{post.title}</h2>
              <div className="content-gray align-content-end">
                {post.reading_time} min read
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}
