import React, { useState } from "react";
import img1 from "../../assets/img/Landing_image.png";
import "../../assets/css/LandingPage.css";
import axios from "axios";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import FlowTurboComponent from "../../components/Landing/MobileComponents/FlowTurboComponent";

const formFields = [
  { name: "FirstName", label: "First Name", type: "text" },
  { name: "LastName", label: "Last Name", type: "text" },
  { name: "Title", label: "Title", type: "text" },
  { name: "Company", label: "Company Name", type: "text" },
  { name: "Email", label: "Work Email", type: "email" },
];

function LandingPageMobile() {
  const [formData, setFormData] = useState({
    FirstName: "",
    LastName: "",
    Title: "",
    Company: "",
    Email: "",
    form_type: "LandingPage",
  });

  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [message, setMessage] = useState("");
  const [downloaded, setDownloaded] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const newErrors = {};

    formFields.forEach((field) => {
      if (!formData[field.name]) {
        newErrors[field.name] = `${field.label} is required`;
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      console.log("Submitting to:", `${process.env.REACT_APP_API}api/leads/`);
      const response = await axios.post(
        `${process.env.REACT_APP_API}api/leads/`,
        formData
      );
      if (response.status === 201 || response.status === 200) {
        setFormData(response.data.updated_data || formData);
        setSubmitted(true);
        setMessage("Details saved Succesfully.");
      }
    } catch (error) {
      setMessage("Error: " + error.message);
    }
  };

  const handleDownloadClick = () => {
    if (!downloaded) {
      setDownloaded(true); // Disable future downloads
    }
  };

  return (
    <>
      <div className="flex flex-col items-center bg-white text-black pb-14">
        <div className="centered-text p-4 fadeInAnimation">
          <div className="font-black py-1">Unlock B2B Sales Mastery!</div>
          <p className="text-base font-black pt-10">
            Discover the art of winning in B2B sales with our free ebook, 'B2B
            Sales Unveiled: The Numbers Game Redefined for Maximum Commissions!'
            Why Sales Is A Numbers Game and Why It's Also About Eliminating The
            Ones Who Are Not Right For Your Product.
          </p>
        </div>
        <img src={img1} alt="icon" />
      </div>

      <input type="hidden" name="form_type" value="Landing_page" />
      <div className="flex flex-col items-center bg-white text-black pb-6">
        <div className="form-container p-4">
          <form onSubmit={handleSubmit}>
            {formFields.map((field) => (
              <div key={field.name} className="formField">
                <input
                  type={field.type}
                  id={field.name}
                  name={field.name}
                  placeholder={`${field.label}*`}
                  onChange={handleChange}
                  value={formData[field.name]}
                  className="bg-gray-100 text-xs text-black font-neueHaas w-full bg-var(--background-grey) h-10 pl-3 border border-customGray"
                />
                {errors[field.name] && (
                  <div className="error">{errors[field.name]}</div>
                )}
              </div>
            ))}
            {submitted ? (
              <>
                <div className="responseMessage">{message}</div>
                <div className="font4 form-container">
                  Download your Ebook{" "}
                  {downloaded ? (
                    <span style={{ color: "grey" }}>here.</span>
                  ) : (
                    <a
                      href="/Flow_Turbo_Ebook.pdf"
                      download="Flow_Turbo_Ebook.pdf"
                      style={{ color: "green" }}
                      onClick={handleDownloadClick}
                    >
                      here.
                    </a>
                  )}
                </div>
              </>
            ) : (
              <>
                <p
                  className="text-xs font-semibold"
                  style={{ padding: "15px 0px" }}
                >
                  By clicking the button below, I acknowledge receipt of Flow’s
                  <Link to="/privacy"> Privacy Policy</Link>.
                </p>
                <button
                  type="submit"
                  className="mobileformButton bold w-full"
                  style={{ fontFamily: "Neue Haas Grotesk Display Pro" }}
                >
                  Submit
                </button>
                <div className="responseMessage">{message}</div>
              </>
            )}
          </form>
        </div>
      </div>

      <FlowTurboComponent></FlowTurboComponent>

      <Footer />
    </>
  );
}

export default LandingPageMobile;
