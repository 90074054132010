import React from 'react';
import { useForm } from 'react-hook-form';
import './Billing.css';

export default function BillingInfoForm({ onSubmit, tierSelection, users }) {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const tierSelectionToPriceMap = {
    "elite": 1080,
    "ultra": 720,
    "premium": 480,
    "starter": 180
  };
  const cost = tierSelectionToPriceMap[tierSelection] * users.length;

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  // Define form data structure
  const formData = {
    "contactInformation": [
      { label: "Full Name*", name: "cardHolderName", error: errors.cardHolderName, errorMessage: "Cardholder name is required" },
      { label: "Street Address*", name: "addressStreetAddress", error: errors.addressStreetAddress, errorMessage: "Street address is required" },
      { label: "State Initials* (e.g., PA)", name: "addressRegion", error: errors.addressRegion, errorMessage: "State is required" },
      { label: "City*", name: "addressCity", error: errors.addressCity, errorMessage: "Address city is required" },
      { label: "Postal Code*", name: "addressPostalCode", error: errors.addressPostalCode, errorMessage: "Postal code is required" }
    ],
    "billingInformation": [
      { label: "Card Number*", name: "cardNumber", error: errors.cardNumber, errorMessage: "Card number is required" },
      { label: "Expiration Date* (MM/YYYY)", name: "expiry", error: errors.expiry, errorMessage: "Expiration date is required" },
      { label: "CVC*", name: "cvc", error: errors.cvc, errorMessage: "CVC is required" }
    ]
  };

  // Function to render form field
  const renderFormField = (field) => (
    <div key={field.name}>
      <label htmlFor={field.name} className='block small smallBottomPadding'>{field.label}</label>
      <input
        type="text"
        className='largeWidth medium formTextBox small-border'
        id={field.name}
        placeholder={field.label}
        {...register(field.name, { required: field.errorMessage })}
      />
      {field.error && <p className='small red'>*{field.error.message}*</p>}
    </div>
  );

  // Function to handle form submission
  const onFormSubmit = (formData) => {
    // Extract form data
    const { cardNumber, cardHolderName, expiry, cvc, addressRegion, addressPostalCode,
      addressStreetAddress, addressCountry, addressCity } = formData;

    // Create card object
    const cardObject = {
      card: {
        expYear: expiry.slice(-4), // Extract the last 4 chars to get year
        expMonth: expiry.slice(0, 2), // Extract the first 2 chars to get month
        address: {
          region: addressRegion,
          postalCode: addressPostalCode,
          streetAddress: addressStreetAddress,
          country: addressCountry,
          city: addressCity,
        },
        number: cardNumber,
        cvc: cvc,
        name: cardHolderName,
      },
    };

    // Prepare response data
    const responseData = {
      "cost": cost,
      "cardObject": cardObject,
    };

    // Submit form data
    onSubmit(responseData);
  };

  return (
    <>
   
    <div className='grey-bkg'>
    <h1 className='centerText noMargin medTopPadding'>Checkout</h1>
    <form onSubmit={handleSubmit(onFormSubmit)} className='checkoutForm'>
      <div className='formContainer'>
        {/* Map over form sections */}
        {Object.keys(formData).map(section => (
          <div className='formSection white-bkg' key={section}>
          <h4 className='noMargin lightBold'>{section === 'contactInformation' ? 'Contact Information' : 'Billing Information'}</h4>
            <div className='line maxWidth'></div>
            {/* Map over form fields within each section */}
            {formData[section].map(field => renderFormField(field))}
          </div>
        ))}
      </div>

      <div className='formSection confirmPurchaseContainer white-bkg'>
        <h4 className='noMargin lightBold'>Summary</h4>
        <div className='line maxWidth'></div>
        <p className='smallMarginVert small'>{capitalizeFirstLetter(tierSelection)} Tier</p>
        <p className='smallMarginVert small'>Total: {cost}$ yearly</p>
        <button type="submit" className='button purchaseButton'>
          <p className='bold' style={{ margin: '10px 0px' }}>Purchase</p>
        </button>
      </div>

    </form>
    </div>
    
    </>
  );
}
