import React, { useEffect, useState, useRef } from 'react';
import AccountSelectionCard from './AccountSelectionCard';
import account_selection_features from "../../Content/Product-Page/AIAccountSelectionFeaturesContent.js";
import why_flow from '../../../assets/img/productmobile/whyturbo1.png';
import WhyTurboDropdownCard from './WhyTurboDropdownCard.jsx';


const FlowTurboComponent = () => {
  const [accountSelectionCardList, setCardList] = useState(null);
  const [isImageVisible, setImageVisible] = useState(false);
  const targetRef = useRef(null);  // Reference to the specific <p> tag

  useEffect(() => {
    setCardList(account_selection_features.map((content, index) => (
      <AccountSelectionCard 
        title={content.title} 
        description={content.description} 
        cardID={index + 1} 
        key={index} 
      />
    )));
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setImageVisible(true);
            observer.disconnect();  // Stop observing once the element is visible
          }
        });
      },
      {
        threshold: 0.1,  // Trigger when 10% of the element is visible
      }
    );

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, []);

  return (
    <div className='bg-[#f3f3f3] py-10 px-4'>
      <h1 className='font-bold font-neue-haas text-2xl'>
        Flow Turbo: Ensuring An Abundance of AI Qualified-Out Accounts, Streamlined Success
      </h1>
      <p className='mt-4 font-work-sans text-base'>
        Don’t let scarcity of truly qualified-out accounts hinder your sales team’s potential. Flow Turbo is here to revolutionize your prospecting game, ensuring your sales reps are never scarce for truly qualified-out accounts and relevant contacts
      </p>

      <h1 className='font-bold font-neue-haas text-2xl mt-10'>
        Unlocking 300 AI-Prequalified Contacts:
      </h1>
      <p className='mt-4 font-work-sans text-base'>
        In a world overflowing with data from multiple sources, researching, verifying, and validating the right accounts and leads can be extremely overwhelming for a sales rep. Flow Turbo simplifies this process by delivering 300 AI-prequalified contacts directly from AI-qualified accounts. This means your sales team gets access to a rich pool of potential clients without the unusual hassle.
      </p>

      <h1 className='font-bold font-neue-haas text-2xl mt-10'>
        AI Account Selection: Eliminating the Daily Struggle
      </h1>
      <p className='mt-4 font-work-sans text-base'>
        Traditionally, sales reps spend a significant portion of their day navigating through hundreds if not thousands of potential accounts or contacts that are completely junk or trash, an energy intensive, mentally strenuous, and time-consuming process that often leads to sales rep burnout. Flow Turbo changes this narrative by introducing the powerful AI Account Selection feature.
      </p>

      <div className="flex overflow-x-scroll space-x-4 py-4">
        <div className="flex space-x-4">
          {accountSelectionCardList && accountSelectionCardList}
        </div>
      </div>

      <div className='mt-16'>
        <h1 className='font-bold font-neue-haas text-2xl mb-9'>Why Turbo</h1>
        <div className='my-8 text-base font-neue-haas'>
          <h2 className='my-3 font-inter font-light text-[18px]'>
            Abundance in Conflict-Free Accounts
          </h2>
          <p className='font-work-sans font-medium text-base'>
            Never face scarcity again. Flow Turbo delivers an abundance of AI-prequalified contacts, so your sales reps always have a pool of potential clients at their fingertips.
          </p>
          
          <h2 className='my-3 font-inter font-light text-[18px]'>
            Maximized Sales Productivity
          </h2>
          <p className='font-work-sans font-medium text-base'>
            Reclaim lost days and boost overall sales productivity. Flow Turbo ensures that your team’s efforts are channeled into tasks that directly contribute to your bottom line.
          </p>
          
          <h2 className='my-3 font-inter font-light text-[18px]'>
            Effortless Prospecting
          </h2>
          <p ref={targetRef} className='font-work-sans font-medium text-base'>
            Never face scarcity again. Flow Turbo delivers an abundance of AI-prequalified contacts, so your sales reps always have a pool of potential clients at their fingertips.
          </p>

          {/* Conditionally render the image after paragraph is in view */}
          {isImageVisible && (
            <img
              src={why_flow}
              alt="AI-prequalified contacts"
              className="mt-4 w-[363px] h-[220px] opacity-0 transition-opacity duration-1000 ease-in-out"
              onLoad={(e) => e.currentTarget.classList.add('opacity-100')}
            />
          )}
        </div>
        <WhyTurboDropdownCard></WhyTurboDropdownCard>
      </div>
    </div>
  );
};

export default FlowTurboComponent;
