const gains_selection_features = [
    {
        "title": "Insider Insights",
        "description": "Unlock revolutionary insights into the world of B2B sales."
    },
    {
        "title": "Commission Triumph",
        "description": "Learn to navigate the numbers game for unprecedented commission opportunities."
    },
    {
        "title": "Strategic Eliminations",
        "description": "Craft success by strategically eliminating barriers to your sales journey."
    },
    {
        "title": "Roadmap to Opportunities",
        "description": "Craft success by strategically eliminating barriers to your sales journey."
    }
];

export default gains_selection_features;
