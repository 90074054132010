import React, { useState, useRef, useEffect } from 'react';
import { cn } from "../../lib/utils";

const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(window.matchMedia(query).matches);

  useEffect(() => {
    const mediaQueryList = window.matchMedia(query);
    const listener = () => setMatches(mediaQueryList.matches);

    mediaQueryList.addEventListener('change', listener);
    return () => mediaQueryList.removeEventListener('change', listener);
  }, [query]);

  return matches;
};

const ResponsiveCarousel = ({ items, direction = "left", speed = "slow", pauseOnHover = true, className }) => {
  const isDesktop = useMediaQuery('(min-width: 768px)');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);
  const containerRef = useRef(null);
  const scrollerRef = useRef(null);
  const carouselRef = useRef(null);
  const [start, setStart] = useState(false);

  useEffect(() => {
    if (isDesktop) {
      function addAnimation() {
        if (containerRef.current && scrollerRef.current) {
          const scrollerContent = Array.from(scrollerRef.current.children);

          scrollerContent.forEach((item) => {
            const duplicatedItem = item.cloneNode(true);
            if (scrollerRef.current) {
              scrollerRef.current.appendChild(duplicatedItem);
            }
          });

          getDirection();
          getSpeed();
          setStart(true);
        }
      }
      const getDirection = () => {
        if (containerRef.current) {
          if (direction === "left") {
            containerRef.current.style.setProperty("--animation-direction", "forwards");
          } else {
            containerRef.current.style.setProperty("--animation-direction", "reverse");
          }
        }
      };
      const getSpeed = () => {
        if (containerRef.current) {
          if (speed === "fast") {
            containerRef.current.style.setProperty("--animation-duration", "20s");
          } else if (speed === "normal") {
            containerRef.current.style.setProperty("--animation-duration", "40s");
          } else {
            containerRef.current.style.setProperty("--animation-duration", "80s");
          }
        }
      };
      addAnimation();
    } else {
      const handleScroll = () => {
        if (!isScrolling) {
          const newIndex = Math.round(
            carouselRef.current.scrollLeft / carouselRef.current.clientWidth
          );
          setCurrentIndex(newIndex);
        }
      };

      const handleScrollEnd = () => {
        setIsScrolling(false);
      };

      const element = carouselRef.current;
      element.addEventListener('scroll', handleScroll);
      element.addEventListener('scroll', handleScrollEnd, { once: true });

      return () => {
        element.removeEventListener('scroll', handleScroll);
        element.removeEventListener('scroll', handleScrollEnd);
      };
    }
  }, [direction, speed, isScrolling, isDesktop]);

  const handleIndicatorClick = (index) => {
    setIsScrolling(true);
    setCurrentIndex(index);
    if (carouselRef.current) {
      const scrollAmount = carouselRef.current.clientWidth * index;
      carouselRef.current.scrollTo({
        left: scrollAmount,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className={cn("mx-auto overflow-hidden", className)}>
      {isDesktop ? (
        // Desktop View
        <div
          ref={containerRef}
          className="scroller relative z-20 w-full overflow-hidden"
        >
          <ul
            ref={scrollerRef}
            className={cn(
              "flex min-w-full shrink-0 gap-4 py-4 w-max flex-nowrap",
              start && "animate-scroll",
              pauseOnHover && "hover:[animation-play-state:paused]"
            )}
          >
            {items.map((item, idx) => (
              <li
                className="w-[80vw] max-w-full relative rounded-2xl flex-shrink-0 px-8 py-6 md:w-[450px] 2xl:w-[600px]"
                style={{
                  background: "linear-gradient(180deg, #FFFFFF, #F8F8F8)",
                }}
                key={`${item.name}-${idx}`}
              >
                <blockquote>
                  <div
                    aria-hidden="true"
                    className="user-select-none -z-1 pointer-events-none absolute -left-0.5 -top-0.5 h-[calc(100%_+_4px)] w-[calc(100%_+_4px)]"
                  ></div>
                  <div className="relative z-20 mt-6 flex flex-row items-center">
                    <span className="flex flex-col gap-1">
                      <span className="text-sm md:text-md 2xl:text-2xl leading-[1.6] text-gray-400 font-bold font-neue-haas">
                        {item.title}
                      </span>
                    </span>
                  </div>
                  <span className="relative z-20 text-sm 2xl:text-2xl leading-[1.6] font-normal">
                    {item.quote}
                  </span>
                </blockquote>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        // Mobile View
        <div className="relative w-full">
          <div
            ref={carouselRef}
            className="overflow-x-scroll scroll-smooth snap-x snap-mandatory flex gap-4 scrollbar-hide"
          >
            {items.map((item, idx) => (
              <li
                key={`${item.name}-${idx}`}
                className="w-[80vw] max-w-full relative rounded-2xl flex-shrink-0 px-8 py-6 snap-center list-none"
                style={{
                  background: "linear-gradient(180deg, #FFFFFF, #F8F8F8)",
                }}
              >
                <blockquote>
                  <div
                    aria-hidden="true"
                    className="user-select-none -z-1 pointer-events-none absolute -left-0.5 -top-0.5 h-[calc(100%_+_4px)] w-[calc(100%_+_4px)]"
                  ></div>
                  <div className="relative z-20 mt-6 flex flex-row items-center">
                    <span className="flex flex-col gap-1">
                      <span className="text-sm md:text-md 2xl:text-2xl leading-[1.6] text-gray-400 font-bold font-neue-haas">
                        {item.title}
                      </span>
                    </span>
                  </div>
                  <span className="relative z-20 text-sm 2xl:text-2xl leading-[1.6] font-normal">
                    {item.quote}
                  </span>
                </blockquote>
              </li>
            ))}
          </div>
          <div className="absolute bottom-3 left-1/2 transform -translate-x-1/2 flex space-x-3">
            {items.map((_, index) => (
              <button
                key={index}
                className={`w-3 h-3 rounded-full ${
                  currentIndex === index ? "bg-black" : "bg-gray-300"
                }`}
                onClick={() => handleIndicatorClick(index)}
              ></button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ResponsiveCarousel;