import React from "react";
import DesktopHomePage from "./DesktopHome.js";
import MobileHomePage from "./MobileHome.js";
import { useState, useEffect } from "react";


const CUTOFF = 600; // adjust if necessary, temporary for now

const Home = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < CUTOFF);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < CUTOFF);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);


    return (
        <>
            {
                isMobile ? <MobileHomePage /> : <DesktopHomePage />
            }
        </>
    );
}

export default Home;
