import React from 'react'
import { MacbookScroll } from '../../../AcertinityAnimations/MacBookScroll.tsx';
import { TextGenerateEffect } from '../../../AcertinityAnimations/TextGenerateEffect.tsx';



const IntroComponent = () => {
  return (
    <div className="flex flex-col items-cente bg-white pb-14">
      <div className="centered-text pt-4 px-4">
        <div className='bold'>Revolutionizing Sales Prospecting with the Flow Turbo SaaS <span style={{ color: 'var(--primary)' }}>AI Platform</span></div>
        <TextGenerateEffect words='Empower Your Sales Team With AI-Driven Sales Prospecting' className='font-neue-haas font-medium'></TextGenerateEffect>
      </div>
      <div className='overflow-hidden px-10'>
        <MacbookScroll></MacbookScroll>

      </div>

      {/* <div className="w-full max-w-md">
        <div className='centerAlign overflow-hidden px-3'>
          <MacbookScroll></MacbookScroll>
        </div>
      </div> */}

    </div>
  )
}

export default IntroComponent