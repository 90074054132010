import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Footer from '../../components/Footer';
import './Contact.css';

import { Button } from "@mui/material";

const Contact = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1439);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1440);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div className='fadeInAnimation customer-service_container'>
        <div className='header_text'>Contact Flow</div>
        <div className="customer-service_two-item-block">
          <div className="info-card">
            <img
              src="/assets/images/contact/sales-support.png"
              alt=""
              className="hide3"
            />
            <h2 className="medium bold">Sales support</h2>
            <div>Submit a sales request or connect with a Flow senior consultant.</div>
            <div>
              <Link to="/contact/sales-support">
                <Button className="primary-btn">
                  Contact sales
                </Button>
              </Link>
            </div>
          </div>
          <div className="info-card">
            <img
              src="/assets/images/contact/subscriber-technical-support.png"
              alt=""
              className="hide3"
            />
            <h2 className="medium bold">Subscriber support services</h2>
            <h2 className="medium bold">Technical support</h2>
            <div>
              Support for service-related technical issues for Elite Support Tier customers.
              Currently unavailable under the Starter, Premium, or Ultra Tier support plans.
            </div>
            <div>
              <Link to="/contact/sales-support">
                <Button className="primary-btn">
                  Submit technical support request
                </Button>
              </Link>
            </div>
          </div>
        </div>
        <div className="customer-service_two-item-block">
          <div className="float-bottom">
            <div className="info-card">
              <h2 className="medium bold">Billing or account support</h2>
              <div>Assistance with account and billing-related inquiries.</div>
              <div>
                <Link to="/contact/sales-support">
                  <Button 
                    className={isMobile ? "billing-btn" : "primary-btn"}
                    style={{ width: '90%' }}
                  >
                    {isMobile ?
                      "Submit a billing request" : 
                      "Sign in and submit a Flow billing request"
                    }
                  </Button>
                </Link>
              </div>
              <div>
                <Link to="/contact/sales-support">
                  <Button className={isMobile ? "primary-btn" : "password-btn"}>
                    {isMobile ?
                      "Recover your password":
                      "Get help recovering your Flow account password"
                    }
                  </Button>
                </Link>
              </div>
            </div>
          </div>
          <img
            src="/assets/images/contact/billing-account-support.png"
            alt=""
            className="hide3 mobile-hidden"
          />
        </div>
      </div>
      <Footer />
    </>
  );
};



export default Contact;

