import flowLogo from "./../../assets/img/icon/logo.png";
import { FaSearch } from "react-icons/fa";
import { FaRegUserCircle } from "react-icons/fa";
import { Link } from "react-router-dom";

function AccountHeader({ accountID }) {
  return (
    <div className="account-header flex flex-row justify-between pt-[20px] pb-[20px] pl-[40px] pr-[40px] w-[100%] ">
      <div className="left-nav flex flex-row gap-20 w-[50%] items-center">
        <div id="logo w-[30%]">
          <Link to="/">
            <img src={flowLogo} />
          </Link>
        </div>
        <div id="company w-[70%]">company-org.</div>
      </div>
      <div className="right-nav flex flex-row gap-20 w-[50%] items-center justify-end">
        <div
          id="search-bar"
          className="w-[45%] border-2 border-black rounded-lg flex flex-row justify-center items-center bg-white pt-1 pb-1 pl-2 pr-2"
        >
          <input
            type="text"
            className="w-[90%] rounded-lg pl-2 placeholder:bold outline-none"
            placeholder="Search"
          />
          <FaSearch className="w-[10%]" />
        </div>
        <div id="user" className="flex flex-row gap-1">
          <div>Username</div>
          <div>
            <FaRegUserCircle />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountHeader;
