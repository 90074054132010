import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import TinyMCEEditor from './TinyMCEEditor';
import './UpdateJobForm.css';

const UpdateJobForm = () => {
  const { jobId } = useParams();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [department, setDepartment] = useState('');
  const [location, setLocation] = useState('');
  const [closeDate, setCloseDate] = useState('');
  const [keywords, setKeywords] = useState(''); // New state for Keywords
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const API_BASE_URL = process.env.REACT_APP_API;

  const fetchJobDetails = useCallback(async () => {
    try {
      const token = localStorage.getItem('accessToken');
      if (!token) {
        throw new Error('No token found');
      }
      const response = await axios.get(`${API_BASE_URL}api/hr/jobs/${jobId}/`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const { Title, Description, Department, Location, CloseDate, Keywords } = response.data;
      setTitle(Title);
      setDescription(Description);
      setDepartment(Department);
      setLocation(Location);
      setCloseDate(CloseDate);
      setKeywords(Keywords); // Set Keywords field from the response data
    } catch (error) {
      console.error('Error fetching job details:', error);
      setError('Failed to fetch job details. Please try again.');
    }
  }, [jobId, API_BASE_URL]); // Add API_BASE_URL to the dependency array

  useEffect(() => {
    fetchJobDetails();
  }, [fetchJobDetails]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      const token = localStorage.getItem('accessToken');
      await axios.put(
        `${API_BASE_URL}api/hr/jobs/${jobId}/`,
        {
          Title: title,
          Description: description,
          Department: department,
          Location: location,
          CloseDate: closeDate,
          Keywords: keywords, // Send Keywords to the backend
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      navigate('/internal-hr-joblist'); // Redirect to JobList after successful job update
    } catch (error) {
      console.error('Error updating job:', error);
      setError('Failed to update job. Please try again.');
    }
  };

  const renderError = () => {
    if (!error) return null;

    if (typeof error === 'string') {
      return <p className="error-message">{error}</p>;
    }

    return (
      <ul className="error-list">
        {Object.keys(error).map((key, index) => (
          <li key={index}>{`${key}: ${error[key].join(', ')}`}</li>
        ))}
      </ul>
    );
  };

  return (
    <div className="update-job-container">
      <h2 className="update-create-job-title">Update Job</h2>
      {renderError()}
      <form onSubmit={handleSubmit} className="update-job-form">
        <div>
          <label>Title</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Description</label>
          <TinyMCEEditor value={description} onChange={setDescription} />
        </div>
        <div>
          <label>Keywords</label> {/* New Keywords input field */}
          <input
            type="text"
            value={keywords}
            onChange={(e) => setKeywords(e.target.value)}
            placeholder="Enter keywords for the job"
          />
        </div>
        <div>
          <label>Department</label>
          <select
            value={department}
            onChange={(e) => setDepartment(e.target.value)}
            required
          >
            <option value="" disabled>Select department</option>
            <option value="Engineering">Engineering</option>
            <option value="Design">Design</option>
            <option value="Marketing">Marketing</option>
            <option value="Finance and Accounting">Finance and Accounting</option>
          </select>
        </div>
        <div>
          <label>Location</label>
          <input
            type="text"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Close Date</label>
          <input
            type="date"
            value={closeDate}
            onChange={(e) => setCloseDate(e.target.value)}
            required
          />
        </div>
        <button type="submit">Update Job</button>
      </form>
    </div>
  );
};

export default UpdateJobForm;