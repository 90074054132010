import React from 'react'


import '../../../assets/img/icon/feature_icon1.png'

import { CardBody, CardContainer, CardItem } from "../../../AcertinityAnimations/3DCard.tsx";



const KeyFeatureCard = ({ icon, title, description }) => {
    return (
        <div>
        <CardContainer className="inter-var">
            <CardBody className="bg-black relative group/card  dark:hover:shadow-2xl dark:hover:shadow-emerald-500/[0.1] dark:bg-black dark:border-white/[0.2] border-black/[0.1] w-auto sm:w-[30rem] h-auto rounded-xl p-6 border  ">
                <CardItem translateZ="100" className="w-full mt-4">
                    <div className="key-feature">
                        <div className="centerAlign">
                            <img src={icon} alt={title} className="feature-icon" />
                        </div>

                    </div>
                </CardItem>

                <CardItem className='key-feature-text text-white' translateZ="50">

                    <div className='bold'>{title}</div>
                    <div>{description}</div>

                </CardItem>

            </CardBody>
        </CardContainer>
        </div>
       

    )
}

export default KeyFeatureCard
