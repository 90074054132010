import React from 'react';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';

import '../assets/css/CustomerService.css';

// TODO: have 'Submit a Flow Support Request' and 'Submit a Flow Billing Request' buttons link to JIRA technical desk after logging in
//   - needs account management first for customers only.
// TODO: 'Submit a Flow Support Request' link does not have the same styling as the other link+div combinations
const CustomerService = () => {
    return(
        <>
            <div className='customer-services fadeInAnimation'>
                <div className='customer_service_title px-2 header_text'>
                    <h1>
                        Customer Service
                    </h1>
                </div>
                <div style={{ margin: '0 10vw' }}>
                    <div className='section section1'>
                        <div className='subheader_text'>Contact Flow</div>
                        <div className='sub_title_text bold'>General Support Services</div>
                        <div className='sub_title_text'>Sales Support</div>
                        <div>Submit a sales request or connect with a senior consultant.</div>
                        <Link to="/contact/sales-support" className='btn_link'><div className='green_btn'>Connect with Flow Sales Support</div></Link>
                    </div>
                    <div className='section section2'>
                        <div className='subheader_text'>Subscriber Support Services</div>
                        <div className='sub_title_text bold'>Technical Support</div>
                        <div>Support for service-related technical issues for Elite Support Tier Customers. Currently unavailable Starter, Premium, or Ultra Tier support plans.</div>
                        {/* <Link to="/login" className='btn_link'><div className='green_btn'>Sign in and Submit a Flow Support Request</div></Link> */}
                        <Link to="/contact/sales-support" className='btn_link'><div className='green_btn'>Submit a Flow Support Request</div></Link>
                    </div>
                    <div className='section section3'>
                        <div className='subheader_text'>Billing or Account Support</div>
                        <div>Assistance with account and billing related issues</div>
                        {/* <Link to="/login" className='btn_link'><div className='green_btn'>Sign in and Submit a Flow Billing Request</div></Link> */}
                        <Link to="/contact/sales-support" className='btn_link'><div className='green_btn'>Submit a Flow Billing Request</div></Link>
                        <Link to="/contact" className='bold underline small'>
                            <div className="customer-service-link">
                                Get Help Recovering your Flow account password
                            </div>
                        </Link>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default CustomerService;