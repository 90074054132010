import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

const TinyMCEEditor = ({ value, onChange }) => {
  return (
    <Editor
      value={value}
      apiKey="nkvvdu8h0v1cff9c8a41vvn4hwcnfnukmrt9yfog8mh9jgp3"
      init={{
        height: 400,
        menubar: false,
        plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount linkchecker',
        toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
        setup: editor => {
          editor.on('change', () => {
            onChange(editor.getContent());
          });
        },
        readonly: false,  // Ensure readonly is false to allow editing
        init_instance_callback: editor => {
          console.log('Editor was initialized');
        },
        error: (message) => {
          console.error('TinyMCE error:', message);
        }
      }}
      onEditorChange={onChange}
    />
  );
};

export default TinyMCEEditor;