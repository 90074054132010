// Privacy.js
import React from 'react';
import '../assets/css/Privacy.css';
import Footer from '../components/Footer';

function Privacy() {
  return (
    <div className="privacy">
      <div className='privacy_container'>
        <h1 className="header_text">Privacy Policy</h1>
        <p>
          Last Updated: July 2nd, 2024
        </p>
        <p>
          Welcome to Flow Global Software Technologies, LLC ("we," "us," or "our").
          This Privacy Policy explains how we collect, use, disclose, and protect your
          personal information when you visit our website and use our services.
        </p>
        <h2>Introduction</h2>
        <p>
          Flow Global Software Technologies, LLC (referred to by ‘we’, ‘us’ or ‘our’) recognizes the importance of privacy.
          In this privacy policy (“Privacy Policy”), we describe how we collect, use, and disclose information that we obtain
          about visitors to our website, http://www.flowai.tech, and other Flow Global Software Technologies websites where
          we post this policy (the “Site”), and the services available through our Site (the “Services”).
        </p>
        <p>
          Your use of our Site or Services, and any dispute over privacy, is subject to this Privacy Policy,
          our Terms and Conditions, and any client agreement you may have with Flow Global Software Technologies
          and/or its affiliated companies. Any client agreement between you and Flow Global Software Technologies
          and/or its affiliated companies supersedes the terms of this Privacy Policy to the extent there are any
          discrepancies. As used in this Privacy Policy, “Personal Information” is any information that we can reasonably
          use to identify you.
        </p>
        <p>
          If you are a resident of California, Colorado, Connecticut, Utah, or Virginia, please see our Supplemental
          U.S. State Privacy Notice for additional information applicable to residents of those states. This supplemental
          notice includes how consumers of those states may exercise their privacy rights.

        </p>
        <h2>How and Why We Collect Information</h2>
        <p>
          We collect Personal Information solely for business purposes. We collect Personal Information about you directly from you,
          from third-party sources, and automatically through your use of our Site or Services. We may combine the Personal
          Information that we collect from various sources.
        </p>
        <ul>
          <li> <b>Public Information We Collect Directly from You through External, Third-Party Sources.</b> We collect professional
            information you have decided yourself to make public (to the general public) from publicly available professional
            social networks, start-up websites, business consulting websites, and widely distributed media.
          </li>
          <li>
            <b>Information We Collect Directly from You through Our Site. </b> We and our service providers collect certain information that you
            provide directly to us on our Site, including information you provide when you contact us as a prospective or existing customer.
            We and our service providers also collect certain information through password-protected portals (like the log-in page on our
            Site). The information we collect directly from you includes, for example, your name and email address. We may also collect
            information that you provide to us when contacting us as a prospective or existing customer. We will also collect any other
            information that you choose to provide to us through our Site.
          </li>
          <li>
            <b>Information We Collect Automatically. </b>We and our service providers automatically collect information through your use of our
            Services using cookies and other technologies. This information may include, without limitation, information about your operating
            system; settings and system configurations; device IDs; push notification tokens; IP address; device model; the webpages you
            access within our Services; the website that led you to our Services; the website to which you go after leaving our Services;
            the number of times you access our Service, including the dates and times you do so; and other Services usage data.
          </li>
        </ul>



        <h2>Categories of Personal Information We May Collect</h2>
        The categories of personal information that we may collect about you are:

        <ul>
          <li> Your name, job title, name of the company you represent, your position, e-mail address, and phone number
            (for example, if you are a prospective or existing customer). If you submit a case (inquiry) to us, we may also collect
            the following information about you: your bank account details, the content and date of your inquiry, our response to your
            inquiry, other communications with you about your case, and any other information that you provide to us directly.
            Additionally, in order for us to enable you to create and manage your account with us, as well as access and use our
            Database Data API service via our self-service platform, we also collect the following information about you: your industry,
            your credit card information (applicable for paid services only), your account credentials (e.g., username and encrypted
            password), and usage data (e.g., access logs, activity history, and preferences).</li>

          <li>
            We may also collect public professional information you have decided yourself to make public on professional
            social networks, start-up websites, and business consulting websites, including: your name, public profile URL,
            job title, industry, work experience, education information, number of recommendations received, awards, certifications,
            courses, languages, organizations, patents, projects, publications, test scores, volunteering experience, title of
            your online profile, country of origin, groups that you are a member of, interests, websites that you are interested in,
            publicly available summary about you within the “About” or similar type of sections, number of connections, static
            location (if any is specified by you), account creation date, publicly available contact information such as email
            address, and other information about you that you made public.
          </li>
        </ul>
        <h2 className='black'> How we use your information </h2>
        We may use your Personal Information for the following purposes:

        <ul>
          <li> <b>Providing Our Services.</b>
            To provide our Site and Services to you; to communicate with you, including via email, about your use of our Site and
            Services; to perform under a contract (for example, concerning our service agreement); to enable you to create an account
            and use our services via a self-service platform; to respond to your inquires as a prospective or existing customer;
            to offer you our services; and for other customer service and support purposes. </li>

          <li> <b>Customized Experience. </b>
            We may offer personalized help and instructions (for instance, on our self-service platform), and to otherwise personalize your
            experiences while using the Services. </li>

          <li>
            <b> Marketing and Communications.</b>
            To communicate with you about your account and use of our Site and Services; to provide you with news and newsletters,
            research, and other items of interest.
          </li>

          <li>
            <b>Surveys.</b>
            To administer surveys and questionnaires, such as for market research or client satisfaction purposes.
          </li>

          <li>
            <b> Research and Analytics. </b>
            To analyze how users interact with our Site and Services; and for other research, analytical, and statistical purposes.
          </li>

          <li>
            <b> Protecting Rights and Interests. </b>
            To protect our rights and interests as well as the rights and interests of our users and any other person, as well as
            to enforce this Privacy Policy and our Terms and Conditions.
          </li>

          <li>
            <b>Legal Compliance.</b>To comply with applicable legal or regulatory obligations, including informal requests from
            law enforcement or other governmental authorities; to engage in legal proceedings related to you or the company you represent.
          </li>
        </ul>

        <h2> How We Disclose Your Information to Third Parties</h2>
        We may disclose your Personal Information with the following third parties:

        <ul>
          <li> <b> Clients.</b> We disclose your public professional information to our clients. This is information that you have
            decided yourself to make public on publicly available professional social networks, start-up websites, and business consulting
            websites.</li>

          <li> <b> Affiliates.</b> We may disclose information to any current or future affiliates, parent companies, or subsidiaries
            of Flow Global Software Technologies.</li>

          <li> <b> Service Providers.</b> We may disclose your information to our vendors, service providers, agents, or others who
            perform functions for us and/or on our behalf.</li>
        </ul>

        We may also disclose your information in the following ways:

        <ul>
          <li> <b>Business Transfers.</b> We may disclose information to another entity if we are or may be acquired by or merged with
            another company, if we sell or transfer a business unit or assets to another company, as part of a bankruptcy proceeding,
            or as part of any other similar business transfer. If the surviving entity involved in such a transfer is not Flow Global
            Software Technologies, in accordance with applicable law, the surviving company may use the information we collect about
            you pursuant to its own privacy policies, which may be different from this Privacy Policy.</li>

          <li>
            <b>Protecting Rights and Interests. </b>
            We may disclose information where we believe it is necessary to investigate, prevent, or take action regarding illegal activities,
            suspected fraud, situations involving potential threats to the safety of any person, violations of our Terms and Conditions or
            this Privacy Policy, or as evidence in litigation in which we are involved.
          </li>

          <li>
            <b>Legal Compliance.</b>
            We may disclose your information to comply with applicable legal or regulatory obligations, including as part of a
            judicial proceeding; in response to a subpoena, warrant, court order, or other legal process; or as part of an
            investigation or request, whether formal or informal, from law enforcement or a government official.
          </li>

          <li>
            <b>Aggregate and De-Identified Information.</b>
            We may disclose aggregate, anonymous, or de-identified information about users for marketing, advertising, research,
            compliance, security, or other purposes.
          </li>
        </ul>

        <h2 className='black'> Cookies and Other Tracking Mechanisms </h2>

        Flow Global Software Technologies and our service providers use cookies and other tracking mechanisms to track
        information about your use of our Site and Services. Flow Global Software Technologies and our service providers
        may combine this information with other Personal Information we collect about you.

        <ul>
          <li>

            <b>Cookies. </b>
            We and our service providers use cookies to track visitor activity on our Site, such as the pages visited, the amount
            of time spent on each page, and general information about the device used to access our Site. Necessary cookies are a
            condition of using our website. If you reject these types of cookies, then we cannot predict how our Site will perform
            when you visit it. You can control the use of functionality cookies, targeting cookies, or advertising cookies by
            adjusting your browser settings. Please note that by deleting cookies or disabling future cookies you may be unable
            to access certain areas or features of our Site. If you adjust your cookie settings this will also affect the other
            websites you visit. Most browsers (like Google Chrome, Mozilla Firefox, and Microsoft Edge) offer their own settings
            to manage cookies. The Help portion of the toolbar on most browsers will tell you how to prevent your device from
            accepting new cookies, how to have the browser notify you when you receive a new cookie, and how to disable cookies
            altogether. To find out how to manage cookies in your browser, please visit the browsers’ websites.
          </li>

          <li>
            <b>Clear GIFs, Web Beacons, Pixel Tags, and Other Technologies.</b>
            Clear GIFs are tiny graphics with a unique identifier, similar in function to cookies. In contrast to cookies, which
            are stored on a user’s hard drive, clear GIFs are embedded invisibly on web pages. We may use clear GIFs
            (a.k.a. web beacons, web bugs, or pixel tags) in connection with our Services to, among other things, track the
            activities of visitors to our Site, help us manage content, and compile statistics about the usage of our Site. We and
            our service providers also use clear GIFs in HTML emails to our customers to help us track email response rates, identify
            when our emails are viewed, and track whether our emails are forwarded.
          </li>

          <li>
            <b>Third-Party Analytics.</b>
            We use automated devices and applications, such as Google Analytics, to evaluate the usage of
            our Site and Services. We use these tools to help us improve our Services, performance, and user experiences. We may
            also engage third parties to analyze our users' online activities over time and across different websites. We do not
            respond to browser “Do Not Track” signals.
          </li>
        </ul>

        <h2 className='black'> Third-Party Links </h2>
        <p>
          Our Site and Services may contain links to third-party websites. Any access to and use of such linked websites is not
          governed by this Privacy Policy but instead is governed by the privacy policies of those third-party websites. We are
          not responsible for the information practices of such third-party websites.
        </p>

        <h2 className='black'> Security </h2>

        <p>
          We have implemented safeguards to protect the information we collect. Please be aware that despite our efforts, no data
          security measures can guarantee 100% security. You should take steps to protect against unauthorized access to your password,
          phone, and computer by, among other things, signing off after using a shared computer, choosing a robust password, and
          keeping your password private. We are not responsible for any lost, stolen, or compromised passwords, or for any activity
          on your account via unauthorized password activity.
        </p>

        <h2 className='black'> Your Privacy Rights </h2>
        <p>Depending on your location, you may have the following rights regarding your personal information:</p>

        <ul>
          <li>
            <b> Access and Update Information. </b>
            You may access, update, or correct the personal information in your account by contacting us at privacy@flowai.tech or
            through your account settings.
          </li>

          <li>
            <b>Delete Information.</b>
            You may request that we delete your personal information by contacting us at privacy@flowai.tech. We may retain certain
            information as required by law or for legitimate business purposes.
          </li>

          <li>
            <b>Opt-Out of Marketing Communications. </b>
            You may opt-out of receiving marketing emails from us by following the opt-out instructions provided in those emails.
            Please note that even if you opt-out of marketing communications, we may still send you administrative messages, such as
            information about your account or our ongoing business relations.
          </li>
        </ul>


        <h2 className='black'> Children's Privacy </h2>
        <p>
          Our Services are not designed for children under 13. If we become aware that we have collected personal information
          from a child under 13, we will delete such information from our files.
        </p>


        <h2 className='black'> International Users </h2>
        <p>
          If you are visiting our Site from outside the United States, please be aware that your information may be transferred to,
          stored, and processed in the United States where our servers are located and our central database is operated. By using our
          Site and Services, you acknowledge and agree that your information may be transferred to our servers and facilities located
          in the United States and other countries as necessary to provide you with our Services. Different countries have different
          data protection laws, and these laws may be less stringent than the laws in your country.
        </p>

        <h2 className='black'> Changes to this Privacy Policy </h2>
        <p>
          We may update this Privacy Policy to reflect changes to our practices, the Site, or Services. If we make any material changes,
          we will notify you by email (sent to the email address specified in your account) or by means of a notice on this Site prior
          to the change becoming effective. We encourage you to periodically review this page for the latest information on our privacy
          practices.
        </p>


        <h1 className='black'> Supplemental U.S. State Privacy Notice</h1>
        <h2 className='black'> Effective Date: July 2nd, 2024</h2>
        <p>
          This Supplemental U.S. State Privacy Notice ("Supplemental Notice") provides additional information to residents of California,
          Colorado, Connecticut, Utah, and Virginia ("Residents") about how Flow Global Software Technologies, LLC ("we," "us," or "our")
          processes their personal information. This Supplemental Notice includes details about the rights that Residents may have under
          the privacy laws of these states and how they can exercise those rights.
        </p>


        <h2 className='black'> Categories of Personal Information We May Collect </h2>
        <ul>
          <li> Name   </li>
          <li> Phone number(s)   </li>
          <li>  Email address(es)  </li>
          <li>  Mailing address(es)  </li>
          <li>  User ID(s), online identifiers, and log-in credentials  </li>
          <li>  Internet Protocol Addresses  </li>
          <li>  Signature  </li>
          <li> Banking and other financial data    </li>
          <li>  Products or services purchased, obtained, or considered, and other purchasing or consuming histories or tendencies</li>
          <li>  Professional or employment-related information, such as job title and business contact information</li>
          <li> Publicly available information, including professional information that you make available </li>
          <li> Education information    </li>
          <li>  Static location (if specified)   </li>
          <li>
            Internet activity information, such as browsing and search history, and interactions with websites and applications,
            our systems, and networks
          </li>
        </ul>


        <h2 className='black'> Sources from Which Personal Information May Be Collected </h2>
        <ul>
          <li>  Public sources  </li>
          <li> Third parties that perform services for us or on our behalf   </li>
          <li>  You or your representative(s), including your employer, if your employer is a client of ours  </li>
          <li>  Government agencies  </li>
        </ul>



        <h2 className='black'> Purposes for Collecting Personal Information</h2>

        <ul>
          <li>   Marketing and communications  </li>
          <li>   Training and quality control  </li>
          <li> Customer education    </li>
          <li>  To provide a customized and personalized list of our products and services that may interest you </li>
          <li>  To conduct business analysis, planning, and reporting </li>
          <li>  To verify your identity </li>
          <li>  To protect you (e.g., to protect you against fraudulent or illegal activity) </li>
          <li>  To maintain the accuracy of our records </li>
        </ul>

        <h2 className='black'> Disclosures to Third Parties</h2>
        <ul>
          <li> <b> Clients:</b>
            We disclose your public professional information to our clients. This is information that you have decided yourself to
            make public on publicly available professional social networks, start-up websites, and business consulting websites.</li>

          <li><b>Affiliates:</b>
            We may disclose information to any current or future affiliates, parent companies, or subsidiaries.</li>

          <li>
            <b> Services Providers:</b>
            We may disclose your information to our vendors, service providers, agents, or others who perform functions for us
            and/or on our behalf.
          </li>

          <li>
            <b> Business Transfers:</b>
            We may disclose information to another entity if we are or may be acquired by or merged with another company, if we sell or
            transfer a business unit or assets to another company, as part of a bankruptcy proceeding, or as part of any other similar
            business transfer. If the surviving entity involved in such a transfer is not us, the surviving company may use the information
            we collect about you pursuant to its own privacy policies, which may be different from this Privacy Policy.
          </li>

          <li>
            <b> Protecting Rights and Interests:</b>
            We may disclose information where we believe it is necessary to investigate, prevent, or take action regarding illegal activities,
            suspected fraud, situations involving potential threats to the safety of any person, violations of our Terms and Conditions or
            this Privacy Policy, or as evidence in litigation in which we are involved.
          </li>

          <li>
            <b> Legal Compliance:</b>
            We may disclose your information to comply with applicable legal or regulatory obligations, including as part of a judicial
            proceeding; in response to a subpoena, warrant, court order, or other legal process; or as part of an investigation or request,
            whether formal or informal, from law enforcement or a government official.
          </li>

          <li>
            <b>Aggregate and De-Identified Information:</b>
            We may disclose aggregate, anonymous, or de-identified information about users for marketing, advertising, research,
            compliance, security, or other purposes.
          </li>

        </ul>

        <h2 className='black'> Your Rights </h2>
        <p>Consumers have the following rights under the U.S. State Privacy Laws:</p>
        <h2 className='black'> Right to Know/Access (Confirm) </h2>
        <p>
          You have the right to submit a verifiable request (i.e., a request we can authenticate) for copies of your personal information
          that Flow Global Software Technologies, LLC has held, free of charge, including:
        </p>

        <ul>
          <li>The categories of personal information that we have collected.</li>
          <li>The categories of sources from which we collected personal information.</li>
          <li>The business or commercial purpose for collecting, selling, or sharing personal information.</li>
          <li>The categories of third parties to whom we disclose personal information.</li>
          <li>The specific pieces of personal information we have collected.</li>
        </ul>

        <h2 className='black'> Right to Deletion </h2>
        <p>
          At your request, we will delete your personal information unless we need to keep such information for legal or business
          purposes and/or to provide services or further the relationship with us that you have requested.
        </p>

        <h2 className='black'> Right to Opt-Out of Selling, Sharing, and/or Targeting</h2>
        <p>Subject to certain business and legal exceptions, you have the right to opt out of certain processing activities.</p>
        <ul>
          <li>In California, you may request that we stop selling or sharing your personal information for cross-context
            behavioral advertising. </li>
          <li>Residents of Colorado and Utah specifically have the right to opt out of the processing of personal data for purposes
            of (i) targeted advertising and (ii) the sale of personal data.</li>
          <li>Residents of Virginia specifically have the right to opt out of the processing of personal data for purposes of
            (i) targeted advertising, (ii) the sale of personal data, and (iii) profiling in furtherance of decisions that
            produce legal or similarly significant effects concerning the consumer.</li>
          <li>Residents of Connecticut specifically have the right to opt out of the processing of personal data for purposes of (i)
            targeted advertising, (ii) the sale of personal data, and (iii) profiling in furtherance of solely automated decisions that
            produce legal or similarly significant effects concerning the consumer.</li>
        </ul>
        <h2 className='black'> Right to Correct </h2>
        <p>We will use commercially reasonable efforts to comply with your request to correct any inaccurate personal information.</p>


        <h2 className='black'> Right to Limit the Use and Disclosure of Sensitive Personal Information </h2>
        <p>
          Subject to certain business and legal exceptions, residents of certain states may request that we limit the use and
          disclosure of sensitive personal information, as that term is defined in the applicable U.S. State Privacy Laws.
          For example, under the CCPA, the following may qualify as sensitive personal information: social security number,
          driver’s license number, passport number, account log-in information, and precise geolocation information.
        </p>


        <h2 className='black'> Right to Data Portability</h2>
        <p>
          At your request, and subject to certain exceptions, we will provide you free of charge with any of your personal
          information that we hold and will provide it in a structured, commonly used, and machine-readable format.
        </p>


        <h2 className='black'> Right to Nondiscrimination</h2>
        <p>
          You are entitled to exercise the rights described above free from discriminatory treatment prohibited by
          the applicable U.S. State Privacy Laws.
        </p>


        <h2 className='black'> Exercising Your Rights</h2>
        <p>
          To exercise your privacy rights under the laws of California, Colorado, Connecticut, Utah, or Virginia, please
          contact us by:
        </p>

        <ul>
          <li>Emailing us at privacy@flowai.tech</li>
        </ul>




        <h2 className='black'> Verification Process </h2>
        <p>
          To protect your privacy and maintain security, we will take steps to verify your identity before granting you access
          to your personal information or complying with your request. If you make a request through an authorized agent,
          we may require you to provide the authorized agent written permission to make the request, and we may need to
          verify your identity directly.
        </p>


        <h2 className='black'> Changes to this Supplemental Notice </h2>
        <p>
          We may update this Supplemental Notice to reflect changes to our practices or for other operational, legal, or
          regulatory reasons. If we make any material changes, we will notify you by email (if we have your email address)
          or by means of a notice on our Site prior to the change becoming effective.
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default Privacy;
