import "./BillingManagementStyles.css";
import addFolder from "./../../../assets/img/icon/add-quotes.png";

function BillingQuotes() {
  const quotes = [];
  return (
    <>
      <h2 className="font-light text-lg mb-5">Admin/company-organization</h2>
      <h1 className="font-extrabold text-4xl mb-5">Quotes</h1>
      <div className="flex flex-row justify-between items-center  mb-10">
        <h2 className="font-light text-lg">
          View and manage your latest Flow quotes
        </h2>
        <button>Request Quote</button>
      </div>
      <div className="quotes-filters flex flex-row border-b-2">
        <h2 className="quotes-filter active">All quotes</h2>
        <h2 className="quotes-filter">Open</h2>
        <h2 className="quotes-filter">Accepted</h2>
        <h2 className="quotes-filter">Needs review</h2>
        <h2 className="quotes-filter">Canceled</h2>
      </div>
      <table>
        <thead>
          <tr>
            <th className="table-heading w-[30%] font-bold">Quote number</th>
            <th className="table-heading w-[14%] font-bold">Status</th>
            <th className="table-heading w-[14%] font-bold">Creation</th>
            <th className="table-heading w-[14%] font-bold">Expiry</th>
            <th className="table-heading w-[14%] font-bold">Quote Total</th>
            <th className="table-heading w-[14%] font-bold">Action</th>
          </tr>
        </thead>
        <tbody>
          {quotes.length === 0 ? (
            <tr>
              <td rowSpan="6"></td>
            </tr>
          ) : (
            quotes.map((quote, index) => (
              <tr key={index}>
                <td>{quote.number}</td>
                <td>{quote.status}</td>
                <td>{quote.creation}</td>
                <td>{quote.expiry}</td>
                <td>{quote.quoteTotal}</td>
                <td>{quote.action}</td>
              </tr>
            ))
          )}
        </tbody>
      </table>
      {quotes.length === 0 ? (
        <div className="w-[100%] h-[50vh] flex flex-row justify-center items-center">
          <img src={addFolder} className="scale-50" />
        </div>
      ) : (
        <div className="w-0 h-0"></div>
      )}
    </>
  );
}

export default BillingQuotes;
