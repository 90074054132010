import React from 'react'

const AccountSelectionCard = ({cardID,title,description}) => {
    return (
        <div className="bg-black text-white rounded-lg py-5 px-8 w-80 h-96 font-neue-haas overflow-hidden text-base">
            <div className="flex items-center mb-4">
                <div className="bg-orange-500 text-black rounded-full w-8 h-8 flex items-center justify-center text-xl font-bold">
                    {cardID}
                </div>
            </div>
            <h2 className="text-2xl font-normal font-inter text-[18px] mb-4">{title}</h2>
            <p className='font-medium text-base font-work-sans'>
                {description}
            </p>

        </div>)
}

export default AccountSelectionCard;