

import Product from "./ProductDesktop";
import ProductMobile from "./ProductMobile";

const ProductGateway = () => {
  if (window.innerWidth < 768) {
    return <ProductMobile />;
  } else {
    return <Product />;
  }
};

export default ProductGateway;
