const classMap = {
    'hide1': 'show1',
    'hide2': 'show2',
    'hide3': 'show3',
}

const options = {
    threshold: 0.2
}
const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
        // console.log(entry.target.classList);
        const classSet = new Set([...entry.target.classList]);
        // console.log(classSet);
        if (entry.isIntersecting) {
            for (let className in classMap) {
                if (classSet.has(className)) {
                    entry.target.classList.add(classMap[className]);
                }
            }
        }
    })
}, options);

export default function loadAnimations() {
    setTimeout(() => {
        // gets all classes listed in classMap and maps them to '.(class)' format
        const hiddenElements = document.querySelectorAll(Object.keys(classMap).map(x => `.${x}`));
        hiddenElements.forEach((el) => observer.observe(el));
    }, 0)
}


