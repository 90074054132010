// Pricing.js
"use client";
import { React, useState, useEffect } from "react";
import '../assets/css/Pricing.css';
import Footer from '../components/Footer';
import pricing_img from '../assets/img/pricing.png';
import Check from '../assets/img/icon/Checkmark.png';
import ResponsiveCarousel from '../aceternity-components/ui/infinite-moving-cards';
import { Carousel, Card } from "../aceternity-components/ui/apple-cards-carousel";
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import pricing_img_1 from '../assets/img/pricing-bg1.png';
import CollapsibleBox from '../components/collapsibleBox/collapsibleBox';
import easy from '../assets/icons/PricingMobile/easy.png';
import effortLess from '../assets/icons/PricingMobile/effortless.png';
import instant from '../assets/icons/PricingMobile/instant.png';
import intuitive from '../assets/icons/PricingMobile/intuitive.png';
import rapid from '../assets/icons/PricingMobile/rapid.png';
import responsive from '../assets/icons/PricingMobile/responsive.png';
import start from '../assets/icons/PricingMobile/start.png';
import tailored from '../assets/icons/PricingMobile/tailored.png';
import ReadMore from '../components/readMore/readMore';



const tierData = [
    {
        title: "Elite Tier",
        price: 1080,
        features: [
            "Advanced prospecting capabilities",
            "Integration with your existing B2B lead source databases (manage API calls through your current vendors)",
            "Comprehensive analytics and reporting",
            "AI Pre-qualified Contacts: Provides up to 400 net new AI pre-qualified contacts daily per sales rep, extrapolated from AI pre-qualified accounts",
            "AI Account Selection",
            "AI Flat Files",
            "Dedicated account manager",
            "Custom analytics",
            "Integration support",
            "Personalized training",
            "24/7 premium support",
            "Enterprise-grade security"
        ],
        checkIcon: Check,
        ctaText: "Go Elite",
        ctaAction: "Contact Sales"
    },
    {
        title: "Ultra Tier",
        price: 720,
        features: [
            "Advanced prospecting capabilities",
            "Integration with your existing B2B lead source databases (manage API calls through your current vendors)",
            "Comprehensive analytics and reporting",
            "AI Pre-qualified Contacts: Provides up to 300 net new AI pre-qualified contacts daily per sales rep, extrapolated from AI pre-qualified accounts",
            "AI Account Selection",
            "AI Flat Files",
            "Priority support",
            "Customizable dashboards",
            "Early access to new features"
        ],
        checkIcon: Check,
        ctaText: "Unlock Ultra Features",
        ctaAction: "Contact Sales"
    },
    {
        title: "Premium Tier",
        price: 480,
        features: [
            "Enhanced prospecting functionalities",
            "Integration with your existing B2B lead source databases (manage API calls through your current vendors)",
            "Advanced analytics and reporting",
            "AI Pre-qualified Contacts: Provides up to 300 net new AI pre-qualified contacts daily per sales rep, extrapolated from AI pre-qualified accounts",
            "AI Account Selection",
            "AI Flat Files",
            "Standard support",
            "Exclusive access to webinars and tutorials"
        ],
        checkIcon: Check,
        ctaText: "Upgrade to Premium",
        ctaAction: "Contact Sales"
    },
    {
        title: "Starter Tier",
        price: 180,
        features: [
            "Essential prospecting capabilities",
            "Integration with your existing B2B lead source databases (manage API calls through your current vendors)",
            "AI Pre-qualified Contacts: Provides up to 200 net new AI pre-qualified contacts daily per sales rep, extrapolated from AI pre-qualified accounts",
            "AI Account Selection",
            "AI Flat Files",
            "Basic support",
            "Access to our vibrant community"
        ],
        checkIcon: Check,
        ctaText: "Get Started",
        ctaAction: "Get Started"
    }
];
const testimonials = [
    {
        title: "Revolutionary Live AI Sales Rep Assistant",
        quote: "Flow Turbo’s proprietary Live AI Sales Rep Assistant redefines what’s possible in sales. It brings a new level of service to your sales operations, meticulously analyzing vast datasets to pre-qualify accounts and contacts, and present your reps with the crème de la crème. Eliminate the drudgery of manual and mundane activities and embrace high-efficiency prospecting.",
    },
    {
        title: "Unmatched AI Account Selection",
        quote: "With Flow Turbo's AI Account Selection, we don't just give you a list of names—we provide a path to your next closed-won deal. Our AI dives deep into historical data, market trends, and behavioral insights to handpick accounts that are most likely to convert. It’s like having a top-tier sales analyst in your corner, 24/7.",
    },
    {
        title: "Streamlined AI Flat Files Integration",
        quote: "Flow Turbo’s AI Flat Files feature guarantees that no data is overlooked. Easily upload large data sets and watch as our AI get to the bottom of actionable accounts and contacts. Your sales reps will always have the latest, most pertinent information, driving better decisions and sales outcomes.",
    },
    {
        title: "Ultimate Flexibility and Control",
        quote: "Flow Turbo seamlessly integrates with your existing B2B lead source databases, giving you the flexibility and optionality to manage API calls through your current vendors. This means you can maintain your existing or preferred vendors while supercharging them with Flow Turbo’s advanced capabilities.",
    },
    {
        title: "In-Depth Analytics and Reporting",
        quote: "Flow Turbo’s analytics and reporting tools provide a comprehensive view of your sales activities. Customize dashboards, track vital metrics, and explore detailed reports to uncover insights that drive performance. With Flow Turbo, data is transformed into a powerful strategic asset."
    },
    {
        title: "Unmatched Support",
        quote: "We offer more than support; we integrate into your team. From simple questions to complex challenges, Flow Turbo’s support is with you every step of the way. With dedicated account managers and 24/7 premium support, we ensure you maximize the platform’s potential."
    },
    {
        title: "Continuous Innovation",
        quote: "At Flow, innovation is a core principle. We continuously evolve, providing regular updates and early access to new features. This commitment ensures you always have the latest tools and technologies, keeping your sales results at the cutting edge."
    }
];

const cards = tierData.map((tier, index) => (
    <Card key={tier.title} tier={tier} index={index} layout={true} />
));



function Pricing() {
    const [isOpen, setIsOpen] = useState({
        part1: false,
        part2: false,
        part3: false,
        part4: false,
        part5: false,
    });

    const text = `In the present landscape of sales, the journey from prospecting to closing a deal resembles an arduous odyssey, filled with slow, manual, and mundane tasks that drain the energy and enthusiasm of your sales reps.<br>The repetitive and time-consuming nature of these processes often feels exhaustive, leaving your team longing for a more efficient and rewarding approach.The repetitive and time consuming nature of the work becomes a painful reminder of the inefficiencies that hinder growth and financial success.

    Now, picture a future where the traditional sales processes are not just streamlined but completely revolutionized—a landscape where every interaction, from prospect identification to deal closure, is infused with efficiency and excitement.Our SaaS AI sales prospecting solution is not merely a tool; it's a catalyst for change, transforming the mundane into the extraordinary.Imagine a world where your sales reps no longer grapple with the monotony of repetitive tasks but instead find renewed vigor and focus on what truly matters—building relationships, generating qualified deals and opportunities, and ultimately, boosting sales commissions.
    
    The adoption of this groundbreaking solution is not just a practical step; it's an invitation to an enlightened and empowered sales ecosystem.Envision your sales reps navigating through a landscape free from the shackles of manual labor, where AI becomes a trusted ally, anticipating needs, automating tasks, and empowering your team to achieve unprecedented success.<br>Embrace the prospect of a sales organization unburdened by the weight of repetitive processes, where each sales representative is a beacon of productivity, fueled by the excitement of a transformative technology that redefines the sales narrative.
    
    Choosing the Flow Turbo SaaS AI sales prospecting solution is not just a decision; it's a declaration of belief in a future where sales is not a struggle but a thrilling journey towards unprecedented success.It's an opportunity to be part of a revolution that transcends the ordinary, offering your sales organization a chance to thrive in an environment that is efficient, rewarding, and truly inspiring.`;
    


    const toggleSection = (section) => {
        setIsOpen(prevState => ({ 
            ...prevState,
            [section]: !prevState[section]
        }));
    };
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth <= 768);
        };
    
        window.addEventListener('resize', handleResize);
        handleResize(); // Call it initially to set the state based on the current window width
    
        return () => window.removeEventListener('resize', handleResize);
      }, []);



    return (
        <div className="pricing">
            <div className='pricing_container fadeInAnimation'>
                <div className='pricing_header'>Pricing</div>
                <div className='pricing_container1'>
                    <div className="pricing_text_container">
                        <h1 className="subheader_text bold">Welcome to Flow Turbo! Select the plan that best fits your business needs.</h1>
                        <div>Our pricing is designed to integrate smoothly with your existing B2B lead source databases, giving you maximum flexibility and control.</div>
                    </div>
                    <div className='pricing_img_container'>
                        <img src={pricing_img} alt="Pricing" />
                    </div>
                </div>
                <div className='pricing_container2'>
                    <h3>Experience the Power of Flow Turbo</h3>
                    <h4 className="subheader_text bold">Revolutionize Your Sales with Flow Turbo's Advanced AI Technology</h4>
                    <div>Embrace the power of Flow Turbo. Our proprietary Live AI Sales Rep Assistant sets the standard for excellence, using cutting-edge
                        AI to deliver a steady stream of qualified deals and opportunities to fill pipeline gaps. Minimize sales rep burnout and turnover.
                        Flow Turbo’s AI Account Selection ensures you target the right prospects, while AI Flat Files integrate data seamlessly, optimizing
                        your sales process for extraordinary efficiency and success.</div>
                </div>
                <div className='pricing_container3'>
                    <h3>Why Choose Flow Turbo?</h3>
                    <div>
                        <ResponsiveCarousel
                            items={testimonials}
                            direction="right"
                            speed="slow"
                        />
                    </div>
                </div>
                <div className='pricing_container4'>
                    <h3 className="pricing_plan_title">Plan</h3>
                    <h4 className="pricing_plan_sub_title">All Plans Include</h4>
                    <ul className='pricing-list'>
                        <li><img src={Check} alt="Checkmark" className="check-icon" /><span>Regular Software Updates: Stay at the forefront of technology with continuous improvements.</span></li>
                        <li><img src={Check} alt="Checkmark" className="check-icon" /><span>Access to Knowledge Base: A wealth of resources to empower your team.</span></li>
                        <li><img src={Check} alt="Checkmark" className="check-icon" /><span>Community Forums: Engage and learn from a vibrant community.</span></li>
                    </ul>
                    <div className="w-full h-full py-10">
                        <Carousel items={cards} />
                    </div>
                </div>

                <div className="faq">
                    <h3 className="subheader_text bold">Frequently Asked Questions</h3>
                    <div className='part1'>
                        <div className='head'>
                            <div className='bold faq-question'>How are AI pre-qualified contacts provided with each plan?</div>
                            <button
                                className="section_button"
                                onClick={() => toggleSection('part1')}
                            >
                                {isOpen.part1 ? <RemoveIcon /> : <AddIcon />}
                            </button>
                        </div>
                        {isOpen.part1 && <div className='content'>
                            Each plan provides up to 300 net new AI pre-qualified contacts per sales rep daily, extrapolated from AI pre-qualified accounts.
                        </div>}
                        <div className='divider_black'></div>
                    </div>

                    <div className='part2'>
                        <div className='head'>
                            <div className='bold faq-question'>How do I manage my API call subscriptions?</div>
                            <button
                                className="section_button"
                                onClick={() => toggleSection('part2')}
                            >
                                {isOpen.part2 ? <RemoveIcon /> : <AddIcon />}
                            </button>
                        </div>
                        {isOpen.part2 && <div className='content'>
                            You manage your API call subscriptions directly with your B2B lead source vendors, giving you full control over usage and costs. Flow integrates well with your existing tools.
                        </div>}
                        <div className='divider_black'></div>
                    </div>

                    <div className='part3'>
                        <div className='head'>
                            <div className='bold faq-question'>Can I upgrade or downgrade my plan?</div>
                            <button
                                className="section_button"
                                onClick={() => toggleSection('part3')}
                            >
                                {isOpen.part3 ? <RemoveIcon /> : <AddIcon />}
                            </button>
                        </div>
                        {isOpen.part3 && <div className='content'>
                            Yes, you can upgrade or downgrade your plan at any time. Contact our support team for assistance.
                        </div>}
                        <div className='divider_black'></div>
                    </div>

                    <div className='part4'>
                        <div className='head'>
                            <div className='bold faq-question'>Is there a free trial available?</div>
                            <button
                                className="section_button"
                                onClick={() => toggleSection('part4')}
                            >
                                {isOpen.part4 ? <RemoveIcon /> : <AddIcon />}
                            </button>
                        </div>
                        {isOpen.part4 && <div className='content'>
                            Yes, we offer a 14-day free trial for all plans. Sign up or speak to a senior consultant today to experience the power of Flow Turbo!
                        </div>}
                        <div className='divider_black'></div>
                    </div>

                    <div className='part5'>
                        <div className='head'>
                            <div className='bold faq-question'>What payment methods do you accept?</div>
                            <button
                                className="section_button"
                                onClick={() => toggleSection('part5')}
                            >
                                {isOpen.part5 ? <RemoveIcon /> : <AddIcon />}
                            </button>
                        </div>
                        {isOpen.part5 && <div className='content'>
                            Securely pay with major credit cards, ACH transfers, wire transfers for peace of mind.
                        </div>}
                        <div className='divider_black'></div>
                    </div>
                </div>

                <div className="contact-section">
                    <h3 className="subheader_text">Need Help Choosing?</h3>
                    <div>
                        Our expert Sales Team is ready to assist you. Contact us for personalized guidance and consultation.
                        Ready to revolutionize your sales prospecting? Sign Up Now and chart your course toward success!
                    </div>
                </div>

                <div className="info-section">
                    <h3 className="subheader_text">Look Into The Future Of Your Sales Organization</h3>
                    {isMobile ? (
        
                    <ReadMore
                    id="combined-section"
                    text={text}
                    amountOfWords={80}
                />

                    ) : (
                        <>
                        <div>
                            In the present landscape of sales, the journey from prospecting to closing a deal resembles an arduous odyssey, filled with slow, manual, and mundane tasks that drain the energy and enthusiasm of your sales reps. The repetitive and time-consuming nature of these processes often feels exhaustive, leaving your team longing for a more efficient and rewarding approach. The repetitive and time consuming nature of the work becomes a painful reminder of the inefficiencies that hinder growth and financial success
                        </div>
                        <div>
                            Now, picture a future where the traditional sales processes are not just streamlined but completely revolutionized—a landscape where every interaction, from prospect identification to deal closure, is infused with efficiency and excitement. Our SaaS AI sales prospecting solution is not merely a tool; it's a catalyst for change, transforming the mundane into the extraordinary. Imagine a world where your sales reps no longer grapple with the monotony of repetitive tasks but instead find renewed vigor and focus on what truly matters—building relationships, generating qualified deals and opportunities, and ultimately, boosting sales commissions.
                        </div>
                        <div>
                            The adoption of this groundbreaking solution is not just a practical step; it's an invitation to an enlightened and empowered sales ecosystem. Envision your sales reps navigating through a landscape free from the shackles of manual labor, where AI becomes a trusted ally, anticipating needs, automating tasks, and empowering your team to achieve unprecedented success. Embrace the prospect of a sales organization unburdened by the weight of repetitive processes, where each sales representative is a beacon of productivity, fueled by the excitement of a transformative technology that redefines the sales narrative.
                        </div>
                        <div>
                            Choosing the Flow Turbo SaaS AI sales prospecting solution is not just a decision; it's a declaration of belief in a future where sales is not a struggle but a thrilling journey towards unprecedented success. It's an opportunity to be part of a revolution that transcends the ordinary, offering your sales organization a chance to thrive in an environment that is efficient, rewarding, and truly inspiring.
                        </div>
                        </>
                    )}

                </div>

                <div className="pricing_img_container1">
                    <img src={pricing_img_1} alt="Flow Pricing" />
                </div>

                <div className='onboard-section'>
                    <h2 className='subheader_text mobile-fix bold'>Streamlined Onboarding for Instant Impact</h2>
                    <div>Getting started with Flow is a straightforward journey designed with your ease in mind. Our user-centric approach ensures a hassle-free onboarding process, minimizing energy input while maximizing the benefits you'll reap:</div>
                    {isMobile ? (
                        <>
                        <CollapsibleBox className="collapsible-box" icon={easy} title="1. Easy Sign-Up Process">
                            <p>Dive into the power of Flow swiftly. Our simple and quick sign-up process ensures you're up and running in no time, without unnecessary complexities.</p>
                        </CollapsibleBox>

                        <CollapsibleBox className="collapsible-box" icon={intuitive} title="2. Intuitive Plan Selection">
                            <p>Choose the plan that aligns perfectly with your needs. Our transparent pricing and tier options make selecting the right plan a breeze, tailored to suit businesses of all sizes.</p>
                        </CollapsibleBox>

                        <CollapsibleBox className="collapsible-box" icon={effortLess} title="3. Effortless Integration">
                            <p>Seamlessly connect Flow to your existing sales tools, CRM, and third-party lead source databases. Our intuitive interface guides you through the integration process, ensuring a smooth start.</p>
                        </CollapsibleBox>

                        <CollapsibleBox className="collapsible-box" icon={tailored} title="4. Tailored to Your Workflow">
                            <p>Customize Flow to complement your unique sales process. Adapt the platform effortlessly to your team's preferences, ensuring it seamlessly integrates with your established workflow.</p>
                        </CollapsibleBox>

                        <CollapsibleBox className="collapsible-box" icon={instant} title="5. Instant AI Empowerment">
                            <p>Experience the immediate impact of AI-driven sales prospecting. No extensive training required – start leveraging cutting-edge features right from the beginning for enhanced productivity.</p>
                        </CollapsibleBox>

                        <CollapsibleBox className="collapsible-box" icon={responsive} title="6. Responsive Support">
                            <p>Need assistance? Our real-time support team is here to address any queries or challenges you may face during the onboarding process, providing a helping hand when you need it.</p>
                        </CollapsibleBox>

                        <CollapsibleBox className="collapsible-box" icon={rapid} title="7. Rapid Deployment for Rapid Results">
                            <p>Once set up, delve into intelligent sales prospecting without delay. Witness the tangible benefits of Flow in action, empowering your team to achieve more with less effort.</p>
                        </CollapsibleBox>

                        <CollapsibleBox className="collapsible-box" icon={start} title="8. Start Prospecting with Confidence">
                            <p>Dive into intelligent sales prospecting with confidence. Our platform minimizes the learning curve, ensuring you can focus on driving sales and witnessing immediate business growth.</p>
                        </CollapsibleBox>
                        </>
                    ) : (
                    <ol>
                        <li>
                            <strong>1. Easy Sign-Up Process</strong>
                            <p>Dive into the power of Flow swiftly. Our simple and quick sign-up process ensures you're up and running in no time, without unnecessary complexities.</p>
                        </li>
                        <li>
                            <strong>2. Intuitive Plan Selection</strong>
                            <p>Choose the plan that aligns perfectly with your needs. Our transparent pricing and tier options make selecting the right plan a breeze, tailored to suit businesses of all sizes.</p>
                        </li>
                        <li>
                            <strong>3. Effortless Integration</strong>
                            <p>Seamlessly connect Flow to your existing sales tools, CRM, and third-party lead source databases. Our intuitive interface guides you through the integration process, ensuring a smooth start.</p>
                        </li>
                        <li>
                            <strong>4. Tailored to Your Workflow</strong>
                            <p>Customize Flow to complement your unique sales process. Adapt the platform effortlessly to your team's preferences, ensuring it seamlessly integrates with your established workflow.</p>
                        </li>
                        <li>
                            <strong>5. Instant AI Empowerment</strong>
                            <p>Experience the immediate impact of AI-driven sales prospecting. No extensive training required – start leveraging cutting-edge features right from the beginning for enhanced productivity.</p>
                        </li>
                        <li>
                            <strong>6. Responsive Support</strong>
                            <p>Need assistance? Our real-time support team is here to address any queries or challenges you may face during the onboarding process, providing a helping hand when you need it.</p>
                        </li>
                        <li>
                            <strong>7. Rapid Deployment for Rapid Results</strong>
                            <p>Once set up, delve into intelligent sales prospecting without delay. Witness the tangible benefits of Flow in action, empowering your team to achieve more with less effort.</p>
                        </li>
                        <li>
                            <strong>8. Start Prospecting with Confidence</strong>
                            <p>Dive into intelligent sales prospecting with confidence. Our platform minimizes the learning curve, ensuring you can focus on driving sales and witnessing immediate business growth.</p>
                        </li>
                    </ol>
                    )}
                    <div>Flow is committed to making your onboarding experience as simple as possible, ensuring you can focus on what matters most: driving sales and fostering business growth. Minimize the energy invested and maximize the benefits gained from the very start.</div>
                </div>

                <div className='onboard-section'>
            
                    <h2 className='subheader_text bold secure-spot'>Secure Your Spot - Transform Your B2B Journey!</h2>
                    <div className="secure-spot-description">Only 100 limited spots are available, and the early adopter advantage awaits. Join the Flow Early Access Program today to revolutionize your B2B sales. For inquiries or to secure your spot, contact our dedicated sales team. Your journey toward unparalleled success begins with Flow Turbo!</div>

                    {isMobile ? (
                        <>
                        <h3 className="subheader_text bold">Limited to the First 100 Accounts:</h3>
                        <ul className="pricing_onboard_ul">
                            <li>Only the first 100 accounts will enjoy our exclusive early access pricing. This is your chance to revolutionize your sales game at locked-in pricing.</li>
                        </ul>

                        <h3 className="subheader_text bold">Special Pricing for Early Access Accounts:</h3>
                        <ul className="pricing_onboard_ul">
                            <li>As a token of appreciation for being part of our launch, you'll receive special pricing that won't be available once the program reaches capacity.</li>
                        </ul>
                        <h3 className="subheader_text bold">What Early Access Offers:</h3>
                        <ul className="pricing_onboard_ul">
                            <li>Special discounted pricing for the duration of the program.</li>
                            <li>Full access to all features, ensuring you experience the AI magic firsthand.</li>
                            <li>Direct engagement with our engineering team to shape the future of Flow Turbo.</li>
                        </ul>

                        <h3 className="subheader_text bold beat-the-clock-heading">Beat the Clock - Price Increase Imminent:</h3>
                        <ul className="pricing_onboard_ul">
                        <li >Act swiftly! Once the 100-account limit is reached, our pricing will undergo an update. Don't miss the chance to secure your spot and lock in the exclusive early access program pricing.</li>
                        </ul>
                        
                        </>
                    ) : (
                        <>
                        <h3 className='subheader_text bold'>What Early Access Offers:</h3>
                        <h3 className="subheader_text bold">Limited to the First 100 Accounts:</h3>
                        <ul>
                            <li>Only the first 100 accounts will enjoy our exclusive early access pricing. This is your chance to revolutionize your sales game at locked-in pricing.</li>
                        </ul>

                        <h3 className="subheader_text bold">Special Pricing for Early Access Accounts:</h3>
                        <ul>
                            <li>As a token of appreciation for being part of our launch, you'll receive special pricing that won't be available once the program reaches capacity.</li>
                        </ul>

                        <ul className="pricing_onboard_ul">
                            <li>Special discounted pricing for the duration of the program.</li>
                            <li>Full access to all features, ensuring you experience the AI magic firsthand.</li>
                            <li>Direct engagement with our engineering team to shape the future of Flow Turbo.</li>
                        </ul>
                        <h3 className="subheader_text bold beat-the-clock-heading">Beat the Clock - Price Increase Imminent:</h3>
                        <div >Act swiftly! Once the 100-account limit is reached, our pricing will undergo an update. Don't miss the chance to secure your spot and lock in the exclusive early access program pricing.</div>
                
                        </>
                    )}


                </div>
            
            </div>
            <Footer />
        </div>
    );
}

export default Pricing;

