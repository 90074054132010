import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Footer from '../components/Footer';
import { Checkbox, FormControlLabel, Pagination, Select, MenuItem } from "@mui/material";
import '../assets/css/Career.css';

import woman2 from '../assets/img/careers/woman2.png';
import careers_technical from '../assets/img/careers/careers_technical.png';
import careers_communication from '../assets/img/careers/careers_communication.png';
import careers_problem_solving from '../assets/img/careers/careers_problem_solving.png';
import careers_adaptability from '../assets/img/careers/careers_adaptability.png';

import adaptabilityFlexibilityIcon from '../assets/img/careers/icons/adaptability-flexibility.png';
import competencyIcon from '../assets/img/careers/icons/competency.png';
import innovationCreativityIcon from '../assets/img/careers/icons/innovation-creativity.png';
import qualityResultsIcon from '../assets/img/careers/icons/quality-results.png';
import teamworkCollaborationIcon from '../assets/img/careers/icons/teamwork-collaboration.png';
import timelinessIcon from '../assets/img/careers/icons/timeliness.png';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

const CareersCheckbox = ({ label, checked, handleDepartmentCheck, name }) => {
    return (
        <FormControlLabel
            label={<span style={{ fontSize: '30px' }}>{label}</span>}
            control={
                <Checkbox
                    checked={checked}
                    onChange={handleDepartmentCheck}
                    name={name}
                    sx={{
                        '&.Mui-checked': { color: 'black' },
                        '& .MuiSvgIcon-root': { fontSize: '40px' },
                    }}
                    disableRipple
                    icon={<CheckBoxOutlineBlankIcon style={{ stroke: "var(--background-grey)", strokeWidth: 1.5 }} />}
                />
            }
        />
    )
};

const Career = () => {


    const coreValues = [
        { number: 1, title: 'Purpose', content: 'We are driven by a clear sense of purpose and a desire to make a positive impact on the world.' },
        { number: 2, title: 'Performance', content: 'We measure our performance based on the quality of our work, the timeliness of our delivery, and our collaboration with team members.' },
        { number: 3, title: 'Execution', content: 'We believe that execution is key to success. We focus on delivering results and achieving our goals.' },
        { number: 4, title: 'Time Management', content: 'We believe that time management is critical to success. We prioritize our work and manage our time effectively to ensure that we meet our goals.' },
        { number: 5, title: 'Teamwork', content: 'We are a team, and we work together to achieve our goals. We support each other, collaborate effectively, and celebrate our successes together.' },
        { number: 6, title: 'Excellence', content: 'We strive for excellence in everything we do, always pushing ourselves to be better and to achieve more.' },
        { number: 7, title: 'Competency', content: 'We are committed to hiring and developing the best people in the world that are truly experts in their field and takes high performance standards seriously.' },
        { number: 8, title: 'Trust', content: 'We trust our team members to make independent decisions and take ownership of their work. We believe that trust is essential for building a strong, collaborative team. Integrity: We operate with integrity, always being honest and transparent in our interactions with each other and with our customers.' },
        { number: 9, title: 'Communications', content: 'We communicate effectively, listening actively and responding thoughtfully. We believe that clear communication is critical to success.' },
    ];


  const [departmentChecked, setDepartmentChecked] = useState({
    engineering: false,
    design: false,
    marketing: false,
    financeAccounting: false,
  });
  const [jobs, setJobs] = useState([]);
  const [allJobs, setAllJobs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showFullText, setShowFullText] = useState(false);
  const [sortOrder, setSortOrder] = useState('newest');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const API_BASE_URL = process.env.REACT_APP_API;

  const handleDepartmentCheck = (event) => {
      setDepartmentChecked({
          ...departmentChecked,
          [event.target.name]: event.target.checked,
      });
  };

  const handleSortChange = (event) => {
    setSortOrder(event.target.value);
    // TODO: Implement sorting logic here
  };

  const clearAll = () => {
    setDepartmentChecked({
      engineering: false,
      design: false,
      marketing: false,
      financeAccounting: false,
    });
  };

  const changePage = (event, value) => {
    setCurrentPage(value);
  };


  const toggleFullText = () => {
    setShowFullText(!showFullText);
  };


  const fetchJobs = useCallback(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}api/hr/jobs/`);
      setJobs(response.data);
      setAllJobs(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching jobs:', error.response ? error.response.data : error.message);
      setError('Failed to fetch jobs. Please try again.');
      setLoading(false);
    }
  }, [API_BASE_URL]);

  useEffect(() => {
    fetchJobs();
  }, [fetchJobs]);

  const slugifyTitle = (title) => {
    return title
        .toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^\w-]+/g, '');
};

  const handleApplyNow = (jobTitle) => {
      const slugifiedTitle = slugifyTitle(jobTitle);
      navigate(`/${slugifiedTitle}`); // Only the title in the URL
};


  useEffect(() => {
    const isAnyDepartmentChecked = Object.values(departmentChecked).some(checked => checked);
    const filteredJobs = isAnyDepartmentChecked
      ? allJobs.filter(job => {
          const department = job.Department ? job.Department.toLowerCase() : '';
          return (departmentChecked.engineering && department === 'engineering') ||
            (departmentChecked.design && department === 'design') ||
            (departmentChecked.marketing && department === 'marketing') ||
            (departmentChecked.financeAccounting && department === 'finance and accounting');
        })
      : allJobs;
    setJobs(filteredJobs);
    setCurrentPage(1);
  }, [departmentChecked, allJobs]);



  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <div className='career_container fadeInAnimation'>
        <div className="careers_two-item-block">
          <div>
            <h1 className='header_text'>Welcome to Flow Careers</h1>
          </div>
          <div className="header_img-block">
            <img src={woman2} alt="Woman" />
          </div>
        </div>
        <div className="subheader_text line_adjust">Here we make champions</div>
        <div className="careers_two-item-block values_block">
          <div className='subtext'>
            Flow is dedicated to hiring and developing
            the best people. We are always looking for
            individuals who are experts in their field
            and role, and are driven to make a
            difference in the world.
          </div>
          <div className='careers_body'>
            <div>
              At Flow, we foster a culture of creative freedom,
              independent decision making, asymmetric thinking,
              innovation, collaboration, strong leadership,
              teamwork, and communication.
              {!showFullText && (
                <span className="read-more-link" onClick={toggleFullText}>
                  {" "}Read More
                </span>
              )}
            </div>
            <br />
            <div className={showFullText ? '' : 'hidden'}>
              Our team members are encouraged to think
              creatively, take risks, and push the boundaries of
              what is possible. We are committed to creating a
              workplace where our team members feel
              appreciated, valued, and respected. We believe in
              recognizing and rewarding our team members for
              their hard work and contributions.
              <span className="read-less-link" onClick={toggleFullText}>
                {" "}Read Less
              </span>
            </div>
          </div>
        </div>

        {/* Core Values Section */}
        <div className="subheader_text"><h1>Flow's Core Values</h1></div>
        <div className="careers_left-block flowValues">
          Flow's core values are the foundation of our company
          culture. We are committed to living these values every
          day, in everything we do.
        </div>
        {/* start here */}

        <div className="core-values-section">
          <div className="desktop-layout">
            <div className="column">
              {coreValues.slice(0, 5).map((value) => (
                <div key={value.number} className="core-value-item desktop_core_item">
                  <div className="core-value-number desktop_core_value">{value.number}</div>
                  <div className="core-value-content desktop_core_content">
                    {/* <span className='desktop_core_title'>{value.title}: </span>
                    {value.content} */}
                    {/* <h3 className='core-value-title'>{value.title}</h3>
                    <p className='core-value-desc'>{value.content}</p> */}
                    <div className="desktop_core_title">{value.title}:</div>
                    <div className="desktop_core_description">{value.content}</div>
                  </div>
                </div>
              ))}
            </div>
            <div className="column">
              {coreValues.slice(5).map((value) => (
                <div key={value.number} className="core-value-item desktop_core_item">
                  <div className="core-value-number desktop_core_value">{value.number}</div>
                  <div className="core-value-content desktop_core_content">
                    {/* <span className='desktop_core_title'>{value.title}: </span> {value.content} */}
                    <div className="desktop_core_title">{value.title}:</div>
                    <div className="desktop_core_description">{value.content}</div>
                    {/* <h3 className='core-value-title'>{value.title}</h3>
                    <p className='core-value-desc'>{value.content}</p> */}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="mobile-layout">
            <div className="carousel_wrapper">
              {coreValues.map((value) => (
                <div key={value.number} className="core-value carousel_card">
                  <div className="core-value_card">
                    <div className="mobile_tick">
                      {/* <div className="core-value_number mobile_value_number">{value.number}</div> */}
                      ✔
                    </div>
                    <div className="flowValues">
                      <span className="medium flow-val">{value.title}</span>
                      {/* <span className="colon">:</span>  */}
                      {value.content}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

{/* // end here */}
        {/* Competency Model Section */}
        <div className="subheader_text"><h1>Our Competency Model</h1></div>
        <div className='careers_left-block medium mobile_medium'>
          Our competency model is designed to
          help our people grow and develop their
          skills. We focus on four key competencies:
          technical skills, communication skills,
          problem-solving skills, and adaptability.
        </div>
        <div className="competency-model mobile_model_container">
          {/* Competency model content here */}
          <div className="careers_two-item-block mobile_competency_model">
            <div className='skills_content'>
              <div className='medium competency_mobile_even'>Technical Skills</div>
              <div className='skills_des_mobile'>
                We are looking for individuals who have a strong
                foundation in computer science, mathematics, and
                programming. Our team members must be proficient in at
                least one programming language and have experience
                working with large datasets. Additionally, we expect our
                team members to stay up-to-date with the latest
                advancements in AI and machine learning.
              </div>
            </div>
            <div className='skills_image_mobile'>
              <img src={careers_technical} alt="" />
            </div>
          </div>
          <div className="careers_two-item-block mobile_competency_model">
            <div className='skills_image_mobile'>
              <img src={careers_communication} alt="" />
            </div>
            <div className='skills_content'>
              <div className='medium competency_mobile_skills'>Communication Skills</div>
              <div className='skills_des_mobile'>
                Effective communication is critical to our success.
                We expect our team members to be able to
                communicate complex ideas clearly and concisely.
                We also expect our team members to be able to
                listen actively and respond thoughtfully.
              </div>
            </div>
          </div>
          <div className="careers_two-item-block mobile_competency_model">
            <div className='skills_content'>
              <div className='medium competency_mobile_even'>Problem-Solving Skills</div>
              <div className='skills_des_mobile'>
                We are looking for individuals who are passionate about
                solving complex problems. Our team members must be
                able to analyze data, identify patterns, and develop
                solutions that drive results. We also expect our team
                members to be able to approach problems from multiple
                angles and think creatively.
              </div>
            </div>
            <div className='skills_image_mobile'>
              <img src={careers_problem_solving} alt="" />
            </div>
          </div>
          <div className="careers_two-item-block mobile_competency_model">
            <div className='skills_image_mobile'>
              <img src={careers_adaptability} alt="" />
            </div>
            <div className='skills_content'>
              <div className='medium competency_mobile_skills'>Adaptability</div>
              <div className='skills_des_mobile'>
                Flow is constantly evolving, and we need team
                members who can adapt quickly to new
                technologies, processes, and priorities. We expect
                our team members to be flexible and nimble. We also
                expect our team members to be able to pivot when
                necessary and adjust their approach to meet the
                needs of the business.
              </div>
            </div>
          </div>
        </div>

        {/* Performance Expectations Section */}
        <div className="subheader_text"><h1>Performance Expectations</h1></div>
        <div className="careers_left-block mobile_performance_block">
          Our performance expectations are high, and we expect
          our team members to deliver high-quality work that meets
          our standards. We measure performance based on the
          following criteria:
        </div>
        <div className="performance-expectations">
          {/* Performance expectations content here */}
          <div>
            <div className="icon-block">
              <img className='mobile_icon_image' src={competencyIcon} alt="" />
            </div>
            <div>Competency</div>
          </div>
          <div>
            <div className="icon-block">
              <img className='mobile_icon_image' src={qualityResultsIcon} alt="" />
            </div>
            <div>Quality of work and results</div>
          </div>
          <div>
            <div className="icon-block">
              <img className='mobile_icon_image' src={timelinessIcon} alt="" />
            </div>
            <div>Timeliness of delivery</div>
          </div>
          <div>
            <div className="icon-block">
              <img className='mobile_icon_image' src={teamworkCollaborationIcon} alt="" />
            </div>
            <div>Teamwork and collaboration</div>
          </div>
          <div>
            <div className="icon-block">
              <img className='mobile_icon_image' src={innovationCreativityIcon} alt="" />
            </div>
            <div>Innovation and creativity</div>
          </div>
          <div>
            <div className="icon-block">
              <img className='mobile_icon_image' src={adaptabilityFlexibilityIcon} alt="" />
            </div>
            <div>Adaptability and Flexibility</div>
          </div>
        </div>

        {/* Rewards and Recognition Section */}
        <div className='medium mobile_rewards_title' style={{ paddingBottom: '20px' }}>Rewards and Recognition</div>
        <div className="careers_left-block mobile_rewards_content">
          We believe in recognizing and rewarding our team
          members for their hard work and contributions. We offer
          a competitive salary and benefits package, as well as
          opportunities for growth and development. We also
          recognize and celebrate team members who embody our
          company culture and values.
        </div>

        {/* Worklife at Flow Section */}
        <div className="subheader_text mobile_worklife_title" style={{ padding: '40px 0px' }}><h1>Worklife at Flow</h1></div>
        <div className="worklife-container mobile_worklife_container">
          <div className="worklife-options mobile_options">
            <div style={{ cursor: 'pointer' }} onClick={clearAll}>Clear all</div>
            <div className="mobile-sort-container">
              <div className="mobile-sort-label">SORT BY: </div>
              <Select
                value={sortOrder}
                onChange={handleSortChange}
                displayEmpty
                className="mobile-sort-select"
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem value="newest">Newest</MenuItem>
                <MenuItem value="oldest">Oldest</MenuItem>
              </Select>
            </div>
          </div>
          <hr className="hide-on-mobile" />
          <div className="careers_two-item-block">
            <div className='mobile_workflow'>
              <div className="subheader_text mobile_changed_text">Teams</div>
              <hr className="hide-on-mobile" />
              <CareersCheckbox
                label="Engineering Department"
                checked={departmentChecked.engineering}
                handleDepartmentCheck={handleDepartmentCheck}
                name="engineering"
              />
              <hr className="hide-on-mobile" />
              <CareersCheckbox
                label="Design Department"
                checked={departmentChecked.design}
                handleDepartmentCheck={handleDepartmentCheck}
                name="design"
              />
              <hr className="hide-on-mobile" />
              <CareersCheckbox
                label="Marketing Department"
                checked={departmentChecked.marketing}
                handleDepartmentCheck={handleDepartmentCheck}
                name="marketing"
              />
              <hr className="hide-on-mobile" />
              <CareersCheckbox
                label="Finance and Accounting Department"
                checked={departmentChecked.financeAccounting}
                handleDepartmentCheck={handleDepartmentCheck}
                name="financeAccounting"
              />
              <hr className="hide-on-mobile" />
            </div>
            <div>
              <div className="subheader_text mobile_job_results">{jobs.length} Result(s)</div>
              <div className="jobs" key={`jobs-${jobs.length}`}>
              {jobs.slice((currentPage - 1) * 8, currentPage * 8).map((job, index) => (
                  <div key={`job-${job.Title}`}>
                     <a
            href={`/${slugifyTitle(job.Title)}`} // Link directly to the title

                      className="job-posting jobs_css"
                      onClick={(e) => {
                        e.preventDefault();
                        handleApplyNow(job.Title);
                    }}
                    >
                      <div className='mobile_jobs'>{job.Title}</div>
                      </a>
                      {(index < jobs.length - 1) && <hr className='mobile_change_weight' />}
                      </div>
                      ))}
                      </div>
              {jobs.length > 8 && (
                <div className="center">
                  <Pagination
                    count={Math.ceil(jobs.length / 8)}
                    page={currentPage}
                    onChange={changePage}
                  />

                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className='careers_box center large bold'>
                    <div>
                        Thank you for visiting our career page!
                        If you have any questions or would like to learn more about our
                        company culture and values, please don't hesitate to contact us.
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Career;