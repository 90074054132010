import "./BillingManagementStyles.css";
import { FaWallet } from "react-icons/fa6";
import { FaCalendarAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

function BillingPayments() {
  const accountID = "12345"; // Dummy value
  const navigate = useNavigate();
  return (
    <>
      <h2 className="font-light text-lg mb-5">Admin/company-organization</h2>
      <h1 className="font-extrabold text-4xl mb-8">Payment Method</h1>
      <h3 className="font-bold text-[1.2rem] mb-5">Add card payment</h3>
      <div className="flex flex-col card-payment-container">
        <div className="payment-method flex flex-row justify-between w-[100%]">
          <div className="w-[10%] flex justify-center items-center">
            <FaWallet className="scale-[200%]" />
          </div>
          <div className="w-[60%] flex flex-col justify-start">
            <h2 className="font-bold text-lg">Add payment method</h2>
            <p className="font-extralight text-sm">
              We don't have any credit cards on this account
            </p>
            <p className="font-extralight text-sm">
              Add a new payment method to make future payments
            </p>
          </div>
          <div className="w-[30%] flex justify-center items-center">
            <button
              className="w-[100%]"
              onClick={() => {
                navigate(`/account/${accountID}/billing-payments/details`);
              }}
            >
              Add payment method
            </button>
          </div>
        </div>
      </div>
      <h3 className="font-bold text-[1.2rem] mb-5">Pay by invoice</h3>
      <div className="flex flex-col invoice-payment-container">
        <div className="invoice-payment flex flex-row justify-between w-[100]">
          <div className="w-[10%] flex justify-center items-center">
            <FaCalendarAlt className="scale-[200%]" />
          </div>
          <div className="w-[30%]">
            <h2 className="font-bold text-lg">Pay later by invoice</h2>
          </div>
          <div className="w-[60%] font-extralight flex flex-row text-lg justify-end items-center">
            <div className="border-r-2 px-4 h-[100%] flex justify-center items-center">
              <p>0 subscription</p>
            </div>

            <div className="border-r-2 px-4">
              <p>Outstanding amount</p>
              <p>USD 0.00</p>
            </div>
            <div className="pl-4">
              <p>Available Credit</p>
              <p>USD 20,000.00</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BillingPayments;
