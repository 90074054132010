import use_case_bg3 from "../../assets/img/use-case-bg3.png";
// import use_case_bg4 from "../../assets/img/use-case-bg4.png";
import use_case_bg5 from "../../assets/img/use-case-bg5.png";
import check from "../../assets/img/icon/check.png";
import case1 from "../../assets/img/icon/userCase1.png";
import case2 from "../../assets/img/icon/userCase2.png";
import case3 from "../../assets/img/icon/userCase3.png";
import Tick from "../../assets/img/icon/tick.png";
import icon0 from "../../assets/img/icon/icon0.png";
import icon0_1 from "../../assets/img/icon/icon0-1.png";
import icon0_2 from "../../assets/img/icon/icon0-2.png";
import icon0_3 from "../../assets/img/icon/icon0-3.png";
import icon0_4 from "../../assets/img/icon/icon0-4.png";
import whyflow1 from "../../assets/img/icon/whyflow1.png";
import whyflow2 from "../../assets/img/icon/whyflow2.png";
import whyflow3 from "../../assets/img/icon/whyflow3.png";
import annual from "../../assets/img/icon/annual.png";
import average from "../../assets/img/icon/average.png";
import increased from "../../assets/img/icon/increased.png";
import qualified from "../../assets/img/icon/qualified.png";
import case_study from "../../assets/img/icon/case_study.png";
import customer_story from "../../assets/img/icon/customer_story.png";

const UseCaseData = [
  {
    title: "Increased Customer Acquisition",
    description:
      "Flow Turbo's AI-driven prospecting capabilities empower sales reps to connect with 300 AI pre-qualified contacts daily. By targeting high-potential prospects, your team can significantly increase customer acquisition. Let's break it down: If your current acquisition rate is X, Flow Turbo has the potential to amplify that, leading to a notable increase in new customers.",
    image: increased,
  },
  {
    title: "Average Customer Value (ACV) and Total Contract Value (TCV)",
    description:
      "Flow Turbo not only impacts the quantity of customers but also the quality. By targeting the right prospects, the average value of each customer increases. Calculate your current ACV and TCV, and then envision the growth with Flow Turbo. The AI-powered precision ensures that the customers you acquire are of higher value.",
    image: average,
  },
  {
    title: "Qualified Deals and Opportunities",
    description:
      "The platform's focus on delivering qualified leads ensures that your sales team spends their time on leads with a higher likelihood of conversion. The result? An increase in the number of qualified deals and opportunities. By leveraging Flow Turbo, you can expect a Y% rise in qualified deals, translating directly into revenue.",
    image: qualified,
  },

  {
    title: "Annual Increase in New Customers",
    description:
      "With Flow Turbo's efficiency and effectiveness, your sales team can accomplish more in less time. This compounds over the years, resulting in a significant annual increase in new customers. The mathematics of this growth factor into sustained success.",
    image: annual,
  },
];

const ROIandCostBenefitRatio = [
  {
    number: 1,
    title: "Increased Productivity Leading to Time Savings",
    description:
      "Flow Turbo not only streamlines operations but also enhances overall productivity. By automating manual and mundane tasks, your sales team gains valuable hours daily. Time saved can now be directed towards high-impact, revenue-generating activities.",
  },
  {
    number: 2,
    title: "Reduced Burnout and Turnover Costs",
    description:
      "Sales rep burnout is a prevalent challenge in high-pressure environments. Flow Turbo, by shouldering repetitive tasks, plays a pivotal role in mitigating burnout. A less stressed and more engaged sales team contributes to reduced turnover rates. The cost associated with recruitment, onboarding, and training is a substantial saving.",
  },
  {
    number: 3,
    title: "Improved Employee Satisfaction",
    description:
      "Flow Turbo's contribution to a healthier work-life balance enhances overall job satisfaction. Satisfied employees are more likely to stay committed, reducing the costs associated with replacing disengaged or departing team members.",
  },
];

const EmployeeRetentionBenefits = {
  subheader: "Quantifying Employee Retention Benefits:",
  subTitle: [
    "Calculate recruitment costs: (Recruitment Costs = Hiring Expenses + Training Expenses)",
    "Consider the average tenure of a sales rep in your organization.",
    "Assess the impact of Flow Turbo on reducing turnover and its associated costs.",
  ],
};

const FlowTurboBenefits = [
  {
    title: "Strategic Implications for Employee Well-Being",
    description:
      "Flow Turbo goes beyond improving financial metrics; it plays a crucial role in fostering a positive work environment. A content and engaged sales team is an invaluable asset for long-term success.",
  },
  {
    title: "Long-Term Vision",
    description:
      "When evaluating the ROI and Cost-Benefit Ratio from the lens of time savings and reduced turnover, look beyond immediate gains. Consider how Flow Turbo becomes a catalyst for building a resilient and motivated sales force, contributing to sustained success.",
  },
  {
    title: "Adaptable to Your Business Context",
    description:
      "The ROI and Cost-Benefit Ratio calculations are adaptable to your unique business context. Customize the metrics, plug in your values, and witness the transformational impact Flow Turbo can have on both productivity and employee retention.",
  },
  {
    title: "Investing in Your Sales Team's Well-Being",
    description:
      "Flow Turbo is not just a tool; it's an investment in the well-being of your sales team. This sheds light on the tangible and intangible benefits that contribute to a thriving, productive, and committed sales force.",
  },
  // {
  //   title:
  //     "Unlocking Success: Realizing the Potential of Guided End-to-End AI Sales",
  //   description:
  //     "In the dynamic landscape of modern sales, success stories are not just accomplishments; they are testaments to innovation. Enter [Company Name]'s Guided End-to-End AI Sales Platform—an evolutionary leap in reshaping how sales teams achieve unprecedented success. Let's delve into real-world use cases and customer stories that illuminate the transformative power of this groundbreaking platform.",
  // },
];

const caseStudies = [
  {
    title: "Case Study 1:",
    subtitle: "Turbocharging Conversions",
    description:
      "One of our esteemed clients, a B2B sales giant, sought a solution to accelerate their conversion rates. Implementing the Flow Turbo Platform proved to be the catalyst they needed. The meticulously crafted UI/UX design seamlessly aligned with their sales philosophy—the numbers game. By ensuring a daily pool of 300 AI pre-qualified prospects, their sales reps experienced a surge in efficiency. The result? A noteworthy uptick in conversion rates, positioning them as leaders in their industry.",
    image: case_study,
  },
  {
    title: "Case Study 2:",
    subtitle: "Streamlining Workflows",
    description:
      "A mid-sized tech firm faced challenges in streamlining their sales workflows. They needed a tool that not only adhered to the principle of targeted outreach but also eliminated time-consuming manual tasks. Enter our platform, a game-changer in optimizing sales workflows. By automating mundane activities and presenting a daily set of pre-qualified prospects, their sales team achieved unparalleled efficiency. The AI-driven insights didn't just streamline processes; they provided a roadmap for proactive and consistent sales approaches.",
    image: case_study,
  },
  {
    title: "Customer Story 1:",
    subtitle: "From Struggling to Thriving",
    description:
      "Jim, a novice sales rep, was struggling to meet his sales quotas in a hyper-competitive market. Sales reps live or die by their commissions. The Flow Turbo Platform changed the game for him. The daily set of 300 pre-qualified prospects meant he could focus on meaningful interactions, resulting in a significant uptick in qualified deals generated to meet his quarterly quota. Jim's success story is a testament to how this platform empowers individual sales reps to thrive in their roles.",
    image: customer_story,
  },
  {
    title: "Customer Story 2:",
    subtitle: "Scaling Heights",
    description:
      "Sarah, a sales manager leading a growing team, needed a solution that could scale with the expanding demands of her organization. The platform's fundamental principle—more interactions lead to success—resonated with her vision. With an intuitive UI/UX design and daily sets of high-potential prospects, her team not only met sales quotas but consistently exceeded them. The scalable nature of the platform was the catalyst for their rapid growth.",
    image: customer_story,
  },
];

const Propositions = [
  {
    title: "The Value Proposition Unveiled",
    description:
      "At the core of these use cases and customer stories is a shared narrative of success—a narrative crafted by Flow Turbo Platform. It's not just a sales AI; it's a strategic ally that makes money for sales organizations. By increasing commissions, generating more qualified deals and opportunities, and consistently meeting sales quotas, this platform is more than a solution; it's a blueprint for sales excellence. The real-world impact of Flow Turbo is not just transformative; it's a paradigm shift in the dynamics of modern sales success.",
  },
  {
    title: "Unlocking Sales Potential: Use Cases and Value Proposition",
    description:
      "At Flow, we've crafted the Flow Turbo SaaS AI Sales Prospecting Platform with a mission – to maximize the productivity day-to-day for sales reps. Here's how our Flow Turbo Platform and the Live AI Sales Rep Assistant features reshape the landscape of your sales operations, elevating your team's performance to unparalleled heights.",
  },
];

// const guidedEndToEndAIPlatform = {
//   subheader: "Guided End-to-End AI Sales Platform",
//   content: [
//     {
//       number: 1,
//       title: "Streamlined Account Selection",
//       description:
//         "Automatically extract 300 AI pre-qualified contacts daily, enabling sales reps to focus on high-value conversations.",
//     },
//     {
//       number: 2,
//       title: "Automated Qualification",
//       description:
//         "Eliminate the manual and mundane. Our AI identifies and qualifies prospects, ensuring your team engages with the most promising leads.",
//     },
//     {
//       number: 3,
//       title: "Dynamic Sales UI/UX",
//       description:
//         "Tailored UI/UX guide sales reps through each stage, optimizing their efforts and ensuring a consistent, effective sales strategy.",
//     },
//   ],
//   imgSrc: use_case_bg4,
// };

const liveAISalesRepAssistant = {
  subheader: "Live AI Sales Rep Assistant",
  content: [
    {
      number: 1,
      title: "Automating Repetitive Tasks",
      description:
        "Say goodbye to monotonous tasks. The Live AI Sales Rep Assistant takes care of routine actions, freeing up your sales reps to focus on strategic initiatives.",
    },
    {
      number: 2,
      title: "Open Integrations",
      description:
        "Interact effortlessly with your favorite sales tools. Our Live AI Sales Assistant seamlessly integrates with your tech stack, eliminating the need for constant tool-switching.",
    },
    // {
    //   number: 3,
    //   title: "Real-time Guidance",
    //   description:
    //     "Empower your reps with instant insights and guidance during calls. The Live AI Assistant acts as a real-time ally, enhancing the quality of every interaction.",
    // },
  ],
  imgSrc: use_case_bg3,
};

const valueProposition = {
  subheader: "Value Proposition",
  content: [
    {
      number: 1,
      title: "Time Efficiency",
      description:
        "Your team will spend less time on manual tasks, enabling them to allocate their efforts where it truly matters - building relationships and closing deals.",
    },
    {
      number: 2,
      title: "Precision Targeting",
      description:
        "By leveraging AI for account selection and prospect qualification, your sales reps will focus on prospects with the highest potential, leading to a more efficient sales pipeline.",
    },
    {
      number: 3,
      title: "Enhanced Productivity",
      description:
        "With the Live AI Sales Rep Assistant handling repetitive tasks, your sales reps can dedicate their time to strategic activities, ultimately boosting their productivity and the overall effectiveness of your sales operations.",
    },
  ],
  imgSrc: use_case_bg5,
};

const Efficiencies = {
  subheader: "Revolutionizing Sales: AI-Powered Efficiencies Beyond Tools",
  introduction:
    "Step into the world of Flow Turbo, where AI takes center stage, not just as a tool but as the driving force behind eliminating the manual and mundane efforts associated with juggling different sales tools. In the relentless pursuit of efficiency in B2B sales, Flow Turbo's AI becomes the linchpin, transforming how sales reps interact with tools and unleashing a new era of productivity.",
  content: [
    {
      title: "Unified Tool Interaction:",
      description:
        "Bid farewell to the complexity of navigating multiple interfaces. Flow Turbo's AI simplifies the interaction with different sales tools by providing a unified interface. Sales reps communicate directly with the AI, streamlining the entire process and making tool interaction a seamless and intuitive experience.",
      imgSrc: { check },
    },
    {
      title: "Intelligent Data Integration:",
      description:
        "Data integration across diverse tools is often a cumbersome task. Flow Turbo's AI takes charge by intelligently integrating data from various sources, be it CRM software, email platforms, or other essential tools. This use case ensures that data is shared seamlessly, eliminating the need for manual entry and enhancing overall accuracy.",
      imgSrc: { check },
    },
    {
      title: "Streamlined Reporting:",
      description:
        "Reporting becomes a breeze with Flow Turbo's AI. Instead of navigating through different tools to generate reports, the AI autonomously collates and presents relevant data. Sales reps gain access to comprehensive reports without the hassle of manual data aggregation, enabling them to make informed decisions with ease.",
      imgSrc: { check },
    },
    {
      title: "Seamless Communication Channels:",
      description:
        "Engaging prospects across various channels is a critical aspect of modern sales. Flow Turbo's AI acts as a centralized manager, overseeing communication channels such as calls, voicemails, texts, emails, LinkedIn, and re-targeting follow-ups. Sales reps no longer need to switch between tools for different communication modes, ensuring a consistent and effective outreach strategy.",
      imgSrc: { check },
    },
    {
      title: "Automated Task Execution:",
      description:
        "Routine tasks that demand manual execution are now automated with Flow Turbo's AI. From setting reminders to handling follow-ups, the AI takes care of the repetitive tasks, allowing sales reps to redirect their efforts toward more strategic and impactful activities.",
      imgSrc: { check },
    },
    // {
    //   title: "Guided Workflow Optimization:",
    //   description:
    //     "Navigating through different tools often requires constant manual adjustments. Flow Turbo's AI optimizes workflows by actively guiding sales reps through strategic sequences. This use case ensures that sales reps follow predefined workflows without the need for continuous manual intervention, streamlining the overall process.",
    //   imgSrc: { check },
    // },
  ],
  conclusion:
    "In essence, Flow Turbo's AI becomes the catalyst for revolutionizing the sales landscape by seamlessly integrating with various tools. It's not just about making technology more accessible; it's about empowering sales reps to focus on what truly matters – building relationships and closing deals. Welcome to a transformative journey where AI-driven efficiencies redefine the very essence of B2B sales.",
};

const EmpoweringSalesReps = {
  subheader:
    "Empowering Sales Reps: Unleashing the Full Potential with Flow Turbo's AI",
  introduction:
    "As we explore the transformative capabilities of Flow Turbo's AI in eliminating the manual and mundane efforts associated with sales tools, it's crucial to understand the profound impact on sales reps' day-to-day activities. Let's delve deeper into how this AI-driven revolution translates into tangible benefits for sales professionals, empowering them to unlock their full potential.",
  content: [
    {
      subTitleText: "Maximizing Productivity:",
      text: "By streamlining tool interactions, Flow Turbo's AI liberates sales reps from the complexities of navigating through various interfaces. This means more time dedicated to core activities like engaging prospects, refining sales strategies, and driving meaningful conversations. The result? A significant boost in productivity, allowing sales reps to accomplish more in less time.",
      icon: icon0,
    },
    {
      subTitleText: "Enhancing Decision-Making:",
      text: "The intelligent data integration feature ensures that sales reps have access to accurate and relevant information at their fingertips. This translates into well-informed decision-making, as data from different tools seamlessly converges, providing a holistic view of prospects and accounts. Informed decisions lead to more effective sales strategies and improved outcomes.",
      icon: icon0_1,
    },
    {
      subTitleText: "Fostering Consistent Outreach:",
      text: "With the AI managing communication channels effortlessly, sales reps can maintain a consistent and strategic outreach. Whether it's calls, voicemails, texts, emails, LinkedIn interactions, or re-targeting follow-ups, the AI ensures a harmonized approach. Consistency in communication strengthens relationships with prospects, contributing to a more effective and impactful sales journey.",
      icon: icon0_2,
    },
    {
      subTitleText: "Redirecting Focus to High-Value Tasks:",
      text: "Automating routine tasks allows sales reps to redirect their focus to high-value activities that demand a human touch. Instead of being bogged down by manual entries, follow-ups, or adjustments to workflows, sales reps can concentrate on building relationships, refining sales pitches, and customizing strategies to fit the unique needs of their prospects.",
      icon: icon0_3,
    },
    {
      subTitleText: "Seamless Collaboration:",
      text: "As the AI seamlessly integrates with various tools, it fosters a collaborative environment within sales teams. Unified tool interactions mean a shared platform where data is accurate, accessible, and consistent. This collaboration extends beyond individual efforts, creating a synergistic approach to achieving collective sales goals.",
      icon: icon0_4,
    },
  ],
  conclusion:
    "In conclusion, the use cases of Flow Turbo's AI extend far beyond the 'how' of eliminating manual efforts – they define the 'why' for sales reps. It's about maximizing efficiency, making informed decisions, fostering consistency, redirecting focus, and enabling seamless collaboration. Flow Turbo's AI isn't just a tool; it's a game-changer that empowers sales reps to thrive in the ever-evolving landscape of B2B sales. Welcome to a future where technology and human ingenuity converge to redefine what's possible in sales excellence.",
};

const FlowTurboExperience = {
  content: [
    {
      title: "Elevate Your Prospecting Experience",
      description: `In the dynamic world of sales, scarcity of truly qualified accounts and relevant contacts is a challenge that every business faces. That's why we created Flow Turbo—to turn the tide, providing your sales reps with an abundance of opportunities.`,
    },
    {
      title: "300 AI Pre-Qualified Contacts from AI-Qualified Accounts:",
      description: `With Flow Turbo, your sales team gains access to a treasure trove of 300 AI-prequalified contacts sourced from AI-qualified accounts. Qualified accounts and relevant contacts are no longer a scarcity—they're a certainty.`,
    },
    {
      title: "AI Account Selection: Your Time-Saving Powerhouse:",
      description: `We understand that time is the most valuable asset in sales. That's why Flow Turbo introduces the revolutionary AI Account Selection feature, liberating your sales reps from the time-consuming prospecting process.`,
    },
  ],
  benefits: [
    {
      title: "Efficiency Redefined",
      description: `Our advanced AI algorithms streamline the account selection process, ensuring that your team spends less time navigating through thousands of accounts and prospects and more time engaging with clients.`,
      icon: case1,
    },
    {
      title: "Precision at Scale",
      description: `Our advanced AI algorithms streamline the account selection process, ensuring that your team spends less time navigating through thousands of accounts and prospects and more time engaging with clients.`,
      icon: case2,
    },
    {
      title: "Time Liberation",
      description: `Reclaim lost days spent on mundane prospecting tasks. With the AI Account Selection feature, your sales reps are free to focus on strategic initiatives, client interactions, and generating qualified deals and opportunities.`,
      icon: case3,
    },
  ],
};

const WhyFlowTurbo = {
  title: "Why Choose Flow Turbo?",
  description: `Flow Turbo stands out in the crowded landscape of sales tools by delivering unmatched efficiency and relevance. Here’s how our platform can transform your sales operations:`,
  benefits: [
    {
      title: "Boosted Relevance",
      description:
        "Target your relevant contacts with precision. Flow Turbo's AI algorithms ensure that the accounts and contacts presented align perfectly with your business objectives.",
      number: 1,
      icon: whyflow1,
    },
    {
      title: "Efficiency at Its Core",
      description:
        "Bid farewell to the days of laborious manual prospecting, and say goodbye to the painstaking process of manually selecting accounts. Flow Turbo optimizes efficiency, allowing your team to channel efforts where they matter most. With Flow Turbo, prospecting becomes a streamlined and efficient operation.",
      number: 2,
      icon: whyflow2,
    },
    {
      title: "Maximized Sales Productivity",
      description:
        "Reclaim lost days and turbocharge your team's productivity. Flow Turbo is your ally in ensuring every sales minute counts towards your success.",
      number: 3,
      icon: whyflow3,
    },
  ],
  additionalTexts: [
    "Don't let scarcity hold you back. Turbocharge your prospecting with Flow Turbo and redefine what's possible in sales. Abundance awaits.",
    "Unlock unparalleled efficiency in your sales team's daily operations with Flow's transformative AI capabilities. Traditional sales tools often consume nearly half of a sales rep's day, adding unnecessary complexity and hindering productivity.",
    "In the dynamic realm of sales, time is of the essence, and every nanosecond counts. Flow's AI-driven platform is not just a tool; it's a revolution designed to maximize the day-to-day productivity of your sales reps. Here's how we're reshaping the sales landscape:",
  ],
};

const KeyBenefits = [
  {
    number: 1,
    icon: Tick,
    title: "Minimizing Tool Overhead",
    description: `Traditional sales tools often inundate reps with multiple platforms, causing a significant loss in productivity. Flow consolidates essential functionalities, providing a unified hub for seamless sales operations.`,
  },
  {
    number: 2,
    icon: Tick,
    title: "No More Juggling Acts",
    description: `Sales reps are tired of toggling between numerous tools. Flow eradicates the need for these juggling acts, replacing the necessity of using multiple tools, ensuring your reps focus on what truly matters—generating qualified deals and opportunities.`,
  },
  {
    number: 3,
    icon: Tick,
    title: "AI-Driven Efficiency",
    description: `Our AI doesn't just assist; it takes the lead in the account selection process. Say goodbye to the tedious, time-consuming task of sifting through potential leads. Flow's AI does it smarter, faster, and with precision, liberating your reps from mundane activities.`,
  },
  {
    number: 4,
    icon: Tick,
    title: "Reducing Stress, Avoiding Burnout",
    description: `Sales burnout is a harsh reality. Flow brings relief by automating repetitive tasks and reducing the mental load on reps. This isn't just about productivity; it's about fostering a healthier and more sustainable work environment.`,
  },
  {
    number: 5,
    icon: Tick,
    title: "Consistent Peak Performance",
    description: `Flow ensures that your sales reps operate at peak efficiency consistently. By eliminating unnecessary stressors and streamlining operations, our platform empowers your team to focus on strategic tasks that drive results.`,
  },
  {
    number: 6,
    icon: Tick,
    title: "Unprecedented Time Savings",
    description: `Time is money, especially in sales. Flow unlocks unprecedented time savings by automating critical processes, enabling your reps to spend more time engaging with prospects and closing deals.`,
  },
  {
    number: 7,
    icon: Tick,
    title: "Strategic Focus on Growth",
    description: `With tedious tasks off their plate, your sales reps can redirect their energy towards strategic initiatives, fostering business growth and ensuring they stay ahead of the competition.`,
  },
];

const NeedForFlowTurbo = {
  sections: [
    {
      sectionText:
        "At Flow, we recognize that your sales team deserves a solution that not only assists but transforms their daily grind. Our AI-driven platform is the key to unlocking a new era of productivity, efficiency, and success for your sales reps.",
      subheader: "Unlocking Sales Success",
      subsections: [
        {
          title: "Achieve Your Sales Quota",
          description:
            "Discover how our platform empowers sales reps to not just meet but exceed their sales quotas. Realize the potential of AI-driven insights in identifying and engaging with high-value prospects.",
        },
        {
          title: "Gain New Customers",
          description:
            "Witness the impact of our platform on customer acquisition. Explore stories of businesses that expanded their customer base through targeted outreach and personalized communication.",
        },
      ],
    },
    {
      subheader: "Boosting Sales Efficiency",
      subsections: [
        {
          title: "Every Nanosecond Counts",
          description:
            "In the world of sales, time is money. Learn how Flow Turbo optimizes every nanosecond for sales reps, enhancing their speed and productivity.",
        },
        {
          title: "200% Increase in Sales Rep Speed",
          description:
            "Read success stories of sales reps experiencing a remarkable 200% increase in speed and productivity, allowing them to engage with more prospects and close deals faster.",
        },
        {
          title: "50% Less Wasted Time",
          description:
            "Explore how our platform eliminates time-wasting activities, reducing the daily grind and allowing sales reps to focus on what truly matters—closing deals.",
        },
      ],
    },
    {
      subheader: "Turbocharging Productivity",
      subsections: [
        {
          title: "Time Savings of 50%",
          description:
            "Delve into case studies showcasing a time savings of 50% for sales reps. Experience a paradigm shift in productivity, giving your team the edge in a competitive market.",
        },
        {
          title: "1040+ Hours of Unlocked Productivity",
          description:
            "Learn how Flow Turbo unlocks over 1,040 hours of productivity per sales rep annually, providing your organization with a substantial competitive advantage.",
        },
        {
          title: "90% Decrease in Rep Burnout",
          description:
            "Discover the impact on the well-being of your sales team. Experience a 90% decrease in rep burnout, leading to lower attrition rates and a healthier, more motivated workforce.",
        },
      ],
    },
    {
      subheader: "Driving Revenue Growth",
      subsections: [
        {
          title: "70% More Closed Deals",
          description:
            "Explore stories of businesses achieving a 70% increase in closed deals. Flow Turbo doesn't just streamline processes; it drives tangible results in revenue growth.",
        },
        {
          title: "70% Increase in Organization Revenue",
          description:
            "Witness how our platform contributes to a 70% increase in overall revenue for organizations. From startups to enterprises, businesses experience substantial financial growth.",
        },
      ],
    },
  ],
};

const ImagineNewSalesOrg = {
  title: "Imagine Your New Sales Organization",
  description: [
    {
      subtitle: "Step into the Future",
      text: "Step into the future of sales, where the heavy burden of slow, manual, and mundane processes is replaced with an exhilarating journey of efficiency, empowerment, and unprecedented success. In the current state of sales, the daily grind is a maze of exhaustive and repetitive tasks, draining your team's energy and leaving them yearning for transformation. The pain of navigating through this labyrinth is apparent, hindering not only productivity but also stunting the potential for increased commissions and financial prosperity.",
    },
    {
      subtitle: "A New World of Efficiency",
      text: "Now, envision a world where your sales reps are no longer shackled by the constraints of the mundane. With our SaaS AI sales prospecting solution, every interaction becomes a gateway to streamlined success. No longer do your reps have to endure the tedium of manual tasks; instead, they step into a realm where the AI takes care of the heavy lifting, allowing them to focus on what truly matters—building relationships, generating qualified deals, and maximizing their earnings potential. The monotony is replaced by a sense of empowerment, as each day becomes a step toward not just meeting quotas but exceeding them.",
    },
    {
      subtitle: "The Current Struggle",
      text: "In the landscape of contemporary sales, the current state of affairs often resembles a laborious and tedious journey—an unending cycle of slow, manual, and mundane tasks that drain the life out of the sales process. The relentless effort required to navigate through the exhaustive and repetitive nature of each day can feel overwhelming, leaving sales reps yearning for a more efficient and effective solution. The pain points are numerous: from the time-consuming aspects of prospecting to the cumbersome task of closing deals, the existing model seems to be designed to thwart success rather than facilitate it.",
    },
    {
      subtitle: "A Future Transformed",
      text: "Now, picture a future where every step in the sales journey is a joy, a future where the burdens of manual and mundane tasks are lifted, and sales reps are free to focus on what truly matters—the art of building relationships and closing deals. Our SaaS AI sales prospecting solution isn't just a tool; it's a paradigm shift that transforms the monotonous into the extraordinary. Imagine a world where the arduous path to success is replaced by a streamlined, efficient process, and the commissions that once seemed elusive become a tangible and attainable reality.",
    },
    {
      subtitle: "A New Era of Sales",
      text: "This is more than just a SaaS AI sales prospecting platform; it's an invitation to a new era of sales—one that is marked by efficiency, prosperity, and fulfillment. Envision your sales reps not merely as navigators of complex sales processes but as architects of their success, empowered by a groundbreaking solution that defies the norm. Adopting this SaaS AI sales prospecting platform is not just a choice; it's a declaration that your sales organization deserves more—a commitment to a future where each interaction is a step toward increased commissions, financial prosperity, and unparalleled success. Embrace the excitement, witness the transformation, and embark on a journey where your sales organization is not just thriving but reaching unprecedented heights of excellence.",
    },
  ],
};

const SimplicityInAction = {
  title: "Simplicity in Action: From Onboarding to Success Stories",
  subTitle_subText:
    "Embark on a journey of simplicity with Flow's revolutionary AI sales prospecting platform. Our commitment to user-friendly experiences begins the moment you decide to explore the power of Flow.",
  description: [
    {
      subtitle: "An Effortless Onboarding Experience",
      points: [
        {
          title: "Sign Up with Ease",
          text: "Joining the Flow community is a breeze. Our user-friendly onboarding process ensures that you spend less time registering and more time exploring the capabilities of our platform. Sign up effortlessly and get ready to unlock a new era of prospecting.",
        },
        {
          title: "Explore Intuitively",
          text: "Navigating through Flow is a delight. The intuitive design means you can effortlessly find and use powerful features without the need for extensive tutorials.",
        },
        {
          title: "Immediate Prospect Engagement",
          text: "No more waiting. Flow's straightforward approach means you can begin engaging with your prospects sooner. Flow streamlines your efforts, making every interaction count.",
        },
      ],
    },
    {
      subtitle: "Why Simplicity Matters",
      points: [
        {
          title: "User Empowerment",
          text: "Our simple onboarding puts you in control. You don’t need a manual; the platform guides you intuitively.",
        },
        {
          title: "Reduced Learning Curve",
          text: "No steep learning curves. The simplicity of Flow means less time spent figuring things out and more time dedicated to effective prospecting.",
        },
      ],
    },
  ],
};

const TheBottomLine = {
  subheader: "The Bottom Line - Benefits Summary",
  subtext: "Benefits Summary: Unlocking Potential",
  section: [
    {
      title: "Eliminates Bottlenecks and Time-Wasted Activities",
      description:
        "Streamline your workflow and eliminate bottlenecks, ensuring every action contributes to closing deals.",
    },
    {
      title: "Faster Daily Workflow",
      description:
        "Speed up your daily operations with a platform designed to maximize efficiency at every step.",
    },
    {
      title: "Less Time Wasted Daily",
      description:
        "Minimize idle time and wasted efforts, allowing your sales reps to focus on high-impact activities.",
    },
    {
      title: "Fewer Back-and-Forth Interactions",
      description:
        "Say goodbye to unnecessary back-and-forths with your tech stack and devices, ensuring a seamless and uninterrupted workflow.",
    },
    {
      title: "More Quality Accounts Analyzed",
      description:
        "Our platform empowers you to analyze, select, contact, or eliminate accounts efficiently, enhancing the quality of your sales pipeline.",
    },
    {
      title: "More Closed Sales and Revenue",
      description:
        "Realize the direct impact on your bottom line with more closed sales and increased revenue on a daily basis.",
    },
    {
      title: "More Prospects Scheduled for Closing Calls",
      description:
        "Witness an increase in the number of qualified prospects scheduled for closing calls on a daily basis.",
    },
    {
      title: "More Leads Contacted and Qualified",
      description:
        "Reach out to more leads and qualify them faster, ensuring a steady stream of engaged prospects.",
    },
  ],
};

export {
  UseCaseData,
  ROIandCostBenefitRatio,
  EmployeeRetentionBenefits,
  FlowTurboBenefits,
  caseStudies,
  Propositions,
  // guidedEndToEndAIPlatform,
  liveAISalesRepAssistant,
  valueProposition,
  Efficiencies,
  EmpoweringSalesReps,
  FlowTurboExperience,
  WhyFlowTurbo,
  KeyBenefits,
  NeedForFlowTurbo,
  ImagineNewSalesOrg,
  SimplicityInAction,
  TheBottomLine,
};
