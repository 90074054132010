import React from 'react';
import './coreValueCard.css';

const CoreValueCard = ({icon, title, description}) => {
    return (
        <div className="core-value-card">
            <div className='core-value-content'>
                <img src={icon} alt="icon" className="core-value-icon" />
                <div className="core-value-text">
                    <h3 className="core-value-title">{title}</h3>
                    <p className="core-value-description">{description}</p>
                </div>
            </div>
        </div>
    );
};

export default CoreValueCard;