import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import api from "./ghostAPI";
import "./Post.css";
import { FadeText } from "../magicui/fade-text";
import OtherPostCard from "./OtherPostCard";
import laptop from "../../assets/img/app_laptop_cropped.png";
import Footer from "../Footer";
import blogFallbackImage from "../../assets/img/blog-image-placholder.webp";
import { Helmet } from "react-helmet-async";
import parse, { domToReact } from "html-react-parser";

function PostPage() {
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const [otherPosts, setOtherPosts] = useState([]);

  useEffect(() => {
    api.posts
      .read({
        id: id,
        formats: "plaintext,html",
      })
      .then((post) => {
        setPost(post);
      })
      .catch((err) => {
        console.error(err);
      });

    api.posts
      .browse({
        limit: "all",
        include: "tags,authors",
      })
      .then((posts) => {
        const shuffledPosts = posts.sort(() => 0.5 - Math.random());
        const selectedPosts = shuffledPosts
          .filter((p) => p.id !== id)
          .slice(0, 3);
        setOtherPosts(selectedPosts);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [id]);

  if (!post) {
    return <div className="loading-text">Loading...</div>;
  }

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const options = {
    replace: ({ name, attribs, children }) => {
      if (!attribs) return;

      switch (name) {
        case "p":
          return (
            <p className="my-4 text-lg leading-relaxed">
              {domToReact(children, options)}
            </p>
          );
        case "h1":
          return (
            <h1 className="text-4xl font-bold my-6">
              {domToReact(children, options)}
            </h1>
          );
        case "h2":
          return (
            <h2 className="text-3xl font-semibold my-4">
              {domToReact(children, options)}
            </h2>
          );
        case "h3":
          return (
            <h3 className="text-2xl font-semibold my-4">
              {domToReact(children, options)}
            </h3>
          );
        case "a":
          return (
            <a className="text-pink-500 underline" href={attribs.href}>
              {domToReact(children, options)}
            </a>
          );
        case "img":
          return (
            <img
              className="rounded-lg shadow-lg mx-auto"
              src={attribs.src}
              alt={attribs.alt || ""}
            />
          );
        case "ul":
          return (
            <ul className="list-disc list-inside my-4">
              {domToReact(children, options)}
            </ul>
          );
        case "ol":
          return (
            <ol className="list-decimal list-inside my-4">
              {domToReact(children, options)}
            </ol>
          );
        case "li":
          return <li className="ml-6">{domToReact(children, options)}</li>;
        case "figure":
          return (
            <figure className="my-4">{domToReact(children, options)}</figure>
          );
        case "figcaption":
          return (
            <figcaption className="text-center text-sm text-gray-500">
              {domToReact(children, options)}
            </figcaption>
          );
        default:
          return;
      }
    },
  };

  return (
    <>
      <Helmet>
        <title>{post.title}</title>
        <meta name="description" content={post.excerpt} />
        <meta property="og:title" content={post.title} />
        <meta property="og:description" content={post.excerpt} />
        <meta
          property="og:image"
          content={post.feature_image || blogFallbackImage}
        />
        <meta
          property="og:url"
          content={`https://www.flowai.tech/blog/${post.slug}`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={post.title} />
        <meta name="twitter:description" content={post.excerpt} />
        <meta
          name="twitter:image"
          content={post.feature_image || blogFallbackImage}
        />
      </Helmet>
      <div className="wrapper">
        <div className="page-margin flex flex-column">
          <div className="blog-heading tracking-tighter hidden md:flex md:justify-content-center md:pt-3 ">
            {post.title}
          </div>
          <div className="blog-heading flex md:hidden md:justify-content-center pt-5 md:pt-0">
            <FadeText
              className="md:hidden tracking-tight font-extrabold text-black text-left"
              direction="up"
              framerProps={{
                show: { transition: { delay: 0.2 } },
              }}
              text={post.title}
            />
          </div>
        </div>
        <div className="page-margin flex justify-center blog-feature-image-wrapper">
          <div className="flex blog-feature-image">
            <img
              className="grow"
              src={post.feature_image ? post.feature_image : blogFallbackImage}
              alt=""
            />
          </div>
          {console.log(post.html)}
        </div>
        <div className="page-margin">
          <div className="blog-date flex pt-3">
            {formatDate(post.updated_at)}
          </div>

          <div className="blog-content">{parse(post.html, options)}</div>
          <br />
        </div>
        <div className="other-posts-section md:px-3">
          <div className="blog-heading other-posts-section-heading flex">
            You may also like
          </div>
          <div className="other-posts-wrapper flex flex-col md:flex-row justify-content-center">
            {otherPosts.map((otherPost, index) => (
              <OtherPostCard
                post={otherPost}
                key={otherPost.id}
                idValue={index}
              />
            ))}
          </div>
        </div>

        <div className="hidden md:flex blog-content-promo-wrapper justify-content-center align-content-center">
          <div className="blog-content-promo flex p-5 justify-content-center align-self-center">
            <div className="blog-content-promo-body">
              <div className="blog-content-promo-header">
                Ready to Get Started?
              </div>
              <div className="blog-content-promo-subheadding">
                Schedule your free product tour right now and witness firsthand
                how Flow Turbo, the world’s first SaaS AI sales prospecting
                platform, can transform your sales process. Unlock the potential
                of AI and achieve unparalleled success in your sales efforts.
                It’s time to elevate your prospecting game with Flow Turbo.
              </div>
              <div className="blog-content-promo-subheadding py-4">
                <Link
                  to="/contact/sales-support"
                  className="btn_link promo-button flex justify-content-center"
                >
                  <div className="green-button-product">
                    Schedule A Product Tour
                  </div>
                </Link>
              </div>
            </div>
            <div className="blog-content-promo-img">
              <img src={laptop} alt="" />
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
}

export default PostPage;
