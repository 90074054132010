import React from "react";
import "../assets/css/Marketplace.css";
import Footer from "../components/Footer";

const Marketplace = () => {
  return (
    <>
      <div className="marketplace fadeInAnimation">
        <div className="padding1">
          <div className="marketplace-title center">
            <p className="header_text bold">Marketplace</p>
          </div>
          <div className="center padding3">
            <p className="subheader_text bold">Building Strong Foundations: Nurturing Long-Term Connections with Our Customers and Partners</p>
          </div>
          <div className="center padding4">
            <p className="font3">
              At Flow, we go beyond technology—we believe in fostering enduring
              relationships. Our commitment extends beyond providing exceptional AI
              solutions; we strive to build long-term partnerships that stand the test of time.
            </p>
          </div>
          <div className="padding1">
            <div className="marketplace-item">
              <div className="sub_title_text bold">💼 Customer-Centric Integrations: </div>
              <div className="text">
                Experience a new era of customer-centricity with our seamlessly integrated AI solutions.
                Tailored to meet your unique needs, our integrations solidify our dedication to your
                success, forming the cornerstone of a lasting collaboration.
              </div>
            </div>
            <div className="marketplace-item">
              <div className="sub_title_text bold">🤝 Partnerships That Flourish: </div>
              <div className="text">
                Collaboration is key, and we are dedicated to nurturing partnerships that flourish. Our
                integrated approach ensures that our partners thrive in an interconnected ecosystem,
                fostering growth, innovation, and shared success.
              </div>
            </div>
            <div className="marketplace-item">
              <div className="sub_title_text bold">🌐 Open Doors to Possibilities:</div>
              <div className="text">
                Unlock a world of possibilities as our integrations seamlessly connect you to enhanced
                functionalities and expanded capabilities. We believe in opening doors that lead to mutual
                growth, propelling our relationships into the future.
              </div>
            </div>
            <div className="marketplace-item">
              <div className="sub_title_text bold">🚀 Forward-Thinking Connectivity:</div>
              <div className="text">
                As technology evolves, so do we. Our forward-thinking integrations are designed to adapt,
                ensuring that your AI experience remains cutting-edge and aligned with your evolving
                needs. Together, let's navigate the future of innovation.
              </div>
            </div>
            <div className="marketplace-item">
              <div className="sub_title_text bold">🛡 Trust in Secure Collaboration:</div>
              <div className="font4">
                Security is paramount in our relationships. Rest assured that our integrations prioritize
                robust security measures, creating a foundation of trust and reliability. Your data's safety is
                our unwavering commitment.
              </div>
            </div>
          </div>
          <div className="center padding3">
            <p className="sub_title_text bold">
              Embrace the journey of long-term connections with Flow.
              Explore our integrations and witness the transformative power
              of collaborative technology. Together, let's shape a future built
              on enduring partnerships.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
};

export default Marketplace;