const benefits_content = [
    {
        "title": "Increased Efficiency",
        "description": "The platform's vision is to seamlessly integrate with existing CRM systems, ensuring that all prospect data, interactions, and insights are accurately logged in the CRM."
    },
    {
        "title": "Focused Sales Efforts",
        "description": "By eliminating manual work, sales reps can direct their efforts towards high-impact activities that drive sales and foster customer relationships."
    },
    {
        "title": "Consistent Performance",
        "description": "The AI ensures a consistent and standardized approach to tasks, reducing the likelihood of errors and ensuring a high level of performance across the board."
    },
    {
        "title": "Effortless Sales Mastery",
        "description": "As we conclude this exploration of our Live AI Sales Rep Assistant, we invite you to envision the transformative power it brings to your sales ecosystem. It's not just about automating tasks; it's about liberating your team from the shackles of manual and mundane activities. The Live AI Sales Rep Assistant stands as a testament to our commitment to redefining the sales experience, freeing your reps to do what they do best - build relationships, engage meaningfully, and generating qualified deals and opportunities."
    },
    {
        "title": "Embrace the Future of Sales",
        "description": "With Live AI, your sales reps are no longer bogged down by tedious administrative work. Instead, they become orchestrators of value, leveraging insights, and personal connections to drive revenue. The Live AI Sales Rep Assistant isn't just a tool; it's a strategic ally in your quest for sales excellence. As your team embraces this future-forward technology, watch as they evolve from task executors to value creators, steering your organization toward unprecedented success in the ever-evolving landscape of sales innovation. Welcome to the future of sales - welcome to Flow."
    }
];

export default benefits_content;
