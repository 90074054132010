import React, { useState } from 'react';

import card_dropdown from '../../../assets/img/icon/dropdown.png';

const KeyFeaturesCard = ({ title, description, icon }) => {
  const [dropDownClicked, setDropDownClicked] = useState(false);

  const toggleDropDown = () => {
    setDropDownClicked(!dropDownClicked);
  };

  return (
    <div className="bg-white rounded-2xl px-3 py-7 my-5">
      <div className="flex flex-row justify-between gap-x-3.5">
        <img src={icon} className="w-12 h-12" alt="icon" />
        <div className="m-auto font-bold text-black font-neue-haas text-[18px] text-left">{title}</div>
        <div className="text-right">
          <img
            src={card_dropdown}
            className={`w-9 h-9 transition-transform duration-300 ${dropDownClicked ? 'rotate-180' : ''}`}
            alt="dropdown_icon"
            onClick={toggleDropDown}
          />
        </div>
      </div>

      {/* Dropdown content */}
      {dropDownClicked && (
        <div className="mt-4 font-work-sans text-base font-medium">
          {description}
        </div>
      )}
    </div>
  );
};

export default KeyFeaturesCard;
