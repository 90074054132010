import React from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import BlogPost from "../components/Blog/BlogPost";
import "../assets/css/Blog.css";
import { FadeText } from "../components/magicui/fade-text";
import { Button } from "@mui/material";
import { Helmet } from "react-helmet-async";

const Blog = () => {
  const pageTitle = "The Future of Sales is with Flow";
  const pageUrl = "https://www.flowai.tech/blog";

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageTitle} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:description" content={pageTitle} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageTitle} />
        {/* Explicitly setting empty og:image and twitter:image */}
        <meta property="og:image" content="" />
        <meta name="twitter:image" content="" />
      </Helmet>
      <div className="blog fadeInAnimation">
        <div className="hidden md:block blog-header px-2 large">
          <h1>Blog</h1>
        </div>
        <div className="flex flex-col space-y-8 pt-6">
          <FadeText
            className="md:hidden text-[29px] tracking-tight font-extrabold text-black text-left "
            direction="up"
            framerProps={{
              show: { transition: { delay: 0.2 } },
            }}
            text="Blog"
          />
        </div>

        <div className="blog-subheading-text">
          <div className="flex bold md:font-extrabold ">
            The Future of Sales is with Flow
          </div>
          {/* <div className="flex text-lg font-light md:text-[32px] md:font-normal pt-3">
            Exploring AI/ML Advancements and Productivity Strategies in Sales
          </div> */}
        </div>

        {/*BlogPost component called  */}
        <BlogPost pageTitle={pageTitle} />
      </div>
      <div className="hidden md:block blog-promo-wrapper">
        <div className="blog-promo flex flex-column justify-content-center align-self-center">
          <div className="blog-promo-header flex justify-content-center align-self-center">
            Unlock Your Sales Potential with Flow Turbo. <br /> Transform Your
            Team. Multiply Your Success.
          </div>
          <div className="blog-promo-header flex justify-content-center align-self-center">
            <Link
              to="/contact/sales-support"
              style={{ textDecoration: "none" }}
            >
              <Button className="primary-btn promo-green-button">
                Schedule A Product Tour
              </Button>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Blog;
