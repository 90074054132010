import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';

import Navigation from '../pages/Navigation';

import '../assets/css/Navigation.css';
import '../assets/css/Header.css';
import '../App.css';
import logo from '../assets/img/icon/logo.png';

const Header = () => {
  const [isLoginPage, setIsLoginPage] = useState(false);
  const location = useLocation();

  useEffect(() => {

    const hrPaths = [
      '/internal-hr',
      '/internal-hr-joblist',
      '/create-job',
      '/update-job/:jobId',
      '/job-details/:id',
      '/job/:job_id/applications/:application_id',
      '/job/:jobId/applications'
    ];

    // Function to check if the current pathname matches any of the specified paths
    const isHRPath = hrPaths.some(path => {
      const regexPath = new RegExp(`^${path.replace(/:[^\s/]+/g, '([\\w-]+)')}$`);
      return regexPath.test(location.pathname);
    });

    setIsLoginPage(isHRPath);
  }, [location.pathname]);

  return (
    <div>
      {!isLoginPage ? (
        <Navigation />
      ) : (
        <nav className='navigation internal'>
          <ul>
            <li>
              <Link to="/">
                <img className="logo" src={logo} alt="Logo" />
              </Link>
            </li>
          </ul>
        </nav>
      )}
    </div>
  );
};

export default Header;
