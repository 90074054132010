import React,{useState} from 'react'

import add_sign_icon from '../../../assets/img/icon/add_sign.png'


const BenefitsCardComponent = ({title,description}) => {

  const [dropDownClicked, setDropDownClicked] = useState(false);

  const toggleDropDown = () => {
    setDropDownClicked(!dropDownClicked);
  };

  return (
    <div className='py-4'>
        <div className='flex flex-row justify-between'>
            <div className='font-bold font-neue-haas text-xl'>
                {title}
            </div>

            <img src={add_sign_icon} alt='add_sign' className='w-12 h-12'  onClick={toggleDropDown}></img>
        </div>
        {/* Dropdown content */}
      {dropDownClicked && (
        <div className="my-4 font-neue-haas font-medium text-base">
          {description}
        </div>
      )}
        <div className='border border-black'></div>

        

    </div>
  )
}

export default BenefitsCardComponent