import React,{useEffect, useState} from 'react'
import KeyFeaturesCard from './KeyFeaturesCard';

import key_features_content from '../../Content/Product-Page/KeyFeaturesContent'

const KeyFeaturesComponent = () => {
   
    const [keyFeaturesCardList,setKeyFeaturesList] = useState(null);

    useEffect(()=>{
      
      setKeyFeaturesList(key_features_content.map((content,index)=><KeyFeaturesCard key={index} title={content.title} description={content.description} icon={content.icon}></KeyFeaturesCard>));
    },[])
    
  
    return (
        <div className='bg-black p-6'>
            <h1 className='font-bold text-white font-neue-haas text-2xl'>Introducing the Future of Sales Productivity: Live AI Sales Rep Assistant</h1>
            <h1 className='font-bold text-white font-neue-haas text-[18px] mt-2'>Key Features</h1>
            {keyFeaturesCardList && keyFeaturesCardList}
        </div>
    );
  };

export default KeyFeaturesComponent