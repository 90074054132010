import React from "react";
import { Link } from "react-router-dom";
import "./postpreview.css";
import blogFallbackImage from "../../assets/img/blog-image-placholder.webp";
import { useWindowSize } from "@uidotdev/usehooks";
export const GetNearestSymbol = (text, symbol, charLimit) => {
  let paras;
  // Extract the text before and after the 500th character
  const textBefore = text.substring(0, charLimit);
  const textAfter = text.substring(charLimit);

  // Find the last Symbol in the text before the nth character
  const lastSymbolBefore = textBefore.lastIndexOf(symbol);

  // Find the first Symbol in the text after the nth character
  const firstSymbolAfter = textAfter.indexOf(symbol);

  // If there's no Symbol before the nth character, take the first Symbol after
  if (lastSymbolBefore === -1 && firstSymbolAfter !== -1) {
    paras = text.substring(0, charLimit + firstSymbolAfter + 1);
  }
  // If there's no Symbol after the nth character, take the last Symbol before
  else if (firstSymbolAfter === -1 && lastSymbolBefore !== -1) {
    paras = text.substring(0, lastSymbolBefore + 1);
  }
  // If there are both, take the nearest one
  else if (firstSymbolAfter !== -1 && lastSymbolBefore !== -1) {
    paras =
      charLimit - lastSymbolBefore < firstSymbolAfter + 1
        ? text.substring(0, lastSymbolBefore + 1)
        : text.substring(0, charLimit + firstSymbolAfter + 1);
  }
  // If there's no Symbol at all, print the subtext
  else {
    paras = text.substring(0, charLimit);
  }
  return paras;
};

function PostPreview(props) {
  const post = props.post;
  const id = props.post.id;
  const size = useWindowSize();
  const subheadingPara = GetNearestSymbol(
    post.plaintext.replace(/\n/g, ""),
    ".",
    size.width < 700 ? 15 : 50
  );

  // const charLimit = 650;

  return (
    <div className="postpreview-card-wrapper">
      <Link to={`${id}`}>
        <div className=" postpreview-card h-100 p-2 md:p-4 justify-content-between">
          {size.width > 700 ? (
            props.idValue % 2 === 0 ? (
              <>
                <div className="col-5">
                  <div className="flex h-100 flex-column">
                    <h2 className="blog-preview-heading">{post.title}</h2>
                    <div className="blog-preview-content-gray">
                      {post.reading_time} min read
                    </div>
                    <div className="text-decoration-none blog-preview-content">
                      {subheadingPara}
                    </div>
                    <p className="text-decoration-none blog-preview-content-gray">
                      Read More
                    </p>
                  </div>
                </div>
                <div className="flex col-6 center">
                  <div className="blog-preview-image-wrapper">
                    <img
                      className="blog-preview-image grow"
                      src={
                        post.feature_image
                          ? post.feature_image
                          : blogFallbackImage
                      }
                      alt=""
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="flex col-6 center">
                  <div className="blog-preview-image-wrapper">
                    <img
                      className="blog-preview-image grow"
                      src={
                        post.feature_image
                          ? post.feature_image
                          : blogFallbackImage
                      }
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-5">
                  <div className="flex  h-100 flex-column">
                    <h2 className="blog-preview-heading">{post.title}</h2>
                    <div className="blog-preview-content-gray">
                      {post.reading_time} min read
                    </div>
                    <div className="text-decoration-none blog-preview-content">
                      {subheadingPara}
                    </div>
                    <p className="text-decoration-none blog-preview-content-gray">
                      Read More
                    </p>
                  </div>
                </div>
              </>
            )
          ) : (
            <div className="flex flex-col">
              <div className="flex  center">
                <div className="blog-preview-image-wrapper">
                  <img
                    className="blog-preview-image grow"
                    src={
                      post.feature_image
                        ? post.feature_image
                        : blogFallbackImage
                    }
                    alt=""
                  />
                </div>
              </div>
              <div className="">
                <div className="flex  h-100 flex-column">
                  <h2 className="blog-preview-heading pt-3 pb-1">
                    {post.title}
                  </h2>
                  <div className="blog-preview-content-gray tracking-wide">
                    {post.reading_time} min read
                  </div>
                  <div className="text-decoration-none blog-preview-content ">
                    {subheadingPara}
                  </div>
                  <p className="text-black text-[0.7rem] font-bold underline tracking-wide py-3">
                    Read More
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </Link>
    </div>
  );
}

export default PostPreview;
