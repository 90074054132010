import React, { useEffect, useState } from 'react'
import TakeawaysSelectionCard from './TakeawaysSelectionCard';
import GainsSelectionCard from './GainsSelectionCard';

import takeaways_selection_features from "../../Content/Landing-Page/TakeawaysSelectionFeaturesContent"
import gains_selection_features from '../../Content/Landing-Page/GainsSelectionFeaturesContent';



const FlowTurboComponent = () => {

    const [TakeawaysSelectionCardList, setCardList] = useState(null);
    const [GainsSelectionCardList, setGainsCardList] = useState(null);

    useEffect(() => {
        setCardList(takeaways_selection_features.map((content, index) => <TakeawaysSelectionCard description={content.description} cardID={index + 1} key={index}></TakeawaysSelectionCard>));
        setGainsCardList(gains_selection_features.map((content, index) => <GainsSelectionCard title={content.title} description={content.description} cardID={index + 1} key={index}></GainsSelectionCard>));
    }, []);

    return (
        <div className='px-4 font-work-sans text-black'>
            <h3 className="font-extrabold py-1"> Key Takeaways </h3>
            <div className="flex overflow-x-scroll space-x-4 py-4">
                <div className="flex space-x-4">
                    {TakeawaysSelectionCardList && TakeawaysSelectionCardList}
                </div>
            </div>
            <div className='py-12'>
                <h1 className='font-extrabold text-xl'>Unleash Sales Success with Our Exclusive Ebook!</h1>
                <p className='font-medium text-base mt-4'>Are you a B2B Sales Executive looking to skyrocket your commissions and fill your pipeline with qualified deals? Discover the secrets to mastering the art of sales numbers and strategic eliminations with our free ebook.</p>
                <p className='font-medium text-base mt-4'>B2B Sales Unveiled: The Numbers Game Redefined for Maximum Commissions! Why Sales Is A Numbers Game and Why It's Also About Eliminating The Ones Who Are Not Right For Your Product.</p>
            </div>
            <h3 className="font-extrabold py-1"> What You'll Gain </h3>
            <div className="flex overflow-x-scroll space-x-4 py-4">
                <div className="flex space-x-4">
                    {GainsSelectionCardList && GainsSelectionCardList}
                </div>
            </div>
            <div className='py-16'>
                <h1 className='font-extrabold text-xl'>Exclusive Early Access</h1>
                <p className='font-medium  text-base mt-4'>Be among the first 100 accounts to access this game-changing ebook! Early Access accounts get a special promo offer for a limited time.</p>
                <h1 className='pt-12 font-extrabold text-xl'>Discover the Winning Boost for Commission Triumph</h1>
                <p className='font-medium text-base mt-4'>Fill out the form above to receive your free copy of the ebook and gain exclusive early access.</p>
                <p className='font-medium text-base mt-4'>Our expert consultants are also available for a complimentary strategy session to discuss how our Flow Turbo SaaS AI sales platform can turbocharge your sales team. Don't miss out on this opportunity to revolutionize your B2B sales journey.</p>
            </div>
        </div>
    )
}

export default FlowTurboComponent