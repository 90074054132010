import React, { useState } from "react";
import Footer from "../../components/Footer";
import use_case_bg1 from "../../assets/img/use-case-bg1.png";
import use_case_bg2 from "../../assets/img/use-case-bg2.png";
import DownArrow from "../../assets/img/icon/DownArrow.png";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import ResponsiveCarousel from "../../aceternity-components/ui/usecase-moving-cards";
import ResponsiveCarouselLight from "../../aceternity-components/ui/usecase-moving-cards-light";
import { Link } from "react-router-dom";
import Accordian from "./accordian";
import "./UseCase.css";
import {
  UseCaseData,
  ROIandCostBenefitRatio,
  EmployeeRetentionBenefits,
  FlowTurboBenefits,
  caseStudies,
  Propositions,
  // guidedEndToEndAIPlatform,
  liveAISalesRepAssistant,
  valueProposition,
  Efficiencies,
  EmpoweringSalesReps,
  FlowTurboExperience,
  WhyFlowTurbo,
  KeyBenefits,
  NeedForFlowTurbo,
  ImagineNewSalesOrg,
  SimplicityInAction,
  TheBottomLine,
} from "./content";

const UseCases = () => {
  const [isSection3Open, setIsSection3Open] = useState({});
  const toggleSection3 = (index) => {
    setIsSection3Open((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const [isSection5Open, setIsSection5Open] = useState({});
  const toggleSection5 = (index) => {
    setIsSection5Open((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const [isSection9P1Open, setIsSection9P1Open] = useState({});
  const toggleSection9P1 = (index) => {
    setIsSection9P1Open((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const [isSection9P2Open, setIsSection9P2Open] = useState({});
  const toggleSection9P2 = (index) => {
    setIsSection9P2Open((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  // const [isSection10P1Open, setIsSection10P1Open] = useState({});
  // const toggleSection10P1 = (index) => {
  //   setIsSection10P1Open((prevState) => ({
  //     ...prevState,
  //     [index]: !prevState[index],
  //   }));
  // };

  const [isSection10P2Open, setIsSection10P2Open] = useState({});
  const toggleSection10P2 = (index) => {
    setIsSection10P2Open((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const [isSection10P3Open, setIsSection10P3Open] = useState({});
  const toggleSection10P3 = (index) => {
    setIsSection10P3Open((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const [isSection11Open, setIsSection11Open] = useState({});
  const toggleSection11 = (index) => {
    setIsSection11Open((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  return (
    <>
      <div className="use-cases fadeInAnimation">
        {/* Section 1 */}
        <div className="section1">
          <div className="section1_title center">
            <h1 className="custom-h1">Use Cases and Customer Stories</h1>
          </div>
          <div className="section1_subsection">
            <h2 className="section1_subtext">
              Sales tools waste sales rep time, but Flow Turbo's Gray Matter AI
              Engine prospects for sales reps while they sell.
            </h2>
            <h2 className="section1_subtext">
              New SaaS AI Sales Prospecting Platform has specialized proprietary
              AI model at it's core foundation.
            </h2>
          </div>
        </div>

        {/* Section 2 */}
        <div className="section2">
          <div className="section2_texts">
            <div className="section2_sub_title_text">
              Flow Turbo ROI Calculation:
            </div>
            <div className="section2_sub_title_text">
              Unlocking Sales Success
            </div>
            <div className="sub-text-section2">
              At Flow, we understand that every investment in technology must
              translate into tangible returns. The Flow Turbo SaaS AI Sales
              Prospecting Solution is engineered not only to optimize the
              efficiency of sales processes but to deliver a significant return
              on investment (ROI). Let's delve into the mathematics of how Flow
              Turbo can elevate your business to new heights.
            </div>
          </div>
          <div className="section2_img-container ">
            <img src={use_case_bg1} alt="" />
          </div>
        </div>

        {/* Section 3 */}
        <div className="section3">
          <div className="section3-cards-container">
            {UseCaseData.map((item, index) => (
              <div className="section3-item" key={`section3-cards-${index}`}>
                <h2 className="section3-item-title">{item.title}</h2>
                <p className="section3-item-description">{item.description}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Section 3 Mobile */}
        <div className="section3-mobile-usecase-section">
          {UseCaseData.map((item, index) => (
            <div
              className="section3-mobile-usecase-item"
              key={`section3-mobile-usecase-${index}`}
            >
              <div className="section3-mobile-usecase-header">
                <div>
                  <img
                    src={item.image}
                    alt="img"
                    className="section3-mobile-titleIcon"
                  />
                </div>
                <div className="section3-mobile-usecase-title">
                  {item.title}
                </div>
                <img
                  src={DownArrow}
                  alt="Down Arrow"
                  className="section3-mobile-down"
                  onClick={() => toggleSection3(index)}
                />
              </div>
              {isSection3Open[index] && (
                <div className="section3-mobile-usecase-content">
                  {item.description}
                </div>
              )}
            </div>
          ))}
        </div>

        {/* Section 4 */}
        <div className="section4">
          <div className="section4-subheader_text">Formula for ROI:</div>
          <div className="section4-content">
            <div className="section4-left-content">
              <div className="section4-subtitle_text">
                ROI = (Gain from Investment - Cost of Investment) / Cost of
                Investment x 100
              </div>
            </div>
            <div className="section4-right-content">
              Plug in the specific values from your business to understand the
              precise ROI that Flow Turbo can deliver. The equation encapsulates
              the essence of what Flow Turbo brings to the table – a powerful
              tool that not only pays for itself but becomes a catalyst for
              ongoing growth.
              <br />
              In conclusion, Flow Turbo is not just an expenditure; it's an
              investment in the future of your sales operations. The numbers
              speak for themselves – increased customers, amplified deal
              quality, and a substantial ROI that positions your business for
              sustained success.
            </div>
          </div>
        </div>

        {/* Section 5 */}
        <div className="section5">
          <div className="section5-subheader_text">
            Flow Turbo ROI and Cost-Benefit Ratio: A Deeper Dive into
            Productivity and Retention
          </div>
          <div className="section5-content">
            {ROIandCostBenefitRatio.map((item) => (
              <div
                className="section5-subsection"
                key={`section5-content-${item.number}`}
              >
                <div>
                  <div className="section5-number bold">{item.number}</div>
                </div>
                <div className="section5-subsection_text">
                  <div className="mt-10 bold">{item.title}</div>
                  <div>{item.description}</div>
                </div>
              </div>
            ))}
          </div>
          <div className="section5-mobile-usecase-section">
            {ROIandCostBenefitRatio.map((item, index) => (
              <div
                className="section5-mobile-usecase-item"
                key={`section5-mobile-usecase-${index}`}
              >
                <div className="section5-mobile-usecase-header">
                  <div className="section5-mobile-usecase-title">
                    {item.title}
                  </div>
                  <button
                    className="section5-mobile-usecase-button"
                    onClick={() => toggleSection5(index)}
                  >
                    {isSection5Open[index] ? <RemoveIcon /> : <AddIcon />}
                  </button>
                </div>
                {isSection5Open[index] && (
                  <div className="section5-mobile-usecase-content">
                    {item.description}
                  </div>
                )}
                <div className="section5-mobile-divider_black"></div>
              </div>
            ))}
          </div>
        </div>

        {/* Section 6 */}
        <div className="section6">
          <div className="section6-sub_title_text">
            Cost-Benefit Ratio: Time Savings and Retention Metrics
          </div>
          <div className="section6-sub_title_text">
            Cost-Benefit Ratio = (Total Time Savings + Retention Benefits) /
            Total Costs
          </div>
        </div>

        {/* Section 7 */}
        <div className="section7">
          <div className="section7-content">
            <div className="section7-text-content">
              <div className="section7-subheader_text">
                {EmployeeRetentionBenefits.subheader}
              </div>
              <div className="section7-sub_title_text">
                <ul>
                  {EmployeeRetentionBenefits.subTitle.map((item, index) => (
                    <li key={`section7-content-${index}`}>{item}</li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="section7-img-container">
              <img src={use_case_bg2} alt="" />
            </div>
          </div>
        </div>

        {/* Section 8 */}
        <div className="section8">
          <div className="section8-subsection">
            {FlowTurboBenefits.map((item, index) => (
              <div key={`section8-subsection_title-${index}`}>
                <div className="section8-subsection_title bold">
                  {item.title}
                </div>
                <div>{item.description}</div>
              </div>
            ))}
          </div>
        </div>

        {/* Section 9 */}
        <div className="section9">
          {caseStudies
            .reduce((rows, caseStudy, index) => {
              if (index % 2 === 0) {
                rows.push([caseStudy]);
              } else {
                rows[rows.length - 1].push(caseStudy);
              }
              return rows;
            }, [])
            .map((row, rowIndex) => (
              <div key={`section-9-row-${rowIndex}`} className="section9-row">
                {row.map((caseStudy, i) => (
                  <div
                    key={`section-9-case-study-${i}`}
                    className="section9-sub_section"
                  >
                    <div className="section9-subheader_title">
                      {caseStudy.title}
                    </div>
                    <div className="section9-subheader_text">
                      {caseStudy.subtitle}
                    </div>
                    <div>{caseStudy.description}</div>
                  </div>
                ))}
              </div>
            ))}
          <div className="section9-row">
            {Propositions.map((item, id) => (
              <div
                key={`section-9-propositions-${id}`}
                className="section9-sub_section"
              >
                <div className="section9-subheader_PropositionsTitle">
                  {item.title}
                </div>
                <div className="section9-subheader_text">{item.subtitle}</div>
                <div className="section9-subheader-desc">
                  {item.description}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Section 9 Mobile */}
        {/* Part-1 */}
        <div className="section9-mobile-usecase-section">
          {caseStudies.map((item, index) => (
            <div
              className="section9-mobile-usecase-item"
              key={`section9-mobile-usecase-item-${index}`}
            >
              <div className="section9-mobile-usecase-header">
                <div>
                  <img
                    src={item.image}
                    alt="img"
                    className="section9-mobile-titleIcon"
                  />
                </div>
                <div className="section9-mobile-usecase-title">
                  {item.title}
                  <br />
                  {item.subtitle}
                </div>
                <img
                  src={DownArrow}
                  alt="Down Arrow"
                  className="section9-mobile-down"
                  onClick={() => toggleSection9P1(index)}
                />
              </div>
              {isSection9P1Open[index] && (
                <div className="section9-mobile-usecase-content">
                  {item.description}
                </div>
              )}
            </div>
          ))}
        </div>
        {/* Part-2 */}
        <div className="section9-p2-mobile-usecase-section">
          {Propositions.map((item, index) => (
            <div
              className="section9-p2-mobile-usecase-item"
              key={`section9-p2-mobile-usecase-item-${index}`}
            >
              <div className="section9-p2-mobile-usecase-header">
                <div className="section9-p2-mobile-usecase-title">
                  {item.title}
                </div>
                <button
                  className="section9-p2-mobile-usecase-button"
                  onClick={() => toggleSection9P2(index)}
                >
                  {isSection9P2Open[index] ? <RemoveIcon /> : <AddIcon />}
                </button>
              </div>
              {isSection9P2Open[index] && (
                <div className="section9-p2-mobile-usecase-content">
                  {item.description}
                </div>
              )}
              <div className="section9-p2-mobile-divider_black"></div>
            </div>
          ))}
        </div>

        {/* Section 10 */}
        <div className="section10">
          {/* <div className="section10-subsection">
            <div className="section10-subheader_text">
              {guidedEndToEndAIPlatform.subheader}
            </div>
            <div className="section10-content">
              <div className="section10-sub_content">
                {guidedEndToEndAIPlatform.content.map((point, pointIndex) => (
                  <div className="section10-leftContent">
                    <div className="section10-number bold">{point.number}</div>
                    <div className="section10-text">
                      <div className="bold">{point.title}</div>
                      <div>{point.description}</div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="section10-img-container">
                <img src={guidedEndToEndAIPlatform.imgSrc} alt="" />
              </div>
            </div>
          </div> */}
          <div className="section10-subsection">
            <div className="section10-content">
              <div className="section10-img-container">
                <img src={liveAISalesRepAssistant.imgSrc} alt="" />
              </div>
              <div className="section10-sub_content">
                <div className="section10-subheader_text">
                  {liveAISalesRepAssistant.subheader}
                </div>
                {liveAISalesRepAssistant.content.map((point, pointIndex) => (
                  <div
                    className="section10-leftContent"
                    key={`section10-leftContent-${pointIndex}`}
                  >
                    <div className="section10-number bold">{point.number}</div>
                    <div className="section10-text">
                      <div className="bold">{point.title}</div>
                      <div>{point.description}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="section10-subsection">
            <div className="section10-subheader_text">
              {valueProposition.subheader}
            </div>
            <div className="section10-content">
              <div className="section10-sub_content">
                {valueProposition.content.map((point, pointIndex) => (
                  <div
                    className="section10-leftContent"
                    key={`section10-leftContent-${pointIndex}`}
                  >
                    <div className="section10-number bold">{point.number}</div>
                    <div className="section10-text">
                      <div className="bold">{point.title}</div>
                      <div>{point.description}</div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="section10-img-container">
                <img src={valueProposition.imgSrc} alt="" />
              </div>
            </div>
          </div>
          <div>
            In embracing Flow Turbo, you're not just adopting a platform; you're
            unleashing a wave of innovation that will reshape your approach to
            sales. Experience the future of sales – experience Flow.
          </div>
        </div>

        {/* Section 10 Mobile */}
        {/* <div className="section10-mobile-usecase-section">
          <div className="section10-subsection">
            <div className="section10-mobile-subheader_text">
              {guidedEndToEndAIPlatform.subheader}
            </div>
            <div className="section10-mobile-img-container">
              <img src={guidedEndToEndAIPlatform.imgSrc} alt="" />
            </div>
          </div>
          {guidedEndToEndAIPlatform.content.map((item, index) => (
            <div className="section10-mobile-usecase-item" key={index}>
              <div className="section10-mobile-usecase-header">
                <div>
                  <div className="section10-mobile-number1 bold">
                    {index + 1}
                  </div>
                </div>
                <div className="section10-mobile-usecase-title">
                  {item.title}
                  <br />
                  {item.subtitle}
                </div>
                <img
                  src={DownArrow}
                  alt="Down Arrow"
                  className="section10-mobile-down"
                  onClick={() => toggleSection10P1(index)}
                />
              </div>
              {isSection10P1Open[index] && (
                <div className="section10-mobile-usecase-content">
                  {item.description}
                </div>
              )}
            </div>
          ))}
        </div> */}
        <div className="section10-mobile-usecase-section">
          <div className="section10-subsection">
            <div className="section10-mobile-subheader_text">
              {liveAISalesRepAssistant.subheader}
            </div>
            <div className="section10-mobile-img-container">
              <img src={liveAISalesRepAssistant.imgSrc} alt="" />
            </div>
          </div>
          {liveAISalesRepAssistant.content.map((item, index) => (
            <div
              className="section10-mobile-usecase-item"
              key={`section10-mobile-usecase-item-${index}`}
            >
              <div className="section10-mobile-usecase-header">
                <div>
                  <div className="section10-mobile-number2 bold">
                    {index + 1}
                  </div>
                </div>
                <div className="section10-mobile-usecase-title">
                  {item.title}
                  <br />
                  {item.subtitle}
                </div>
                <img
                  src={DownArrow}
                  alt="Down Arrow"
                  className="section10-mobile-down"
                  onClick={() => toggleSection10P2(index)}
                />
              </div>
              {isSection10P2Open[index] && (
                <div className="section10-mobile-usecase-content">
                  {item.description}
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="section10-mobile-usecase-section">
          <div className="section10-subsection">
            <div className="section10-mobile-subheader_text">
              {valueProposition.subheader}
            </div>
            <div className="section10-mobile-img-container">
              <img src={valueProposition.imgSrc} alt="" />
            </div>
          </div>
          {valueProposition.content.map((item, index) => (
            <div
              className="section10-mobile-usecase-item"
              key={`section10-mobile-usecase-item-${index}`}
            >
              <div className="section10-mobile-usecase-header">
                <div>
                  <div className="section10-mobile-number1 bold">
                    {index + 1}
                  </div>
                </div>
                <div className="section10-mobile-usecase-title">
                  {item.title}
                  <br />
                  {item.subtitle}
                </div>
                <img
                  src={DownArrow}
                  alt="Down Arrow"
                  className="section10-mobile-down"
                  onClick={() => toggleSection10P3(index)}
                />
              </div>
              {isSection10P3Open[index] && (
                <div className="section10-mobile-usecase-content">
                  {item.description}
                </div>
              )}
            </div>
          ))}
        </div>

        {/* Section 11 */}
        <div className="section11">
          <div className="section11-subheader_text">
            {Efficiencies.subheader}
          </div>
          <div className="section11-subheader_intro">
            {Efficiencies.introduction}
          </div>
          <div className="section11-content">
            <div className="section11-grid">
              {Efficiencies.content.map((item, index) => (
                <div
                  key={`section11-grid-item-${index}`}
                  className="section11-grid-item"
                >
                  <div className="section11-sub_content">
                    <div className="section11-title">
                      <img src={item.imgSrc.check} alt="" />
                      <div className="section11-sub_title_text">
                        {item.title}
                      </div>
                    </div>
                    <div className="section11-sub_title_desc">
                      {item.description}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Section 11 Mobile */}
          <div className="section11-mobile-usecase-section">
            {Efficiencies.content.map((item, index) => (
              <div
                className="section11-mobile-usecase-item"
                key={`section11-mobile-usecase-item-${index}`}
              >
                <div className="section11-mobile-usecase-header">
                  <div className="section11-mobile-usecase-title">
                    {item.title}
                  </div>
                  <button
                    className="section11-mobile-usecase-button"
                    onClick={() => toggleSection11(index)}
                  >
                    {isSection11Open[index] ? <RemoveIcon /> : <AddIcon />}
                  </button>
                </div>
                {isSection11Open[index] && (
                  <div className="section11-mobile-usecase-content">
                    {item.description}
                  </div>
                )}
                <div className="section11-mobile-divider_black"></div>
              </div>
            ))}
          </div>

          <div className="section11-text">{Efficiencies.conclusion}</div>
        </div>

        {/* Section 12 */}
        <div className="section12">
          <div className="section12-subheader_text">
            {EmpoweringSalesReps.subheader}
          </div>
          <div className="section12-text">
            {EmpoweringSalesReps.introduction}
          </div>
          <div className="section12-content">
            <div className="section12-grid">
              {EmpoweringSalesReps.content.map((item, index) => (
                <React.Fragment key={`section12-grid-item-${index}`}>
                  {index % 2 === 0 ? (
                    <>
                      <div className="section12-grid-item">
                        <div className="section12-sub_title_text">
                          {item.subTitleText}
                        </div>
                        <div className="section12-sub_title_desc">
                          {item.text}
                        </div>
                      </div>
                      <div className="section12-grid-item empty"></div>
                    </>
                  ) : (
                    <>
                      <div className="section12-grid-item empty"></div>
                      <div className="section12-grid-item">
                        <div className="section12-sub_title_text">
                          {item.subTitleText}
                        </div>
                        <div className="section12-sub_title_desc">
                          {item.text}
                        </div>
                      </div>
                    </>
                  )}
                </React.Fragment>
              ))}
            </div>
            <div className="section12-text">
              {EmpoweringSalesReps.conclusion}
            </div>
          </div>
          <div className="section12-mobile-content">
            {EmpoweringSalesReps.content.map((item, index) => (
              <div
                className="section12-mobile-usecase-item"
                key={`section12-mobile-usecase-item-${index}`}
              >
                <div className="section12-mobile-usecase-header">
                  <div></div>
                  <img
                    src={item.icon}
                    alt="img"
                    className="section9-mobile-titleIcon"
                  />
                  <div className="section12-mobile-usecase-title">
                    {item.subTitleText}
                  </div>
                  <img
                    src={DownArrow}
                    alt="Down Arrow"
                    className="section12-mobile-down"
                    onClick={() => toggleSection10P2(index)}
                  />
                </div>
                {isSection10P2Open[index] && (
                  <div className="section12-mobile-usecase-content">
                    {item.text}
                  </div>
                )}
              </div>
            ))}
            <div className="section12-text">
              {EmpoweringSalesReps.conclusion}
            </div>
          </div>
        </div>

        {/* Section 13 */}
        <div className="section13">
          {FlowTurboExperience.content.map((item, index) => (
            <div
              className="section13-sub_section"
              key={`section13-sub_section-${index}`}
            >
              <div className="section13-subheader_text">{item.title}</div>
              <div className="section13-sub_title_text">{item.description}</div>
            </div>
          ))}
          <div className="section13-content">
            {FlowTurboExperience.benefits.map((benefit, index) => (
              <div
                className="section13-sub_content"
                key={`section13-sub_content-${index}`}
              >
                <div className="section13-icon">
                  <img src={benefit.icon} alt="" />
                </div>
                <div className="bold">{benefit.title}</div>
                <div>{benefit.description}</div>
              </div>
            ))}
          </div>
          <div className="section11-mobile-usecase-section">
            <div className="section13-mobile">
              <ResponsiveCarousel
                items={FlowTurboExperience.content}
                direction="right"
                speed="slow"
              />
            </div>
            {FlowTurboExperience.benefits.map((item, index) => (
              <div
                className="section11-mobile-usecase-item"
                key={`section11-mobile-usecase-item-${index}`}
              >
                <div className="section11-mobile-usecase-header">
                  <div className="section11-mobile-usecase-title">
                    {item.title}
                  </div>
                  <button
                    className="section11-mobile-usecase-button"
                    onClick={() => toggleSection11(index)}
                  >
                    {isSection11Open[index] ? <RemoveIcon /> : <AddIcon />}
                  </button>
                </div>
                {isSection11Open[index] && (
                  <div className="section11-mobile-usecase-content">
                    {item.description}
                  </div>
                )}
                <div className="section11-mobile-divider_black"></div>
              </div>
            ))}
          </div>
        </div>

        {/* Section 14 */}
        <div className="section14">
          <div className="section14-subheader_text">{WhyFlowTurbo.title}</div>
          {WhyFlowTurbo.benefits.map((benefit, index) => (
            <>
              <div
                className="section14-sub_section"
                key={`section14-sub_section-${benefit.number}`}
              >
                <div className="section14-header_text">{benefit.number}</div>
                <div className="section14-sub_title_text bold">
                  {benefit.title}
                </div>
                <div>{benefit.description}</div>
              </div>
              <div className="section12-mobile-content">
                <div
                  className="section12-mobile-usecase-item"
                  key={`section12-mobile-usecase-item-${index}`}
                >
                  <div className="section12-mobile-usecase-header">
                    <div>
                      <img
                        src={benefit.icon}
                        alt="img"
                        className="section14-mobile-titleIcon"
                      />
                    </div>
                    <div className="section12-mobile-usecase-title">
                      {benefit.title}
                    </div>
                    <img
                      src={DownArrow}
                      alt="Down Arrow"
                      className="section12-mobile-down"
                      onClick={() => toggleSection10P2(index)}
                    />
                  </div>
                  {isSection10P2Open[index] && (
                    <div className="section12-mobile-usecase-content">
                      {benefit.description}
                    </div>
                  )}
                </div>
              </div>
            </>
          ))}
          {WhyFlowTurbo.additionalTexts.map((text, index) => (
            <div className="section14-text" key={`section14-text-${index}`}>
              {text}
            </div>
          ))}
          <div className="section15-mobile">
            <ResponsiveCarouselLight
              items={KeyBenefits}
              direction="right"
              speed="slow"
            />
          </div>
        </div>

        {/* Section 15 */}
        <div className="section15">
          {KeyBenefits.map((benefit) => (
            <div
              className="section15-sub_section"
              key={`section15-sub_section-${benefit.number}`}
            >
              <div className="section15-title">
                <div>
                  <div className="section15-sub_title_text section15-number bold">
                    {benefit.number}.
                  </div>
                </div>
                <div className="section15-sub_title_text bold">
                  {benefit.title}
                </div>
              </div>
              <div>{benefit.description}</div>
            </div>
          ))}
        </div>
        <div className="section16">
          <div className="section16-text">{NeedForFlowTurbo.title}</div>
          {NeedForFlowTurbo.sections.map((section, index) => (
            <div
              key={`section16-subsection-${index}`}
              className="section16-subsection"
            >
              <div className="section16-subheader_text">
                {section.subheader}
              </div>
              <div className="section16-desc">{section.sectionText}</div>

              <div className="section16-grid">
                {section.subsections.map((subsection, subIndex) => (
                  <div
                    className="section16-grid-row"
                    key={`section16-grid_item-${subIndex}`}
                  >
                    {subIndex % 2 === 0 ? (
                      <>
                        <div className="section16-grid_item section16-left">
                          <div className="section16-sub_title_text">
                            {subsection.title}
                          </div>
                          <div>{subsection.description}</div>
                        </div>
                        <div className="section16-grid_item section16-right empty"></div>
                      </>
                    ) : (
                      <>
                        <div className="section16-grid_item section16-left empty"></div>
                        <div className="section16-grid_item section16-right">
                          <div className="section16-sub_title_text">
                            {subsection.title}
                          </div>
                          <div>{subsection.description}</div>
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </div>
              <div
                className="section16-mobile-usecase-item"
                key={`section16-mobile-usecase-item-${index}`}
              >
                {section.subsections.map((item, index) => (
                  <>
                    <Accordian
                      index={index}
                      title={item.title}
                      description={item.description}
                    />
                  </>
                ))}
              </div>
            </div>
          ))}
        </div>

        {/* Section 17 */}
        <div className="section17">
          <div className="section17-subheader_text">
            {ImagineNewSalesOrg.title}
          </div>
          {ImagineNewSalesOrg.description.map((item, index) => (
            <div key={`section17-text-${index}`} className="section17-text">
              {/* <div className="section17-subtitle">{item.subtitle}</div> */}
              <div className="section17-text_content">{item.text}</div>
            </div>
          ))}
        </div>

        {/* Section 18 */}
        <div className="section18">
          <div className="section18-text">{SimplicityInAction.title}</div>
          <div className="section18-subTitle-subText">
            {SimplicityInAction.subTitle_subText}
          </div>
          {SimplicityInAction.description.map((section, index) => (
            <div
              key={`section18-subsection-${index}`}
              className="section18-subsection"
            >
              <div className="section18-subheader_text">{section.subtitle}</div>
              <div className="section18-grid">
                {section.points.map((point, subIndex) => (
                  <div
                    className="section18-grid-row"
                    key={`section18-grid_item-${subIndex}`}
                  >
                    {subIndex % 2 === 0 ? (
                      <>
                        <div className="section18-grid_item section18-left">
                          <div className="section18-sub_title_text">
                            {point.title}
                          </div>
                          <div>{point.text}</div>
                        </div>
                        <div className="section18-grid_item section18-right empty"></div>
                      </>
                    ) : (
                      <>
                        <div className="section18-grid_item section18-left empty"></div>
                        <div className="section18-grid_item section18-right">
                          <div className="section18-sub_title_text">
                            {point.title}
                          </div>
                          <div>{point.text}</div>
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </div>
              <div
                className="section18-mobile-usecase-item"
                key={`section18-mobile-usecase-item-${index}`}
              >
                {section.points.map((item, index) => (
                  <>
                    <Accordian
                      index={index}
                      title={item.title}
                      description={item.text}
                    />
                  </>
                ))}
              </div>
            </div>
          ))}
        </div>

        {/* Section 19 */}
        <div className="section19">
          <div className="section19-subheader_text">
            {TheBottomLine.subheader}
          </div>
          <div className="section19-subtext">{TheBottomLine.subtext}</div>
          <div className="section19-grid">
            {TheBottomLine.section.map((item, index) => (
              <div
                className="section19-grid-item"
                key={`section19-grid-item-${index}`}
              >
                <div className="section19-sub_content">
                  <div className="section19-title">
                    <div className="section19-sub_title_text">{item.title}</div>
                  </div>
                  <div className="section19-sub_title_desc">
                    {item.description}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="section19-mobile">
            <ResponsiveCarousel
              items={TheBottomLine.section}
              direction="right"
              speed="slow"
            />
          </div>
        </div>

        {/* Section 20 */}
        <div className="section20">
          <div className="section20-subheader_text center">
            Unlock Your Sales Potential with Flow Turbo.
          </div>
          <div className="section20-subheader_text center">
            Transform Your Team. Multiply Your Success.
          </div>
          <Link className="section20-link" to="/contact/sales-support">
            <div className="section20-button">
              Schedule Your Free Product Tour
            </div>
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default UseCases;
