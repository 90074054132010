import React from 'react';
import { Link } from 'react-router-dom';
import './ApplicationSuccessPage.css'; // Assuming you have a CSS file for styling

const ApplicationSuccessPage = () => {
  return (
    <div className="success-page">
      <h1>Application Submitted Successfully!</h1>
      <p>Thank you for submitting your application. We appreciate your interest in joining our team.</p>
      <p>We will review your application and get back to you as soon as possible.</p>
      <p>If you have any questions or need further assistance, please feel free to <Link to="/contact">contact us</Link>.</p>
    </div>
  );
};

export default ApplicationSuccessPage;
