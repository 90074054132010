import React, { useState } from "react";
import axios from "axios";
import "../../assets/css/Home.css";
import TextField from "@mui/material/TextField";

import ".././account/account.css";
import Footer from "../../components/Footer";
import lines1 from "../../assets/img/home-line1.png";
import lines2 from "../../assets/img/home-line2.png";

// import flowBgImg from "../assets/img/flow-bg1.png";

import Icon0 from "../../assets/img/icon/icon0.png";
import Icon1 from "../../assets/img/icon/icon1.png";
import Icon2 from "../../assets/img/icon/icon2.png";

// import HomeBg1 from "../assets/img/home-bg1.png";
// import HomeBg2 from "../assets/img/home-bg2.png";
// import HomeBg3 from "../assets/img/home-bg3.png";
// import HomeBg4 from "../assets/img/home-bg4.png";
// import HomeBg5 from "../assets/img/home-bg5.png";

import JunkIcon from "../../assets/img/icon/junk.png";
import ContVerIcon from "../../assets/img/icon/cont-ver.png";
import ConflictIcon from "../../assets/img/icon/conflicts.png";
import WorkflowIcon from "../../assets/img/icon/workflow.png";
import IntegrationsIcon from "../../assets/img/icon/integration.png";
import DataColIcon from "../../assets/img/icon/data-coll.png";

import Benefit1 from "../../assets/img/benefit1.png";
import Benefit2 from "../../assets/img/benefit2.png";
import Benefit4 from "../../assets/img/benefit4.png";

import { Button } from "@mui/material";
// import { Button, TextField } from "@mui/material";

import { Link } from "react-router-dom";

const Home = () => {
  const [formData, setFormData] = useState({
    FirstName: "",
    LastName: "",
    JobTitle: "",
    Email: "",
    Phone: "",
    Company: "",
    Industry: "",
    SalesTeamSize: "",
    AnnualBudget: "",
    Message: "",
    form_type: "EarlyAccess", // Add formType to differentiate the forms
  });

  const freeEmailDomains = [
    "gmail.com",
    "yahoo.com",
    "hotmail.com",
    "outlook.com",
    "icloud.com",
    "aol.com",
    "protonmail.com",
    "zoho.com",
    "mail.com",
  ];

  const validateField = (name, value) => {
    let error = "";
    if (name === "Email" && value) {
      // Check for valid email structure
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
        error = "Please use a valid email address";
      }
      // Check if it's a free email domain
      else if (freeEmailDomains.includes(value.split("@")[1])) {
        error = "Please use a valid business email address";
      }
    } else if (value === "") {
      error = `${name} is required`;
    }

    return error;
  };

  const [submitted, setSubmitted] = useState(false);
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    const error = validateField(name, value);
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevState) => ({
      ...prevState,
      [name]: error,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}api/leads/`,
        formData
      );

      if (response.status === 201 || response.status === 200) {
        setSubmitted(true);
        setMessage("Details submitted successfully!");
      }
    } catch (error) {
      setMessage("An error occurred: " + error.message);
    }
  };
  return (
    <div className="home-page">
      <div className="header">
        <div id="heading">
          <h1 className="large3 bold hide2">
            <span>
              Transform Your Sales Day: Maximize Productivity, Meet Quotas,
              Generate Truly Qualified Opportunities, and Boost Commissions with
              Flow Turbo
            </span>
          </h1>
          <h4 className="normal medium hide2 delay1">
            Introducing Flow Turbo: The World's First SaaS AI Sales Prospecting
            Platform
          </h4>
        </div>
        <div className="orange-btn-container">
          <Link to="/contact/sales-support" className="orange-btn">
            Get Started Today
          </Link>
        </div>
        <div id="space-home"></div>
        <img
          src={lines1}
          alt=""
          className="fadeInAnimation"
          id="lines-bottom"
        />
        <img src={lines2} alt="" className="fadeInAnimation" id="lines-left" />

        <div className="section hide1 delay2" id="about-section">
          <div className="content max-content">
            <div className="right">
              {/* <img id="flow-bg" src={flowBgImg} alt="" /> */}
              <div className="flow-bg" />
            </div>
            <div id="about-description" className="text left small">
              <div className="title bold large">About</div>
              <p>
                Flow is a{" "}
                <span className="normal_text">
                  cutting-edge, high-tech enterprise AI company{" "}
                </span>
                leading the charge in transformative technologies. At the heart
                of our innovative offerings is a state-of-the-art SaaS AI sales
                prospecting platform, redefining the landscape of sales
                efficiency and productivity. Flow specializes in the development
                and implementation of advanced artificial intelligence
                solutions, we empower organizations to unlock unprecedented
                efficiency and productivity.. With a relentless commitment to
                pushing the boundaries of what's possible, Flow stands as a
                pioneer in the field, empowering enterprises to navigate the
                complexities of the modern business landscape with cutting-edge
                AI technology.
              </p>
            </div>
          </div>
        </div>

        <div className="section hide1 delay2" id="flow-turbo-section">
          <div className="title bold large max-content">
            What is Flow Turbo?
          </div>
          <div className="content max-content">
            <div className="left">
              <div className="title1 bold medium">
                The{" "}
                <span className="bold medium green">
                  NEW Flow Turbo SaaS AI sales prospecting platform
                </span>
              </div>
              <div className="text small">
                is designed to maximize the productivity day-to-day for sales
                reps. With real artificial intelligence living in its core
                foundation, Flow Turbo is ONE unified cohesive composable AI
                desktop super platform designed to turbocharge your sales team's
                efficiency and success, and to be the sole primary platform that
                sales reps utilize on a daily basis, eliminating the need to
                have 20-30 browser tabs and toolstacks having to be opened at
                the same time with chaotic, confusing, and messy screen
                switching, and back and forth interactions with different
                categories of sales tools and information.
                {/*Unleash the power of*/}
                {/*real semi-sentient AI intelligence to guide your sales team*/}
                {/*through every step of the prospecting phase of the sales cycle.*/}
                {/*Flow Turbo's meticulously created UI/UX design is architected to*/}
                {/*be a guided end-to-end AI platform, enabling sales reps to*/}
                {/*navigate their daily tasks with maximum success with*/}
                {/*Selling-as-a-Science built in to the design.*/}
              </div>
            </div>
            <div className="right">
              <div className="title1 bold medium">
                <br />
                <br />
              </div>
              <div className="text left small">
                From the transformative AI Account Selection and AI Flat Files
                capabilities that this almost real person AI has, the AI
                provides and delivers 300 net new AI pre-qualified contacts
                daily without automatic disqualification that are extrapolated
                from conflict-free accounts, to the Live AI Sales Rep Assistant
                eliminating manual and mundane, energy intensive, mentally
                strenuous tasks, the NEW Flow Turbo SaaS AI Sales Platform is
                designed and engineered to eliminate all impediments, blockers,
                barriers, and obstacles that slows sales reps down, and to
                accelerate your B2B sales organization to achieve demanding B2B
                sales quotas.
              </div>
            </div>
          </div>
        </div>

        <div className="section hide1 delay2" id="key-value-section">
          <div className="content">
            <div className="top">
              <div className="title bold large">Key Value Propositions</div>
              <div className="title1 bold large2">
                Flow Turbo SaaS AI Sales Platform: Elevating Sales Success
              </div>
              <div className="title1 medium2" style={{ width: "70vw" }}>
                At Flow, we are committed to revolutionizing the landscape of
                sales operations. The Flow Turbo SaaS AI Sales Platform is not
                just a brand new AI platform; it's a strategic ally designed to
                propel sales reps and B2B sales executives toward unprecedented
                success.
              </div>
            </div>

            <div className="bottom">
              <div className="card-container">
                <div className="card">
                  <div className="card-no bold medium">1</div>
                  <div className="title1 bold medium">Commission Booster</div>
                  <div className="text small">
                    Flow Turbo is engineered to be a commission accelerator. By
                    automating mundane tasks and providing valuable insights,
                    sales reps close more deals in less time, directly impacting
                    their commission earnings.
                  </div>
                </div>
                <div className="card">
                  <div className="card-no bold medium">2</div>
                  <div className="title1 bold medium">
                    Sales Quota Attainment
                  </div>
                  <div className="text small">
                    For B2B sales executives striving to meet sales quotas, Flow
                    Turbo offers a strategic advantage. The guided end-to-end AI
                    platform optimizes the sales process, ensuring that each
                    interaction aligns with overarching sales targets.
                  </div>
                </div>
                <div className="card">
                  <div className="card-no bold medium">3</div>
                  <div className="title1 bold medium">
                    Increased Opportunities
                  </div>
                  <div className="text small">
                    The platform's AI capabilities result in a surge of
                    qualified deals and opportunities for sales reps. By
                    automating AI account selection and qualification, Flow
                    Turbo provides a steady flow of high-potential prospects.
                  </div>
                </div>
                <div className="card">
                  <div className="card-no bold medium">4</div>
                  <div className="title1 bold medium">
                    Closed Deals Amplification
                  </div>
                  <div className="text small">
                    Closing deals is the ultimate goal, and Flow Turbo is
                    designed to enhance the efficacy of the closing process and
                    eliminates pipeline gaps that drain the sales organization
                    at the front-half of the sales cycle. The Live AI Sales Rep
                    Assistant ensures that every deal that's generated by sales
                    reps is well-processed and productive, increasing the
                    likelihood of closing deals.
                  </div>
                </div>
                <div className="card">
                  <div className="card-no bold medium">5</div>
                  <div className="title1 bold medium">
                    Elimination of Sales Rep Burn Out
                  </div>
                  <div className="text small">
                    Manual and mundane tasks can be a drain on a sales rep's
                    energy and time. Flow Turbo eliminates this sales rep burn
                    out by taking care of repetitive activities, allowing sales
                    reps to focus on high-value tasks and meaningful
                    interactions.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section hide1 delay2">
          <div className="content max-content">
            <div className="text left small">
              <div className="title bold large2">In essence,</div>
              Flow Turbo is not just a sales tool; it's a strategic ally that
              aligns with your sales objectives, propelling your team towards
              increased earnings, quota attainment, and overall sales success.
              Welcome to a future where Flow Turbo redefines the landscape of
              B2B sales.
            </div>
            <div className="right"></div>
          </div>
        </div>

        <div className="section hide1 delay2">
          <div className="content max-content">
            <div className="left"></div>
            <div className="text right small">
              <div className="title bold large2">
                As you embrace Flow Turbo,
              </div>
              you're not just adopting a brand new SaaS AI sales prospecting
              platform; you're ushering in a new era of sales excellence. With
              Flow Turbo, experience a future where innovation meets efficiency,
              and your sales team redefines success. Elevate your sales journey
              - schedule your free product tour right now of the new Flow Turbo
              SaaS AI sales prospecting platform today.
              <Link to="/contact/sales-support">
                <Button variant="contained" className="primary-btn">
                  Get It Now
                </Button>
              </Link>
            </div>
          </div>
        </div>

        <div className="section hide1 delay2" id="target-section">
          <div className="content">
            <div className="top">
              <div
                className="title bold large"
                style={{ width: "60vw", textAlign: "center", margin: "0 auto" }}
              >
                Reach your earnings targets and ideal prospect with Flow Turbo
              </div>

              <div className="bottom">
                <div className="card-container">
                  <div className="card">
                    <div className="card-icon bold medium">
                      <img src={Icon0} alt="" />
                    </div>
                    <div className="title1 bold medium">
                      Increased Efficiency
                    </div>
                    <div className="text small">
                      Eliminates wasted time for the sales reps from doing
                      mundane or manual activities; reclaiming half the entire
                      day back for sales reps.
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-icon bold medium">
                      <img src={Icon1} alt="" />
                    </div>
                    <div className="title1 bold medium">
                      Achieve Your Sales Quota
                    </div>
                    <div className="text small">
                      Allows sales reps to be able to accomplish a 200-300 call
                      day.
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-icon bold medium">
                      <img src={Icon2} alt="" />
                    </div>
                    <div className="title1 bold medium">
                      Unified Cohesive Composable Platform
                    </div>
                    <div className="text small">
                      Combines 5-6+ different disjointed sales tools on Flow
                      Turbo for elegance and cohesion. Eliminates the need to
                      have 10-30 browser tabs open at the same time.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section hide1 delay2" id="prod-section">
          {/* <div className="section" id="prod-section"> */}
          {/* <div className="title bold large max-content">
            Maximizing productivity day-to-day for sales reps
          </div> */}

          <h2 className="large" style={{ textAlign: "left" }}>
            Maximizing productivity day-to-day for sales reps
          </h2>

          {/* <div className="content">
            <div className="text left small">
              <div className="title bold large2">
                Guided End-to-End AI Sales Platform
              </div>
              <p>
                Welcome to the future of sales excellence. At Flow, we
                understand that exceptional sales performance isn't just an
                art—it's a science. Introducing our flagship feature, the Guided
                End-to-End AI Sales Platform, a cutting-edge innovation that
                transforms the natural talents of great salespeople into a
                repeatable, data-driven process for any salesperson. Our
                platform is meticulously designed to align with the fundamental
                principle of sales: the numbers game. We recognize that success
                in sales is deeply connected to the quantity and quality of
                interactions, and our UI/UX design reflects this understanding.
              </p>

              <p>
                The heart of this groundbreaking feature derives from its
                commitment to making the AI sales prospecting platform
                consistently effective. We've integrated a fundamental truth
                into our Flow Turbo product—more interactions lead to more
                success. To embody this, our AI ensures a daily ready-to-go pool
                of 300 AI pre-qualified prospects for sales reps to engage with,
                eliminating the need to spend valuable time hunting for accounts
                or weeding out potential accounts with automatic
                disqualifications.
              </p>

              <p>
                For sales reps, this means a streamlined workflow and the luxury
                of focusing efforts on high-potential leads. The Flow Turbo
                platform's intuitive design optimizes the sales process,
                encouraging a proactive and consistent approach. Flow Turbo is
                not just a new SaaS AI sales platform; it's a strategic
                companion in the sales journey, providing daily insights and
                increasing the probability of successful engagements.
              </p>

              <a href="/product" className="home-anchor">Find out More</a>
            </div>
            <div className="right img1">
            </div>
          </div> */}

          {/* <div className="content hide1 delay2" id='prod-section1'> */}
          <div className="content" id="prod-section1">
            <div className="left img2"></div>
            <div className="text right small">
              <div className="title bold large2">
                Live AI Sales Rep Assistant
              </div>
              <p>
                Introducing our groundbreaking Live AI Sales Rep Assistant, a
                revolutionary feature of the new Flow Turbo SaaS AI sales
                prospecting platform designed to transform the daily workflow of
                sales professionals. This cutting-edge AI is engineered to take
                charge of all manual and mundane tasks, eliminating the need for
                repetitive clicks and interactions that often consume valuable
                time. Our Live AI Sales Rep Assistant goes beyond conventional
                automation – it intuitively navigates through sales tools,
                streamlining processes, and delivering an unparalleled user
                experience. Say goodbye to tedious tasks and hello to
                productivity, as our AI empowers sales reps to concentrate on
                high-value activities, ensuring every interaction counts. It's
                time to redefine efficiency in sales with the seamless
                integration of AI technology. Welcome to a future where your
                sales team's potential is maximized, and every moment is
                dedicated to driving success.
              </p>

              <p>
                Imagine a seamless sales workflow where the AI takes the lead in
                handling all manual and mundane tasks that often consume a sales
                rep's valuable time. Our innovative assistant eliminates the
                need for repetitive clicks, interactions, and navigating through
                various sales tools, empowering your sales team to concentrate
                on what truly matters – building relationships and driving
                revenue. With the Live AI Sales Rep Assistant, experience a
                shift from routine tasks to value-added activities, giving your
                sales reps the time and energy to engage with prospects,
                generating qualified deals, and achieve unparalleled success in
                the world of sales.
              </p>

              <a href="/product" className="home-anchor">
                Find out More
              </a>
            </div>
          </div>

          <div className="content">
            <div className="text left small">
              <div className="title bold large2">
                AI Account Selection Feature
              </div>
              <p>
                Don't let sales tools dictate your time. The Flow Turbo AI
                Account Selection feature empowers your sales reps with 300 AI
                pre-qualified contacts extracted from AI qualified accounts,
                eliminating the need to sift through unproductive leads.
                Traditional tools waste your sales reps' valuable time—Flow
                Turbo streamlines this process, ensuring your team's efficiency.
              </p>

              <p>
                Bid farewell to the days of tool-juggling that waste your team's
                precious time. Sales tools, often touted as efficiency
                enhancers, paradoxically become time sinks, devouring half the
                day with manual and mundane tasks. The Flow Turbo AI Account
                Selection feature rewrites this narrative, effortlessly
                integrating into your workflow to identify not just any accounts
                and prospects, but the most promising ones. Say goodbye to the
                tedious prospecting account selection process—Flow's AI-driven
                precision ensures your sales reps focus on what truly matters:
                meaningful engagement and generating qualified deals and
                opportunities. Transform your sales process with Flow Turbo—a
                paradigm shift in account selection that redefines productivity.
              </p>

              <a href="/product" className="home-anchor">
                Find out More
              </a>
            </div>
            <div className="right img3"></div>
          </div>

          <div className="content">
            <div className="left img4"></div>
            <div className="text right small">
              <div className="title bold large2">AI Flat Files Feature</div>
              <p>
                Empower your sales reps with Flow Turbo's AI Flat Files Feature,
                your ticket to liberating sales reps from the arduous task of
                manually sifting through vast spreadsheets. Eliminate the need
                for exhaustive research and analysis when dealing with marketing
                generated lists, lead source database exports, CRM report
                exports, and more. Our sophisticated Gray Matter AI Engine uses
                advanced algorithms to automate the process of identifying and
                eliminating junk accounts and contacts with automatic
                disqualifications. Boost productivity, streamline workflows, and
                ensure your sales team focuses on what truly matters—building
                meaningful connections and generating qualified deals and
                opportunities. Embrace efficiency and elevate your data-driven
                decision-making with Flow's AI Flat Files—where data complexity
                meets intelligent simplicity.
              </p>

              <a href="/product" className="home-anchor">
                Find out More
              </a>
            </div>
          </div>

          <div className="content">
            <div className="text left small">
              <div className="title bold large2">
                Unified Access Integrations and Integrations Pop-Ups
              </div>
              <p>
                Experience the future of streamlined sales prospecting with Flow
                Turbo's innovative Unified Access Integrations and Integrations
                Pop-ups feature. Our SaaS AI sales platform is meticulously
                crafted to eliminate the hassles of constant manual actions and
                the chaos of managing multiple browser tabs simultaneously. By
                consolidating diverse categories of sales tools into one
                cohesive and composable platform, Flow Turbo ensures a seamless
                and efficient workflow. Say goodbye to screen-switching fatigue
                and the clutter of numerous tabs—Flow Turbo's heavy integrations
                transform your sales toolstack into a unified powerhouse,
                offering unparalleled convenience and productivity for your
                prospecting endeavors. The Flow Turbo platform consolidates
                diverse sales tools into one seamlessly integrated, cohesive,
                and composable platform, significantly enhancing workflow
                efficiency.
              </p>

              <a href="/product" className="home-anchor">
                Find out More
              </a>
            </div>
            <div className="right img5"></div>
          </div>
        </div>

        {/* <div className="section" id="open-platform-section">
          <div className="content">
            <div className="top">
              <div className="title bold large">Open Platform</div>
              <div className="title1 medium">
                We believe in relationships over the long-term with our
                customers and partners
              </div>

              <Button variant="outlined" className="tirtiary-btn">
                Explore Integrations
              </Button>
            </div>
          </div>
        </div> */}

        <div className="section hide1 delay2" id="laser-target-section">
          <div className="content">
            <div className="top">
              <div className="title bold large centered centerText">
                Laser target your next best prospects with all the right tools
              </div>
            </div>
            <div className="bottom">
              <div className="card-container">
                <div className="card-row">
                  <div className="card">
                    <div className="card-icon">
                      <img src={JunkIcon} alt="" />
                    </div>
                    <div className="title1 bold text">No Junk Data</div>
                  </div>

                  <div className="card">
                    <div className="card-icon">
                      <img src={ContVerIcon} alt="" />
                    </div>
                    <div className="title1 bold text">Contact Verification</div>
                  </div>

                  <div className="card">
                    <div className="card-icon">
                      <img src={ConflictIcon} alt="" />
                    </div>
                    <div className="title1 bold text">Check for conflicts</div>
                  </div>
                </div>

                <div className="card-row">
                  <div className="card">
                    <div className="card-icon">
                      <img src={WorkflowIcon} alt="" />
                    </div>
                    <div className="title1 bold text">Automatic Workflow</div>
                  </div>

                  <div className="card">
                    <div className="card-icon">
                      <img src={IntegrationsIcon} alt="" />
                    </div>
                    <div className="title1 bold text">Heavy Integrations</div>
                  </div>

                  <div className="card">
                    <div className="card-icon">
                      <img src={DataColIcon} alt="" />
                    </div>
                    <div className="title1 bold text">Data Collection</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section hide1 delay2" id="benefits-section">
          <div className="content">
            <div className="top">
              <div className="title bold large">More Great Benefits</div>
            </div>
            <div className="bottom">
              <div className="card-container">
                <div className="card">
                  <div className="left">
                    <img
                      src={Benefit1}
                      alt=""
                      style={{ width: "16vw", marginTop: "2vw" }}
                    />
                  </div>
                  <div className="right">
                    <div className="title1 bold medium">
                      Improved Sales Performance
                    </div>
                    <div className="text small">
                      <ul>
                        <li>Achieving sales quotas</li>
                        <li>
                          200% increase in sales rep speed and productivity
                        </li>
                        <li>
                          100% increase in probability of finding interested and
                          qualified prospects
                        </li>
                        <li>70% more closed deals</li>
                        <li>70% increase in revenue for the organization</li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="left">
                    <img
                      src={Benefit2}
                      alt=""
                      style={{ width: "18vw", marginTop: "2vw" }}
                    />
                  </div>
                  <div className="right">
                    <div className="title1 bold medium">
                      Enhanced Efficiency and Productivity
                    </div>
                    <div className="text small">
                      <ul>
                        <li>50% less wasted time during an 8-hour workday</li>
                        <li>
                          50% less resources needed for marketing and sales
                          enablement and 2x cost savings
                        </li>
                        <li>
                          1,040+ hours of unlocked sales organization
                          productivity per sales rep annually
                        </li>
                        <li>
                          Eliminates bottlenecks and time-wasted activities,
                          faster workflow on a daily basis, less back and forth
                          interactions with tech stack and devices
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="card" style={{ marginBottom: "2vw" }}>
                  <div className="left">
                    <img
                      src={Benefit4}
                      alt=""
                      style={{ width: "16vw", marginTop: "2vw" }}
                    />
                  </div>
                  <div className="right">
                    <div className="title1 bold medium">
                      Reduced Burnout and Improved Quality of Work Life{" "}
                    </div>
                    <div className="text small">
                      <ul>
                        <li>Achieving sales quotas</li>
                        <li>
                          200% increase in sales rep speed and productivity
                        </li>
                        <li>
                          100% increase in probability of finding interested and
                          qualified prospects
                        </li>
                        <li>70% more closed deals</li>
                        <li>70% increase in revenue for the organization</li>
                      </ul>
                    </div>
                  </div>
                </div>

                <Link to="/product" className="home_btn1">
                  <Button variant="contained" className="primary-btn">
                    See Products
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="section hide1 delay2">
          <div className="content max-content">
            <div className="text left small">
              <div className="title bold large">Starting Is a Breeze</div>
              <p>
                Embark on a journey of streamlined success with our
                revolutionary Flow Turbo SaaS AI sales prospecting platform.
                Getting started is as easy as it gets—our user-friendly
                interface ensures a seamless onboarding process. Say goodbye to
                complexity, as our platform simplifies every step, enabling you
                to kickstart your sales strategy effortlessly. The cost of
                entry? Minimal. We believe in providing unparalleled value
                without breaking company budgets. Join us in transforming your
                sales approach, where simplicity meets efficiency, making your
                journey to success both straightforward and cost-effective.
                Discover the power of simplicity—start your Flow Turbo
                experience today.
              </p>
            </div>
            <div className="right img1"></div>
          </div>

          <div className="content max-content">
            <div className="left img2"></div>
            <div className="text right small">
              <div className="title bold large">Seize The Opportunity</div>
              <p>
                Act now and seize the opportunity to revolutionize your sales
                strategy with Flow Turbo’s Early Access Program. Our special
                promo pricing is exclusively available to the first 100 special
                access accounts. Don’t miss out on being among the pioneers to
                experience the game-changing benefits of Flow Turbo SaaS AI
                sales prospecting. Join the elite few who will enjoy exclusive
                pricing—time is of the essence. Secure your spot in the future
                of sales effectiveness and start your journey with Flow Turbo
                today.
              </p>
            </div>
          </div>
        </div>

        <div className="section" id="contact-section">
          <div className="title bold large">
            Sign up for the Flow Turbo Early <br /> Access Program
          </div>
          <form
            className="contact-container account-container max-content"
            onSubmit={handleSubmit}
          >
            <div className="form-fields">
              <TextField
                InputLabelProps={{ shrink: true }}
                sx={fieldStyle}
                size="small"
                autoComplete="off"
                placeholder="First Name*"
                name="FirstName"
                value={formData.FirstName}
                onChange={handleChange}
              />
              <TextField
                InputLabelProps={{ shrink: true }}
                sx={fieldStyle}
                size="small"
                autoComplete="off"
                placeholder="Last Name*"
                name="LastName"
                value={formData.LastName}
                onChange={handleChange}
              />
              <TextField
                InputLabelProps={{ shrink: true }}
                sx={fieldStyle}
                size="small"
                autoComplete="off"
                placeholder="Job Title*"
                name="JobTitle"
                value={formData.JobTitle}
                onChange={handleChange}
              />
              <TextField
                InputLabelProps={{ shrink: true }}
                sx={fieldStyle}
                size="small"
                autoComplete="off"
                placeholder="Work Email*"
                name="Email"
                value={formData.Email}
                onChange={handleChange}
              />
              {errors.Email && (
                <div className="error-validation">{errors.Email}</div>
              )}{" "}
              {/* Display error */}
              <TextField
                InputLabelProps={{ shrink: true }}
                sx={fieldStyle}
                size="small"
                autoComplete="off"
                placeholder="Work Phone*"
                name="Phone"
                value={formData.Phone}
                onChange={handleChange}
              />
              <TextField
                InputLabelProps={{ shrink: true }}
                sx={fieldStyle}
                size="small"
                autoComplete="off"
                placeholder="Company*"
                name="Company"
                value={formData.Company}
                onChange={handleChange}
              />
              <TextField
                InputLabelProps={{ shrink: true }}
                sx={fieldStyle}
                size="small"
                autoComplete="off"
                placeholder="Industry*"
                name="Industry"
                value={formData.Industry}
                onChange={handleChange}
              />
              <TextField
                InputLabelProps={{ shrink: true }}
                sx={fieldStyle}
                size="small"
                autoComplete="off"
                placeholder="Sales Team Size*"
                name="SalesTeamSize"
                value={formData.SalesTeamSize}
                onChange={handleChange}
              />
              <TextField
                InputLabelProps={{ shrink: true }}
                sx={fieldStyle}
                size="small"
                autoComplete="off"
                placeholder="Annual Budget*"
                name="AnnualBudget"
                value={formData.AnnualBudget}
                onChange={handleChange}
              />
              <TextField
                InputLabelProps={{ shrink: true }}
                sx={{ ...fieldStyle, height: "100px" }}
                size="small"
                multiline
                rows={4}
                autoComplete="off"
                placeholder="Message*"
                name="Message"
                value={formData.Message}
                onChange={handleChange}
              />
              <button
                type="submit"
                className="account-button account-button_submit"
              >
                <p>Submit</p>
              </button>
              {submitted && <div className="responseMessage">{message}</div>}
            </div>
          </form>
        </div>
      </div>

      <Footer />
    </div>
  );
};

const fieldStyle = {
  width: "100%",
  height: "40px",
  backgroundColor: "white",
  borderRadius: "5px",
  "& fieldset": { border: "none" },
};

export default Home;
