import "./BillingManagementStyles.css";

function BillingAddress() {
  const addresses = [];
  return (
    <>
      <h2 className="font-light text-lg mb-5">Admin/company-organization</h2>
      <h1 className="font-extrabold text-4xl mb-8">Addresses</h1>
      <h2 className="font-light text-lg mb-10">
        View or edit you billing addresses and sold-to address.
      </h2>
      <h3 className="font-bold text-[1.2rem] mb-5">Billing Address</h3>
      <p className="font-light text-lg mb-10">
        This address appears on your invoice. It could be your company address
        or your billing department's address
      </p>
      <div className="address-container">
        {addresses.length > 0 ? (
          <div></div>
        ) : (
          <div className=" p-10 flex flex-col justify-center items-center">
            <h3 className="font-bold text-[1.2rem] mb-5">
              Billing address information not available
            </h3>
            <p className="font-light text-lg mb-8">
              Billing details address may be needed for tax purposes. Please
              provide it.
            </p>
            <button>Add billing details</button>
          </div>
        )}
      </div>
    </>
  );
}

export default BillingAddress;
