import React, { useEffect, useState } from "react";
import api from "./ghostAPI";
import PostPreview from "./PostPreview";
import { Helmet } from "react-helmet-async";

export default function BlogPost({ pageTitle }) {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    api.posts
      .browse({
        limit: "all",
        include: "tags,authors",
        formats: "plaintext",
      })
      .then((posts) => {
        setPosts(posts);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <div className="align-items-center">
      {posts.map((post, index) => {
        const isCurrentPostPage = window.location.pathname.includes(post.slug);

        return (
          <div key={post.id} className="md:flex md:justify-content-center">
            <Helmet>
              <title>{isCurrentPostPage ? post.title : pageTitle}</title>
              <meta name="description" content={post.excerpt || ""} />
              <meta
                property="og:title"
                content={isCurrentPostPage ? post.title : pageTitle}
              />
              <meta property="og:description" content={post.excerpt || ""} />
              <meta property="og:image" content={post.feature_image || ""} />
              <meta
                property="og:url"
                content={`https://www.flowai.tech/blog/${
                  isCurrentPostPage ? post.slug : ""
                }`}
              />
              <meta name="twitter:card" content="summary_large_image" />
              <meta
                name="twitter:title"
                content={isCurrentPostPage ? post.title : pageTitle}
              />
              <meta name="twitter:description" content={post.excerpt || ""} />
              <meta name="twitter:image" content={post.feature_image || ""} />
            </Helmet>

            {/*Post Preview component is called*/}
            <PostPreview post={post} idValue={index} />
          </div>
        );
      })}
    </div>
  );
}
