import React from "react";
import DesktopLandingPage from "./LandingPageDesktop.js";
import MobileLandingPage from "./LandingPageMobile.js";

const LandingPage = () => {
    if (window.innerWidth < 700) {
        return <MobileLandingPage />;
    } else {
        return <DesktopLandingPage />;
    }
}

export default LandingPage

