import React from 'react'
import { useRef } from 'react';
import { useInView,motion } from 'framer-motion';



const ImageRevealAnimation = ({children}) => {
    const ref = useRef(null);
  
    // Detect when the element is in view
    const isInView = useInView(ref, { once: true });
  
    return (
      <div
        ref={ref}
       
      >
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{
            opacity: isInView ? 1 : 0,
            scale: isInView ? 1 : 0.8,
            transition: { duration: 0.8, ease: "easeIn" },
          }}
          className="rounded-lg"
        >
          {children}
        </motion.div>
      </div>
    );
  };
  
export default ImageRevealAnimation