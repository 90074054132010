const account_selection_features =[
    {
      "title": "Streamlined Efficiency",
      "description": "By leveraging sophisticated AI algorithms, Flow Turbo automates the account selection process. No more wasting precious hours sifting through countless accounts and prospects—our AI does the heavy lifting for you."
    },
    {
      "title": "Precision Personified",
      "description": "The AI Account Selection feature ensures that your sales team is presented with accounts and contacts that align precisely with your business objectives like your particular industry, and the particular product and service that you're selling. Say goodbye to guesswork; say hello to precision prospecting."
    },
    {
      "title": "Unleash More Sales Time",
      "description": "Imagine what your sales reps could achieve with the thousands of hours per year reclaimed from the prospecting grind. Flow Turbo empowers your team to invest more time in meaningful interactions, relationship-building, and ultimately, generating more qualified deals and opportunities for the closing team."
    }
  ];

  export default account_selection_features;
  
