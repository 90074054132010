import React from 'react';
import './jobCard.css';

const JobCard = ({ statusIcon, secondaryIcon, title, subtitle, linkText, linkUrl }) => {
  return (
    <div className="job-card">
      {/* Wrapper for Icons, Title, and Subtitle */}
      <div className="job-content-wrapper">
        {/* Icons Section */}
     
          <img src={statusIcon} alt="Status Icon" className="status-icon" />
          <img src={secondaryIcon} alt="Secondary Icon" className="secondary-icon" />
        

        {/* Title and Subtitle Container */}
       
          <div className="job-title" style={{fontSize: "22px" , paddingLeft: "12px", marginTop: "5px", margin:"0"}}>{title}</div>
          <p className="job-subtitle" style={{margin: "0", paddingBottom:"30px"}}>{subtitle}</p>
        
      </div>

      {/* View Button */}
      <div className="job-link" style={{marginTop: "10px"}}>
        <a href={linkUrl} className="view-button">{linkText}</a>
      </div>
    </div>
  );
};

export default JobCard;
