const takeaways_selection_features = [
    {
        "description": "Numbers Game Mastery: Learn why sales is a numbers game and how to cast your rod for a greater catch."
    },
    {
        "description": "Strategic Eliminations: Stop wasting time on the wrong prospects. Focus on those ready to buy."
    },
    {
        "description": "Pipeline Optimization: Master the art of managing a steady flow of quality leads."
    },
    {
        "description": "Exclusive Early Access: Be once of the first 100 accounts to access this game-changing ebook. Early Access Accounts enjoy a limited-time promo offer."
    },
    {
        "description": "Elevate Your Sales: Our expert consultants await you for a complimentary strategy session. Unleash the potential of your sales team with the Flow Turbo SaaS AI sales platform."
    }
];

export default takeaways_selection_features;
