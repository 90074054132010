import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import TinyMCEEditor from './TinyMCEEditor'; // Ensure this path is correct
import './UpdateJobForm.css';

const JobForm = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [department, setDepartment] = useState('');
  const [location, setLocation] = useState('');
  const [closeDate, setCloseDate] = useState('');
  const [keywords, setKeywords] = useState(''); // New state for Keywords
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const API_BASE_URL = process.env.REACT_APP_API;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      const token = localStorage.getItem('accessToken');
      const user = JSON.parse(localStorage.getItem('user'));
      await axios.post(
        `${API_BASE_URL}api/hr/jobs/`,
        {
          Title: title,
          Description: description,
          Department: department,
          Location: location,
          CloseDate: closeDate,
          Keywords: keywords, // Sending keywords to the database
          HRID: user.HRID, // Assuming user.HRID exists in your stored user data
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      navigate('/internal-hr-joblist');
    } catch (error) {
      if (error.response && error.response.data) {
        console.error('Error creating job:', error.response.data);
        setError(error.response.data);
      } else {
        console.error('Error creating job:', error);
        setError('Failed to create job. Please try again.');
      }
    }
  };

  const renderError = () => {
    if (!error) return null;

    if (typeof error === 'string') {
      return <p className="error-message">{error}</p>;
    }

    return (
      <ul className="error-message">
        {Object.keys(error).map((key, index) => (
          <li key={index}>{`${key}: ${error[key].join(', ')}`}</li>
        ))}
      </ul>
    );
  };

  return (
    <div className="update-job-container">
      <h2 className='update-create-job-title'>Create Job</h2>
      {renderError()}
      <form onSubmit={handleSubmit} className="update-job-form">
        <div>
          <label>Title</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Description</label>
          <TinyMCEEditor value={description} onChange={setDescription} />
        </div>
        <div>
          <label>Keywords</label> {/* New Keywords input field */}
          <input
            type="text"
            value={keywords}
            onChange={(e) => setKeywords(e.target.value)}
            placeholder="Enter keywords for the job"
          />
        </div>
        <div>
          <label>Department</label>
          <select
            value={department}
            onChange={(e) => setDepartment(e.target.value)}
            required
          >
            <option value="" disabled>Select department</option>
            <option value="Engineering">Engineering</option>
            <option value="Design">Design</option>
            <option value="Marketing">Marketing</option>
            <option value="Finance and Accounting">Finance and Accounting</option>
          </select>
        </div>
        <div>
          <label>Location</label>
          <input
            type="text"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Close Date</label>
          <input
            type="date"
            value={closeDate}
            onChange={(e) => setCloseDate(e.target.value)}
            required
          />
        </div>
        
        <button type="submit">Create Job</button>
      </form>
    </div>
  );
};

export default JobForm;