import React, { useEffect, useState } from 'react'
import GenericCardWhiteBG from './GenericCardWhiteBG'
import why_turbo_content from '../../Content/Product-Page/WhyTurboContent'
import product_img from '../../../assets/img/app_laptop.png'

const ProductTourComponent = () => {

    const [productTourCardsList,setProductTourCardsList] = useState(null);

    useEffect(() => 
    {
       setProductTourCardsList(why_turbo_content.map((content)=>
            <GenericCardWhiteBG>
                <div>
                    <p className='text-base font-bold'>{content.title}</p>
                    <p className='font-work-sans text-base font-medium'>{content.description}</p>
                </div>
            </GenericCardWhiteBG>
        )); 
    }, []);

    return (
        <div className='bg-black py-10 px-4'>
            <h1 className='font-neue-haas font-bold text-white text-2xl'>Experience the Future of Sales Prospecting Today</h1>
            <h3 className='font-neue-haas font-bold text-[#01f7d3] text-2xl mt-1.5'>Limited-Time Early Access: Secure Your Launch Pricing Now!</h3>

            <p className='font-inter text-lg text-white font-normal mt-1.5'>Exciting News! Flow Turbo’s Early Access Program is here with exclusive pricing, but act fast – it’s limited to the first 100 accounts!</p>
            <div className="flex overflow-x-scroll space-x-4 py-4">
                <div className="flex space-x-4">
                    {productTourCardsList && productTourCardsList}
                </div>
            </div>

            <div className='mt-5'>
                <h2 className='font-neue-haas font-bold text-2xl text-white mb-5'>Ready to Get Started?</h2>
                <p className='font-work-sans font-medium text-base text-white'>Schedule your free product tour right now and witness firsthand how Flow Turbo, the world’s first SaaS AI sales prospecting platform, can transform your sales process. Unlock the potential of AI and achieve unparalleled success in your sales efforts. It’s time to elevate your prospecting game with Flow Turbo.</p>
                <div className='flex justify-center my-4'>
                    <button className='text-black rounded-xl w-[337px] h-[47px] bg-[#01f7d3] font-neue-haas font-bold text-[18px] justify-center'>Schedule A Product Tour</button>
                </div>

                <img src={product_img} alt="macbook_product_image" />
               
            </div>

        </div>
    )
}

export default ProductTourComponent