import React, { useState } from "react";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import "./UseCase.css";

const Accordian = ({index, title, description}) => {

  const [isSectionOpen, setIsSectionOpen] = useState({});
  const toggleSection = (index) => {
    setIsSectionOpen((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  return (
    <>
      <div className="section11-mobile-usecase-header">
        <div className="section11-mobile-usecase-title">{title}</div>
        <button
          className="section11-mobile-usecase-button"
          onClick={() => toggleSection(index)}
        >
          {isSectionOpen[index] ? <RemoveIcon /> : <AddIcon />}
        </button>
      </div>
      {isSectionOpen[index] && (
        <div className="section11-mobile-usecase-content">
          {description}
        </div>
      )}
      <div className="section11-mobile-divider_black"></div>
    </>
  );
};

export default Accordian;
