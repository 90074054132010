import React from 'react'

const GainsSelectionCard = ({ cardID, title, description }) => {
    return (
        <div className="bg-black text-white rounded-2xl py-5 px-8 w-80 h-56 font-sans overflow-hidden text-base">
            <div className="flex items-center mb-4">
                <div className="bg-orange-600 text-black rounded-full w-8 h-8 flex items-center justify-center text-xl font-bold">
                    {cardID}
                </div>
            </div>
            <h2 className="text-xl mb-4">{title}</h2>
            <p>
                {description}
            </p>
        </div>)
}

export default GainsSelectionCard
