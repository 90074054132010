import React from 'react';
import Footer from '../components/Footer';
import company_overview_img from '../assets/img/company-overview-img.png';
//import img1 from '../assets/img/img_aboutus.png';
import img2 from '../assets/img/img_aboutus2.png';
import accolades_img from '../assets/img/accolades-img.png';
//import img3 from '../assets/img/img_aboutus3.png';
import about_us_bg from '../assets/img/about-us-bg.png';
import '../assets/css/AboutUs.css';
import {useState, useEffect,useRef} from 'react';
import CollapsibleBox from '../components/collapsibleBox/collapsibleBox';
import CoreValueCard from '../components/CoreValueCard/coreValueCard.js';
import ReadMoreCard from '../components/ReadMoreCard/readMoreCard';
import ai from '../assets/icons/AboutUsMobileContent/AI.png';
import check from '../assets/icons/AboutUsMobileContent/check.png';
import continuous from '../assets/icons/AboutUsMobileContent/continuous.png';
import customer from '../assets/icons/AboutUsMobileContent/customer.png';
import human from '../assets/icons/AboutUsMobileContent/human.png';
import person from '../assets/icons/AboutUsMobileContent/person.png';
import scaleable from  '../assets/icons/AboutUsMobileContent/scaleable.png';
import stateofheart from '../assets/icons/AboutUsMobileContent/stateoftheart.png';
import transparent from '../assets/icons/AboutUsMobileContent/transparent.png';
import encrypted from '../assets/icons/AboutUsMobileContent/encrypted.png';
import JobCard from '../components/JobCard/jobCard';
import hiringButton  from '../assets/icons/AboutUsMobileContent/Group 627.png'


const AboutUs = () => {



  const [isMobile, setIsMobile] = useState(false);
  const cardRefs = useRef([]);
  const [currentPage, setCurrentPage] = useState(0);
  const cardsPerPage = 1; // Number of cards to display per page

  const coreValues = [
    {
      title: "Innovation:",
      description: "We are pioneers, pushing the boundaries of what's possible. Innovation is not just a goal; it's a way of life at Flow. We foster a culture where creativity thrives, where creative freedom and decision making is maximized, encouraging every team member to think beyond the conventional and explore the uncharted."
    },
    {
      title: "Competency:",
      description: "Our team comprises the best in the industry. Competency is not just a skill; it's our first expectation. We invest in continuous learning and development, ensuring that our team stays at the forefront of industry trends."
    },
    {
      title: "Collaboration:",
      description: "Greatness is achieved collectively. Collaboration is the cornerstone of our success. Within our organization and in partnerships, we value the synergy that arises when diverse minds come together. It's in this collective brilliance that we find the spark to illuminate the path forward."
    },
    {
      title: "Time Management:",
      description: "In a fast-paced world, time is our most valuable resource. We are meticulous in our time management, understanding that efficiency and productivity are key to achieving our ambitious goals."
    },
    {
      title: "Communication:",
      description: "Transparency and open dialogue define our communication philosophy. Whether within teams or with our valued partners and clients, clear and honest communication is paramount. It's the bedrock of trust, ensuring that everyone is on the same page and aligned with our shared vision."
    },
    {
      title: "Trust and Integrity:",
      description: "Trust is the foundation of every relationship. We cultivate trust through unwavering integrity. Our actions align with our words, fostering an environment where trust is not just earned but treasured."
    },
    {
      title: "Purpose:",
      description: "We believe in purpose-driven endeavors. Every line of code, every innovation, and every decision is guided by a deeper sense of purpose – to make a meaningful impact."
    },
    {
      title: "Teamwork:",
      description: "The synergy of a team is greater than the sum of its parts. We value and encourage teamwork, recognizing that the best solutions emerge through collective effort."
    },
    {
      title: "Focus on the Long-Term:",
      description: "We don't just plan for the next quarter; we envision decades ahead. Our focus on the long-term ensures that every decision, every strategy is crafted with sustainable success in mind."
    },
    {
      title: "Excellence:",
      description: "Excellence is not a goal; it's a standard. We strive for nothing short of excellence in every facet of our work."
    },
    {
      title: "Performance:",
      description: "We are results-oriented, driven by a commitment to achieving and exceeding goals. Our focus on performance is not just about meeting expectations; it's about surpassing them consistently over the long-term. Every project, every task is an opportunity to showcase our commitment to excellence."
    },
    {
      title: "Maximizing Shareholder Value:",
      description: "We are committed to delivering maximum value to our shareholders as the stewards of capital. Our actions are guided by the understanding that consistent performance is the key to maximizing shareholder value over the long term."
    },
    {
      title: "Execution:",
      description: "Promises kept, expectations exceeded. We prioritize execution that delights customers and builds lasting relationships. We believe in flawless execution. We trust our people to make the correct decisions and take ownership. Execution thrives in an environment of autonomy and accountability."
    },
    {
      title: "Leadership:",
      description: "Meet the strong and trustworthy leaders of Flow's executive leadership team."
    }
  ];
  
  useEffect(() => {
    const adjustCardHeights = () => {
      if (cardRefs.current.length > 0) {
        const heights = cardRefs.current.map(ref => ref?.offsetHeight || 0);
        const maxHeight = Math.max(...heights);
        cardRefs.current.forEach(ref => {
          if (ref) {
            ref.style.height = `${maxHeight}px`;
          }
        });
      }
    };

    adjustCardHeights();
    window.addEventListener('resize', adjustCardHeights);

    return () => window.removeEventListener('resize', adjustCardHeights);
  }, [coreValues.length]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call it initially to set the state based on the current window width

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const CollapsibleSection = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
      <div className="collapsible-section">
        <div className="collapsible-header" onClick={() => setIsOpen(!isOpen)}>
          <h1>{title}</h1>
          <span>{isOpen ? '-' : '+'}</span>
        </div>
        {isOpen && <div className="collapsible-content">{children}</div>}
      </div>
    );
  };

  const handleScroll = (e) => {
    const scrollLeft = e.target.scrollLeft;
    const width = e.target.offsetWidth;
    const newPage = Math.round(scrollLeft / width);
    setCurrentPage(newPage);
  };

  const totalPages = Math.ceil(coreValues.length / cardsPerPage) - 1;
  return (
    <>
      <div className="about-us fadeInAnimation">
        <div className="about-us_section-white">
          <div className="padding1">
            <div className="intro">
              <h1>Flow</h1>
              <img src={about_us_bg} alt="Team Collaboration" className="illustration" />
              <h2 className="intro-text subheader_text">
                Flow stands as a pioneering force in the realm of Enterprise AI,<br />
                dedicated to reshaping industries through cutting-edge<br />
                artificial intelligence solutions
              </h2>
            </div>
        <div className="overview">
        {isMobile ? (
          // Mobile Layout
          <>
            <h1>Company Overview:</h1>
            <div>
              <img src={company_overview_img} alt="Company Overview" className="img1" />
            </div>
            <div>
              <p>
                Flow operates in the information technology sector, specializing in the engineering and marketing of Enterprise AI solutions, catering to a diverse array of industries. Our proprietary technologies, including artificial intelligence innovations, leverage advanced neural networks and machine learning algorithms to deliver unparalleled efficiency, productivity, and strategic insight to our clients.
              </p>
            </div>
          </>
        ) : (
          // Desktop Layout
          <>
            <div>
              <h1>Company Overview:</h1>
              <p>
                Flow operates in the information technology sector, specializing in the engineering and marketing of Enterprise AI solutions, catering to a diverse array of industries. Our proprietary technologies, including artificial intelligence innovations, leverage advanced neural networks and machine learning algorithms to deliver unparalleled efficiency, productivity, and strategic insight to our clients. 
              </p>
            </div>
            <div>
              <img src={company_overview_img} alt="Company Overview" className="img1" />
            </div>
          </>
        )}
      </div>
            <div className="competencies">
              <h1>Core Competencies:</h1>
              <div style={{ paddingTop: '30px' }}>
                {isMobile ?(
                        <>
                        <CollapsibleBox
                          icon={ai} // Add the appropriate path to the icon
                          title="AI-Powered Solutions"
                        >
                          Our suite of AI solutions is engineered to optimize enterprise
                          operations, enhance decision-making processes, and foster
                          innovation across diverse sectors.
                        </CollapsibleBox>
                        <CollapsibleBox
                          icon={scaleable} // Add the appropriate path to the icon
                          title="Scalable Technologies"
                        >
                          Flow emphasizes scalability, allowing our clients, ranging from
                          startups to established enterprises, to seamlessly integrate and
                          expand AI applications tailored to their unique needs.
                        </CollapsibleBox>
                        <CollapsibleBox
                          icon={human} // Add the appropriate path to the icon
                          title="Human-Centric Design"
                        >
                          We believe in the synergy of human and artificial intelligence. Our
                          solutions are designed to empower human capabilities, providing
                          tools for more informed decision-making and strategic planning.
                        </CollapsibleBox>
                      </>
                ): (
                <div className="center2" style={{ columnGap: '120px' }}>
                  <div className="core-competency">
                    <div className="center">
                      <div className="core-competency_square center">
                        <h3>1</h3>
                      </div>
                    </div>
                    <h3 className='core-competency-text'>AI-Powered Solutions</h3>
                    <p className='core-competency-text'>Our suite of AI solutions is
                      engineered to optimize enterprise
                      operations, enhance decision-
                      making processes, and foster
                      innovation across diverse sectors.</p>
                  </div>
                  <div className="core-competency">
                    <div className="center">
                      <div className="core-competency_square center">
                        <h3>2</h3>
                      </div>
                    </div>
                    <h3 className='core-competency-text'>Scalable Technologies</h3>
                    <p className='core-competency-text'>Flow emphasizes scalability,
                      allowing our clients, ranging from
                      startups to established enterprises,
                      to seamlessly integrate and expand
                      AI applications tailored to their
                      unique needs.</p>
                  </div>
                  <div className="core-competency">
                    <div className="center">
                      <div className="core-competency_square center">
                        <h3>3</h3>
                      </div>
                    </div>
                    <h3 className='core-competency-text'>Human-Centric Design</h3>
                    <p className='core-competency-text'>We believe in the synergy of human
                      and artificial intelligence. Our
                      solutions are designed to empower
                      human capabilities, providing tools
                      for more informed decision-making
                      and strategic planning.</p>
                  </div>
                </div>
                )}
              </div>
            </div>
            <div className="overview2">
            <div
                className={isMobile ? "large2-mobile" : "large2"}
                style={
                  isMobile
                    ? {
                        fontWeight: 'bold',
                        width: '100%',
                        lineHeight: '1.2',
                        fontSize: '16px',
                        textAlign: 'left',
                        margin: '5px 0',
                      }
                    : {fontWeight: 'bold', width: "50%", lineHeight: "1"}
                }
              >
                A company that stands behind our products and our customers
              </div>
              <div   style={
                    isMobile
                      ? {
                          marginTop: '-70px', // Reduce top margin for mobile
                        }
                      : {}
                  }
                >
                As a distinguished leader in the AI industry, we take
                immense pride in not only pioneering cutting-edge
                solutions but also standing resolutely behind our
                products. At Flow, we transcend being merely a
                technology company; we are your devoted partners
                in progress, relentlessly dedicated to ensuring
                your success.
              </div>
            </div>
          </div>
        </div>

       
        <div className={isMobile ? "about-us_section-white" : "about-us_section-gray"}>
          <div className="block-container ">
          {isMobile ? (
              <>
                <CollapsibleSection title="Our Unyielding Commitment" className="left-aligned">
                  Embedded within the core of our philosophy is an
                  unwavering commitment to excellence. Beyond the
                  delivery of products, we fulfill promises. A cadre of long-
                  term thinkers and tech enthusiasts propels Flow, driven
                  by the pursuit of perfection. Every product carrying the
                  approval of Flow epitomizes quality, functionality, and innovation.
                </CollapsibleSection>

                <CollapsibleSection title="Pinnacle of Customer Satisfaction" className="left-aligned">
                  For us, customer satisfaction is not a mere metric; it
                  is the pulsating force driving us forward. We revel in
                  achieving and maintaining the zenith of customer
                  satisfaction levels. Your success is not just a metric
                  but an encapsulation of the impact our solutions
                  make on your journey.
                </CollapsibleSection>

                <CollapsibleSection title="Customer at the Center" className="left-aligned">
                  What truly distinguishes us is our singular focus on you —
                  our customer. Our journey extends far beyond the point
                  of sale; it marks the commencement of an enduring
                  partnership. Recognizing that technology evolves, we
                  offer not just products but solutions that evolve with you.
                  The customer service team at Flow operates with a
                  white-glove five diamond approach, ensuring you receive
                  the attention and support that transcend the ordinary.
                </CollapsibleSection>

                <CollapsibleSection title="A Commitment to the Long Term" className="left-aligned">
                  In the dynamic realm of technology, we stand as your
                  steadfast anchor over the long term. Beyond the
                  immediate, we are committed to supporting your
                  journey every step of the way. We understand that
                  success is a continuum, and as your strategic
                  partner, we embark on this journey with you. Your
                  goals and aspirations find unwavering support and
                  realization at every juncture.
                </CollapsibleSection>
              </>
            ) : (
              <>
                <div className="two-item-block">
                  <div>
                    <h1 className='med-line-height'>Our Unyielding Commitment</h1>
                    Embedded within the core of our philosophy is an
                    unwavering commitment to excellence. Beyond the
                    delivery of products, we fulfill promises. A cadre of long-
                    term thinkers and tech enthusiasts propels Flow, driven
                    by the pursuit of perfection. Every product carrying the
                    approval of Flow epitomizes quality, functionality, and innovation.
                  </div>
                  <div>
                    <h1 className='med-line-height'>Pinnacle of Customer Satisfaction</h1>
                    For us, customer satisfaction is not a mere metric; it
                    is the pulsating force driving us forward. We revel in
                    achieving and maintaining the zenith of customer
                    satisfaction levels. Your success is not just a metric
                    but an encapsulation of the impact our solutions
                    make on your journey.
                  </div>
                </div>
                <div className="two-item-block">
                  <div>
                    <h1 className='med-line-height'>Customer at the Center:</h1>
                    What truly distinguishes us is our singular focus on you —
                    our customer. Our journey extends far beyond the point
                    of sale; it marks the commencement of an enduring
                    partnership. Recognizing that technology evolves, we
                    offer not just products but solutions that evolve with you.
                    The customer service team at Flow operates with a
                    white-glove five diamond approach, ensuring you receive
                    the attention and support that transcend the ordinary.
                  </div>
                  <div>
                    <h1 className='med-line-height'>A Commitment to the Long Term</h1>
                    In the dynamic realm of technology, we stand as your
                    steadfast anchor over the long term. Beyond the
                    immediate, we are committed to supporting your
                    journey every step of the way. We understand that
                    success is a continuum, and as your strategic
                    partner, we embark on this journey with you. Your
                    goals and aspirations find unwavering support and
                    realization at every juncture.
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="about-us_section-white">
        <div className="block-container">
              <h1>Core values that define us</h1>
              {isMobile ? (
              // Mobile view: Display the CoreValueCard components in a list with pagination dots
              <>
              <div className="core-values-mobile-scroll" onScroll={handleScroll}>
                {coreValues.map((value, index) => (
                  <div
                    key={index}
                    className="core-value-card-wrapper"
                    ref={el => (cardRefs.current[index] = el)}
                    style={{ height: `${Math.max(...cardRefs.current.map(el => el ? el.offsetHeight : 0))}px` }}
                  >
                    <CoreValueCard
                      icon={check}
                      title={value.title}
                      description={value.description}
                    />
                  </div>
                ))}
              </div>
              <div className="pagination-dots">
                {Array.from({ length: totalPages }).map((_, index) => (
                  <span
                    key={index}
                    className={`dot ${index === currentPage ? 'active' : ''}`}
                  ></span>
                ))}
              </div>
            </>
          ) : (
            
                // Desktop view: Keep the existing two-item-block structure
                <>
                  <div className="two-item-block">
                    <div>
                      <h3><span>✓ </span>Innovation:</h3>
                      <p>We are pioneers, pushing the boundaries of what's
                      possible. Innovation is not just a goal; it's a way of life at
                      Flow. We foster a culture where creativity thrives, where
                      creative freedom and decision making is maximized,
                      encouraging every team member to think beyond the
                      conventional and explore the uncharted.</p>
                    </div>
                    <div>
                      <h3><span>✓ </span>Competency:</h3>
                      <p>Our team comprises the best in the industry.
                      Competency is not just a skill; it's our first
                      expectation. We invest in continuous learning and
                      development, ensuring that our team stays at the
                      forefront of industry trends.</p>
                    </div>
                  </div>
                  <div className="two-item-block">
                    <div>
                      <h3><span>✓ </span>Collaboration:</h3>
                      <p>Greatness is achieved collectively. Collaboration is the
                      cornerstone of our success. Within our organization and
                      in partnerships, we value the synergy that arises when
                      diverse minds come together. It's in this collective
                      brilliance that we find the spark to illuminate the path
                      forward.</p>
                    </div>
                    <div>
                      <h3><span>✓ </span>Time Management:</h3>
                      <p>In a fast-paced world, time is our most valuable
                      resource. We are meticulous in our time
                      management, understanding that efficiency and
                      productivity are key to achieving our ambitious goals.</p>
                    </div>
                  </div>
                  <div className="two-item-block">
                    <div>
                      <h3><span>✓ </span>Communication:</h3>
                      <p>Transparency and open dialogue define our
                      communication philosophy. Whether within teams or with
                      our valued partners and clients, clear and honest
                      communication is paramount. It's the bedrock of trust,
                      ensuring that everyone is on the same page and aligned
                      with our shared vision.</p>
                    </div>
                    <div>
                      <h3><span>✓ </span>Trust and Integrity:</h3>
                      <p>Trust is the foundation of every relationship. We
                      cultivate trust through unwavering integrity. Our
                      actions align with our words, fostering an
                      environment where trust is not just earned but
                      treasured.</p>
                    </div>
                  </div>
                  <div className="two-item-block">
                    <div>
                      <h3><span>✓ </span>Purpose:</h3>
                      <p>We believe in purpose-driven endeavors. Every line of
                      code, every innovation, and every decision is guided by a
                      deeper sense of purpose – to make a meaningful impact.</p>
                    </div>
                    <div>
                      <h3><span>✓ </span>Teamwork:</h3>
                      <p>The synergy of a team is greater than the sum of its
                      parts. We value and encourage teamwork,
                      recognizing that the best solutions emerge through
                      collective effort.</p>
                    </div>
                  </div>
                  <div className="two-item-block">
                    <div>
                      <h3><span>✓ </span>Focus on the Long-Term:</h3>
                      <p>We don't just plan for the next quarter; we envision
                      decades ahead. Our focus on the long-term ensures
                      that every decision, every strategy is crafted with
                      sustainable success in mind.</p>
                    </div>
                    <div>
                      <h3><span>✓ </span>Excellence:</h3>
                      <p>Excellence is not a goal; it's a standard. We strive for
                      nothing short of excellence in every facet of our work.</p>
                    </div>
                  </div>
                  <div className="two-item-block">
                    <div>
                      <h3><span>✓ </span>Performance:</h3>
                      <p>We are results-oriented, driven by a commitment to
                      achieving and exceeding goals. Our focus on
                      performance is not just about meeting expectations; it's
                      about surpassing them consistently over the long-term.
                      Every project, every task is an opportunity to showcase
                      our commitment to excellence.</p>
                    </div>
                    <div>
                      <h3><span>✓ </span>Maximizing Shareholder Value:</h3>
                      <p>We are committed to delivering maximum value to
                      our shareholders as the stewards of capital. Our
                      actions are guided by the understanding that
                      consistent performance is the key to maximizing
                      shareholder value over the long term.</p>
                    </div>
                  </div>
                  <div className="two-item-block">
                    <div>
                      <h3><span>✓ </span>Execution:</h3>
                      <p>Promises kept, expectations exceeded. We prioritize
                      execution that delights customers and builds lasting
                      relationships. We believe in flawless execution. We trust
                      our people to make the correct decisions and take
                      ownership. Execution thrives in an environment of
                      autonomy and accountability.</p>
                    </div>
                    <div>
                      <h3><span>✓ </span>Leadership:</h3>
                      <p>Meet the strong and trustworthy leaders of Flow's
                      executive leadership team.</p>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="padding1">
          {isMobile ? (
            <>
              <ReadMoreCard
                id="ceo-description"
                imageSrc={img2} // Use the appropriate image source
                title="William Hinsche 豐寧 - Chief Executive Officer"
                text={`William Hinsche 豐寧, a luminary in the tech industry, is an American technology CEO, technology sales leader, and a visionary entrepreneur. With nearly a decade's worth of industry experience, Mr. Hinsche has consistently delivered massive long-term value and benefits across various high-tech domains. A trailblazer in navigating globally distributed, complex technical landscapes, Mr. Hinsche is a third-time founder with a trench-proven track record in cloud computing, Platform as a Service (PaaS), Software as a Service (SaaS), artificial intelligence (AI), enterprise software, cybersecurity, telecommunications, finance, and electronic hardware.
                  Having operated in extreme-pressure environments, Mr. Hinsche brings invaluable insights into steering enterprises toward innovation and sustainable growth. His leadership has been instrumental in shaping top-tier technology enterprises, driving them to excel in highly competitive markets. With a profound understanding of the intricacies of technology sales, Mr. Hinsche has mastered the art of creating and maintaining strategic partnerships that yield long-term success.
                  Beyond the boardroom, Mr. Hinsche is recognized for fostering a culture of innovation and excellence. A champion of leveraging technology to solve complex challenges, Mr. Hinsche has consistently pushed the boundaries of what is possible, cementing their reputation as a thought leader in the tech industry.
                  As the guiding force behind Flow, Mr. Hinsche continues to lead with trustworthiness, the highest levels of ethics and integrity, and a relentless commitment to advancing technology, creating unparalleled solutions, and contributing to the broader technological landscape. With a focus on customer value, employee growth, and ecosystem prosperity, Mr. Hinsche remains at the forefront of shaping the future of technology.`}
              />

        <div className="job-cards-container">
              <JobCard
                statusIcon={hiringButton}
                secondaryIcon={person}
                title="Chief Technology Officer:" 
                subtitle={"Co-Founder (Immediate Start)\n***US Citizens Only***"}
                linkText="View" 
                linkUrl="https://example.com/job-link"
              />
              <JobCard
                statusIcon={hiringButton}
                secondaryIcon={person}
                title="Chief Marketing Officer:" 
                subtitle={"Co-Founder (Immediate Start)\n***US Citizens Only***"}
                linkText="View" 
                linkUrl="https://example.com/job-link"
              />
              <JobCard
                statusIcon={hiringButton}
                secondaryIcon={person}
                title="Chief Financial Officer:" 
                subtitle={"Co-Founder (Immediate Start)\n***US Citizens Only***"}
                linkText="View" 
                linkUrl="https://example.com/job-link"
              />
            </div>

                    

            </>
          ) : (
            <>
              <h1 className='subheader_text'>William Hinsche 豐寧 - Chief Executive Officer:</h1>
              <div className="about-text">
                <div>
                  William Hinsche 豐寧, a luminary in the tech industry, is an
                  American technology CEO, technology sales leader, and a
                  visionary entrepreneur. With nearly a decade's worth of
                  industry experience, Mr. Hinsche has consistently
                  delivered massive long-term value and benefits across
                  various high-tech domains. A trailblazer in navigating
                  globally distributed, complex technical landscapes, Mr.
                  Hinsche is a third-time founder with a trench-proven
                  track record in cloud computing, Platform as a Service
                  (PaaS), Software as a Service (SaaS), artificial
                  intelligence (AI), enterprise software, cybersecurity,
                  telecommunications, finance, and electronic hardware.
                  <br /><br />
                  Having operated in extreme-pressure environments, Mr.
                  Hinsche brings invaluable insights into steering
                  enterprises toward innovation and sustainable growth.
                  His leadership has been instrumental in shaping top-tier
                  technology enterprises, driving them to excel in highly
                  competitive markets. With a profound understanding of
                  the intricacies of technology sales, Mr. Hinsche has
                  mastered the art of creating and maintaining strategic
                  partnerships that yield long-term success.
                </div>
                <div>
                  <img src={img2} alt="" className="img2" />
                  <p>
                    Beyond the boardroom, Mr. Hinsche is recognized for
                    fostering a culture of innovation and excellence. A
                    champion of leveraging technology to solve complex
                    challenges, Mr. Hinsche has consistently pushed the
                    boundaries of what is possible, cementing their
                    reputation as a thought leader in the tech industry.
                    <br /><br />
                    As the guiding force behind Flow, Mr. Hinsche
                    continues to lead with trustworthiness, the highest
                    levels of ethics and integrity, and a relentless
                    commitment to advancing technology, creating
                    unparalleled solutions, and contributing to the
                    broader technological landscape. With a focus on
                    customer value, employee growth, and ecosystem
                    prosperity, Mr. Hinsche remains at the
                    forefront of shaping the future of technology.
                  </p>
                </div>
              </div>
            </>
          )}

          
       <div className="padding1" style={isMobile ? { padding: '10px', fontSize: '14px', lineHeight: '1.5' } : {}}>
          <h1 style={isMobile ? { fontSize: '18px', marginBottom: '10px' } : {}}>
            Your trusted partner in Enterprise AI
          </h1>
          <p style={isMobile ? { marginTop: '0' } : {}}>
            At Flow, we understand that trust is the bedrock of successful partnerships. We prioritize the security and
            integrity of your data, ensuring your peace of mind as you harness the power of AI for your sales endeavors.
            Here's why you can trust us with your data:
          </p>
        </div>
        </div>
        <div className={isMobile ? "about-us_section-white" : "about-us_section-grey"}>
        <div className="padding1">
          {isMobile ? (
            <>
              <CollapsibleBox icon={stateofheart} title="State-of-the-Art Security Measures:">
                <ul>
                  <li>Your data is shielded with state-of-the-art encryption protocols, ensuring it remains confidential and protected from unauthorized access.</li>
                  <li>Our commitment to utilizing the latest in cybersecurity methods ensures protection for your information.</li>
                </ul>
              </CollapsibleBox>

              <CollapsibleBox icon={transparent} title="Transparent Data Handling Practices:">
                <ul>
                  <li>Transparency builds trust. Our policies clearly outline how your data is handled, processed, and stored, providing you with a comprehensive understanding of our data management practices.</li>
                </ul>
              </CollapsibleBox>

              <CollapsibleBox icon={encrypted} title="Encrypted Transactions:">
                <ul>
                  <li>Communications and transactions within the Flow platform are encrypted.</li>
                  <li>This ensures that your data remains confidential and protected from potential threats during transit.</li>
                </ul>
              </CollapsibleBox>

              <CollapsibleBox icon={continuous} title="Continuous Monitoring and Auditing:">
                <ul>
                  <li>Our systems undergo continuous monitoring and regular audits to identify and address any potential vulnerabilities.</li>
                  <li>Proactive measures are taken to fortify our defenses, ensuring a secure environment for your data.</li>
                </ul>
              </CollapsibleBox>

              <CollapsibleBox icon={customer} title="Customer-First Approach:">
                <ul>
                  <li>Your trust is earned through our dedication to customer satisfaction.</li>
                  <li>We prioritize your needs, actively seeking feedback to improve our security protocols and enhance your overall experience.</li>
                </ul>
              </CollapsibleBox>
            </>
          ) : (
            <>
              <h3>1. State-of-the-Art Security Measures:</h3>
              <ul>
                <li>Your data is shielded with state-of-the-art encryption protocols, ensuring it remains confidential and protected from unauthorized access.</li>
                <li>Our commitment to utilizing the latest in cybersecurity methods ensures protection for your information.</li>
              </ul>

              <h3>2. Transparent Data Handling Practices:</h3>
              <ul>
                <li>Transparency builds trust. Our policies clearly outline how your data is handled, processed, and stored, providing you with a comprehensive understanding of our data management practices.</li>
              </ul>

              <h3>3. Encrypted Transactions:</h3>
              <ul>
                <li>Communications and transactions within the Flow platform are encrypted.</li>
                <li>This ensures that your data remains confidential and protected from potential threats during transit.</li>
              </ul>

              <h3>4. Continuous Monitoring and Auditing:</h3>
              <ul>
                <li>Our systems undergo continuous monitoring and regular audits to identify and address any potential vulnerabilities.</li>
                <li>Proactive measures are taken to fortify our defenses, ensuring a secure environment for your data.</li>
              </ul>

              <h3>5. Customer-First Approach:</h3>
              <ul>
                <li>Your trust is earned through our dedication to customer satisfaction.</li>
                <li>We prioritize your needs, actively seeking feedback to improve our security protocols and enhance your overall experience.</li>
              </ul>
            </>
          )}
        </div>
      </div>
        <div className="about-us_section-white">
          <div className="reputation">
            {isMobile ? (
              // Mobile Layout
              <>
                <h1 className='subheader_text'>Reputation and accolades: At Flow Turbo, our reputation precedes us.</h1>
                <div><img src={accolades_img} alt="" className="img3" /></div>
                <ul className="overview_ul mobile-layout">
                  <li>
                    <b>Customer Satisfaction:</b>
                    <p>Boasting the highest customer satisfaction rates, Flow Turbo's commitment to excellence is reflected in every success story shared by our clients.</p>
                  </li>
                  <li>
                    <b>Compliance and Certifications:</b>
                    <p>We adhere to the highest standards of data protection and compliance, ensuring that your business operates within a secure and trusted environment.</p>
                  </li>
                  <li>
                    <b>Industry Recognition:</b>
                    <p>Flow Turbo has been honored with prestigious awards, solidifying our position as an industry leader committed to pushing boundaries.</p>
                  </li>
                </ul>
              </>
            ) : (
              // Desktop Layout
              <>
                <div><img src={accolades_img} alt="" className="img3" /></div>
                <div>
                  <h1 className='subheader_text'>Reputation and accolades: At Flow Turbo, our reputation precedes us.</h1>
                  <ul className="overview_ul">
                    <li style={{ paddingBottom: "30px" }}>Customer Satisfaction: Boasting the highest customer satisfaction rates, Flow Turbo's commitment to excellence is reflected in every success story shared by our clients.</li>
                    <li style={{ paddingBottom: "30px" }}>Compliance and Certifications: We adhere to the highest standards of data protection and compliance, ensuring that your business operates within a secure and trusted environment.</li>
                    <li>Industry Recognition: Flow Turbo has been honored with prestigious awards, solidifying our position as an industry leader committed to pushing boundaries.</li>
                  </ul>
                </div>
              </>
            )}
          </div>

          <div className="pledge">
          {isMobile ? (
              <>
              <h1>Our Pledge</h1>
               <h1>to Human Health</h1>
 
                    {/* Render two separate <h3> elements on separate lines for mobile */}
                    <h3>Corporate citizenship:</h3>
                    <h3>Our philanthropic commitments</h3>
                  </>
                ) : (
                  <>
                  <h1>Our Pledge to Human Health</h1>
                  <h3>Corporate citizenship: Our philanthropic commitments</h3>
                  </>
                    )}  
            <ul>
              {isMobile ? (
                <>
                  <CollapsibleSection title="Eradicating Diseases">
                    We are on an effort to contribute to the global effort of eradicating diseases like cancer and Alzheimer's. By supporting organizations dedicated to medical research and disease eradication, we aim to create a healthier future for generations to come.
                  </CollapsibleSection>
                  <CollapsibleSection title="Humanitarian Assistance in Crisis and Conflict Zones">
                    In times of crisis, swift and effective medical aid and healthcare rendering is crucial. Our contributions extend to humanitarian assistance in crisis zones, providing essential medical resources, personnel, and technology to support communities in need.
                  </CollapsibleSection>
                  <CollapsibleSection title="Childhood and Youth Development">
                    Investing in the health and development of children and youth is an investment in the future. Our support extends to programs that focus on childhood nutrition, healthcare, and overall well-being, ensuring a strong foundation for the leaders of tomorrow.
                  </CollapsibleSection>
                </>
              ) : (
                <>
                  <li>
                    <b>Eradicating Diseases:</b> We are on an effort to contribute to the global effort of eradicating diseases like cancer and Alzheimer's. By supporting organizations dedicated to medical research, and disease eradication, we aim to create a healthier future for generations to come.
                  </li>
                  <li>
                    <b>Humanitarian Assistance in Crisis and Conflict Zones:</b> In times of crisis, swift and effective medical aid and healthcare rendering is crucial. Our contributions extend to humanitarian assistance in crisis zones, providing essential medical resources, personnel, and technology to support communities in need.
                  </li>
                  <li>
                    <b>Childhood and Youth Development:</b> Investing in the health and development of children and youth is an investment in the future. Our support extends to programs that focus on childhood nutrition, healthcare, and overall well-being, ensuring a strong foundation for the leaders of tomorrow.
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
};

export default AboutUs;