import React from "react";

import "./App.css";
import { HelmetProvider } from "react-helmet-async";
import AppRouter from "./AppRouter.js";
import { BrowserRouter } from "react-router-dom";

function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
