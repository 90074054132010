import React, { useState } from 'react';
import '../collapsibleBox/collapsibleBox.css'; // Add the styles here
import { SlArrowDown } from "react-icons/sl";
import { SlArrowUp } from "react-icons/sl";

const CollapsibleBox = ({ icon, title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="collapsible-box">
      <div className="collapsible-header" onClick={() => setIsOpen(!isOpen)}>
        <img src={icon} alt="icon" className="collapsible-icon" />
        <span style={{fontSize:'16px'}}className="collapsible-title">{title}</span>
        <span className="collapsible-arrow">
          {isOpen ? <SlArrowUp color="lightgrey" size={20} /> : <SlArrowDown color="lightgrey" size={20} />}
        </span>
      </div>
      {isOpen && <div className="collapsible-content">{children}</div>}
    </div>
  );
};

export default CollapsibleBox;
