import React from 'react'

const GenericCardWhiteBG = ({children}) => {
  return (
    <div className='bg-white h-[415px] w-[300px] rounded-2xl py-10 px-5'>
        {children}
    </div>
  )
}

export default GenericCardWhiteBG