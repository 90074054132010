import { Link, NavLink } from "react-router-dom";
import "./AccountStyles.css";
import { useState } from "react";

function AccountNavigationPanel({ accountID }) {
  const [activeNav, setNavActive] = useState(null);

  const navData = [
    {
      path: "#",
      label: "Overview",
      children: [],
    },
    {
      path: "#",
      label: "Directory",
      children: [
        {
          path: "#",
          label: "Users",
          children: [],
        },
        {
          path: "#",
          label: "Groups",
          children: [],
        },
        {
          path: "#",
          label: "Managed Accounts",
          children: [],
        },
      ],
    },
    {
      path: "#",
      label: "Products",
      children: [
        {
          path: "#",
          label: "Products",
          children: [],
        },
        {
          path: "#",
          label: "User access settings",
          children: [],
        },
        {
          path: "#",
          label: "Product URLs",
          children: [],
        },
        {
          path: "#",
          label: "Release Management",
          children: [
            {
              path: "#",
              label: "Product updates",
            },
            {
              path: "#",
              label: "Release tracks",
            },
            {
              path: "#",
              label: "Sandbox",
            },
          ],
        },
      ],
    },
    {
      path: "#",
      label: "Security",
      children: [
        {
          path: "#",
          label: "Security Guide",
          children: [],
        },
        {
          path: "#",
          label: "User Security",
          children: [
            {
              path: "#",
              label: "Authentication Policies",
            },
            {
              path: "#",
              label: "External Users",
            },
            {
              path: "#",
              label: "Identity Providers",
            },
          ],
        },
        {
          path: "#",
          label: "Data Protection",
          children: [
            {
              path: "#",
              label: "Data Security Policies",
            },
            {
              path: "#",
              label: "Data Residency",
            },
            {
              path: "#",
              label: "BYOK Encryption",
            },
            {
              path: "#",
              label: "IP allowlists",
            },
          ],
        },
        {
          path: "#",
          label: "Monitoring",
          children: [
            {
              path: "#",
              label: "Mobile app policy",
            },
            {
              path: "#",
              label: "Audit log",
            },
            {
              path: "#",
              label: "User API tokens",
            },
            {
              path: "#",
              label: "Discovered products",
            },
            {
              path: "#",
              label: "Product requests",
            },
          ],
        },
      ],
    },
    {
      path: "billing-management",
      label: "Billing",
      children: [
        {
          path: "billing-management",
          label: "Overview",
          children: [
            {
              path: "billing-subs",
              label: "Subscriptions",
            },
            {
              path: "billing-quotes",
              label: "Quotes",
            },
            {
              path: "billing-profiles",
              label: "Billing Profiles",
            },
            {
              path: "billing-payments",
              label: "Payment Method",
            },
            {
              path: "billing-addresses",
              label: "Addresses",
            },
            {
              path: "billing-permissions",
              label: "Billing Permissions",
            },
            {
              path: "billing-invoices",
              label: "Invoices",
            },
          ],
        },
      ],
    },
    {
      path: "#",
      label: "Settings",
      children: [],
    },
  ];

  const handleNavChange = (navActive) => {
    setNavActive(navActive);
  };

  return (
    <div className="navigation-sidebar pr-5 border-r-2 w-[20%]">
      {navData.map((item, index) => (
        <div className="navigation-headings" key={index}>
          <h1
            className={`navigation-heading ${
              activeNav === item.label ? "active" : ""
            }`}
          >
            <Link
              to={`/account/${accountID}/${item.path}`}
              onClick={() => handleNavChange(item.label)}
            >
              {item.label}
            </Link>
          </h1>
          {activeNav === item.label && item.children && (
            <div className="navigation-subheadings">
              {item.children.map((child) => (
                <>
                  <div className="navigation-submain">
                    <NavLink
                      to={`/account/${accountID}/${child.path}`}
                      className={({ isActive }) =>
                        isActive ? "active-subheading" : ""
                      }
                    >
                      {child.label}
                    </NavLink>
                  </div>
                  {child.children &&
                    child.children.map((subChild) => (
                      <div className="navigation-sub">
                        <NavLink
                          to={`/account/${accountID}/${subChild.path}`}
                          className={({ isActive }) =>
                            isActive ? "active-subheading" : ""
                          }
                        >
                          {subChild.label}
                        </NavLink>
                      </div>
                    ))}
                </>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default AccountNavigationPanel;
